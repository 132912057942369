import { useState } from 'react'
import { Text, Select, Stack, Skeleton } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'

import { useClient } from '../../hooks/clients'
import EditCompany from './EditCompany'
import EditPerson from './EditPerson'

// Formulario para editar cliente
const EditClient = () => {
  const [type, setType] = useState('')
  const { clientId } = useParams()
  const {
    data: client,
    isFetching,
    isLoading: loadingGet,
    error
  } = useClient(clientId)

  const handleChangetype = (event) => {
    setType(event.target.value)
  }
  return (
    <Stack spacing={8} py={12} px={6}>
      {client ? (
        <Stack spacing={8} py={12} px={6}>
          <Text fontSize="16px" fontWeight="bold" color="#36355F">
            Tipo de cliente
          </Text>
          <Select
            placeholder="Seleccione"
            name="type"
            onChange={handleChangetype}
            disabled
            value={client.type}
          >
            <option value="COMPANY">Empresa</option>
            <option value="PERSON">Persona</option>
          </Select>
          {client.type ? (
            client.type === 'COMPANY' ? (
              <EditCompany client={client} />
            ) : (
              <EditPerson client={client} />
            )
          ) : (
            <></>
          )}
        </Stack>
      ) : (
        <Stack>
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
        </Stack>
      )}
    </Stack>
  )
}

export default EditClient
