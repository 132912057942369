import { Text, Grid, Button, IconButton, ScaleFade } from '@chakra-ui/react'
import { ArrowForwardIcon, ArrowBackIcon } from '@chakra-ui/icons'

// Paginación de tabla
const Pagination = ({ totalPages, actualPage, setPage }) => {
  const handleBack = async () => {
    const newPage = actualPage - 1
    setPage(newPage)
  }

  const handleNext = async () => {
    const newPage = actualPage + 1
    setPage(newPage)
  }

  const handleSetPage = async (page) => {
    setPage(page)
  }

  return (
    <Grid display="flex" gap={3}>
      <Grid>
        <IconButton
          onClick={handleBack}
          backgroundColor="transparent"
          icon={
            <ArrowBackIcon
              bgColor={'teal.500'}
              borderRadius="5px"
              color="white"
            />
          }
          fontSize="30px"
          disabled={actualPage === 1}
        ></IconButton>
      </Grid>
      {[...Array(totalPages)].map((_, index) => (
        <ScaleFade
          key={index}
          initialScale={0.9}
          in={true}
          whileHover={{ scale: 1.1 }}
        >
          <Button
            mx="5px"
            bgColor={actualPage === index + 1 ? 'teal.500' : 'grey.200'}
            onClick={() => handleSetPage(index + 1)}
            disabled={actualPage === index + 1}
          >
            <Text
              fontSize="16px"
              color={actualPage === index + 1 ? 'white' : 'black'}
            >
              {index + 1}
            </Text>
          </Button>
        </ScaleFade>
      ))}
      <Grid>
        <IconButton
          onClick={handleNext}
          backgroundColor="transparent"
          icon={
            <ArrowForwardIcon
              bgColor={'teal.500'}
              borderRadius="5px"
              color="white"
            />
          }
          fontSize="30px"
          disabled={actualPage === totalPages}
        ></IconButton>
      </Grid>
    </Grid>
  )
}

export default Pagination
