import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  Flex,
  Stack,
  HStack,
  Box,
  Icon,
  Text,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Select
} from '@chakra-ui/react'
import _ from 'lodash'

// import StationsForm from './StationsForm'
import { DeleteIcon } from '../Shared/Icons'
import ToxicTable from './ToxicTable'
import NonToxicTable from './NonToxicTable'
import HaccpTable from './HaccpTable'
import { InfoIcon } from '@chakra-ui/icons'

const types = [
  { id: 1, name: 'Tóxico' },
  // { id: 2, name: 'No tóxico' },
  { id: 3, name: 'HACCP' },
  { id: 4, name: 'No toxico adhesiva' },
  { id: 5, name: 'No toxico captura viva' }
]

// Formulario de ubicación estación de roedores
const LocationsForm = ({
  index,
  values,
  handleChange,
  addStation,
  locationsList,
  handleChangeStation,
  handleChangeStationChecks,
  addStationList,
  handleDeleteStation
}) => {
  const [locationId, setLocationId] = useState(0)
  const [stationExtensions, setStationExtensions] = useState([])

  //Maneja array de estaciones
  useEffect(() => {
    //Si no hay estaciones las crea
    if (values.stations > 0) {
      if (stationExtensions.length === 0) {
        const arr = Array.from({ length: values.stations }, (v) => {
          return { observations: '', checks: ['Active'] }
        })
        setStationExtensions(arr)
        //Si ya exiten estaciones calcula la diferencia
      } else {
        let dif = values.stations - values.stations_data.length
        //Si se agregan más crea nuevas estaciones
        if (dif > 0) {
          const newArr = Array.from({ length: dif }, (v) => {
            return { observations: '', checks: ['Active'] }
          })
          const otherArr = [...values.stations_data]
          setStationExtensions(otherArr.concat(newArr))
          //Si se quitan elimina las de la derecha
        } else {
          let otherArr = [...values.stations_data]
          dif = Math.abs(dif)
          const newArr = _.dropRight(otherArr, dif)
          setStationExtensions(newArr)
        }
      }
    }
  }, [values.stations])

  // Se guardan las estaciones en el estado
  useEffect(() => {
    if (values.stations_data !== stationExtensions) {
      setStationExtensions(values.stations_data)
    }
  }, [])

  // Se agrega una estacion a la lista
  useEffect(() => {
    addStationList(index, stationExtensions)
  }, [stationExtensions])

  const selectLocation = (id) => {
    setLocationId(id)
  }

  // Actualiza la lista de checks seleccionados
  const handleChangeCheck = (event, checks, position) => {
    const { name } = event.target
    let tempCheck = [...checks, name]
    if (checks.includes(name)) {
      tempCheck = tempCheck.filter((per) => per !== name)
    }
    handleChangeStationChecks(tempCheck, index, position)
  }

  return (
    <>
      <Box w="100%" bg="#D1F1F5" pb={5} borderRadius="20px">
        <Flex spacing={10} align="left" pt="15px">
          <Stack spacing={2} pl={30}>
            <Text fontSize="17px" fontWeight="bold" color="#36355F">
              Ubicacion {index + 1}
            </Text>
          </Stack>
        </Flex>
      </Box>
      <FormControl id="location_name" isInvalid={values.location_name === ''}>
        {/* <FormLabel>Nombre de la ubicación</FormLabel>
        <Select
          placeholder="Seleccione"
          name="location_name"
          onChange={handleChange}
          value={values.location_name}
        >
          {locationsList.map((location) => (
            <option key={location.id} value={location.name}>
              {location.name}
            </option>
          ))}
        </Select> */}
        <Input
          name="location_name"
          value={values.location_name}
          onChange={handleChange}
          placeholder="Nombre de la ubicación"
        />
        <FormErrorMessage>Ingrese nombre</FormErrorMessage>
      </FormControl>
      <HStack spacing={8} w="100%">
        <FormControl
          id="type"
          isInvalid={values.type < 0 || values.type === ''}
        >
          <FormLabel>Tipo de Estación de Control</FormLabel>
          <Select
            placeholder="Seleccione"
            name="type"
            value={values.type}
            onChange={handleChange}
          >
            {types.map((type) => (
              <option value={type.id} key={type.id}>
                {type.name}
              </option>
            ))}
          </Select>
          <FormErrorMessage>Seleccione tipo</FormErrorMessage>
        </FormControl>
        <FormControl id="stations" isInvalid={values.stations < 0}>
          <FormLabel>Cantidad de estaciones</FormLabel>
          <Input
            name="stations"
            value={values.stations}
            onChange={handleChange}
            placeholder="Cantidad de estaciones"
            type={'number'}
          />
          <FormErrorMessage>
            {values.stations < 0 && 'N° de estaciones debe ser mayor a 0'}
          </FormErrorMessage>
        </FormControl>
        <Box
          as="button"
          px={4}
          py={2}
          bg="#F2F1FB"
          border="1px"
          borderRadius="lg"
          borderStyle="dashed"
          borderColor="#59BABA"
          display="flex"
          onClick={() => addStation()}
        >
          <Text fontSize="15px" fontWeight={'bold'} color="#59BABA" m="auto">
            +
          </Text>
        </Box>
      </HStack>
      {values.type.toString() === '' && (
        <Flex
          alignItems="center"
          justify="center"
          borderRadius={8}
          bg="blue.100"
          py={5}
          gap={2}
        >
          <Icon as={InfoIcon} />
          <Text fontWeight={700}>Seleccione Tipo de Estación de Control.</Text>
        </Flex>
      )}
      {values.type.toString() === '1' && (
        <ToxicTable
          index={index}
          handleChangeCheck={handleChangeCheck}
          stations_data={values.stations_data}
          stations={values.stations}
          handleChangeStation={handleChangeStation}
          handleDeleteStation={handleDeleteStation}
        />
      )}
      {(values.type.toString() === '5' || values.type.toString() === '4') && (
        <NonToxicTable
          index={index}
          handleChangeCheck={handleChangeCheck}
          stations_data={values.stations_data}
          stations={values.stations}
          handleChangeStation={handleChangeStation}
          handleDeleteStation={handleDeleteStation}
        />
      )}
      {values.type.toString() === '3' && (
        <HaccpTable
          index={index}
          handleChangeCheck={handleChangeCheck}
          stations_data={values.stations_data}
          stations={values.stations}
          handleChangeStation={handleChangeStation}
          handleDeleteStation={handleDeleteStation}
        />
      )}
    </>
  )
}

export default LocationsForm
