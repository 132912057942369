import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

// Home redirige a la pantalla inicial correspondiendo el usuario
const Home = () => {
  const history = useHistory()
  const { user } = useSelector((state) => state.auth)
  useEffect(() => {
    if (user.id_role === 1 || user.id_role === 4) {
      history.push(`/documentos`)
    }
    if (user.id_role === 2 || user.id_role === 3) {
      history.push(`/visitas`)
    }
  }, [])

  return null
}

export default Home
