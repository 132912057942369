import { useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Stack,
  Button,
  Text,
  Link,
  Flex,
  Image,
  useToast
} from '@chakra-ui/react'

import Logo from '../../assets/images/logo.svg'
import { useMutateAuth } from '../../hooks/auth'

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Ingrese email válido').required('Ingrese correo')
})

// Formulario de recuperación de contraseña
const Recover = () => {
  const toast = useToast()
  const [sent, setSent] = useState(false)
  const { mutate, isLoading, reset } = useMutateAuth('RESET')

  const formik = useFormik({
    validationSchema,
    initialValues: {
      email: ''
    },
    onSubmit: (values) => {
      mutate(values, {
        onSuccess: () => {
          reset()
          formik.resetForm()
          setSent(true)
        },
        onError: (err) => {
          reset()
          console.log(err)
          if (err.response.status === 403) {
            toast({
              title: `Correo no registrado`,
              status: 'error',
              isClosable: true
            })
          } else {
            toast({
              title: `Error al generar reinicio de contraseña`,
              status: 'error',
              isClosable: true
            })
          }
        }
      })
    }
  })
  return (
    <Stack spacing={8} mx={'auto'} py={12} px={6}>
      {sent ? (
        <>
          <Flex h="10" alignItems="center" justifyContent="center" mb={20}>
            <Image src={Logo} alt="Logo" boxSize="250px" />
          </Flex>
          <Stack align={'left'}>
            <Heading fontSize={'4xl'}>Verifica tu correo</Heading>
          </Stack>
          <Box rounded={'lg'} bg={'#FFF'}>
            <Stack spacing={4} minW={'xs'}>
              <Box my={2}>
                <Text fontSize="17px" color="#36355F">
                  Hemos enviado a tu correo las instrucciones para recuperar tu
                  contraseña.
                </Text>
              </Box>
              <Box my={2}>
                <Text fontSize="17px" color="#36355F">
                  ¿No haz recibido el correo? Revisa tu bandeja de Spam o
                </Text>
                <Link
                  onClick={() => setSent(false)}
                  color={'teal.500'}
                  fontSize="17"
                >
                  intenta con otra dirección de correo
                </Link>
              </Box>
            </Stack>
          </Box>
        </>
      ) : (
        <>
          <Stack align={'left'}>
            <Heading fontSize={'4xl'}>Recupera tu contraseña</Heading>
          </Stack>
          <Box rounded={'lg'} bg={'#FFF'}>
            <Stack spacing={4} minW={'xs'}>
              <Box my={2}>
                <Text fontSize="17px" color="#36355F">
                  Introduce el correo asociado a tu cuenta y enviaremos las
                  instrucciones para que puedas recuperar tu contraseña
                </Text>
              </Box>
              <FormControl
                id="email"
                isRequired
                isInvalid={formik.touched.email && Boolean(formik.errors.email)}
              >
                <FormLabel>Correo Electrónico</FormLabel>
                <Input
                  borderRadius="30px"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <FormErrorMessage>
                  {formik.touched.email && formik.errors.email}
                </FormErrorMessage>
              </FormControl>
              <Stack spacing={10} align={'center'}>
                <Button
                  isLoading={isLoading}
                  onClick={formik.handleSubmit}
                  bg={'teal.500'}
                  color={'white'}
                  _hover={{
                    bg: 'teal.600'
                  }}
                  disabled={isLoading}
                  // leftIcon={success && <SuccessIcon />}
                >
                  Enviar
                </Button>
              </Stack>
            </Stack>
          </Box>
        </>
      )}
    </Stack>
  )
}

export default Recover
