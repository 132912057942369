import React from 'react'
import { Link } from 'react-router-dom'
import { Flex, Icon } from '@chakra-ui/react'

import Can from '../../auth/Can'

import {
  ClientsIcon,
  VisitsIcon,
  UsersIcon,
  DocumentsIcon,
  ReportabilityIcon
} from './MenuIcons'

//Administrador = 1
//Operario = 2
//Supervisor = 3
//Capataz = 4
const linkItems = [
  {
    key: 'menu_item_documents',
    to: '/documentos',
    title: 'Documentos',
    icon: DocumentsIcon,
    availableTo: [4, 1, 2]
  },
  {
    key: 'menu_item_visits',
    to: '/visitas',
    title: 'Visitas',
    icon: VisitsIcon,
    availableTo: [3, 2, 1]
  },
  {
    key: 'menu_item_reportability',
    to: '/reportabilidad',
    title: 'Reportabilidad',
    icon: ReportabilityIcon,
    availableTo: [1]
  },
  {
    key: 'menu_item_clients',
    to: '/clientes',
    title: 'Clientes',
    icon: ClientsIcon,
    availableTo: [3, 2, 1]
  },
  {
    key: 'menu_item_users',
    to: '/usuarios',
    title: 'Usuarios',
    icon: UsersIcon,
    availableTo: [1]
  },
  {
    key: 's3_files',
    to: '/archivos_s3',
    title: 'Archivos S3',
    icon: DocumentsIcon,
    availableTo: [1]
  },
  {
    key: 'products',
    to: '/productos',
    title: 'Productos',
    icon: DocumentsIcon,
    availableTo: [1]
  }
]

const Menu = () =>
  linkItems.map((link) => (
    <Can
      availableTo={link.availableTo}
      yes={() => (
        <Link key={link.key} to={link.to}>
          <Flex
            align="center"
            p="4"
            mx="4"
            borderRadius="lg"
            role="group"
            cursor="pointer"
            color="#36355F"
            fontWeight="bold"
            _hover={{
              bg: 'teal.500',
              color: 'white'
            }}
          >
            {link.icon && <Icon mr="4" fontSize="22" as={link.icon} />}
            {link.title}
          </Flex>
        </Link>
      )}
    />
  ))

export default Menu
