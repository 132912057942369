import { useSelector, useDispatch } from 'react-redux'
import {
  Text,
  Select,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  HStack,
  Icon,
  IconButton,
  Box,
  Image,
  Link,
  Flex
} from '@chakra-ui/react'

import Regions from '../../resources/Regions'
import { DeleteIcon } from '../Shared/Icons'
import UploadPDF from '../../components/Shared/UploadPDF'
import { getUrlPdf } from '../../store/slices/fileUpload'
import addFileIcon from '../../assets/images/addFile.svg'

// Formulario de sucursal
const BranchForm = ({
  index,
  values,
  handleChange,
  handleDeleteBranch,
  handleChangePdf
}) => {
  const dispatch = useDispatch()

  // lista de comunas de la region seleccionada
  const communes = (region) => {
    const communesList = Regions.find((data) => data.number === region)
    return communesList ? communesList.communes : []
  }

  //Lógica de subida de pdf
  const pdfUpload = async (formPDF) => {
    try {
      const response = await dispatch(getUrlPdf(formPDF))
      if (response) {
        handleChangePdf(response, index)
      }
    } catch (error) {
      console.error(error)
    }
  }

  //Obtener nombre de imagen
  const getFilename = (img) => {
    const index = img.lastIndexOf('/') + 1
    const filename = img.substr(index)
    return filename
  }

  const removePdf = () => {
    handleChangePdf('', index)
  }

  return (
    <>
      <HStack justify={'space-between'}>
        <Text fontSize="20px" fontWeight="bold" color="#36355F" pt="50px">
          Sucursal {index + 1}
        </Text>
        <IconButton
          icon={
            <Icon
              fontSize="22"
              as={DeleteIcon}
              color="#59BABA"
              bg="#D1F1F5"
              mx={20}
            />
          }
          bg="#D1F1F5"
          onClick={handleDeleteBranch}
        />
      </HStack>
      <FormControl id="name" isInvalid={values.name === ''}>
        <FormLabel>Nombre de la sucursal</FormLabel>
        <Input
          name="name"
          value={values.name}
          onChange={handleChange}
          placeholder="Nombre de la sucursal"
        />
        <FormErrorMessage>Ingrese nombre</FormErrorMessage>
      </FormControl>
      <FormControl id="address" isInvalid={values.address === ''}>
        <FormLabel>Dirección</FormLabel>
        <Input
          name="address"
          value={values.address}
          onChange={handleChange}
          placeholder="Dirección"
        />
        <FormErrorMessage>Ingrese dirección</FormErrorMessage>
      </FormControl>
      <FormControl id="region" isInvalid={values.region === ''}>
        <FormLabel>Región</FormLabel>
        <Select
          placeholder="Seleccione"
          name="region"
          onChange={handleChange}
          value={values.region}
        >
          {Regions.map((region) => (
            <option value={region.number} key={region.number}>
              {region.name}
            </option>
          ))}
        </Select>
        <FormErrorMessage>Seleccione región</FormErrorMessage>
      </FormControl>
      <FormControl id="commune" isInvalid={values.commune === ''}>
        <FormLabel>Comuna</FormLabel>
        <Select
          placeholder="Seleccione"
          name="commune"
          onChange={handleChange}
          value={values.commune}
        >
          {communes(values.region).map((commune) => (
            <option value={commune.identifier} key={commune.identifier}>
              {commune.name}
            </option>
          ))}
        </Select>
        <FormErrorMessage>Seleccione comuna</FormErrorMessage>
      </FormControl>
      {values.pdf ? (
        <Box w="100%" pb={5} borderRadius={'lg'} boxShadow={'md'}>
          <HStack justify="space-between">
            <Image src={addFileIcon} pl={2} />
            <Link href={values.pdf} isExternal>
              {getFilename(values.pdf)}
            </Link>
            <IconButton
              icon={<Icon fontSize="22" as={DeleteIcon} />}
              bg="transparent"
              onClick={removePdf}
            />
          </HStack>
        </Box>
      ) : (
        <UploadPDF pdfUpload={pdfUpload} />
      )}
    </>
  )
}

export default BranchForm
