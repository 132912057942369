import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  Flex,
  Stack,
  Heading,
  Button,
  Text,
  Box,
  Spacer,
  Skeleton,
  useToast,
  Grid,
  FormControl,
  FormLabel,
  Select,
  Input
} from '@chakra-ui/react'
import { ArrowForwardIcon } from '@chakra-ui/icons'

import DailyTable from '../../components/DailyActivities/DailyTable'
import Pagination from '../../components/DailyActivities/Pagination'
import PreVisits from '../../components/Visits/PreVisits'
import { useDailies } from '../../hooks/dailyActivities'
import { useChecklistsVehicles } from '../../hooks/checkVehicles'
import { useChecklistsTools } from '../../hooks/checklistTools'
import { useEPPs } from '../../hooks/epp'
import { useWeeklyDocs } from '../../hooks/visits'

import {
  updateFilterList,
  updateCurrentList
} from '../../store/slices/document'

const types = [
  { id: 1, name: 'Reporte diario' },
  { id: 2, name: 'Checklist vehículo' },
  { id: 3, name: 'Checklist herramientas' },
  { id: 4, name: 'Inspección EPP' }
]

// Página principal de documentos de semanales
const Documents = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const toast = useToast()
  const { user } = useSelector((state) => state.auth)
  const { filterList, currentList } = useSelector((state) => state.document)
  const [docsState, setDocsState] = useState(false)
  const [vehiclePlate, setVehiclePlate] = useState('')
  const {
    data: docs,
    isLoading: loadingDocs,
    error: errorDocs
  } = useWeeklyDocs({ userId: user.id, plate: vehiclePlate })
  const {
    data: dailies,
    error: errorDailies,
    refetch: fetchDaily
  } = useDailies(filterList.page, {
    userId: filterList.userId,
    document: filterList.document,
    startDate: filterList.startDate,
    endDate: filterList.endDate
  })
  const {
    data: checkVehicles,
    error: errorVehicles,
    refetch: fetchVehicles
  } = useChecklistsVehicles(filterList.page, {
    userId: filterList.userId,
    document: filterList.document,
    startDate: filterList.startDate,
    endDate: filterList.endDate
  })
  const {
    data: checkTools,
    error: errorTools,
    refetch: fetchTools
  } = useChecklistsTools(filterList.page, {
    userId: filterList.userId,
    document: filterList.document,
    startDate: filterList.startDate,
    endDate: filterList.endDate
  })
  const {
    data: epps,
    error: errorEpps,
    refetch: fetchEpps
  } = useEPPs(filterList.page, {
    userId: filterList.userId,
    document: filterList.document,
    startDate: filterList.startDate,
    endDate: filterList.endDate
  })

  // Fecha actual
  const currentDate = () => {
    const newDate = new Date().toISOString().slice(0, -14)
    return newDate
  }

  useEffect(() => {
    if (errorDailies) {
      toast({
        title: `Error al cargar actividades diarias`,
        status: 'error',
        isClosable: true
      })
    } else {
      toast.closeAll()
    }
  }, [errorDailies])

  useEffect(() => {
    dispatch(
      updateFilterList({
        userId: user.id_role ? null : user.id,
        document: user.id_role === 2 ? '2' : '1'
      })
    )
  }, [user])

  // Determina que lista de documentos debe mostrar
  useEffect(() => {
    if (filterList.document === '1') {
      dispatch(updateCurrentList(dailies))
    }
    if (filterList.document === '2') {
      dispatch(updateCurrentList(checkVehicles))
    }
    if (filterList.document === '3') {
      dispatch(updateCurrentList(checkTools))
    }
    if (filterList.document === '4') {
      dispatch(updateCurrentList(epps))
    }
  }, [dailies, checkVehicles, checkTools, epps])

  // Realiza el fetch dependiendo de los filtros utilizados
  useEffect(() => {
    async function fetch() {
      if (filterList.document.toString() === '1') {
        await fetchDaily()
        /* setDocument({ document: 'dailies', page: page }) */
      }
      if (filterList.document.toString() === '2') {
        await fetchVehicles()
        /* setDocument({ document: 'vehicles', page: page }) */
      }
      if (filterList.document.toString() === '3') {
        await fetchTools()
        /* setDocument({ document: 'tools', page: page }) */
      }
      if (filterList.document.toString() === '4') {
        await fetchEpps()
        /* setDocument({ document: 'epps', page: page }) */
      }
    }
    fetch()
  }, [filterList])

  const handleClick = () => {
    history.push(`/diario`)
  }

  // Determina si los documentos semanales fueron completados
  const checkDocs = () => {
    if (docs.vehicle && docs.tools && docs.epp) {
      setDocsState(true)
    } else {
      setDocsState(false)
    }
  }

  const handleChangeGlobalState = (fieldName, value) => {
    dispatch(
      updateFilterList({
        page: 1,
        [fieldName]: value
      })
    )
  }

  useEffect(() => {
    if (docs) {
      checkDocs()
    }
  }, [docs])

  return (
    <Stack spacing={8} px={6}>
      <Flex bg={'#FFF'}>
        <Stack spacing={8}>
          <Stack align={'left'}>
            <Heading fontSize={'4xl'} color={'#36355F'}>
              Buenos días
            </Heading>
            <Text fontSize="15px" fontWeight="bold" color="#36355F" m="auto">
              {user.name}
            </Text>
          </Stack>
        </Stack>
      </Flex>
      {!docsState && docs && (
        <PreVisits
          docs={docs}
          vehiclePlate={vehiclePlate}
          setVehiclePlate={setVehiclePlate}
        />
      )}
      {(docsState || user.id_role === 1) && user.role !== 2 && (
        <Box rounded={'lg'} bg={'#FFF'} w={'100%'} px={2} boxShadow={'md'}>
          <Text
            fontSize={['15px', '25px']}
            fontWeight="bold"
            color="#36355F"
            m="auto"
          >
            Completa el registro de actividades diarias
          </Text>
          <Flex spacing={10} align={'right'} my={2}>
            <Spacer />
            <Button
              onClick={handleClick}
              bg={'teal.500'}
              color={'white'}
              _hover={{
                bg: 'teal.600'
              }}
              rightIcon={<ArrowForwardIcon />}
            >
              Iniciar
            </Button>
          </Flex>
        </Box>
      )}
      <Box w="100%">
        <Flex spacing="10px" align="left">
          <FormControl id="document" flex="2">
            <FormLabel>Documento</FormLabel>
            <Select
              placeholder="Documento"
              name="document"
              onChange={(e) =>
                handleChangeGlobalState('document', e.target.value)
              }
              defaultValue={filterList?.document}
            >
              {types.map((t) => (
                <option value={t.id} key={t.id}>
                  {t.name}
                </option>
              ))}
            </Select>
          </FormControl>
          <Spacer />
          <FormControl id="startDate" flex="2">
            <FormLabel>Desde</FormLabel>
            <Input
              name="startDate"
              value={filterList.startDate}
              onChange={(e) =>
                handleChangeGlobalState('startDate', e.target.value)
              }
              type="date"
              max={currentDate()}
            />
          </FormControl>
          <Spacer />
          <FormControl id="endDate" flex="2">
            <FormLabel>Hasta</FormLabel>
            <Input
              name="endDate"
              value={filterList.endDate}
              onChange={(e) =>
                handleChangeGlobalState('endDate', e.target.value)
              }
              type="date"
              max={currentDate()}
            />
          </FormControl>
          {/*           <Button
            onClick={formik.handleSubmit}
            bg={'teal.500'}
            color={'white'}
            _hover={{
              bg: 'teal.600'
            }}
            leftIcon={<Search2Icon />}
            flex="1"
          >
            Buscar
          </Button> */}
        </Flex>
      </Box>
      {currentList ? (
        <>
          <DailyTable data={currentList.items} document={filterList.document} />
          <Grid m="auto" pt="2%" alignSelf={'center'}>
            <Pagination
              totalPages={Math.ceil(currentList.total / 10)}
              actualPage={currentList.page}
              setPage={(page) => {
                dispatch(
                  updateFilterList({
                    page
                  })
                )
              }}
            />
          </Grid>
        </>
      ) : (
        <Stack>
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
        </Stack>
      )}
    </Stack>
  )
}

export default Documents
