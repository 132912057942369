import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import {
  Flex,
  Stack,
  HStack,
  Box,
  Button,
  Icon,
  Spacer,
  Text,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  useDisclosure,
  Select,
  Skeleton,
  useToast
} from '@chakra-ui/react'
import { ArrowBackIcon } from '@chakra-ui/icons'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import _ from 'lodash'

import { InsectIcon } from '../../components/Shared/Icons'
import AreaForm from './AreaForm'
import ConfirmModal from '../Shared/ConfirmModal'
import { useMutateTuv, useTuv } from '../../hooks/tuv'

const frecuencies = [
  { name: 'Semanal', id: 1 },
  { name: 'Quincenal', id: 2 },
  { name: 'Mensual', id: 3 },
  { name: 'Bimensual', id: 4 }
]

const validationSchema = Yup.object().shape({
  frequency: Yup.string().required('Ingrese frecuencia'),
  period: Yup.number().required('Ingrese periodo'),
  areas: Yup.number().required('Ingrese áreas')
})

// Formulario TUV
const TUV = () => {
  const toast = useToast()
  const { tuvId } = useParams()
  const history = useHistory()
  const { data: tuv } = useTuv(tuvId)
  const [confirm, setConfirm] = useState(false)
  const { client, branch } = useSelector((state) => state.visits)
  const {
    isOpen: openConfirm,
    onOpen: onOpenConfirm,
    onClose: closeConfirm
  } = useDisclosure()
  const { mutate, isLoading: loadingUpdate, reset } = useMutateTuv('UPDATE')

  // Guarda en el estado las áreas

  const formik = useFormik({
    validationSchema,
    enableReinitialize: true,
    initialValues: {
      frequency: tuv?.frequency,
      period: tuv?.period,
      areas: tuv?.areas,
      finalObservations: tuv?.final_observations,
      tuv_areas: tuv?.tuv_areas
        ? tuv?.tuv_areas.filter((area) => area.state === 'ACTIVE')
        : [],
      deleted_tuv_areas: []
    },
    onSubmit: (values) => {
      const formData = {
        id: tuv.id,
        frequency: values.frequency,
        period: values.period,
        areas: values.tuv_areas.length,
        final_observations: values.finalObservations,
        tuv_areas: values.tuv_areas,
        deleted_tuv_areas: values.deleted_tuv_areas
      }
      mutate(formData, {
        onSuccess: () => {
          reset()
          formik.resetForm()
          setConfirm(true)
        },
        onError: (err) => {
          reset()
          console.log(err)
          if (err.request.response.includes('ERROR_SAVING_TUV')) {
            toast({
              title: `Error al guardar datos`,
              status: 'error',
              isClosable: true
            })
          } else if (err.request.response.includes('ERROR_GENERATING_PDF')) {
            toast({
              title: `Error al crear PDF, datos guardados sin problemas`,
              status: 'error',
              isClosable: true
            })
          } else {
            toast({
              title: `Error indefinido`,
              status: 'error',
              isClosable: true
            })
          }
        }
      })
    }
  })

  // Actualizar atributo de un área
  const handleChange = (event, position) => {
    const { name, value } = event.target
    const newArray = [...formik.values.tuv_areas]
    newArray[position] = {
      ...newArray[position],
      [name]: value
    }
    formik.setFieldValue('tuv_areas', newArray)
  }

  // Agregar estación al área
  const addStation = (position) => {
    const newArray = [...formik.values.tuv_areas]
    newArray[position] = {
      ...newArray[position],
      stations: parseInt(newArray[position].stations, 10) + 1
    }
    formik.setFieldValue('tuv_areas', newArray)
  }

  // Actualizar la lista de estaciones de un área
  const addStationList = (position, stationsList) => {
    const newArray = [...formik.values.tuv_areas]
    newArray[position] = {
      ...newArray[position],
      tuv_stations: stationsList
    }
    formik.setFieldValue('tuv_areas', newArray)
  }

  // Actualizar atributo de una estación
  const handleChangeStation = (event, position1, position2) => {
    const { name, value } = event.target
    const newArray = _.cloneDeep(formik.values.tuv_areas)
    newArray[position1].tuv_stations[position2] = {
      ...newArray[position1].tuv_stations[position2],
      [name]: value
    }
    formik.setFieldValue('tuv_areas', newArray)
  }

  //Actualiza los formularios booleanos
  const handleChangeStationBools = (value, name, position1, position2) => {
    if (formik.values.tuv_areas) {
      const newArray = _.cloneDeep(formik.values.tuv_areas)
      newArray[position1].tuv_stations[position2] = {
        ...newArray[position1].tuv_stations[position2],
        [name]: value
      }
      formik.setFieldValue('tuv_areas', newArray)
    }
  }

  //Borra una estación de la lista
  const handleDeleteStation = (position1, position2) => {
    const newArray = _.cloneDeep(formik.values.tuv_areas)
    newArray[position1].tuv_stations.splice(position2, 1)
    newArray[position1] = {
      ...newArray[position1],
      stations: parseInt(newArray[position1].stations, 10) - 1
    }
    formik.setFieldValue('tuv_areas', newArray)
  }

  const handleBack = () => {
    history.push(`/visitas/${tuv.id_visit}`)
  }

  const handleClickConfirm = () => {
    onOpenConfirm()
  }

  const handleAddNewArea = () => {
    const tuv_areas_copy = _.cloneDeep(formik.values.tuv_areas)
    const schema = { area_name: '', stations: 0, tuv_stations: [] }
    formik.setFieldValue('tuv_areas', [...tuv_areas_copy, schema])
  }

  const handleDeleteArea = (index) => {
    const tuv_areas_copy = _.cloneDeep(formik.values.tuv_areas)
    const [removedArea] = tuv_areas_copy.splice(index, 1)
    formik.setFieldValue('tuv_areas', tuv_areas_copy)

    if (removedArea.id) {
      formik.setFieldValue('deleted_tuv_areas', [
        ...formik.values.deleted_tuv_areas,
        removedArea.id
      ])
    }
  }

  return (
    <Stack spacing={8} py={12} px={6}>
      <Flex spacing={10} align="left" pt="15px">
        <Button
          onClick={handleBack}
          bg="#FFFFFF"
          color="#666587"
          border="1px"
          borderStyle="solid"
          borderRadius="lg"
          borderColor="#F2F1FB"
          leftIcon={<ArrowBackIcon />}
        >
          Documentos de visita
        </Button>
      </Flex>
      {tuv ? (
        <>
          <Box w="100%" bg="#F2F1FB80" pb={5}>
            <Flex spacing={10} align="left" pt="15px">
              <Stack spacing={2} pl={30}>
                <Text fontSize="10px" color="#36355F">
                  Cliente
                </Text>
                <Text fontSize="17px" fontWeight="bold" color="#36355F">
                  {client}
                </Text>
              </Stack>
              <Stack spacing={2} pl={30}>
                <Text fontSize="10px" color="#36355F">
                  Sucursal
                </Text>
                <Text fontSize="17px" fontWeight="bold" color="#36355F">
                  {branch}
                </Text>
              </Stack>
            </Flex>
          </Box>
          <Box w="100%" bg="#F2F1FB" pb={5}>
            <Flex spacing={10} align="left" pt="15px">
              <Stack spacing={2} pl={30}>
                <Text fontSize="15px" color="#36355F">
                  <Icon mr="4" fontSize="22" as={InsectIcon} color="#36355F" />
                  TUV para insectos voladores
                </Text>
              </Stack>
              <Spacer />
              <Stack spacing={2} pr={30}>
                <Text fontSize="10px" color="#36355F">
                  Serial {tuv.id}
                </Text>
              </Stack>
            </Flex>
          </Box>
          <FormControl
            id="frequency"
            isInvalid={
              formik.touched.frequency && Boolean(formik.errors.frequency)
            }
          >
            <FormLabel>Frecuencia</FormLabel>
            <Select
              placeholder="Seleccione"
              name="frequency"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.frequency}
            >
              {frecuencies.map((period) => (
                <option value={period.id} key={period.id}>
                  {period.name}
                </option>
              ))}
            </Select>
            <FormErrorMessage>
              {formik.touched.frequency && formik.errors.frequency}
            </FormErrorMessage>
          </FormControl>
          <HStack spacing={8} w="100%">
            <FormControl
              id="period"
              isInvalid={formik.touched.period && Boolean(formik.errors.period)}
            >
              <FormLabel>Periodo</FormLabel>
              <Input
                name="period"
                value={formik.values.period}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Periodo"
              />
              <FormErrorMessage>
                {formik.touched.period && formik.errors.period}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              id="areas"
              isInvalid={formik.touched.areas && Boolean(formik.errors.areas)}
            >
              <FormLabel>Cantidad de áreas</FormLabel>
              <Input
                name="areas"
                value={formik.values.tuv_areas.length}
                onChange={formik.handleChange}
                disabled
                onBlur={formik.handleBlur}
                placeholder="Cantidad de áreas"
              />
              <FormErrorMessage>
                {formik.touched.areas && formik.errors.areas}
              </FormErrorMessage>
            </FormControl>
          </HStack>
          <Button onClick={handleAddNewArea}>Agregar area</Button>
          {formik.values.tuv_areas.map((area, idx) => (
            <AreaForm
              key={idx}
              index={idx}
              values={area}
              locationsList={tuv.client_locations}
              handleChange={(event) => handleChange(event, idx)}
              addStation={() => addStation(idx)}
              handleChangeStation={handleChangeStation}
              addStationList={addStationList}
              handleChangeStationBools={handleChangeStationBools}
              handleDeleteStation={handleDeleteStation}
              handleDeleteArea={() => handleDeleteArea(idx)}
            />
          ))}
          <Stack spacing={10} align={'center'}>
            <Button
              align="center"
              isLoading={loadingUpdate}
              onClick={handleClickConfirm}
              bg="#FFFFFF"
              color="#59BABA"
              border="1px"
              borderColor="#59BABA"
              disabled={loadingUpdate}
            >
              Guardar documento
            </Button>
            {openConfirm && (
              <ConfirmModal
                isOpen={openConfirm}
                onClose={closeConfirm}
                route={`/visitas/${tuv.id_visit}`}
                confirm={confirm}
                setConfirm={setConfirm}
                handleSubmit={formik.handleSubmit}
                loading={loadingUpdate}
              />
            )}
          </Stack>
        </>
      ) : (
        <Stack>
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
        </Stack>
      )}
    </Stack>
  )
}

export default TUV
