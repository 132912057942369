import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Flex,
  Box,
  Stack,
  HStack,
  Heading,
  Button,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Text,
  Avatar,
  VStack,
  Grid,
  GridItem,
  Input,
  IconButton,
  Select,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Icon,
  useDisclosure,
  useToast
} from '@chakra-ui/react'

import Categories from '../../resources/Categories'
import { OptionsIcon } from '../../components/Shared/Icons'
import { useMutateClient } from '../../hooks/clients'
import DeleteModal from '../Shared/DeleteModal'

// Tabla de lista de clientes
const ClientsTable = ({ clients }) => {
  const history = useHistory()
  const toast = useToast()
  const [selectedClient, setSelectedClient] = useState(null)
  const { mutate } = useMutateClient('DELETE')
  const {
    isOpen: openDelete,
    onOpen: onOpenDelete,
    onClose: closeDelete
  } = useDisclosure()

  // Eliminar usuario
  const handleDelete = (clientId) => {
    mutate(clientId, {
      onSuccess: () => {
        toast({
          title: `Cliente eliminado`,
          status: 'success',
          isClosable: true
        })
      },
      onError: () => {
        toast({
          title: `Error al eliminar cliente`,
          status: 'error',
          isClosable: true
        })
      }
    })
    closeDelete()
  }

  // Obtener catogría
  const getCategory = (id) => {
    const category = Categories.find((cat) => {
      return cat.id.toString() === id
    })
    return category
  }

  const handleClickDelete = (client) => {
    setSelectedClient(client)
    onOpenDelete()
  }

  const handleClickEdit = (client) => {
    history.push(`/clientes/${client.id}`)
  }

  const handleClickSelect = (client) => {
    history.push(`/clientes/perfil/${client.id}`)
  }

  return (
    <Flex bg={'#FFF'}>
      <Box w="100%">
        <TableContainer>
          <Table variant="simple" size="sm">
            <Thead>
              <Tr>
                <Th color="#36355F" fontWeight={'normal'}>
                  Cliente
                </Th>
                <Th color="#36355F" fontWeight={'normal'}>
                  Categoría
                </Th>
                <Th color="#36355F" fontWeight={'normal'}>
                  Tipo
                </Th>
                <Th color="#36355F" fontWeight={'normal'}>
                  Opciones
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {clients.map((client) => (
                <Tr>
                  <Td>
                    <HStack>
                      <VStack>
                        <Text
                          fontSize={['13px', '20px']}
                          color="#36355F"
                          fontWeight="bold"
                        >
                          {client.company_name || client.name}
                        </Text>
                      </VStack>
                    </HStack>
                  </Td>
                  <Td>
                    <Text
                      fontSize={['10px', '15px']}
                      color="#36355F"
                      fontWeight="bold"
                    >
                      {getCategory(client.category)?.name}
                    </Text>
                  </Td>
                  <Td>
                    <Text
                      fontSize={['10px', '15px']}
                      color="#36355F"
                      fontWeight="bold"
                    >
                      {client.type === 'COMPANY' ? 'Empresa' : 'Persona'}
                    </Text>
                  </Td>
                  <Td>
                    <Menu>
                      <MenuButton
                        as={Button}
                        bg="#FFFFFF"
                        leftIcon={
                          <Icon
                            fontSize="22"
                            as={OptionsIcon}
                            color="#374957"
                          />
                        }
                      />
                      <MenuList>
                        <MenuItem onClick={() => handleClickSelect(client)}>
                          Ver
                        </MenuItem>
                        <MenuItem onClick={() => handleClickEdit(client)}>
                          Editar
                        </MenuItem>
                        <MenuItem onClick={() => handleClickDelete(client)}>
                          Eliminar
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
      {openDelete && (
        <DeleteModal
          isOpen={openDelete}
          onClose={closeDelete}
          message="¿Esta seguro de querer eliminar el cliente?"
          handleDelete={handleDelete}
          selected={selectedClient}
        />
      )}
    </Flex>
  )
}

export default ClientsTable
