import { useState } from 'react'
import Select from 'react-select'
import { Box, Button, Flex, Grid, GridItem, IconButton } from '@chakra-ui/react'
import { IoMdAddCircleOutline as AddIcon } from 'react-icons/io'
import { TiDeleteOutline as DelIcon } from 'react-icons/ti'
import { useGetAll } from '../../../hooks/reactQuery'
import pathProduct from '../../../paths/products/products'
import selectProducts from './adapters/selectProducts'
import { SimpleInput } from '../../Shared/inputs'
import { Nodata } from '../../UI'

const DynamicProductForm = ({
  queryFilters,
  defaultUnits = {
    value: 'U',
    quantity: 'Gr'
  },
  emptyMsg,
  fieldName,
  formik
}) => {
  const [currentProduct, setCurrentProduct] = useState(null)
  const { data: products, isLoading } = useGetAll({
    getAllProps: {
      endPoint: pathProduct['GET-ALL'],
      querys: {
        ...queryFilters
      }
    },
    isPaginated: false,
    key: `LIST-PRODUCTS${queryFilters?.serviceName}`,
    adapterData: selectProducts
  })

  const addProductToForm = () => {
    if (currentProduct) {
      const newProductInForm = formik.values[fieldName]
      const { id, requiresQuantity, commercial, unit } = currentProduct
      newProductInForm.push({
        requiresQuantity,
        value: 0,
        quantity: 0,
        productUnit: unit,
        productCommercialName: commercial,
        productId: id
      })

      formik.setFieldValue(fieldName, newProductInForm)
    }
  }

  const deleteProductToForm = (productId) => {
    const newPeoducts = formik.values[fieldName]?.filter(
      (pro) => pro.productId !== productId
    )

    formik.setFieldValue(fieldName, newPeoducts)
  }

  const onChangeProductItem = (value, index, isQuantity = false) => {
    const newProducts = [...formik.values[fieldName]]

    newProducts[index] = {
      ...newProducts[index],
      [isQuantity ? 'quantity' : 'value']: value
        ? parseInt(value, 10)
        : undefined
    }
    formik.setFieldValue(fieldName, newProducts)
  }

  return (
    <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
      <GridItem>
        {formik.values[fieldName]?.length === 0 && (
          <Nodata customMsg={emptyMsg} />
        )}

        {formik.values[fieldName]?.map((product, index) => (
          <Flex key={product.productId} gap={2} alignItems="center">
            <SimpleInput
              label={product.productCommercialName}
              value={product.value}
              type="number"
              errors={Boolean(formik?.errors?.[fieldName]?.[index]?.value)}
              errorMsg={formik?.errors?.[fieldName]?.[index]?.value}
              onChange={(e) => onChangeProductItem(e.target.value, index)}
              min={0}
              iconsProps={{
                right: {
                  iconText: product?.productUnit || defaultUnits?.value
                }
              }}
            />
            {product?.requiresQuantity && (
              <SimpleInput
                label={'Cantidad'}
                value={product.quantity}
                type="number"
                errors={Boolean(formik?.errors?.[fieldName]?.[index]?.quantity)}
                errorMsg={formik?.errors?.[fieldName]?.[index]?.quantity}
                onChange={(e) =>
                  onChangeProductItem(e.target.value, index, true)
                }
                min={0}
                iconsProps={{
                  right: {
                    iconText: defaultUnits?.quantity
                  }
                }}
              />
            )}

            <IconButton
              aria-label="Borrar producto"
              mt="auto"
              bg="red.500"
              color="white"
              icon={<DelIcon size={30} />}
              onClick={() => deleteProductToForm(product.productId)}
            />
          </Flex>
        ))}
      </GridItem>
      <GridItem ml="auto">
        <Flex gap={2}>
          <Box w={{ lg: 300 }}>
            <Select
              className="basic-single"
              classNamePrefix="select"
              placeholder="Seleccione"
              menuPortalTarget={document.querySelector('body')}
              isDisabled={isLoading}
              isLoading={isLoading}
              isClearable={false}
              isRtl={false}
              isSearchable={true}
              name="area"
              options={products || []}
              onChange={(item) => setCurrentProduct(item)}
            />
          </Box>
          <Button
            leftIcon={<AddIcon />}
            colorScheme="teal"
            variant="solid"
            onClick={addProductToForm}
            disabled={formik.values[fieldName]?.find(
              (pro) => pro.productId === currentProduct?.id
            )}
          >
            Agregar
          </Button>
        </Flex>
      </GridItem>
    </Grid>
  )
}

export default DynamicProductForm
