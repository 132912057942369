import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Text,
  Select,
  Box,
  Stack,
  HStack,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  InputGroup,
  InputLeftAddon,
  Flex,
  Button,
  Spacer,
  useDisclosure,
  useToast,
  Checkbox
} from '@chakra-ui/react'
import * as Yup from 'yup'
import { useFormik } from 'formik'

import Regions from '../../resources/Regions'
import { useMutateClient } from '../../hooks/clients'
import ConfirmModal from '../Shared/ConfirmModal'
import { RutInput } from '../Shared/inputs'
import EmailsContainerForms from './EmailsContainerForms'

const categories = [
  {
    id: 1,
    name: 'Categoría 1'
  },
  {
    id: 2,
    name: 'Categoría 2'
  },
  {
    id: 3,
    name: 'Categoría 3'
  },
  {
    id: 4,
    name: 'Categoría 4'
  },
  {
    id: 5,
    name: 'Categoría 5'
  },
  {
    id: 6,
    name: 'Categoría 6'
  }
]

Yup.addMethod(Yup.array, 'uniqueEmails', function (message) {
  return this.test('unique-emails', message, function (value) {
    const seenEmails = new Set()
    for (let i = 0; i < value.length; i++) {
      const email = value[i].email
      if (seenEmails.has(email)) {
        return this.createError({
          path: `emails[${i}].email`,
          message: message
        })
      }
      seenEmails.add(email)
    }
    return true
  })
})

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Ingrese nombre y apellido'),
  ruc: Yup.string().required('Ingrese rut'),
  phone: Yup.string().required('Ingrese teléfono'),
  address: Yup.string().required('Ingrese dirección'),
  region: Yup.number().required('Ingrese region'),
  commune: Yup.string().required('Ingrese comuna'),
  emails: Yup.array()
    .of(
      Yup.object().shape({
        email: Yup.string()
          .email('Ingrese un correo electrónico válido')
          .required('Ingrese un correo electrónico válido'),
        key: Yup.string()
      })
    )
    .required('Ingrese al menos un correo electrónico')
    .uniqueEmails('Los correos electrónicos no pueden repetirse')
})

// FOrmulario de creación de cliente tipo
const PersonForm = () => {
  const toast = useToast()
  const [confirm, setConfirm] = useState(false)
  const [checks, setChecks] = useState([])
  const { user } = useSelector((state) => state.auth)
  const { mutate, isLoading, reset } = useMutateClient('CREATE')
  const {
    isOpen: openConfirm,
    onOpen: onOpenConfirm,
    onClose: closeConfirm
  } = useDisclosure()

  const formik = useFormik({
    validationSchema,
    initialValues: {
      categoryId: '',
      name: '',
      ruc: '',
      phone: '',
      address: '',
      region: '',
      commune: '',
      emails: []
    },
    onSubmit: (values) => {
      const formData = {
        type: 'PERSON',
        category: values.categoryId,
        name: values.name,
        ruc: values.ruc,
        phone: values.phone,
        company_name: null,
        bussines_name: null,
        emails: values.emails?.map((email) => email.email),
        address: values.address,
        region: values.region,
        commune: values.commune,
        branchs: [],
        documents: checks,
        created_by: user.id
      }

      mutate(formData, {
        onSuccess: () => {
          reset()
          formik.resetForm()
          setConfirm(true)
        },
        onError: (err) => {
          reset()
          console.log(err)
          toast({
            title: `Error al crear cliente`,
            status: 'error',
            isClosable: true
          })
        }
      })
    }
  })

  // Lista de comunas de la región seleccionada
  const communes = (region) => {
    const communesList = Regions.find((data) => data.number === region)
    return communesList ? communesList.communes : []
  }

  const selectCategory = (id) => {
    formik.setFieldValue('categoryId', id)
  }

  const handleClickConfirm = () => {
    onOpenConfirm()
  }

  // Actualizar lista de documentos seleccionados
  const handleChangeChecks = (event) => {
    const { name } = event.target
    let tempCheck = [...checks, name]
    if (checks.includes(name)) {
      tempCheck = tempCheck.filter((per) => per !== name)
    }
    setChecks(tempCheck)
  }

  return (
    <>
      <Text fontSize="20px" fontWeight="bold" color="#36355F">
        Seleccione categoría
      </Text>
      <HStack
        spacing="24px"
        overflowX={'scroll'}
        css={{
          '&::-webkit-scrollbar': {
            display: 'none'
          }
        }}
      >
        {categories.map((data) => (
          <Box
            as="button"
            h="50px"
            w="150%"
            bg={formik.values.categoryId === data.id ? '#A4D4DA' : '#D1F1F5'}
            borderRadius="lg"
            key={data.id}
            display="flex"
            onClick={() => selectCategory(data.id)}
          >
            <Text
              fontSize="15px"
              fontWeight={formik.values.categoryId === data.id ? 'bold' : ''}
              color="#36355F"
              m="auto"
            >
              {data.name}
            </Text>
          </Box>
        ))}
      </HStack>
      <Text fontSize="14px" color="red" pl={2}>
        {formik.values.categoryId === '' && 'Seleccione una categoría'}
      </Text>
      <FormControl
        id="name"
        isInvalid={formik.touched.name && Boolean(formik.errors.name)}
      >
        <FormLabel>Nombre y apellido</FormLabel>
        <Input
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder="Nombre y apellido"
        />
        <FormErrorMessage>
          {formik.touched.name && formik.errors.name}
        </FormErrorMessage>
      </FormControl>
      <RutInput
        fieldName="ruc"
        formik={formik}
        isInvalid={formik.touched.ruc && Boolean(formik.errors.ruc)}
        label="Rut"
        value={formik.values.ruc}
        errorMessage={formik.errors.ruc}
      />
      <FormControl
        id="phone"
        isInvalid={formik.touched.phone && Boolean(formik.errors.phone)}
      >
        <FormLabel>Teléfono</FormLabel>
        <InputGroup>
          <InputLeftAddon children="+56" />
          <Input
            name="phone"
            value={formik.values.phone}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Teléfono"
          />
        </InputGroup>
        <FormErrorMessage>
          {formik.touched.phone && formik.errors.phone}
        </FormErrorMessage>
      </FormControl>
      <FormControl
        id="address"
        isInvalid={formik.touched.address && Boolean(formik.errors.address)}
      >
        <FormLabel>Dirección</FormLabel>
        <Input
          name="address"
          value={formik.values.address}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder="Dirección"
        />
        <FormErrorMessage>
          {formik.touched.address && formik.errors.address}
        </FormErrorMessage>
      </FormControl>
      <FormControl
        id="region"
        isInvalid={formik.touched.region && Boolean(formik.errors.region)}
      >
        <FormLabel>Región</FormLabel>
        <Select
          placeholder="Seleccione"
          name="region"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        >
          {Regions.map((region) => (
            <option value={region.number}>{region.name}</option>
          ))}
        </Select>
        <FormErrorMessage>
          {formik.touched.region && formik.errors.region}
        </FormErrorMessage>
      </FormControl>
      <FormControl
        id="commune"
        isInvalid={formik.touched.commune && Boolean(formik.errors.commune)}
      >
        <FormLabel>Comuna</FormLabel>
        <Select
          placeholder="Seleccione"
          name="commune"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        >
          {communes(formik.values.region).map((commune) => (
            <option value={commune.identifier}>{commune.name}</option>
          ))}
        </Select>
        <FormErrorMessage>
          {formik.touched.commune && formik.errors.commune}
        </FormErrorMessage>
      </FormControl>
      {/* array emails */}
      <EmailsContainerForms
        emails={formik.values?.emails}
        setEmails={(emails) => formik.setFieldValue('emails', emails)}
        errors={formik.errors?.emails}
      />
      {/* --- */}
      <Text fontSize="20px" fontWeight="bold" color="#36355F" pt="50px">
        Documentos a enviar
      </Text>
      <HStack justify={'space-between'}>
        <HStack justify={'space-between'} w={['100%', '15%']}>
          <Text fontSize="14px" color="#36355F">
            Registro estación control de roedores
          </Text>
          <Checkbox
            size="md"
            colorScheme="blue"
            name="Rodents"
            isChecked={checks.includes('Rodents')}
            onChange={handleChangeChecks}
            borderColor="grey"
          ></Checkbox>
        </HStack>
        <HStack justify={'space-between'} w={['100%', '15%']}>
          <Text fontSize="14px" color="#36355F">
            TUV para insectos voladores
          </Text>
          <Checkbox
            size="md"
            colorScheme="blue"
            name="TUV"
            isChecked={checks.includes('TUV')}
            onChange={handleChangeChecks}
            borderColor="grey"
          ></Checkbox>
        </HStack>
        <HStack justify={'space-between'} w={['100%', '15%']}>
          <Text fontSize="14px" color="#36355F">
            Control de calidad
          </Text>
          <Checkbox
            size="md"
            colorScheme="blue"
            name="Quality"
            isChecked={checks.includes('Quality')}
            onChange={handleChangeChecks}
            borderColor="grey"
          ></Checkbox>
        </HStack>
        <HStack justify={'space-between'} w={['100%', '15%']}>
          <Text fontSize="14px" color="#36355F">
            Reporte de visita
          </Text>
          <Checkbox
            size="md"
            colorScheme="blue"
            name="Visits"
            isChecked={checks.includes('Visits')}
            onChange={handleChangeChecks}
            borderColor="grey"
          ></Checkbox>
        </HStack>
      </HStack>
      <Flex spacing={10} align="right" pt="15px">
        <Spacer />
        <Stack spacing={10} align={'center'}>
          <Button
            align="center"
            isLoading={isLoading}
            onClick={handleClickConfirm}
            bg="#FFFFFF"
            color="#59BABA"
            border="1px"
            borderColor="#59BABA"
            disabled={isLoading}
          >
            Crear cliente
          </Button>
          {openConfirm && (
            <ConfirmModal
              isOpen={openConfirm}
              onClose={closeConfirm}
              route={`/clientes`}
              confirm={confirm}
              setConfirm={setConfirm}
              handleSubmit={formik.handleSubmit}
              loading={isLoading}
            />
          )}
        </Stack>
      </Flex>
    </>
  )
}

export default PersonForm
