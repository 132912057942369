import { Flex, Grid, GridItem, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'
import _ from 'underscore'

//@utils
import { getRandomColor } from '../../../utils/getRandomColor'

//@adapters
import { getNoToxicGraphData } from '../adapter/getDashboar'

//@components
import { Nodata } from '../../UI'
import Labels from './Labels'

// Gráfico de roedores
const RodentsGraph = ({ data, type }) => {
  const [formaterData, setFormaterData] = useState({
    graphData: [],
    labelsData: [],
    totalLocations: 0
  })

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <Flex
          bg={'white'}
          p={2}
          border={`1px dashed #319795`}
          direction={'column'}
          textAlign={'center'}
          className="custom-tooltip"
          borderRadius={8}
        >
          <Text fontWeight={700}>{`${label}`}</Text>
          <Text>
            <strong>{`Total, Ubicaciones Tratadas: ${payload[0]?.payload?.total}`}</strong>
          </Text>
        </Flex>
      )
    }

    return null
  }

  useEffect(() => {
    const {
      graph: graphData,
      labels: labelsData,
      totalLocations
    } = getNoToxicGraphData(data, type)

    setFormaterData({
      graphData,
      labelsData,
      totalLocations
    })
  }, [data, type])

  return (
    <>
      {formaterData?.totalLocations > 0 ? (
        <Grid
          templateColumns={{
            base: 'repeat(1, 1fr)',
            lg: 'repeat(2, 70% 30%)'
          }}
          gap={2}
        >
          <GridItem>
            <ResponsiveContainer width="100%" height={400}>
              <BarChart width={'100%'} data={formaterData?.graphData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month" />
                <YAxis />
                <Tooltip content={<CustomTooltip />} />
                <Bar
                  dataKey="total"
                  stackId="a"
                  fill={getRandomColor()}
                  radius={8}
                  stroke="black"
                  maxBarSize={50}
                />
              </BarChart>
            </ResponsiveContainer>
          </GridItem>
          <GridItem>
            <Labels data={formaterData?.labelsData} />
          </GridItem>
        </Grid>
      ) : (
        <Grid templateColumns={'repeat(1, 1fr)'}>
          <GridItem>
            <Nodata />
          </GridItem>
        </Grid>
      )}
    </>
  )
}

export default RodentsGraph
