const selectProducts = (products = []) => {
  return products
    ?.map((product) => ({
      value: product.id,
      label: product.commercial,
      ...product
    }))
    ?.sort((a, b) => a.id - b.id)
}

export default selectProducts
