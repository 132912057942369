import { Checkbox, Flex } from '@chakra-ui/react'

const CheckInput = ({ label, name, value, onChange, ...props }) => {
  return (
    <Flex gap={5} justify="start" alignItems="start" w="100%">
      <Checkbox
        name={name}
        colorScheme="teal"
        isChecked={value}
        onChange={onChange}
        {...props}
      >
        {label}
      </Checkbox>
    </Flex>
  )
}

export default CheckInput
