export const getNoToxicGraphData = (data, type) => {
  const graph = data?.map((obj) => {
    const total = obj?.areas?.reduce(
      (acc, cur) =>
        acc +
        (type === 'TOXIC' ? cur?.total_locations : cur?.total_locations || 0),
      0
    )
    return { month: obj?.date, total }
  })

  const labels = data?.flatMap((obj) =>
    obj?.areas?.map((areaObj) => ({
      area: areaObj?.area,
      value:
        type === 'TOXIC'
          ? areaObj?.total_locations || 0
          : areaObj?.total_locations || 0
    }))
  )

  const totalLocations = graph?.reduce((acc, item) => {
    return acc + item?.total
  }, 0)

  return {
    graph: graph || [],
    labels: labels || [],
    totalLocations: totalLocations || 0
  }
}
