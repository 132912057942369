import axios from '../Axios'

export const getControls = async (page = 1, size = 10) => {
  const { data } = await axios.get(`quality/all?page=${page}&size=${size}`)
  return data
}

export const getOneControl = async (controlId) => {
  const { data } = await axios.get(`quality/get-one?id_control=${controlId}`)
  return data
}

export const createControl = async (formData) => {
  const { data } = await axios.post(`quality/`, formData)
  return data
}

export const updateControl = async (formData) => {
  console.log(formData)
  const { data } = await axios.patch(`quality/`, formData)
  return data
}

export const deleteControl = async (controlId) => {
  const { data } = await axios.delete(`quality/?id=${controlId}`)
  return data
}
