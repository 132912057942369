import { createIcon } from '@chakra-ui/react'

export const DashboardIcon = createIcon({
  displayName: 'DashboardIcon',
  viewBox: '0 0 20 20',
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: [
    <path
      d="M2.28964 11.1348H7.00246C7.38874 11.1348 7.75614 10.9667 8.02478 10.6727C8.29301 10.3792 8.44165 9.98379 8.44165 9.57399V2.41202C8.44165 2.00222 8.29301 1.60679 8.02478 1.31327C7.75614 1.01929 7.38874 0.851251 7.00246 0.851251H2.28964C1.90336 0.851251 1.53596 1.01929 1.26732 1.31327C0.999095 1.60679 0.850458 2.00222 0.850458 2.41202V9.57399C0.850458 9.98379 0.999095 10.3792 1.26732 10.6727C1.53596 10.9667 1.90336 11.1348 2.28964 11.1348ZM2.98668 3.15501H6.31273V8.8389L2.98668 8.83674V3.15501Z"
      fill="#59BABA"
      stroke="#59BABA"
      strokeWidth="0.3"
    />,
    <path
      d="M16.1504 8.85986H11.4371C11.0509 8.85986 10.6834 9.0279 10.4148 9.32187C10.1466 9.6154 9.99795 10.0108 9.99795 10.4206V17.5789C9.99795 17.782 10.0345 17.9835 10.1058 18.1718C10.1771 18.3602 10.2819 18.5322 10.4148 18.6776C10.5478 18.8231 10.7063 18.9393 10.8819 19.0189C11.0574 19.0984 11.2462 19.1396 11.4371 19.1396H16.1504C16.3414 19.1396 16.5301 19.0984 16.7057 19.0189C16.8812 18.9393 17.0398 18.8231 17.1727 18.6776C17.3056 18.5322 17.4104 18.3602 17.4817 18.1718C17.553 17.9835 17.5896 17.782 17.5896 17.5789V10.4206C17.5896 10.0108 17.4409 9.6154 17.1727 9.32187C16.9041 9.0279 16.5367 8.85986 16.1504 8.85986ZM12.132 11.1589H15.4555V16.84L12.132 16.8387V11.1589Z"
      fill="#59BABA"
      stroke="#59BABA"
      strokeWidth="0.3"
    />,
    <path
      d="M9.99209 2.41074V5.56521C9.99209 5.97501 10.1407 6.37044 10.409 6.66396C10.6776 6.95794 11.045 7.12598 11.4313 7.12598H16.151C16.5373 7.12598 16.9047 6.95794 17.1733 6.66396C17.4415 6.37044 17.5902 5.97501 17.5902 5.56521V2.41074C17.5902 2.00094 17.4415 1.60551 17.1733 1.31199C16.9047 1.01801 16.5373 0.849969 16.151 0.849969H11.4313C11.045 0.849969 10.6776 1.01801 10.409 1.31199C10.1407 1.60551 9.99209 2.00094 9.99209 2.41074ZM12.1245 3.15796H15.4496V4.82316H12.1245V3.15796Z"
      fill="#59BABA"
      stroke="#59BABA"
      strokeWidth="0.3"
    />,
    <path
      d="M2.28919 19.15H7.00503C7.196 19.15 7.38473 19.1088 7.5603 19.0292C7.73584 18.9497 7.8944 18.8335 8.02735 18.688C8.16027 18.5425 8.26507 18.3706 8.33636 18.1822C8.40765 17.9939 8.44421 17.7924 8.44421 17.5892V14.4244C8.44421 14.0146 8.29557 13.6192 8.02735 13.3257C7.75871 13.0317 7.39131 12.8636 7.00503 12.8636H2.28919C1.9029 12.8636 1.5355 13.0317 1.26686 13.3257C0.998637 13.6192 0.85 14.0146 0.85 14.4244V17.5892C0.85 17.7924 0.886557 17.9939 0.957848 18.1822C1.02914 18.3706 1.13394 18.5425 1.26686 18.688C1.39981 18.8335 1.55838 18.9497 1.73391 19.0292C1.90948 19.1088 2.09821 19.15 2.28919 19.15ZM2.98107 15.1721H6.31271V16.8368H2.98107V15.1721Z"
      fill="#59BABA"
      stroke="#59BABA"
      strokeWidth="0.3"
    />
  ]
})

export const DocumentsIcon = createIcon({
  displayName: 'DocumentsIcon',
  viewBox: '0 0 16 20',
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: [
    <path
      d="M12 10.4167C12 10.7482 11.8736 11.0661 11.6485 11.3006C11.4235 11.535 11.1182 11.6667 10.8 11.6667H5.2C4.88174 11.6667 4.57651 11.535 4.35147 11.3006C4.12642 11.0661 4 10.7482 4 10.4167C4 10.0852 4.12642 9.76721 4.35147 9.53279C4.57651 9.29837 4.88174 9.16668 5.2 9.16668H10.8C11.1182 9.16668 11.4235 9.29837 11.6485 9.53279C11.8736 9.76721 12 10.0852 12 10.4167ZM8.39999 13.3333H5.2C4.88174 13.3333 4.57651 13.465 4.35147 13.6995C4.12642 13.9339 4 14.2518 4 14.5833C4 14.9149 4.12642 15.2328 4.35147 15.4672C4.57651 15.7016 4.88174 15.8333 5.2 15.8333H8.39999C8.71825 15.8333 9.02348 15.7016 9.24852 15.4672C9.47356 15.2328 9.59999 14.9149 9.59999 14.5833C9.59999 14.2518 9.47356 13.9339 9.24852 13.6995C9.02348 13.465 8.71825 13.3333 8.39999 13.3333ZM16 6.79751V15.4167C15.9987 16.6318 15.5347 17.7969 14.7099 18.6561C13.885 19.5154 12.7666 19.9987 11.6 20H4.4C3.23343 19.9987 2.11502 19.5154 1.29013 18.6561C0.465248 17.7969 0.00127053 16.6318 0 15.4167V4.58335C0.00127053 3.36818 0.465248 2.20316 1.29013 1.34391C2.11502 0.484652 3.23343 0.00134198 4.4 1.85091e-05H9.47439C10.0524 -0.00169862 10.6251 0.116077 11.1591 0.346522C11.6932 0.576967 12.178 0.915502 12.5856 1.34252L14.7112 3.55668C15.121 3.98128 15.446 4.48634 15.6672 5.04263C15.8884 5.59891 16.0015 6.19538 16 6.79751ZM13.6 15.4167V7.50001H10.4C9.97564 7.50001 9.56868 7.32442 9.26862 7.01186C8.96856 6.6993 8.79999 6.27537 8.79999 5.83335V2.50002H4.4C3.86956 2.50002 3.36086 2.71951 2.98578 3.11021C2.61071 3.50091 2.4 4.03081 2.4 4.58335V15.4167C2.4 15.9692 2.61071 16.4991 2.98578 16.8898C3.36086 17.2805 3.86956 17.5 4.4 17.5H11.6C12.1304 17.5 12.6391 17.2805 13.0142 16.8898C13.3893 16.4991 13.6 15.9692 13.6 15.4167Z"
      fill="#59BABA"
    />
  ]
})

export const VisitsIcon = createIcon({
  displayName: 'VisitsIcon',
  viewBox: '0 0 16 20',
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: [
    <path
      d="M12 10.4167C12 10.7482 11.8736 11.0661 11.6485 11.3006C11.4235 11.535 11.1182 11.6667 10.8 11.6667H5.2C4.88174 11.6667 4.57651 11.535 4.35147 11.3006C4.12642 11.0661 4 10.7482 4 10.4167C4 10.0852 4.12642 9.76721 4.35147 9.53279C4.57651 9.29837 4.88174 9.16668 5.2 9.16668H10.8C11.1182 9.16668 11.4235 9.29837 11.6485 9.53279C11.8736 9.76721 12 10.0852 12 10.4167ZM8.39999 13.3333H5.2C4.88174 13.3333 4.57651 13.465 4.35147 13.6995C4.12642 13.9339 4 14.2518 4 14.5833C4 14.9149 4.12642 15.2328 4.35147 15.4672C4.57651 15.7016 4.88174 15.8333 5.2 15.8333H8.39999C8.71825 15.8333 9.02348 15.7016 9.24852 15.4672C9.47356 15.2328 9.59999 14.9149 9.59999 14.5833C9.59999 14.2518 9.47356 13.9339 9.24852 13.6995C9.02348 13.465 8.71825 13.3333 8.39999 13.3333ZM16 6.79751V15.4167C15.9987 16.6318 15.5347 17.7969 14.7099 18.6561C13.885 19.5154 12.7666 19.9987 11.6 20H4.4C3.23343 19.9987 2.11502 19.5154 1.29013 18.6561C0.465248 17.7969 0.00127053 16.6318 0 15.4167V4.58335C0.00127053 3.36818 0.465248 2.20316 1.29013 1.34391C2.11502 0.484652 3.23343 0.00134198 4.4 1.85091e-05H9.47439C10.0524 -0.00169862 10.6251 0.116077 11.1591 0.346522C11.6932 0.576967 12.178 0.915502 12.5856 1.34252L14.7112 3.55668C15.121 3.98128 15.446 4.48634 15.6672 5.04263C15.8884 5.59891 16.0015 6.19538 16 6.79751ZM13.6 15.4167V7.50001H10.4C9.97564 7.50001 9.56868 7.32442 9.26862 7.01186C8.96856 6.6993 8.79999 6.27537 8.79999 5.83335V2.50002H4.4C3.86956 2.50002 3.36086 2.71951 2.98578 3.11021C2.61071 3.50091 2.4 4.03081 2.4 4.58335V15.4167C2.4 15.9692 2.61071 16.4991 2.98578 16.8898C3.36086 17.2805 3.86956 17.5 4.4 17.5H11.6C12.1304 17.5 12.6391 17.2805 13.0142 16.8898C13.3893 16.4991 13.6 15.9692 13.6 15.4167Z"
      fill="#59BABA"
    />
  ]
})

export const ReportabilityIcon = createIcon({
  displayName: 'ReportabilityIcon',
  viewBox: '0 0 18 18',
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: [
    <path
      d="M2.87505 17.325C2.27505 17.325 1.75838 17.1083 1.32505 16.675C0.891715 16.2417 0.675049 15.725 0.675049 15.125V10.875C0.675049 10.275 0.891715 9.75832 1.32505 9.32499C1.75838 8.89165 2.27505 8.67499 2.87505 8.67499C3.49172 8.67499 4.02105 8.89165 4.46305 9.32499C4.90438 9.75832 5.12505 10.275 5.12505 10.875V15.125C5.12505 15.725 4.90438 16.2417 4.46305 16.675C4.02105 17.1083 3.49172 17.325 2.87505 17.325ZM9.00005 17.325C8.40005 17.325 7.88338 17.1083 7.45005 16.675C7.01672 16.2417 6.80005 15.725 6.80005 15.125V2.87499C6.80005 2.27499 7.01271 1.75832 7.43805 1.32499C7.86272 0.891654 8.38338 0.674988 9.00005 0.674988C9.60005 0.674988 10.1167 0.891654 10.55 1.32499C10.9834 1.75832 11.2 2.27499 11.2 2.87499V15.125C11.2 15.725 10.9874 16.2417 10.562 16.675C10.1374 17.1083 9.61672 17.325 9.00005 17.325ZM15.125 17.325C14.5084 17.325 13.9794 17.1083 13.538 16.675C13.096 16.2417 12.875 15.725 12.875 15.125V7.87499C12.875 7.27499 13.096 6.75832 13.538 6.32499C13.9794 5.89165 14.5084 5.67499 15.125 5.67499C15.725 5.67499 16.2417 5.89165 16.675 6.32499C17.1084 6.75832 17.3251 7.27499 17.3251 7.87499V15.125C17.3251 15.725 17.1084 16.2417 16.675 16.675C16.2417 17.1083 15.725 17.325 15.125 17.325Z"
      fill="#59BABA"
    />
  ]
})

export const ClientsIcon = createIcon({
  displayName: 'ClientsIcon',
  viewBox: '0 0 24 20',
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: [
    <path
      d="M17.0999 6.99999H19.0999V4.99999H17.0999V6.99999ZM17.0999 11H19.0999V8.99999H17.0999V11ZM17.0999 15H19.0999V13H17.0999V15ZM17.0999 19.2V16.8H20.8749V3.19999H12.2999V4.47499L9.9499 2.74999C10.0332 2.18332 10.2999 1.71665 10.7499 1.34999C11.1999 0.983321 11.7166 0.799988 12.2999 0.799988H20.6499C21.3832 0.799988 22.0082 1.05832 22.5249 1.57499C23.0416 2.09165 23.2999 2.71665 23.2999 3.44999V16.55C23.2999 17.2833 23.0416 17.9083 22.5249 18.425C22.0082 18.9417 21.3832 19.2 20.6499 19.2H17.0999ZM3.2999 19.2C2.58324 19.2 1.96257 18.9417 1.4379 18.425C0.912569 17.9083 0.649902 17.2833 0.649902 16.55V10.25C0.649902 9.81665 0.749902 9.41232 0.949902 9.03699C1.1499 8.66232 1.4249 8.34999 1.7749 8.09999L6.3499 4.84999C6.81657 4.51665 7.3249 4.34999 7.8749 4.34999C8.4249 4.34999 8.93324 4.51665 9.3999 4.84999L13.9749 8.09999C14.3249 8.34999 14.5999 8.66232 14.7999 9.03699C14.9999 9.41232 15.0999 9.81665 15.0999 10.25V16.55C15.0999 17.2833 14.8416 17.9083 14.3249 18.425C13.8082 18.9417 13.1832 19.2 12.4499 19.2H3.2999ZM3.0999 16.8H5.8749V11.8H9.8749V16.8H12.6499V10.125L7.8749 6.74999L3.0999 10.125V16.8Z"
      fill="#59BABA"
    />
  ]
})

export const UsersIcon = createIcon({
  displayName: 'UsersIcon',
  viewBox: '0 0 20 20',
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: [
    <path
      d="M7.11388 10.4651C4.16557 10.4481 1.89504 8.13108 1.92046 5.17748C1.95435 2.33422 4.35196 -0.0252579 7.18166 0.000204168C10.0537 0.0256662 12.3666 2.35968 12.3666 5.23689C12.3666 8.19049 10.0707 10.4821 7.11388 10.4651ZM9.52844 5.22841C9.51149 3.96379 8.41012 2.85195 7.16471 2.84346C5.88542 2.83498 4.74168 3.98925 4.75015 5.27933C4.75862 6.58638 5.88542 7.65579 7.21554 7.62184C8.51178 7.58789 9.54538 6.51848 9.52844 5.21992V5.22841Z"
      fill="#59BABA"
    />,
    <path
      d="M7.17318 11.3902C8.12206 11.4496 9.07941 11.4327 10.0113 11.5939C12.4259 12.0013 14.1203 13.9534 14.2559 16.3977C14.2898 17.0937 14.2898 17.7982 14.2728 18.4941C14.2389 19.4956 13.7137 20.0218 12.8241 19.9964C11.9938 19.9709 11.4855 19.4362 11.4516 18.5026C11.4347 17.9594 11.4516 17.4247 11.4516 16.8815C11.4177 15.3538 10.5112 14.3523 8.99469 14.2929C7.79165 14.242 6.58013 14.242 5.37709 14.2929C3.80127 14.3523 2.88628 15.3453 2.86087 16.9325C2.84392 17.569 2.89475 18.2056 2.8185 18.8336C2.73378 19.5466 2.28476 19.9539 1.53921 19.9964C0.810608 20.0388 0.192142 19.7078 0.115892 19.0119C-0.138272 16.6948 -0.138272 14.4117 1.86962 12.7058C3.10656 11.6533 4.57224 11.3732 6.13958 11.4411C6.48694 11.4581 6.8343 11.4411 7.19013 11.4411V11.3817L7.17318 11.3902Z"
      fill="#59BABA"
    />,
    <path
      d="M18.0853 5.30479C18.0684 7.63032 16.5773 9.62485 14.366 10.2614C13.4934 10.516 12.7987 10.2105 12.5022 9.44662C12.2056 8.68275 12.5276 7.91889 13.3748 7.62184C14.5101 7.22293 15.2302 6.50151 15.2387 5.27084C15.2387 4.04018 14.5355 3.31875 13.3917 2.91136C12.5445 2.61431 12.2141 1.85044 12.4937 1.08658C12.7733 0.322723 13.468 0.000204175 14.3576 0.23785C16.5264 0.806502 18.0938 2.94531 18.0768 5.30479H18.0853Z"
      fill="#59BABA"
    />,
    <path
      d="M19.9915 17.4502C19.9915 17.7642 19.9915 18.0867 19.9915 18.4008C19.9576 19.4787 19.4578 20.0218 18.5428 19.9964C17.6786 19.9709 17.1873 19.4192 17.1703 18.4177C17.1618 17.9085 17.1703 17.3993 17.1703 16.8985C17.1703 15.6763 16.6027 14.87 15.4589 14.4372C14.4846 14.0637 14.1034 13.3423 14.4169 12.5445C14.7303 11.7382 15.4759 11.4666 16.4671 11.7976C18.797 12.57 20 14.4881 20 17.4672L19.9915 17.4502Z"
      fill="#59BABA"
    />
  ]
})

export const LogOutIcon = createIcon({
  displayName: 'LogOutIcon',
  viewBox: '0 0 20 20',
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: [
    <path
      d="M13.1 14.7249C12.85 14.4582 12.725 14.1456 12.725 13.7869C12.725 13.4289 12.85 13.1249 13.1 12.8749L14.65 11.3249H8.07495C7.70828 11.3249 7.39562 11.1959 7.13695 10.9379C6.87895 10.6792 6.74995 10.3666 6.74995 9.9999C6.74995 9.63324 6.87895 9.32057 7.13695 9.0619C7.39562 8.8039 7.70828 8.6749 8.07495 8.6749H14.65L13.075 7.1249C12.825 6.85824 12.7043 6.5459 12.713 6.1879C12.721 5.82924 12.85 5.5249 13.1 5.2749C13.35 5.0249 13.6583 4.8999 14.025 4.8999C14.3916 4.8999 14.7 5.01657 14.95 5.2499L18.75 9.0749C18.8833 9.20823 18.9793 9.3539 19.038 9.5119C19.096 9.67057 19.125 9.83324 19.125 9.9999C19.125 10.1666 19.096 10.3292 19.038 10.4879C18.9793 10.6459 18.8833 10.7916 18.75 10.9249L14.925 14.7499C14.6583 15.0166 14.35 15.1416 14 15.1249C13.65 15.1082 13.35 14.9749 13.1 14.7249ZM2.97495 19.4749C2.24162 19.4749 1.61662 19.2206 1.09995 18.7119C0.583284 18.2039 0.324951 17.5749 0.324951 16.8249V3.1749C0.324951 2.4249 0.583284 1.79557 1.09995 1.2869C1.61662 0.778902 2.24162 0.524902 2.97495 0.524902H8.79995C9.16662 0.524902 9.47928 0.653902 9.73795 0.911902C9.99595 1.17057 10.125 1.48324 10.125 1.8499C10.125 2.21657 9.99595 2.5289 9.73795 2.7869C9.47928 3.04557 9.16662 3.1749 8.79995 3.1749H2.97495V16.8249H8.79995C9.16662 16.8249 9.47928 16.9539 9.73795 17.2119C9.99595 17.4706 10.125 17.7832 10.125 18.1499C10.125 18.5166 9.99595 18.8289 9.73795 19.0869C9.47928 19.3456 9.16662 19.4749 8.79995 19.4749H2.97495Z"
      fill="#36355F"
    />
  ]
})
