import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  Flex,
  Stack,
  HStack,
  Box,
  Button,
  Spacer,
  Text,
  FormControl,
  FormLabel,
  Input,
  Select,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,
  useToast
} from '@chakra-ui/react'
import _ from 'lodash'
import RenderImageContainer from '../Shared/RenderImageContainer'

import UploadImage from '../Shared/UploadImage'
import { getUrlImage } from '../../store/slices/fileUpload'
import DeleteModal from './DeleteModal'
import CustomToast from '../Shared/CustomToast'

const Tools = [
  { id: 1, name: 'Motosierra' },
  { id: 2, name: 'Motosierra en Altura' },
  { id: 3, name: 'Cortasetos' },
  { id: 4, name: 'Ahoyadora' },
  { id: 5, name: 'Desbrozadora' },
  { id: 6, name: 'Rotovator' },
  { id: 7, name: 'Motopulverizador' },
  { id: 8, name: 'Hurrican' },
  { id: 9, name: 'Herbicida' },
  { id: 10, name: 'Bomba espalda' },
  { id: 11, name: 'No Aplica' }
]

const Status = [
  { id: 'TERMINADO', name: 'Terminado' },
  { id: 'PROCESO', name: 'En proceso' },
  { id: 'INICIO', name: 'Sin iniciar' },
  { id: 'CANCELADO', name: 'Cancelado' }
]

// Formulario de creación de actividad
const ActivityForm = ({
  index,
  values,
  handleChange,
  handleChangeValues,
  handleDeleteActivity,
  handleImage,
  handleChangeImages,
  handleChangeList
}) => {
  const toast = useToast()
  const dispatch = useDispatch()
  const {
    isOpen: openDelete,
    onOpen: onOpenDelete,
    onClose: closeDelete
  } = useDisclosure()

  //Lógica de subida de imagen
  const [isLoadingUploadFile, setIsLoadingUploadFile] = useState(false)
  const imgUpload = async (formImage) => {
    try {
      setIsLoadingUploadFile(true)
      const response = await dispatch(getUrlImage(formImage))
      if (response) {
        handleImage(index, response)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoadingUploadFile(false)
    }
  }

  //Obtener nombre de imagen
  const getFilename = (img) => {
    const index = img.lastIndexOf('/') + 1
    const filename = img.substr(index)
    return filename
  }

  const handleClickDelete = () => {
    onOpenDelete()
  }

  useEffect(() => {
    if (isLoadingUploadFile) {
      toast({
        duration: 9000 * 9000,
        isClosable: true,
        position: 'bottom-right',
        render: ({ onClose }) => (
          <CustomToast
            text={'Cargando Imagen...'}
            status={'info'}
            onClose={onClose}
          />
        )
      })
      return
    }
    toast.closeAll()
  }, [isLoadingUploadFile])

  return (
    <AccordionItem>
      <AccordionButton>
        <Box w="100%" bg="#F2F1FB" pb={5}>
          <Flex spacing={10} align="left" pt="15px" px={10}>
            <Text fontSize="15px" fontWeight={'bold'} color="#36355F">
              Actividad {index + 1}: {values.description}
            </Text>
            <Spacer />
            <AccordionIcon />
          </Flex>
        </Box>
      </AccordionButton>
      <AccordionPanel>
        <FormControl id="description">
          <FormLabel>Descripción de actividad</FormLabel>
          <Input
            name="description"
            value={values.description}
            onChange={handleChange}
            placeholder="Descripción de actividad"
          />
        </FormControl>
        <FormControl id="amount">
          <FormLabel>Número de personas</FormLabel>
          <NumberInput
            id="amount"
            name="amount"
            value={values.amount}
            onChange={(value) => handleChangeValues(value, 'amount', index)}
            min={0}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>
        {/* <FormControl id="tool">
          <FormLabel>Herramienta utilizada</FormLabel>
          <Select placeholder="Seleccione" name="tool" onChange={handleChange}>
            {Tools.map((tool) => (
              <option value={tool.id} key={tool.id}>
                {tool.name}
              </option>
            ))}
          </Select>
        </FormControl> */}
        <FormControl id="tools">
          <FormLabel>Herramienta utilizada</FormLabel>
          <Menu closeOnSelect={false}>
            <MenuButton as={Button} colorScheme="green">
              Herramientas
            </MenuButton>
            <MenuList minWidth="240px" h={60} sx={{ overflow: 'scroll' }}>
              <MenuOptionGroup title="Herramientas" type="checkbox">
                {Tools.map((tool) => (
                  <MenuItemOption
                    value={tool.id}
                    onClick={() => handleChangeList(tool.id, index)}
                  >
                    {tool.name}
                  </MenuItemOption>
                ))}
              </MenuOptionGroup>
            </MenuList>
          </Menu>
        </FormControl>
        <HStack>
          <FormControl id="startKM">
            <FormLabel>KM inicial o lugar</FormLabel>
            <Input
              name="startKM"
              value={values.startKM}
              onChange={handleChange}
              placeholder="KM inicial o lugar"
            />
          </FormControl>
          <FormControl id="endKM">
            <FormLabel>KM final</FormLabel>
            <Input
              name="endKM"
              value={values.endKM}
              onChange={handleChange}
              placeholder="KM final"
            />
          </FormControl>
        </HStack>
        <FormControl id="activityStatus">
          <FormLabel>Estado de la actividad</FormLabel>
          <Select
            placeholder="Seleccione"
            name="activityStatus"
            onChange={handleChange}
          >
            {Status.map((stat) => (
              <option value={stat.number} key={stat.number}>
                {stat.name}
              </option>
            ))}
          </Select>
        </FormControl>
        <Text fontSize="17px" fontWeight="bold" color="#36355F">
          Foto delantero exterior
        </Text>
        <UploadImage imgUpload={imgUpload} />
        {values.images.map((img, idx) => (
          <Box w="100%" pb={5} borderRadius={'lg'} boxShadow={'md'} p={2}>
            <RenderImageContainer
              images={[
                {
                  url: img.url,
                  observation: img.observation
                }
              ]}
              getNewArray={(arr) => {
                const img = arr?.at(0)
                handleChangeImages(img.observation, index, idx)
              }}
              getNewArrayDelete={() => {
                handleChangeImages('deleteImg', index, idx)
              }}
            />
          </Box>
        ))}
        <Stack spacing={10} align={'center'} mt={4}>
          <Button
            align="center"
            onClick={handleClickDelete}
            bg="#F2F1FB"
            color="#59BABA"
          >
            Eliminar actividad
          </Button>
        </Stack>
      </AccordionPanel>
      {openDelete && (
        <DeleteModal
          isOpen={openDelete}
          onClose={closeDelete}
          handleDelete={handleDeleteActivity}
          selected={values}
        />
      )}
    </AccordionItem>
  )
}

export default ActivityForm
