import axios from '../Axios'

export const getTuvs = async (page = 1, size = 10) => {
  const { data } = await axios.get(`tuv/all?page=${page}&size=${size}`)
  return data
}

export const getOneTuv = async (tuvId) => {
  const { data } = await axios.get(`tuv/get-one?id_tuv=${tuvId}`)
  return data
}

export const createTuv = async (formData) => {
  const { data } = await axios.post(`tuv/`, formData)
  return data
}

export const updateTuv = async (formData) => {
  console.log(formData)
  const { data } = await axios.patch(`tuv/`, formData)
  return data
}

export const deleteTuv = async (tuvId) => {
  const { data } = await axios.delete(`tuv/?id=${tuvId}`)
  return data
}
