import { useQuery, useMutation, useQueryClient } from 'react-query'
import {
  getDailies,
  getOneDaily,
  createDaily,
  updateDaily,
  deleteDaily
} from '../api/dailyActivities'

const getAction = (type) => {
  if (type === 'CREATE') return createDaily
  if (type === 'UPDATE') return updateDaily
  if (type === 'DELETE') return deleteDaily
  return null
}

export function useDailies(page, formData, props = {}) {
  return useQuery(
    ['daily', page],
    () => getDailies(page, formData),
    { enabled: true, refetchOnWindowFocus: false },
    {
      ...props,
      onError: (err) => {
        console.log(err.response)
      }
    }
  )
}

export function useDaily(dailyId) {
  return useQuery(['daily', dailyId], () => getOneDaily(dailyId), {
    onError: (err) => {
      console.log(err.response)
    }
  })
}

export function useMutateDaily(type) {
  const queryClient = useQueryClient()
  const actionFn = getAction(type)
  return useMutation(actionFn, {
    onSuccess: () => {
      queryClient.invalidateQueries('daily')
    },
    onError: (err) => {
      console.log(err.response)
    }
  })
}
