import {
  HStack,
  Box,
  Text,
  Input,
  FormControl,
  FormLabel,
  Switch,
  VStack
} from '@chakra-ui/react'

// Módulo de calificación
const Score = ({ title, score, setScore, name }) => {
  const colors = (score, index) => {
    if (score >= index) {
      return { back: '#36355F', font: '#FFFFFF' }
    } else {
      return { back: '#E6E6EF', font: '#36355F' }
    }
  }

  return (
    <Box rounded={'lg'} bg={'#FFF'} w={['90%', '30%', '30%']} boxShadow={'md'}>
      <Text fontSize="14px" fontWeight="bold" color="#36355F" pl={2}>
        {title}
      </Text>
      <HStack justify={'space-between'} align="center" my={7} p={2}>
        <VStack>
          <Box
            as="button"
            px={3}
            py={1}
            w={['40px', '40px', '40px', '40px', '70px']}
            bg={colors(score, 1).back}
            borderRadius="lg"
            display="flex"
            onClick={() => setScore(name, 1)}
          >
            <Text fontSize="13px" color={colors(score, 1).font} m="auto">
              1
            </Text>
          </Box>
          <Text fontSize="7px" color="#36355F" fontWeight="bold" m="auto">
            Malo
          </Text>
        </VStack>
        <VStack>
          <Box
            as="button"
            px={3}
            py={1}
            w={['40px', '40px', '40px', '40px', '70px']}
            bg={colors(score, 2).back}
            borderRadius="lg"
            display="flex"
            onClick={() => setScore(name, 2)}
          >
            <Text fontSize="13px" color={colors(score, 2).font} m="auto">
              2
            </Text>
          </Box>
          <Text fontSize="7px" color="#36355F" fontWeight="bold" m="auto">
            Ineficiente
          </Text>
        </VStack>
        <VStack>
          <Box
            as="button"
            px={3}
            py={1}
            w={['40px', '40px', '40px', '40px', '70px']}
            bg={colors(score, 3).back}
            borderRadius="lg"
            borderStyle={''}
            display="flex"
            onClick={() => setScore(name, 3)}
          >
            <Text fontSize="13px" color={colors(score, 3).font} m="auto">
              3
            </Text>
          </Box>
          <Text fontSize="7px" color="#36355F" fontWeight="bold" m="auto">
            Bueno
          </Text>
        </VStack>
        <VStack>
          <Box
            as="button"
            px={3}
            py={1}
            w={['40px', '40px', '40px', '40px', '70px']}
            bg={colors(score, 4).back}
            borderRadius="lg"
            borderStyle={''}
            display="flex"
            onClick={() => setScore(name, 4)}
          >
            <Text fontSize="13px" color={colors(score, 4).font} m="auto">
              4
            </Text>
          </Box>
          <Text fontSize="7px" color="#36355F" fontWeight="bold" m="auto">
            Muy bueno
          </Text>
        </VStack>
      </HStack>
      <Text fontSize="14px" color="red" pl={2}>
        {score < 1 ? 'Debe agregar calificación' : null}
      </Text>
    </Box>
  )
}

export default Score
