import { Flex } from '@chakra-ui/react'

import LoginForm from '../../components/Login/LoginForm'

// Pantalla de login
const Login = () => {
  return (
    <Flex minH={'100vh'} align={'center'} justify={'center'} bg={'#FFF'}>
      <LoginForm />
    </Flex>
  )
}

export default Login
