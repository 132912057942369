import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { loginUser, loggedUser, logoutUser } from '../store/slices/auth'
import { login, get_authentication } from '../api/login'

export function useMutateLogin() {
  const dispatch = useDispatch()
  const history = useHistory()
  return useMutation(login, {
    onSuccess: (data) => {
      dispatch(loginUser(data))
      history.push('/home')
    }
  })
}

export function useMutateLogged() {
  const dispatch = useDispatch()
  return useMutation(get_authentication, {
    onSuccess: (data) => {
      dispatch(loggedUser(data))
    },
    onError: (err) => {
      console.log(err)
      dispatch(logoutUser())
    }
  })
}
