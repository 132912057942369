import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement
} from '@chakra-ui/react'

const formatDate = (value, type) => {
  if (type === 'date') {
    return value?.slice(0, -14)
  }
  return value
}

const SimpleInput = ({
  name,
  label,
  errors,
  errorMsg,
  value,
  type,
  min,
  max,
  onChange,
  iconsProps = {},
  ...props
}) => {
  return (
    <FormControl id={name} isInvalid={errors}>
      <FormLabel>{label}</FormLabel>
      <InputGroup>
        <Input
          name={name}
          value={value}
          onChange={onChange}
          type={type}
          min={formatDate(min, type)}
          max={formatDate(max, type)}
          {...props}
        />
        {iconsProps?.right && (
          <InputRightElement pointerEvents="none">
            {iconsProps.right.iconText && (
              <Box
                borderRadius={8}
                p={2}
                ml={'auto'}
                fontWeight={700}
                bg="gray.200"
              >
                {iconsProps.right.iconText}
              </Box>
            )}
          </InputRightElement>
        )}
      </InputGroup>
      <FormErrorMessage>{errorMsg}</FormErrorMessage>
    </FormControl>
  )
}

export default SimpleInput
