import { useQuery } from 'react-query'
import { getSuppliesDashboard, getSuppliesXlsx } from '../api/reportability'

export function useSuppliesDashboard(values) {
  return useQuery(
    ['suppliesDash'],
    () => getSuppliesDashboard(values),
    { enabled: true, refetchOnWindowFocus: false },
    {
      onError: (err) => {
        console.log(err.response)
      }
    }
  )
}

export function useSuppliesXlsx(values) {
  return useQuery(
    ['suppliesXlsx'],
    () => getSuppliesXlsx(values),
    { enabled: false },
    {
      onError: (err) => {
        console.log(err.response)
      }
    }
  )
}
