export const getYears = () => {
  const currentYear = new Date().getFullYear()
  const years = []

  for (let year = 2022; year <= currentYear; year++) {
    years.push(year.toString())
  }

  return years
}
