import { useQuery, useMutation, useQueryClient } from 'react-query'
import {
  getControls,
  getOneControl,
  createControl,
  updateControl,
  deleteControl
} from '../api/qualityControl'

const getAction = (type) => {
  if (type === 'CREATE') return createControl
  if (type === 'UPDATE') return updateControl
  if (type === 'DELETE') return deleteControl
  return null
}

export function useControls(page, props = {}) {
  return useQuery(['controls', page], () => getControls(page), {
    ...props,
    onError: (err) => {
      console.log(err.response)
    }
  })
}

export function useControl(controlId) {
  return useQuery(['control', controlId], () => getOneControl(controlId), {
    onError: (err) => {
      console.log(err.response)
    }
  })
}

export function useMutateControl(type) {
  const queryClient = useQueryClient()
  const actionFn = getAction(type)
  return useMutation(actionFn, {
    onSuccess: () => {
      queryClient.invalidateQueries('control')
      queryClient.invalidateQueries('visit')
    },
    onError: (err) => {
      console.log(err.response)
    }
  })
}
