import { useQuery } from 'react-query'
import { getAllTools, getOneTool } from '../api/tools'

export function useAllTools() {
  return useQuery(
    ['toolsAll'],
    () => getAllTools(),
    { enabled: true, refetchOnWindowFocus: false },
    {
      onError: (err) => {
        console.log(err.response)
      }
    }
  )
}

export function useTool(toolId) {
  return useQuery(['tool', toolId], () => getOneTool(toolId), {
    onError: (err) => {
      console.log(err.response)
    }
  })
}
