export const getRandomColor = () => {
  let color
  while (!color || color === '#ffffff') {
    // Genera tres números aleatorios entre 0 y 255
    const r = Math.floor(Math.random() * 256)
    const g = Math.floor(Math.random() * 256)
    const b = Math.floor(Math.random() * 256)

    // Verifica que la diferencia entre los componentes RGB sea mayor que el umbral
    const threshold = 64
    const maxComponent = Math.max(r, g, b)
    const minComponent = Math.min(r, g, b)
    if (maxComponent - minComponent >= threshold) {
      // Convierte los números a formato hexadecimal y concaténalos en una cadena
      color = '#' + r.toString(16) + g.toString(16) + b.toString(16)
    }
  }

  // Devuelve el color generado
  return color
}
