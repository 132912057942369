import { useQuery, useMutation, useQueryClient } from 'react-query'
import { getTuvs, getOneTuv, createTuv, updateTuv, deleteTuv } from '../api/tuv'

const getAction = (type) => {
  if (type === 'CREATE') return createTuv
  if (type === 'UPDATE') return updateTuv
  if (type === 'DELETE') return deleteTuv
  return null
}

export function useTuvs(page, props = {}) {
  return useQuery(['tuvs', page], () => getTuvs(page), {
    ...props,
    onError: (err) => {
      console.log(err.response)
    }
  })
}

export function useTuv(tuvId) {
  return useQuery(['tuv', tuvId], () => getOneTuv(tuvId), {
    onError: (err) => {
      console.log(err.response)
    }
  })
}

export function useMutateTuv(type) {
  const queryClient = useQueryClient()
  const actionFn = getAction(type)
  return useMutation(actionFn, {
    onSuccess: () => {
      queryClient.invalidateQueries('tuv')
      queryClient.invalidateQueries('visit')
    },
    onError: (err) => {
      console.log(err.response)
    }
  })
}
