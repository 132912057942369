import { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Stack,
  Button,
  Text,
  Link,
  Flex,
  Image,
  Skeleton,
  useToast
} from '@chakra-ui/react'

import { useMutateAuth } from '../../hooks/auth'
import newPasswordValidator from '../../validators/newPassword'

const validationSchema = Yup.object().shape({
  oldPassword: Yup.string().required('Ingrese contraseña actual'),
  newPassword1: Yup.string().required('Ingrese nueva contraseña'),
  newPassword2: Yup.string().required('Repita nueva contraseña')
})

// Cambio de contraseña en perfil
const ChangePassword = () => {
  const { userId } = useParams()
  const toast = useToast()
  const [error, setError] = useState('')
  const { mutate, isLoading, reset } = useMutateAuth('CHANGE')

  const formik = useFormik({
    validationSchema,
    enableReinitialize: true,
    initialValues: {
      id: userId,
      oldPassword: '',
      newPassword1: '',
      newPassword2: ''
    },
    onSubmit: (values) => {
      setError('')
      const validate = newPasswordValidator(values.newPassword1)
      if (!validate) {
        setError('La contraseña no cumple con las condiciones')
        formik.setSubmitting(false)
      } else if (values.newPassword1 !== values.newPassword2) {
        setError('Las contraseñas deben ser iguales')
        formik.setSubmitting(false)
      } else {
        mutate(values, {
          onSuccess: () => {
            reset()
            formik.resetForm()
            toast({
              title: `Contraseña actualizada`,
              status: 'success',
              isClosable: true
            })
          },
          onError: (err) => {
            console.log(err)
            if (err.request.response.includes('Contraseña antigua erronea')) {
              toast({
                title: `Contraseña actual erronea`,
                status: 'error',
                isClosable: true
              })
            } else if (
              err.request.response.includes('Contraseñas no pueden ser iguales')
            ) {
              toast({
                title: `Nueva contraseña no puede ser igual a contraseña actual`,
                status: 'error',
                isClosable: true
              })
            } else {
              toast({
                title: `Error al cambiar de contraseña`,
                status: 'error',
                isClosable: true
              })
            }
          }
        })
      }
    }
  })

  return (
    <Stack spacing={8} mx={'auto'} py={12} px={6}>
      <Box rounded={'lg'} bg={'#FFF'}>
        <Stack align={'left'}>
          <Heading fontSize={'3xl'}>Cambiar Contraseña</Heading>
        </Stack>
        <Box my={2}>
          <Text fontSize="17px" color="#36355F">
            Tu contraseña debe contener al menos 8 caracteres, al menos 1
            número, al 1 una letra mayúscula, al 1 una letra minúscula y al
            menos 1 caracter especial.
          </Text>
        </Box>
        <Stack spacing={4} minW={'xs'}>
          <FormControl
            id="oldPassword"
            isRequired
            isInvalid={
              formik.touched.oldPassword && Boolean(formik.errors.oldPassword)
            }
          >
            <FormLabel>Contraseña Actual</FormLabel>
            <Input
              borderRadius="30px"
              name="oldPassword"
              type="password"
              value={formik.values.oldPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <FormErrorMessage>
              {formik.touched.oldPassword && formik.errors.oldPassword}
            </FormErrorMessage>
          </FormControl>
          <FormControl
            id="newPassword1"
            isRequired
            isInvalid={
              formik.touched.newPassword1 && Boolean(formik.errors.newPassword1)
            }
          >
            <FormLabel>Nueva Contraseña</FormLabel>
            <Input
              borderRadius="30px"
              name="newPassword1"
              type="password"
              value={formik.values.newPassword1}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <FormErrorMessage>
              {formik.touched.newPassword1 && formik.errors.newPassword1}
            </FormErrorMessage>
          </FormControl>
          <FormControl
            id="newPassword2"
            isRequired
            isInvalid={
              formik.touched.newPassword2 && Boolean(formik.errors.newPassword2)
            }
          >
            <FormLabel>Repita Nueva Contraseña</FormLabel>
            <Input
              borderRadius="30px"
              name="newPassword2"
              type="password"
              value={formik.values.newPassword2}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <FormErrorMessage>
              {formik.touched.newPassword2 && formik.errors.newPassword2}
            </FormErrorMessage>
          </FormControl>
          {error && (
            <Box my={2}>
              <Text fontSize="17px" color="red">
                {error}
              </Text>
            </Box>
          )}
          <Stack spacing={10} align={'center'}>
            <Button
              isLoading={isLoading}
              onClick={formik.handleSubmit}
              bg={'teal.500'}
              color={'white'}
              _hover={{
                bg: 'teal.600'
              }}
              disabled={isLoading}
            >
              Cambiar Contraseña
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Stack>
  )
}

export default ChangePassword
