import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Stack,
  Button,
  Link,
  Flex,
  Image
} from '@chakra-ui/react'
import { randomBytes, secretbox } from 'tweetnacl'
import { encodeBase64 } from 'tweetnacl-util'

import { useMutateLogin } from '../../hooks/login'
import Logo from '../../assets/images/logo.svg'

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Ingrese email válido').required('Ingrese correo'),
  password: Yup.string().required('Ingrese contraseña')
})

// Buffer de 24 bytes
const nonce = randomBytes(24)

// Llave secreta
const secretKey = Buffer.from(process.env.REACT_APP_NODE_SECRET_KEY, 'utf8')

// Formulario de login
const LoginForm = () => {
  const history = useHistory()
  const { mutate, isLoading, reset } = useMutateLogin()
  const [error, setError] = useState('')

  const formik = useFormik({
    validationSchema,
    initialValues: {
      email: '',
      password: ''
    },
    onSubmit: (values) => {
      // Buffer con el password a encriptar
      const secretData = Buffer.from(values.password, 'utf8')
      const encrypted = secretbox(secretData, nonce, secretKey)
      // Resultado a enviar
      const result = `${encodeBase64(nonce)}:${encodeBase64(encrypted)}`
      const dataForm = { email: values.email, password: result }
      mutate(dataForm, {
        onSuccess: () => {
          reset()
          formik.resetForm()
        },
        onError: (err) => {
          console.log(err)
          if (err.response.status === 403) {
            setError('Correo o contraseña incorrecta')
          } else {
            setError('Error al iniciar sesión')
          }
        }
      })
    }
  })

  const handleBack = () => {
    history.push(`/recuperar`)
  }

  return (
    <Stack spacing={8} mx={'auto'} py={12} px={6}>
      <Flex h="10" alignItems="center" justifyContent="center" mb={20}>
        <Image src={Logo} alt="Logo" boxSize="250px" />
      </Flex>
      <Stack align={'left'}>
        <Heading fontSize={'4xl'}>Inicia sesión</Heading>
      </Stack>
      <Box rounded={'lg'} bg={'#FFF'}>
        <Stack spacing={4} minW={'xs'}>
          <Box my={2}>
            {error && (
              <Alert status="error" borderRadius={'md'}>
                <AlertIcon />
                <AlertTitle mr={2}>{error}</AlertTitle>
              </Alert>
            )}
          </Box>
          <FormControl
            id="email"
            isRequired
            isInvalid={formik.touched.email && Boolean(formik.errors.email)}
          >
            <FormLabel>Correo Electrónico</FormLabel>
            <Input
              borderRadius="30px"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <FormErrorMessage>
              {formik.touched.email && formik.errors.email}
            </FormErrorMessage>
          </FormControl>
          <FormControl
            isRequired
            isInvalid={
              formik.touched.password && Boolean(formik.errors.password)
            }
          >
            <FormLabel>Contraseña</FormLabel>
            <Input
              borderRadius="30px"
              type="password"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              onKeyDown={(e) => {
                const { key } = e
                if (key === 'Enter' && formik.isValid) {
                  formik.submitForm()
                }
              }}
            />
            <FormErrorMessage>
              {formik.touched.password && formik.errors.password}
            </FormErrorMessage>
          </FormControl>
          <Stack spacing={10} align={'center'}>
            <Link onClick={handleBack} color={'teal.500'} fontSize="14">
              ¿Olvidaste tu contraseña?
            </Link>
          </Stack>
          <Stack spacing={10} align={'center'}>
            <Button
              isLoading={isLoading}
              onClick={formik.handleSubmit}
              bg={'teal.500'}
              color={'white'}
              _hover={{
                bg: 'teal.600'
              }}
              disabled={isLoading}
            >
              Entrar
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Stack>
  )
}

export default LoginForm
