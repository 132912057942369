import axios from '../Axios'

export const getAllFiles = async (params = {}) => {
  const { data } = await axios.get(`s3_files/all`, {
    params
  })
  return data
}

export const deleteFiles = async (fileKey) => {
  const { data } = await axios.delete(`s3_files/delete/`, {
    params: {
      object_key: fileKey
    }
  })
  return data
}
