import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import {
  Flex,
  Stack,
  VStack,
  HStack,
  Box,
  Button,
  Icon,
  Spacer,
  Text,
  Textarea,
  Checkbox,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Grid,
  useDisclosure,
  Skeleton,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  useToast
} from '@chakra-ui/react'
import { ArrowBackIcon } from '@chakra-ui/icons'
import * as Yup from 'yup'
import { useFormik } from 'formik'

import CustomToast from '../../components/Shared/CustomToast'
import { OTRIcon } from '../../components/Shared/Icons'
import UploadImage from '../Shared/UploadImage'
import ConfirmModal from '../Shared/ConfirmModal'
import { useMutateOrt, useOrt } from '../../hooks/ort'
import { getUrlImage } from '../../store/slices/fileUpload'
import RenderImageContainer from '../Shared/RenderImageContainer'
import { useGetRequest } from '../../hooks/useGetRequest'
import DynamicProductForm from './DynamicProductForm'
import { productSchema } from './DynamicProductForm/schema'
import { initialValuesOrt } from './initialValuesOrt'
import { SignatureModal } from '../Shared/modals'

const validationSchema = Yup.object().shape({
  rodenticideStations: Yup.number()
    .required('Ingrese n° de estaciones')
    .min(0, 'Valor debe ser 0 o mayor')
    .integer('Solo números enteros'),
  moldyBaits: Yup.number()
    .required('Ingrese n° de cebos con moho')
    .min(0, 'Valor debe ser 0 o mayor')
    .integer('Solo números enteros'),
  gnawedBaits: Yup.number()
    .required('Ingrese n° cebos roidos')
    .min(0, 'Valor debe ser 0 o mayor')
    .integer('Solo números enteros'),
  snailBaits: Yup.number()
    .required('Ingrese n° cebos consumidos')
    .min(0, 'Valor debe ser 0 o mayor')
    .integer('Solo números enteros'),
  otherBaits: Yup.number()
    .required('Ingrese n° cebos')
    .min(0, 'Valor debe ser 0 o mayor')
    .integer('Solo números enteros'),
  adhesiveStations: Yup.number()
    .required('Ingrese n° estaciones adhesivas')
    .min(0, 'Valor debe ser 0 o mayor')
    .integer('Solo números enteros'),
  adhesiveStationsCaptures: Yup.number()
    .required('Ingrese n° estaciones con captura')
    .min(0, 'Valor debe ser 0 o mayor')
    .integer('Solo números enteros'),
  liveStations: Yup.number()
    .required('Ingrese n° trampas')
    .min(0, 'Valor debe ser 0 o mayor')
    .integer('Solo números enteros'),
  liveStationsCaptures: Yup.number()
    .required('Ingrese n° estaciones con captura')
    .min(0, 'Valor debe ser 0 o mayor')
    .integer('Solo números enteros'),
  doveCages: Yup.number()
    .required('Ingrese n° jaulas de paloma')
    .min(0, 'Valor debe ser 0 o mayor')
    .integer('Solo números enteros'),
  doveCagesCaptures: Yup.number()
    .required('Ingrese n° aves capturadas')
    .min(0, 'Valor debe ser 0 o mayor')
    .integer('Solo números enteros'),
  uvEquipment: Yup.number()
    .required('Ingrese n° equipos U.V.')
    .min(0, 'Valor debe ser 0 o mayor')
    .integer('Solo números enteros'),
  flyTraps: Yup.number()
    .required('Ingrese n° trampa de mosca')
    .min(0, 'Valor debe ser 0 o mayor')
    .integer('Solo números enteros'),
  drSerial: Yup.string(),
  dsCrawling: Yup.string(),
  dsCockroach: Yup.string(),
  dsFlyers: Yup.string(),
  snSerial: Yup.string(),

  newRodenticideStations: Yup.number()
    .required('Ingrese n°')
    .min(0, 'Valor debe ser 0 o mayor')
    .integer('Solo números enteros'),
  newStickyTraps: Yup.number()
    .required('Ingrese n°')
    .min(0, 'Valor debe ser 0 o mayor')
    .integer('Solo números enteros'),
  newTriangularDome: Yup.number()
    .required('Ingrese n°')
    .min(0, 'Valor debe ser 0 o mayor')
    .integer('Solo números enteros'),
  newLiveCapture: Yup.number()
    .required('Ingrese n°')
    .min(0, 'Valor debe ser 0 o mayor')
    .integer('Solo números enteros'),
  newStickyPlate: Yup.number()
    .required('Ingrese n°')
    .min(0, 'Valor debe ser 0 o mayor')
    .integer('Solo números enteros'),
  newUV: Yup.number()
    .required('Ingrese n°')
    .min(0, 'Valor debe ser 0 o mayor')
    .integer('Solo números enteros'),
  newOthers: Yup.number()
    .required('Ingrese n°')
    .min(0, 'Valor debe ser 0 o mayor')
    .integer('Solo números enteros'),
  observations: Yup.string().max(1000, 'Máximo 1000 carácteres'),
  otherDescriptionService: Yup.string().max(30, 'Máximo 30 carácteres'),
  rodenticides: Yup.array().of(productSchema),
  incecticides: Yup.array().of(productSchema),
  sanitizers: Yup.array().of(productSchema)
})

// Formulario ORT
const ORT = () => {
  const toast = useToast()
  const { ortId } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const { data: ort } = useOrt(ortId)

  const [rodentInfoActive, setRodentInfoActive] = useState(false)
  const {
    data: rodentInfo,
    isFetching: isLoadingRodentInfo,
    refetch: refetchRodentInfo,
    isError: isErrorRodentInfo
  } = useGetRequest(`ort/${ort?.id_visit}/get_rodents_info`, {
    enabled: Boolean(ort?.id_visit && rodentInfoActive)
  })

  const [isLoadingUploadFile, setIsLoadingUploadFile] = useState(false)
  const [step, setStep] = useState(1)
  const [confirm, setConfirm] = useState(false)
  const [serviceTypeChecks, setServiceTypeChecks] = useState([])
  const [serviceProvidedChecks, setServiceProvidedChecks] = useState([])
  const [treatedAreasChecks, setTreatedAreasChecks] = useState([])
  const [images, setImages] = useState([])
  const { client, branch } = useSelector((state) => state.visits)
  const { mutate, isLoading: loadingUpdate, reset } = useMutateOrt('UPDATE')
  const {
    isOpen: openConfirm,
    onOpen: onOpenConfirm,
    onClose: closeConfirm
  } = useDisclosure()

  // Guarda en estado las listas de checks, imagenes y firma
  useEffect(() => {
    if (ort) {
      setServiceTypeChecks(ort.service_type)
      setServiceProvidedChecks(ort.service_provided)
      setTreatedAreasChecks(ort.treated_areas)
      setImages(ort.images)
    }
  }, [ort])

  useEffect(() => {
    if (isLoadingUploadFile) {
      toast({
        duration: 9000 * 9000,
        isClosable: true,
        position: 'bottom-right',
        render: ({ onClose }) => (
          <CustomToast
            text={'Cargando Imagen...'}
            status={'info'}
            onClose={onClose}
          />
        )
      })
      return
    }
    toast.closeAll()
  }, [isLoadingUploadFile])

  const formik = useFormik({
    validationSchema,
    /* enableReinitialize: true, */
    initialValues: initialValuesOrt,
    onSubmit: (values) => {
      const formData = {
        id: ort.id,
        id_visit: ort.id_visit,
        created_by: ort.created_by,
        service_type: serviceTypeChecks,
        service_provided: serviceProvidedChecks,
        other_description_service: values.otherDescriptionService,
        treated_areas: treatedAreasChecks,
        rodenticide_stations: values.rodenticideStations,
        moldy_baits: values.moldyBaits,
        gnawed_baits: values.gnawedBaits,
        snail_baits: values.snailBaits,
        other_baits: values.otherBaits,
        adhesive_stations: values.adhesiveStations,
        adhesive_stations_captures: values.adhesiveStationsCaptures,
        live_stations: values.liveStations,
        live_stations_captures: values.liveStationsCaptures,
        dove_cages: values.doveCages,
        dove_cages_captures: values.doveCagesCaptures,
        uv_equipment: values.uvEquipment,
        fly_traps: values.flyTraps,
        dr_serial: values.drSerial,
        ds_crawling: values.dsCrawling,
        ds_cockroach: values.dsCockroach,
        ds_flyers: values.dsFlyers,
        sn_serial: values.snSerial,
        new_rodenticide_stations: values.newRodenticideStations,
        new_sticky_traps: values.newStickyTraps,
        new_triangular_dome: values.newTriangularDome,
        new_live_capture: values.newLiveCapture,
        new_sticky_plate: values.newStickyPlate,
        new_uv: values.newUV,
        new_others: values.newOthers,
        observations: values.observations,
        ruc: values.ruc,
        signature: values.signature,
        images: images,
        rodenticides: values.rodenticides,
        incecticides: values.incecticides,
        sanitizers: values.sanitizers,
        start_time: values.start_time,
        end_time: values.end_time,
        fullName: values.fullName
      }
      console.log({ formData })
      mutate(formData, {
        onSuccess: () => {
          reset()
          formik.resetForm()
          setConfirm(true)
        },
        onError: (err) => {
          reset()
          console.log(err)
          if (err.request.response.includes('ERROR_SAVING_ORT')) {
            toast({
              title: `Error al guardar datos`,
              status: 'error',
              isClosable: true
            })
          } else if (err.request.response.includes('ERROR_GENERATING_PDF')) {
            toast({
              title: `Error al crear PDF, datos guardados sin problemas`,
              status: 'error',
              isClosable: true
            })
          } else {
            toast({
              title: `Error indefinido`,
              status: 'error',
              isClosable: true
            })
          }
        }
      })
    }
  })

  useEffect(() => {
    if (ort) {
      formik.setValues({
        otherDescriptionService: ort?.other_description_service || '',
        rodenticideStations: ort?.rodenticide_stations || 0,
        moldyBaits: ort?.moldy_baits || 0,
        gnawedBaits: ort?.gnawed_baits || 0,
        snailBaits: ort?.snail_baits || 0,
        otherBaits: ort?.other_baits || 0,
        adhesiveStations: ort?.adhesive_stations || 0,
        adhesiveStationsCaptures: ort?.adhesive_stations_captures || 0,
        liveStations: ort?.live_stations || 0,
        liveStationsCaptures: ort?.live_stations_captures || 0,
        doveCages: ort?.dove_cages || 0,
        doveCagesCaptures: ort?.dove_cages_captures || 0,
        uvEquipment: ort?.uv_equipment || 0,
        flyTraps: ort?.fly_traps || 0,
        drSerial: ort?.dr_serial || '',
        dsCrawling: ort?.ds_crawling || '',
        dsCockroach: ort?.ds_cockroach || '',
        dsFlyers: ort?.ds_flyers || '',
        snSerial: ort?.sn_serial || '',
        newRodenticideStations: ort?.new_rodenticide_stations || 0,
        newTriangularDome: ort?.new_triangular_dome || 0,
        newLiveCapture: ort?.new_live_capture || 0,
        newStickyPlate: ort?.new_sticky_plate || 0,
        newUV: ort?.new_uv || 0,
        newOthers: ort?.new_others || 0,
        newStickyTraps: ort?.newStickyTraps || 0,
        observations: ort?.observations || '',
        ruc: ort?.ruc || '',
        signature: ort?.signature || null,
        rodenticides: ort?.rodenticides || [],
        incecticides: ort?.incecticides || [],
        sanitizers: ort?.sanitizers || [],
        start_time: ort?.start_time || '',
        end_time: ort?.end_time || '',
        fullName: ort?.fullName || ''
      })
    }
  }, [ort])

  const handleBack = () => {
    history.push(`/visitas/${ort.id_visit}`)
  }

  // Actualiza lista de checks de tipo
  const handleChangeCheckType = (event) => {
    const { name } = event.target
    let tempCheck = [...serviceTypeChecks, name]
    if (serviceTypeChecks.includes(name)) {
      tempCheck = tempCheck.filter((per) => per !== name)
    }
    setServiceTypeChecks(tempCheck)
  }

  // Actualiza lista de checks de servicio
  const handleChangeCheckProvided = (event) => {
    const { name } = event.target
    let tempCheck = [...serviceProvidedChecks, name]
    if (serviceProvidedChecks.includes(name)) {
      tempCheck = tempCheck.filter((per) => per !== name)
    }
    setServiceProvidedChecks(tempCheck)
  }

  // Actualiza lista de checks de áreas
  const handleChangeCheckAreas = (event) => {
    const { name } = event.target
    let tempCheck = [...treatedAreasChecks, name]
    if (treatedAreasChecks.includes(name)) {
      tempCheck = tempCheck.filter((per) => per !== name)
    }
    setTreatedAreasChecks(tempCheck)
  }

  // Manejadores de cambio de página
  const nextStep = () => {
    if (step < 4) {
      setStep(step + 1)
    }
  }
  const prevStep = () => {
    if (step > 1) {
      setStep(step - 1)
    }
  }

  // Seleccionar página
  const changeStep = (number) => {
    setStep(number)
  }

  const handleClickConfirm = () => {
    onOpenConfirm()
  }

  //Maneja la subida de imagen a un elemento de la lista
  const handleImage = (imagesUrl) => {
    const newArray = [...images]
    imagesUrl.forEach((item) => {
      newArray.push({
        url: item.url,
        filename: item.filename,
        observation: ''
      })
    })
    setImages(newArray)
  }

  //logica de subida de imagen
  const imgUpload = async (formImage) => {
    try {
      setIsLoadingUploadFile(true)
      let responses = []
      for (let i = 0; i < formImage.length; i++) {
        const item = formImage[i]
        const formData = new FormData()
        formData.append('file', item)
        const response = await dispatch(getUrlImage(formData))
        responses.push(response)
      }
      if (responses.length) {
        handleImage(responses)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoadingUploadFile(false)
    }
  }

  useEffect(() => {
    const haveOther = Boolean(serviceProvidedChecks.includes('Other'))

    if (!haveOther) {
      formik.setFieldValue('otherDescriptionService', '')
    }
    if (haveOther) {
      formik.setFieldValue(
        'otherDescriptionService',
        ort?.other_description_service
      )
    }
  }, [serviceProvidedChecks])

  useEffect(() => {
    if (rodentInfo) {
      const { toxic, not_toxic } = rodentInfo

      formik.setFieldValue(
        'rodenticideStations',
        toxic ? toxic?.countStations : 0
      )
      formik.setFieldValue('moldyBaits', toxic ? toxic?.moldyBaits : 0)
      formik.setFieldValue('gnawedBaits', toxic ? toxic?.gnawedBaits : 0)
      formik.setFieldValue('snailBaits', toxic ? toxic?.snailBaits : 0)
      formik.setFieldValue('otherBaits', toxic ? toxic?.others : 0)

      formik.setFieldValue(
        'adhesiveStations',
        not_toxic ? not_toxic?.adhesiveStations : 0
      )
      formik.setFieldValue(
        'adhesiveStationsCaptures',
        not_toxic ? not_toxic?.adhesiveStationsCaptures : 0
      )
      formik.setFieldValue(
        'liveStations',
        not_toxic ? not_toxic?.liveStations : 0
      )
      formik.setFieldValue(
        'liveStationsCaptures',
        not_toxic ? not_toxic?.liveStationsCaptures : 0
      )
    }
  }, [rodentInfo, isLoadingRodentInfo])

  const stepOne = () => {
    return (
      <VStack spacing={8} py={12} px={6}>
        <Text fontSize="10px" color="#36355F">
          Tipo de servicio
        </Text>
        <HStack justify={'space-between'} w={['100%', '15%']}>
          <Text fontSize="14px" fontWeight="bold" color="#36355F">
            Implementación
          </Text>
          <Checkbox
            size="md"
            colorScheme="blue"
            name="Implementation"
            isChecked={serviceTypeChecks.includes('Implementation')}
            onChange={handleChangeCheckType}
            borderColor="grey"
          ></Checkbox>
        </HStack>
        <HStack justify={'space-between'} w={['100%', '15%']}>
          <Text fontSize="14px" fontWeight="bold" color="#36355F">
            Servicio Mensual
          </Text>
          <Checkbox
            size="md"
            colorScheme="blue"
            name="Monthly"
            isChecked={serviceTypeChecks.includes('Monthly')}
            onChange={handleChangeCheckType}
            borderColor="grey"
          ></Checkbox>
        </HStack>
        <HStack justify={'space-between'} w={['100%', '15%']}>
          <Text fontSize="14px" fontWeight="bold" color="#36355F">
            Servicio HACCP
          </Text>
          <Checkbox
            size="md"
            colorScheme="blue"
            name="HACCP"
            isChecked={serviceTypeChecks.includes('HACCP')}
            onChange={handleChangeCheckType}
            borderColor="grey"
          ></Checkbox>
        </HStack>
        <HStack justify={'space-between'} w={['100%', '15%']}>
          <Text fontSize="14px" fontWeight="bold" color="#36355F">
            Servicio garantía
          </Text>
          <Checkbox
            size="md"
            colorScheme="blue"
            name="Warranty"
            isChecked={serviceTypeChecks.includes('Warranty')}
            onChange={handleChangeCheckType}
            borderColor="grey"
          ></Checkbox>
        </HStack>
        <HStack justify={'space-between'} w={['100%', '15%']}>
          <Text fontSize="14px" fontWeight="bold" color="#36355F">
            Servicio Esporádico
          </Text>
          <Checkbox
            size="md"
            colorScheme="blue"
            name="Sporadic"
            isChecked={serviceTypeChecks.includes('Sporadic')}
            onChange={handleChangeCheckType}
            borderColor="grey"
          ></Checkbox>
        </HStack>
        <Text fontSize="10px" color="#36355F">
          Tipo de servicio
        </Text>
        <HStack justify={'space-between'} w={['100%', '15%']}>
          <Text fontSize="14px" fontWeight="bold" color="#36355F">
            DR
          </Text>
          <Checkbox
            size="md"
            colorScheme="blue"
            name="DR"
            isChecked={serviceProvidedChecks.includes('DR')}
            onChange={handleChangeCheckProvided}
            borderColor="grey"
          ></Checkbox>
        </HStack>
        <HStack justify={'space-between'} w={['100%', '15%']}>
          <Text fontSize="14px" fontWeight="bold" color="#36355F">
            DS (rastrero)
          </Text>
          <Checkbox
            size="md"
            colorScheme="blue"
            name="Crawling"
            isChecked={serviceProvidedChecks.includes('Crawling')}
            onChange={handleChangeCheckProvided}
            borderColor="grey"
          ></Checkbox>
        </HStack>
        <HStack justify={'space-between'} w={['100%', '15%']}>
          <Text fontSize="14px" fontWeight="bold" color="#36355F">
            DS (cucarachas)
          </Text>
          <Checkbox
            size="md"
            colorScheme="blue"
            name="Cockroach"
            isChecked={serviceProvidedChecks.includes('Cockroach')}
            onChange={handleChangeCheckProvided}
            borderColor="grey"
          ></Checkbox>
        </HStack>
        <HStack justify={'space-between'} w={['100%', '15%']}>
          <Text fontSize="14px" fontWeight="bold" color="#36355F">
            DS (voladores)
          </Text>
          <Checkbox
            size="md"
            colorScheme="blue"
            name="Flyers"
            isChecked={serviceProvidedChecks.includes('Flyers')}
            onChange={handleChangeCheckProvided}
            borderColor="grey"
          ></Checkbox>
        </HStack>
        <HStack justify={'space-between'} w={['100%', '15%']}>
          <Text fontSize="14px" fontWeight="bold" color="#36355F">
            SN
          </Text>
          <Checkbox
            size="md"
            colorScheme="blue"
            name="SN"
            isChecked={serviceProvidedChecks.includes('SN')}
            onChange={handleChangeCheckProvided}
            borderColor="grey"
          ></Checkbox>
        </HStack>
        <HStack justify={'space-between'} w={['100%', '15%']}>
          <Text fontSize="14px" fontWeight="bold" color="#36355F">
            Otro
          </Text>
          <Checkbox
            size="md"
            colorScheme="blue"
            name="Other"
            isChecked={serviceProvidedChecks.includes('Other')}
            onChange={handleChangeCheckProvided}
            borderColor="grey"
          ></Checkbox>
        </HStack>
        <HStack justify={'space-between'} w={['100%', '15%']}>
          {serviceProvidedChecks.includes('Other') && (
            <FormControl
              id="otherDescriptionService"
              isInvalid={
                formik.touched.otherDescriptionService &&
                Boolean(formik.errors.otherDescriptionService)
              }
            >
              <FormLabel>Especifique servicio</FormLabel>
              <Input
                name="otherDescriptionService"
                value={formik.values.otherDescriptionService}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Especifique servicio"
              />
              <FormErrorMessage>
                {formik.touched.otherDescriptionService &&
                  formik.errors.otherDescriptionService}
              </FormErrorMessage>
            </FormControl>
          )}
        </HStack>
        {/* --- */}
        <Flex w="100%" justify="end">
          <Button
            colorScheme={!isErrorRodentInfo ? `teal` : `red`}
            size="sm"
            isLoading={isLoadingRodentInfo}
            loadingText="Refrescando"
            spinnerPlacement="end"
            onClick={refetchRodentInfo}
          >
            {!isErrorRodentInfo
              ? `Refrescar información de Actividad de plagas`
              : `Error al actualizar datos`}
          </Button>
        </Flex>
        {/* --- */}
        <Box w="100%" bg="#F2F1FB" pb={5}>
          <Flex spacing={10} align="left" pt="15px">
            <Stack spacing={2} pl={30}>
              <Text fontSize="10px" color="#36355F">
                Actividad de plagas
              </Text>
              <Text fontSize="17px" fontWeight="bold" color="#36355F">
                Control Químico de roedores
              </Text>
            </Stack>
          </Flex>
        </Box>
        <FormControl
          id="rodenticideStations"
          isInvalid={
            formik.touched.rodenticideStations &&
            Boolean(formik.errors.rodenticideStations)
          }
        >
          <FormLabel>N° de estaciones rodenticidas</FormLabel>
          <Input
            name="rodenticideStations"
            value={formik.values.rodenticideStations}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="N° de estaciones rodenticidas"
            type={'number'}
          />
          <FormErrorMessage>
            {formik.touched.rodenticideStations &&
              formik.errors.rodenticideStations}
          </FormErrorMessage>
        </FormControl>
        <HStack spacing={8} w="100%">
          <FormControl
            id="moldyBaits"
            isInvalid={
              formik.touched.moldyBaits && Boolean(formik.errors.moldyBaits)
            }
          >
            <FormLabel>Cebos con Moho </FormLabel>
            <Input
              name="moldyBaits"
              value={formik.values.moldyBaits}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Cebos con Moho"
              type={'number'}
            />
            <FormErrorMessage>
              {formik.touched.moldyBaits && formik.errors.moldyBaits}
            </FormErrorMessage>
          </FormControl>
          <FormControl
            id="gnawedBaits"
            isInvalid={
              formik.touched.gnawedBaits && Boolean(formik.errors.gnawedBaits)
            }
          >
            <FormLabel>N° de cebos Roidos</FormLabel>
            <Input
              name="gnawedBaits"
              value={formik.values.gnawedBaits}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="N° de cebos Roidos"
              type={'number'}
            />
            <FormErrorMessage>
              {formik.touched.gnawedBaits && formik.errors.gnawedBaits}
            </FormErrorMessage>
          </FormControl>
        </HStack>
        <FormControl
          id="snailBaits"
          isInvalid={
            formik.touched.snailBaits && Boolean(formik.errors.snailBaits)
          }
        >
          <FormLabel>Cebos consumidos por caracoles</FormLabel>
          <Input
            name="snailBaits"
            value={formik.values.snailBaits}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Cebos consumidos por caracoles"
            type={'number'}
          />
          <FormErrorMessage>
            {formik.touched.snailBaits && formik.errors.snailBaits}
          </FormErrorMessage>
        </FormControl>
        <FormControl
          id="otherBaits"
          isInvalid={
            formik.touched.otherBaits && Boolean(formik.errors.otherBaits)
          }
        >
          <FormLabel>Otros</FormLabel>
          <Input
            name="otherBaits"
            value={formik.values.otherBaits}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Otros"
            type={'number'}
          />
          <FormErrorMessage>
            {formik.touched.otherBaits && formik.errors.otherBaits}
          </FormErrorMessage>
        </FormControl>
        <Box w="100%" bg="#F2F1FB" pb={5}>
          <Flex spacing={10} align="left" pt="15px">
            <Stack spacing={2} pl={30}>
              <Text fontSize="10px" color="#36355F">
                Actividad de plagas
              </Text>
              <Text fontSize="17px" fontWeight="bold" color="#36355F">
                Control NO Químico de Roedores
              </Text>
            </Stack>
          </Flex>
        </Box>
        <HStack spacing={8} w="100%">
          <FormControl
            id="adhesiveStations"
            isInvalid={
              formik.touched.adhesiveStations &&
              Boolean(formik.errors.adhesiveStations)
            }
          >
            <FormLabel>Cantidad de estaciones adhesiva</FormLabel>
            <Input
              name="adhesiveStations"
              value={formik.values.adhesiveStations}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Cantidad de estaciones adhesiva"
              type={'number'}
            />
            <FormErrorMessage>
              {formik.touched.adhesiveStations &&
                formik.errors.adhesiveStations}
            </FormErrorMessage>
          </FormControl>
          <FormControl
            id="adhesiveStationsCaptures"
            isInvalid={
              formik.touched.adhesiveStationsCaptures &&
              Boolean(formik.errors.adhesiveStationsCaptures)
            }
          >
            <FormLabel>N° de estaciones adhesivas con captura</FormLabel>
            <Input
              name="adhesiveStationsCaptures"
              value={formik.values.adhesiveStationsCaptures}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="N° de estaciones adhesivas con captura"
              type={'number'}
            />
            <FormErrorMessage>
              {formik.touched.adhesiveStationsCaptures &&
                formik.errors.adhesiveStationsCaptures}
            </FormErrorMessage>
          </FormControl>
        </HStack>
        <HStack spacing={8} w="100%">
          <FormControl
            id="liveStations"
            isInvalid={
              formik.touched.liveStations && Boolean(formik.errors.liveStations)
            }
          >
            <FormLabel>N° de trampas de captura viva</FormLabel>
            <Input
              name="liveStations"
              value={formik.values.liveStations}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="N° de trampas de captura viva"
              type={'number'}
            />
            <FormErrorMessage>
              {formik.touched.liveStations && formik.errors.liveStations}
            </FormErrorMessage>
          </FormControl>
          <FormControl
            id="liveStationsCaptures"
            isInvalid={
              formik.touched.liveStationsCaptures &&
              Boolean(formik.errors.liveStationsCaptures)
            }
          >
            <FormLabel>N° de estaciones con captura viva</FormLabel>
            <Input
              name="liveStationsCaptures"
              value={formik.values.liveStationsCaptures}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="N° de estaciones con captura"
              type={'number'}
            />
            <FormErrorMessage>
              {formik.touched.liveStationsCaptures &&
                formik.errors.liveStationsCaptures}
            </FormErrorMessage>
          </FormControl>
        </HStack>
        <Box w="100%" bg="#F2F1FB" pb={5}>
          <Flex spacing={10} align="left" pt="15px">
            <Stack spacing={2} pl={30}>
              <Text fontSize="10px" color="#36355F">
                Actividad de plagas
              </Text>
              <Text fontSize="17px" fontWeight="bold" color="#36355F">
                Otros controles
              </Text>
            </Stack>
          </Flex>
        </Box>
        <HStack spacing={8} w="100%">
          <FormControl
            id="doveCages"
            isInvalid={
              formik.touched.doveCages && Boolean(formik.errors.doveCages)
            }
          >
            <FormLabel>N° de Jaulas de palomas</FormLabel>
            <Input
              name="doveCages"
              value={formik.values.doveCages}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="N° de Jaulas de palomas"
              type={'number'}
            />
            <FormErrorMessage>
              {formik.touched.doveCages && formik.errors.doveCages}
            </FormErrorMessage>
          </FormControl>
          <FormControl
            id="doveCagesCaptures"
            isInvalid={
              formik.touched.doveCagesCaptures &&
              Boolean(formik.errors.doveCagesCaptures)
            }
          >
            <FormLabel>N° de aves capturada</FormLabel>
            <Input
              name="doveCagesCaptures"
              value={formik.values.doveCagesCaptures}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="N° de aves capturada"
              type={'number'}
            />
            <FormErrorMessage>
              {formik.touched.doveCagesCaptures &&
                formik.errors.doveCagesCaptures}
            </FormErrorMessage>
          </FormControl>
        </HStack>
        <HStack spacing={8} w="100%">
          <FormControl
            id="uvEquipment"
            isInvalid={
              formik.touched.uvEquipment && Boolean(formik.errors.uvEquipment)
            }
          >
            <FormLabel>N° de equipos U.V.</FormLabel>
            <Input
              name="uvEquipment"
              value={formik.values.uvEquipment}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="N° de equipos U.V."
              type={'number'}
            />
            <FormErrorMessage>
              {formik.touched.uvEquipment && formik.errors.uvEquipment}
            </FormErrorMessage>
          </FormControl>
          <FormControl
            id="flyTraps"
            isInvalid={
              formik.touched.flyTraps && Boolean(formik.errors.flyTraps)
            }
          >
            <FormLabel>Trampa de mosca exterior</FormLabel>
            <Input
              name="flyTraps"
              value={formik.values.flyTraps}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Trampa de mosca exterior"
              type={'number'}
            />
            <FormErrorMessage>
              {formik.touched.flyTraps && formik.errors.flyTraps}
            </FormErrorMessage>
          </FormControl>
        </HStack>
        <Box w="100%" bg="#F2F1FB" pb={5}>
          <Flex spacing={10} align="left" pt="15px">
            <Stack spacing={2} pl={30}>
              <Text fontSize="10px" color="#36355F">
                Actividad de plagas
              </Text>
              <Text fontSize="17px" fontWeight="bold" color="#36355F">
                N° de lote del plaguicida
              </Text>
            </Stack>
          </Flex>
        </Box>
        <HStack spacing={8} w="100%">
          <FormControl
            id="drSerial"
            isInvalid={
              formik.touched.drSerial && Boolean(formik.errors.drSerial)
            }
          >
            <FormLabel>DR</FormLabel>
            <Input
              name="drSerial"
              value={formik.values.drSerial}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="DR"
            />
            <FormErrorMessage>
              {formik.touched.drSerial && formik.errors.drSerial}
            </FormErrorMessage>
          </FormControl>
          <FormControl
            id="dsCrawling"
            isInvalid={
              formik.touched.dsCrawling && Boolean(formik.errors.dsCrawling)
            }
          >
            <FormLabel>DS (rastrero)</FormLabel>
            <Input
              name="dsCrawling"
              value={formik.values.dsCrawling}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="DS (rastrero)"
            />
            <FormErrorMessage>
              {formik.touched.dsCrawling && formik.errors.dsCrawling}
            </FormErrorMessage>
          </FormControl>
        </HStack>
        <HStack spacing={8} w="100%">
          <FormControl
            id="dsCockroach"
            isInvalid={
              formik.touched.dsCockroach && Boolean(formik.errors.dsCockroach)
            }
          >
            <FormLabel>DS (cucarachas)</FormLabel>
            <Input
              name="dsCockroach"
              value={formik.values.dsCockroach}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="DS (cucarachas)"
            />
            <FormErrorMessage>
              {formik.touched.dsCockroach && formik.errors.dsCockroach}
            </FormErrorMessage>
          </FormControl>
          <FormControl
            id="dsFlyers"
            isInvalid={
              formik.touched.dsFlyers && Boolean(formik.errors.dsFlyers)
            }
          >
            <FormLabel>DS (voladores)</FormLabel>
            <Input
              name="dsFlyers"
              value={formik.values.dsFlyers}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="DS (voladores)"
            />
            <FormErrorMessage>
              {formik.touched.dsFlyers && formik.errors.dsFlyers}
            </FormErrorMessage>
          </FormControl>
        </HStack>
        <FormControl
          id="snSerial"
          isInvalid={formik.touched.snSerial && Boolean(formik.errors.snSerial)}
        >
          <FormLabel>SN</FormLabel>
          <Input
            name="snSerial"
            value={formik.values.snSerial}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="SN"
          />
          <FormErrorMessage>
            {formik.touched.snSerial && formik.errors.snSerial}
          </FormErrorMessage>
        </FormControl>
      </VStack>
    )
  }

  const stepTwo = () => {
    return (
      <VStack spacing={8} py={12} px={6}>
        <Accordion allowMultiple w="100%">
          <AccordionItem>
            <AccordionButton>
              <Box w="100%" bg="#F2F1FB" pb={5}>
                <Flex spacing={10} align="left" pt="15px" px={10}>
                  <Text fontSize="15px" fontWeight={'bold'} color="#36355F">
                    Rodenticidas
                  </Text>
                  <Spacer />
                  <AccordionIcon />
                </Flex>
              </Box>
            </AccordionButton>
            <AccordionPanel>
              <DynamicProductForm
                queryFilters={{
                  serviceName: 'Desratización'
                }}
                defaultUnits={{
                  value: 'U',
                  quantity: 'Gr'
                }}
                emptyMsg="Libre de rodenticidas añadidos"
                fieldName="rodenticides"
                formik={formik}
              />
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton>
              <Box w="100%" bg="#F2F1FB" pb={5}>
                <Flex spacing={10} align="left" pt="15px" px={10}>
                  <Text fontSize="15px" fontWeight={'bold'} color="#36355F">
                    Insecticidas
                  </Text>
                  <Spacer />
                  <AccordionIcon />
                </Flex>
              </Box>
            </AccordionButton>
            <AccordionPanel>
              <DynamicProductForm
                queryFilters={{
                  serviceName: 'Desinsectación'
                }}
                defaultUnits={{
                  value: 'U',
                  quantity: 'Gr'
                }}
                emptyMsg="Libre de insecticidas añadidos"
                fieldName="incecticides"
                formik={formik}
              />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </VStack>
    )
  }

  const stepThree = () => {
    return (
      <VStack spacing={8} py={12} px={6}>
        <Accordion allowMultiple w="100%">
          <AccordionItem>
            <AccordionButton>
              <Box w="100%" bg="#F2F1FB" pb={5}>
                <Flex spacing={10} align="left" pt="15px" px={10}>
                  <Text fontSize="15px" fontWeight={'bold'} color="#36355F">
                    Sanitizantes
                  </Text>
                  <Spacer />
                  <AccordionIcon />
                </Flex>
              </Box>
            </AccordionButton>
            <AccordionPanel>
              <DynamicProductForm
                queryFilters={{
                  serviceName: 'Sanitización'
                }}
                defaultUnits={{
                  value: 'U',
                  quantity: 'Gr'
                }}
                emptyMsg="Libre de sanitizantes añadidos"
                fieldName="sanitizers"
                formik={formik}
              />
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton>
              <Box w="100%" bg="#F2F1FB" pb={5}>
                <Flex spacing={10} align="left" pt="15px" px={10}>
                  <Text fontSize="15px" fontWeight={'bold'} color="#36355F">
                    Estaciones de monitorero/control instaladas (nuevas)
                  </Text>
                  <Spacer />
                  <AccordionIcon />
                </Flex>
              </Box>
            </AccordionButton>
            <AccordionPanel>
              <FormControl
                id="newRodenticideStations"
                isInvalid={
                  formik.touched.newRodenticideStations &&
                  Boolean(formik.errors.newRodenticideStations)
                }
              >
                <FormLabel>Estación rodenticida</FormLabel>
                <Input
                  name="newRodenticideStations"
                  value={formik.values.newRodenticideStations}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Estación rodenticida"
                  type={'number'}
                />
                <FormErrorMessage>
                  {formik.touched.newRodenticideStations &&
                    formik.errors.newRodenticideStations}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                id="newStickyTraps"
                isInvalid={
                  formik.touched.newStickyTraps &&
                  Boolean(formik.errors.newStickyTraps)
                }
              >
                <FormLabel>Trampa pegajosa ratas (par)</FormLabel>
                <Input
                  name="newStickyTraps"
                  value={formik.values.newStickyTraps}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Trampa pegajosa ratas (par)"
                  type={'number'}
                />
                <FormErrorMessage>
                  {formik.touched.newStickyTraps &&
                    formik.errors.newStickyTraps}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                id="newTriangularDome"
                isInvalid={
                  formik.touched.newTriangularDome &&
                  Boolean(formik.errors.newTriangularDome)
                }
              >
                <FormLabel>Domo triangular</FormLabel>
                <Input
                  name="newTriangularDome"
                  value={formik.values.newTriangularDome}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Domo triangular"
                  type={'number'}
                />
                <FormErrorMessage>
                  {formik.touched.newTriangularDome &&
                    formik.errors.newTriangularDome}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                id="newLiveCapture"
                isInvalid={
                  formik.touched.newLiveCapture &&
                  Boolean(formik.errors.newLiveCapture)
                }
              >
                <FormLabel>Estación captura viva</FormLabel>
                <Input
                  name="newLiveCapture"
                  value={formik.values.newLiveCapture}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Estación captura viva"
                  type={'number'}
                />
                <FormErrorMessage>
                  {formik.touched.newLiveCapture &&
                    formik.errors.newLiveCapture}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                id="newStickyPlate"
                isInvalid={
                  formik.touched.newStickyPlate &&
                  Boolean(formik.errors.newStickyPlate)
                }
              >
                <FormLabel>Placa pegajosa EQ. UV.</FormLabel>
                <Input
                  name="newStickyPlate"
                  value={formik.values.newStickyPlate}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Placa pegajosa EQ. UV."
                  type={'number'}
                />
                <FormErrorMessage>
                  {formik.touched.newStickyPlate &&
                    formik.errors.newStickyPlate}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                id="newUV"
                isInvalid={formik.touched.newUV && Boolean(formik.errors.newUV)}
              >
                <FormLabel>Equipo Ultravioleta</FormLabel>
                <Input
                  name="newUV"
                  value={formik.values.newUV}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Equipo Ultravioleta"
                  type={'number'}
                />
                <FormErrorMessage>
                  {formik.touched.newUV && formik.errors.newUV}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                id="newOthers"
                isInvalid={
                  formik.touched.newOthers && Boolean(formik.errors.newOthers)
                }
              >
                <FormLabel>Otros Estaciones</FormLabel>
                <Input
                  name="newOthers"
                  value={formik.values.newOthers}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Otros"
                  type={'number'}
                />
                <FormErrorMessage>
                  {formik.touched.newOthers && formik.errors.newOthers}
                </FormErrorMessage>
              </FormControl>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </VStack>
    )
  }

  const stepFour = () => {
    return (
      <VStack spacing={8} py={12} px={6}>
        <Box w="100%" bg="#F2F1FB" pb={5}>
          <Flex spacing={10} align="left" pt="15px">
            <Stack spacing={2} pl={30}>
              <Text fontSize="17px" fontWeight="bold" color="#36355F">
                Zonas tratadas
              </Text>
            </Stack>
          </Flex>
        </Box>
        <HStack justify={'space-between'} w={['100%', '15%']}>
          <Text fontSize="14px" fontWeight="bold" color="#36355F">
            DR Perímetro
          </Text>
          <Checkbox
            size="md"
            colorScheme="blue"
            name="DRPerimeter"
            isChecked={treatedAreasChecks.includes('DRPerimeter')}
            onChange={handleChangeCheckAreas}
            borderColor="grey"
          ></Checkbox>
        </HStack>
        <HStack justify={'space-between'} w={['100%', '15%']}>
          <Text fontSize="14px" fontWeight="bold" color="#36355F">
            DR Estructural
          </Text>
          <Checkbox
            size="md"
            colorScheme="blue"
            name="DRStructural"
            isChecked={treatedAreasChecks.includes('DRStructural')}
            onChange={handleChangeCheckAreas}
            borderColor="grey"
          ></Checkbox>
        </HStack>
        <HStack justify={'space-between'} w={['100%', '15%']}>
          <Text fontSize="14px" fontWeight="bold" color="#36355F">
            DR Interior
          </Text>
          <Checkbox
            size="md"
            colorScheme="blue"
            name="DRInterior"
            isChecked={treatedAreasChecks.includes('DRInterior')}
            onChange={handleChangeCheckAreas}
            borderColor="grey"
          ></Checkbox>
        </HStack>
        <HStack justify={'space-between'} w={['100%', '15%']}>
          <Text fontSize="14px" fontWeight="bold" color="#36355F">
            DS Exterior
          </Text>
          <Checkbox
            size="md"
            colorScheme="blue"
            name="DSExterior"
            isChecked={treatedAreasChecks.includes('DSExterior')}
            onChange={handleChangeCheckAreas}
            borderColor="grey"
          ></Checkbox>
        </HStack>
        <HStack justify={'space-between'} w={['100%', '15%']}>
          <Text fontSize="14px" fontWeight="bold" color="#36355F">
            DS Interior
          </Text>
          <Checkbox
            size="md"
            colorScheme="blue"
            name="DSInterior"
            isChecked={treatedAreasChecks.includes('DSInterior')}
            onChange={handleChangeCheckAreas}
            borderColor="grey"
          ></Checkbox>
        </HStack>
        <FormControl
          id="observations"
          isInvalid={
            formik.touched.observations && Boolean(formik.errors.observations)
          }
        >
          <FormLabel>Observaciones</FormLabel>
          <Textarea
            name="observations"
            value={formik.values.observations}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Observaciones"
            h="20%"
            maxLength={1000}
          />
          <FormErrorMessage>
            {formik.touched.observations && formik.errors.observations}
          </FormErrorMessage>
        </FormControl>
        <FormControl
          id="start_time"
          isInvalid={
            formik.touched.start_time && Boolean(formik.errors.start_time)
          }
        >
          <FormLabel>Hora de entrada</FormLabel>
          <Input
            name="start_time"
            value={formik.values.start_time}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Hora entrada"
            size="md"
            type="time"
          />
        </FormControl>
        <FormControl
          id="end_time"
          isInvalid={formik.touched.end_time && Boolean(formik.errors.end_time)}
        >
          <FormLabel>Hora de salida</FormLabel>
          <Input
            name="end_time"
            value={formik.values.end_time}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Hora salida"
            size="md"
            type="time"
          />
        </FormControl>

        <Grid display="inline" float="center">
          <Text fontSize="17px" fontWeight="bold" color="#36355F" as="span">
            Medidas de seguridad:
          </Text>
          <Text fontSize="17px" color="#36355F" as="span">
            {' '}
            Es necesario el retiro de animales domésticos del área a tratar y
            evitar la presencia de terceros ajenos al servicio antes y después
            de realizado (El periodo re reingreso es 2 horas posteriores a la
            aplicación; ventilar antes de ingresar a la zona tratada),
            especialmente, seres de vida acuática (peces) y desconectar fuente
            de poder, alarmas y detectores de humo.
          </Text>
        </Grid>
        <UploadImage imgUpload={imgUpload} multiple />
        {images?.length > 0 && (
          <RenderImageContainer
            images={images}
            getNewArray={setImages}
            getNewArrayDelete={setImages}
          />
        )}
        <SignatureModal
          label="Firma del cliente"
          formik={formik}
          values={{
            fullName: formik.values?.fullName,
            signature: formik.values?.signature
          }}
          fieldsNames={{
            fullName: 'fullName',
            signature: 'signature'
          }}
          fieldRequired={{
            fullName: true,
            ruc: false
          }}
        />
      </VStack>
    )
  }

  const stepper = () => {
    if (step === 1) {
      return stepOne()
    }
    if (step === 2) {
      return stepTwo()
    }
    if (step === 3) {
      return stepThree()
    }
    if (step === 4) {
      return stepFour()
    }
    return null
  }
  return (
    <Stack spacing={8} py={12} px={6}>
      <Flex spacing={10} align="right" pt="15px">
        <Button
          onClick={handleBack}
          bg="#FFFFFF"
          color="#666587"
          border="1px"
          borderStyle="solid"
          borderRadius="lg"
          borderColor="#F2F1FB"
          leftIcon={<ArrowBackIcon />}
        >
          Documentos de visita
        </Button>
      </Flex>
      <Box w="100%" bg="#F2F1FB80" pb={5}>
        <Flex spacing={10} align="left" pt="15px">
          <Stack spacing={2} pl={30}>
            <Text fontSize="10px" color="#36355F">
              Cliente
            </Text>
            <Text fontSize="17px" fontWeight="bold" color="#36355F">
              {client}
            </Text>
          </Stack>
          <Stack spacing={2} pl={30}>
            <Text fontSize="10px" color="#36355F">
              Sucursal
            </Text>
            <Text fontSize="17px" fontWeight="bold" color="#36355F">
              {branch}
            </Text>
          </Stack>
        </Flex>
      </Box>
      {ort ? (
        <>
          <Box w="100%" bg="#F2F1FB" pb={5}>
            <Flex spacing={10} align="left" pt="15px">
              <Stack spacing={2} pl={30}>
                <Text fontSize="15px" color="#36355F">
                  <Icon mr="4" fontSize="22" as={OTRIcon} color="#36355F" />
                  ORT
                </Text>
              </Stack>
              <Spacer />
              <Stack spacing={2} pr={30}>
                <Text fontSize="10px" color="#36355F">
                  Serial {ort.id}
                </Text>
              </Stack>
            </Flex>
            <Stack spacing={2} px={30}>
              <Flex spacing={10} align="left" pt="15px">
                <Text fontSize="10px" color="#36355F">
                  Paginas
                </Text>
                <Spacer />
                <Box
                  as="button"
                  px={4}
                  py={2}
                  bg={step === 1 ? '#59BABA' : '#F2F1FB'}
                  borderRadius="lg"
                  display="flex"
                  onClick={() => changeStep(1)}
                >
                  <Text
                    fontSize="15px"
                    fontWeight="bold"
                    color={step === 1 ? '#FFFFFF' : '#59BABA'}
                    m="auto"
                  >
                    1
                  </Text>
                </Box>
                <Spacer />
                <Box
                  as="button"
                  px={4}
                  py={2}
                  bg={step === 2 ? '#59BABA' : '#F2F1FB'}
                  borderRadius="lg"
                  display="flex"
                  onClick={() => changeStep(2)}
                >
                  <Text
                    fontSize="15px"
                    fontWeight="bold"
                    color={step === 2 ? '#FFFFFF' : '#59BABA'}
                    m="auto"
                  >
                    2
                  </Text>
                </Box>
                <Spacer />
                <Box
                  as="button"
                  px={4}
                  py={2}
                  bg={step === 3 ? '#59BABA' : '#F2F1FB'}
                  borderRadius="lg"
                  display="flex"
                  onClick={() => changeStep(3)}
                >
                  <Text
                    fontSize="15px"
                    fontWeight="bold"
                    color={step === 3 ? '#FFFFFF' : '#59BABA'}
                    m="auto"
                  >
                    3
                  </Text>
                </Box>
                <Spacer />
                <Box
                  as="button"
                  px={4}
                  py={2}
                  bg={step === 4 ? '#59BABA' : '#F2F1FB'}
                  borderRadius="lg"
                  display="flex"
                  onClick={() => changeStep(4)}
                >
                  <Text
                    fontSize="15px"
                    fontWeight="bold"
                    color={step === 4 ? '#FFFFFF' : '#59BABA'}
                    m="auto"
                  >
                    4
                  </Text>
                </Box>
              </Flex>
            </Stack>
          </Box>
          {stepper()}
          <Stack spacing={10} align={'center'}>
            <Flex spacing={10} align="center" pt="15px">
              {step > 1 ? (
                <Button
                  align="center"
                  isLoading={loadingUpdate}
                  onClick={prevStep}
                  bg="#F2F1FB"
                  color="#59BABA"
                  disabled={loadingUpdate}
                  mr={5}
                >
                  Regresar
                </Button>
              ) : null}
              {step > 3 ? null : (
                <Button
                  align="center"
                  isLoading={loadingUpdate}
                  onClick={nextStep}
                  bg={'teal.500'}
                  color={'white'}
                  _hover={{
                    bg: 'teal.600'
                  }}
                  disabled={loadingUpdate}
                >
                  Siguiente
                </Button>
              )}
            </Flex>
            {step > 3 ? (
              <Button
                align="center"
                isLoading={loadingUpdate}
                onClick={handleClickConfirm}
                bg="#FFFFFF"
                color="#59BABA"
                border="1px"
                borderColor="#59BABA"
                disabled={loadingUpdate || !formik.isValid}
              >
                Guardar documento
              </Button>
            ) : null}
          </Stack>
        </>
      ) : (
        <Stack>
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
        </Stack>
      )}
      {openConfirm && (
        <ConfirmModal
          isOpen={openConfirm}
          onClose={closeConfirm}
          route={`/visitas/${ort.id_visit}`}
          confirm={confirm}
          setConfirm={setConfirm}
          handleSubmit={formik.handleSubmit}
          loading={loadingUpdate}
        />
      )}
    </Stack>
  )
}

export default ORT
