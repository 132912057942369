import { useQuery, useMutation, useQueryClient } from 'react-query'
import {
  getClients,
  getAllClients,
  getOneClient,
  createClient,
  updateClient,
  deleteClient,
  dashClient,
  xlsxToxic,
  xlsxNonToxic,
  xlsxDoves,
  xlsxInsects,
  getAllBranches,
  getAreas,
  getVisits,
  getDocuments,
  createMassive
} from '../api/clients'

const getAction = (type) => {
  if (type === 'CREATE') return createClient
  if (type === 'UPDATE') return updateClient
  if (type === 'DELETE') return deleteClient
  return null
}

const getXlsx = (type) => {
  if (type === 'TOXIC') return xlsxToxic
  if (type === 'NONTOXIC') return xlsxNonToxic
  if (type === 'DOVES') return xlsxDoves
  if (type === 'INSECTS') return xlsxInsects
  return null
}

export function useClients(page, formData, props = {}) {
  return useQuery(
    ['clients', page, formData],
    () => getClients(page, formData),
    {
      ...props,
      onError: (err) => {
        console.log(err.response)
      }
    }
  )
}

export function useAllClients() {
  return useQuery(['clientsAll'], () => getAllClients(), {
    onError: (err) => {
      console.log(err.response)
    }
  })
}

export function useClient(clientId) {
  return useQuery(['client', clientId], () => getOneClient(clientId), {
    onError: (err) => {
      console.log(err.response)
    }
  })
}

export function useMutateClient(type) {
  const queryClient = useQueryClient()
  const actionFn = getAction(type)
  return useMutation(actionFn, {
    onSuccess: () => {
      queryClient.invalidateQueries('client')
      queryClient.invalidateQueries('clients')
    },
    onError: (err) => {
      console.log(err.response)
    }
  })
}

export function useDashClient(formData) {
  return useQuery(
    ['dashboard'],
    () => dashClient(formData),
    { enabled: true, refetchOnWindowFocus: false },
    {
      onError: (err) => {
        console.log(err.response)
      }
    }
  )
}

export function useXlsxClient(type, formData) {
  const xlsxFn = getXlsx(type)
  return useQuery(
    ['xlsx'],
    () => xlsxFn(formData),
    { enabled: false },
    {
      onError: (err) => {
        console.log(err.response)
      }
    }
  )
}

export function useMutateXlsx(type) {
  const queryClient = useQueryClient()
  const actionFn = getXlsx(type)
  return useMutation(actionFn, {
    onSuccess: () => {
      queryClient.invalidateQueries('xlsx')
    },
    onError: (err) => {
      console.log(err.response)
    }
  })
}

export function useAllBranchs(clientId) {
  return useQuery(['branchsAll', clientId], () => getAllBranches(clientId), {
    onError: (err) => {
      console.log(err.response)
    }
  })
}

export function useAreas(clientId) {
  return useQuery(['areas', clientId], () => getAreas(clientId), {
    onError: (err) => {
      console.log(err.response)
    }
  })
}

export function useVisits(clientId, page, props = {}) {
  return useQuery(['visits', clientId, page], () => getVisits(clientId, page), {
    ...props,
    onError: (err) => {
      console.log(err.response)
    }
  })
}

export function useDocuments({ clientId, dateFilter }, props = {}) {
  return useQuery(
    ['client_documents', clientId, dateFilter],
    () => getDocuments({ clientId, dateFilter }),
    {
      ...props,
      onError: (err) => {
        console.log(err.response)
      }
    }
  )
}

export function useMutateMassive() {
  const queryClient = useQueryClient()
  return useMutation(createMassive, {
    onSuccess: () => {
      queryClient.invalidateQueries('client')
      queryClient.invalidateQueries('clients')
    },
    onError: (err) => {
      console.log(err.response)
    }
  })
}
