import axios from '../Axios'

export const getUsers = async (page = 1, formData, size = 10) => {
  const { data } = await axios.get(
    `user/all?page=${page}&size=${size}${
      formData.search ? `&search=${formData.search}` : ''
    }${formData.role ? `&role=${formData.role}` : ''}`
  )
  return data
}

export const getOneUser = async (userId) => {
  const { data } = await axios.get(`user/get-one?id=${userId}`)
  return data
}

export const createUser = async (formData) => {
  const { data } = await axios.post(`user/`, formData)
  return data
}

export const updateUser = async (formData) => {
  const { data } = await axios.patch(`user/`, formData)
  return data
}

export const updateUserProfile = async (formData) => {
  const { data } = await axios.patch(`user/profile`, formData)
  return data
}

export const deleteUser = async (userId) => {
  console.log(userId)
  const { data } = await axios.delete(`user/?id=${userId}`)
  return data
}
