import axios from '../Axios'

export const getDailies = async (page = 1, formData, size = 10) => {
  const { data } = await axios.get(
    `daily/all?page=${page}&size=${size}${
      formData.userId ? `&id_user=${formData.userId}` : ''
    }${formData.startDate ? `&start_date=${formData.startDate}` : ''}${
      formData.endDate ? `&end_date=${formData.endDate}` : ''
    }`
  )
  return data
}

export const getOneDaily = async (dailyId) => {
  const { data } = await axios.get(`daily/get-one?id_daily=${dailyId}`)
  return data
}

export const createDaily = async (formData) => {
  const { data } = await axios.post(`daily/`, formData)
  return data
}

export const updateDaily = async (formData) => {
  const { data } = await axios.patch(`daily/`, formData)
  return data
}

export const deleteDaily = async (dailyId) => {
  const { data } = await axios.delete(`daily/?id=${dailyId}`)
  return data
}
