import { Redirect } from 'react-router-dom'
import Layout from '../components/Layout/Layout'
import Home from '../pages/Home/Home'
import Login from '../pages/Login/Login'
import RecoverPass from '../pages/Login/RecoverPass'
import Clients from '../pages/Clients/Clients'
import Products from '../pages/Products/Products'
import ProductForm from '../pages/Products/ProductForm'
import ProductEditForm from '../components/Products/EditForm'
import ClientForm from '../pages/Clients/ClientForm'
import ClientDash from '../pages/Clients/ClientDash'
import Visits from '../pages/Visits/Visits'
import NewVisit from '../pages/Visits/NewVisit'
import VisitMenu from '../pages/Visits/VisitMenu'
import OTR from '../components/Documents/OTR'
import RodentRegistry from '../components/Documents/RodentRegistry'
import TUV from '../components/Documents/TUV'
import EPP from '../components/Documents/EPP'
import Vehicles from '../components/Checklists/Vehicles'
import Tools from '../components/Checklists/Tools'
import VisitReport from '../components/VisitReport/VisitReport'
import QualityControl from '../components/QualityControl/QualityControl'
import DailyActivities from '../components/DailyActivities/DailyActivities'
import Users from '../pages/Users/Users'
import UserForm from '../components/Users/UserForm'
import Documents from '../pages/Documents/Documents'
import NewPass from '../pages/NewPass/NewPass'
import EditDaily from '../components/DailyActivities/EditDaily'
import EditClient from '../components/Clients/EditClient'
import EditVehicles from '../components/Checklists/EditVehicles'
import Reportability from '../pages/Reportability/Reportability'
import EditUser from '../components/Users/EditUser'
import Profile from '../pages/Profile/Profile'
import EditTools from '../components/Checklists/EditTools'
import EditEPP from '../components/Documents/EditEPP'
import S3Files from '../pages/S3Files/S3Files'

const routes = [
  {
    path: '/',
    key: 'INDEX',
    exact: true,
    component: ({ isAuthenticated }) =>
      isAuthenticated ? <Redirect to="/home" /> : <Redirect to="/Login" />
  },
  {
    path: '/home',
    key: 'HOME',
    exact: true,
    component: ({ isAuthenticated }) =>
      isAuthenticated ? (
        <Layout>
          <Home />
        </Layout>
      ) : (
        <Redirect to="/login" />
      )
  },
  {
    path: '/login',
    key: 'LOGIN',
    exact: true,
    component: ({ isAuthenticated }) =>
      isAuthenticated ? <Redirect to="/home" /> : <Login />
  },
  {
    path: '/recuperar',
    key: 'RECOVER',
    exact: true,
    component: ({ isAuthenticated }) =>
      isAuthenticated ? <Redirect to="/home" /> : <RecoverPass />
  },
  {
    path: '/cambio_password/:token',
    key: 'NEWPASS',
    exact: true,
    component: ({ isAuthenticated }) =>
      isAuthenticated ? <Redirect to="/home" /> : <NewPass />
  },
  {
    path: '/clientes',
    key: 'CLIENTS',
    exact: true,
    component: ({ isAuthenticated }) =>
      isAuthenticated ? (
        <Layout>
          <Clients />
        </Layout>
      ) : (
        <Redirect to="/Login" />
      )
  },
  {
    path: '/clientes/nuevo',
    key: 'NEWCLIENT',
    exact: true,
    component: ({ isAuthenticated }) =>
      isAuthenticated ? (
        <Layout>
          <ClientForm />
        </Layout>
      ) : (
        <Redirect to="/Login" />
      )
  },
  {
    path: '/clientes/:clientId',
    key: 'EDITCLIENT',
    exact: true,
    component: ({ isAuthenticated }) =>
      isAuthenticated ? (
        <Layout>
          <EditClient />
        </Layout>
      ) : (
        <Redirect to="/Login" />
      )
  },
  {
    path: '/clientes/perfil/:clientId',
    key: 'DASHCLIENT',
    exact: true,
    component: ({ isAuthenticated }) =>
      isAuthenticated ? (
        <Layout>
          <ClientDash />
        </Layout>
      ) : (
        <Redirect to="/Login" />
      )
  },
  {
    path: '/productos',
    key: 'PRODUCTS',
    exact: true,
    component: ({ isAuthenticated }) =>
      isAuthenticated ? (
        <Layout>
          <Products />
        </Layout>
      ) : (
        <Redirect to="/Login" />
      )
  },
  {
    path: '/productos/nuevo',
    key: 'NEWPRODUCTS',
    exact: true,
    component: ({ isAuthenticated }) =>
      isAuthenticated ? (
        <Layout>
          <ProductForm />
        </Layout>
      ) : (
        <Redirect to="/Login" />
      )
  },
  {
    path: '/productos/:clientId',
    key: 'EDITPRODUCT',
    exact: true,
    component: ({ isAuthenticated }) =>
      isAuthenticated ? (
        <Layout>
          <ProductEditForm />
        </Layout>
      ) : (
        <Redirect to="/Login" />
      )
  },
  {
    path: '/archivos_s3',
    key: 'S3_FILES',
    exact: true,
    component: ({ isAuthenticated }) =>
      isAuthenticated ? (
        <Layout>
          <S3Files />
        </Layout>
      ) : (
        <Redirect to="/Login" />
      )
  },
  {
    path: '/visitas',
    key: 'VISITS',
    exact: true,
    component: ({ isAuthenticated }) =>
      isAuthenticated ? (
        <Layout>
          <Visits />
        </Layout>
      ) : (
        <Redirect to="/Login" />
      )
  },
  {
    path: '/visitas/nuevo',
    key: 'NEWVISIT',
    exact: true,
    component: ({ isAuthenticated }) =>
      isAuthenticated ? (
        <Layout>
          <NewVisit />
        </Layout>
      ) : (
        <Redirect to="/Login" />
      )
  },
  {
    path: '/visitas/:visitId',
    key: 'VISITMENU',
    exact: true,
    component: ({ isAuthenticated }) =>
      isAuthenticated ? (
        <Layout>
          <VisitMenu />
        </Layout>
      ) : (
        <Redirect to="/Login" />
      )
  },
  {
    path: '/ort/:ortId',
    key: 'ORT',
    exact: true,
    component: ({ isAuthenticated }) =>
      isAuthenticated ? (
        <Layout>
          <OTR />
        </Layout>
      ) : (
        <Redirect to="/Login" />
      )
  },
  {
    path: '/recr/:registryId',
    key: 'RECR',
    exact: true,
    component: ({ isAuthenticated }) =>
      isAuthenticated ? (
        <Layout>
          <RodentRegistry />
        </Layout>
      ) : (
        <Redirect to="/Login" />
      )
  },
  {
    path: '/tuv/:tuvId',
    key: 'TUV',
    exact: true,
    component: ({ isAuthenticated }) =>
      isAuthenticated ? (
        <Layout>
          <TUV />
        </Layout>
      ) : (
        <Redirect to="/Login" />
      )
  },
  {
    path: '/epp',
    key: 'EPP',
    exact: true,
    component: ({ isAuthenticated }) =>
      isAuthenticated ? (
        <Layout>
          <EPP />
        </Layout>
      ) : (
        <Redirect to="/Login" />
      )
  },
  {
    path: '/epp/:eppId',
    key: 'EDITEPP',
    exact: true,
    component: ({ isAuthenticated }) =>
      isAuthenticated ? (
        <Layout>
          <EditEPP />
        </Layout>
      ) : (
        <Redirect to="/Login" />
      )
  },
  {
    path: '/check_vehiculo',
    key: 'CVEHICLE',
    exact: true,
    component: ({ isAuthenticated }) =>
      isAuthenticated ? (
        <Layout>
          <Vehicles />
        </Layout>
      ) : (
        <Redirect to="/Login" />
      )
  },
  {
    path: '/check_vehiculo/:checkId',
    key: 'CVEHICLEEDIT',
    exact: true,
    component: ({ isAuthenticated }) =>
      isAuthenticated ? (
        <Layout>
          <EditVehicles />
        </Layout>
      ) : (
        <Redirect to="/Login" />
      )
  },
  {
    path: '/check_herramientas',
    key: 'CTOOLS',
    exact: true,
    component: ({ isAuthenticated }) =>
      isAuthenticated ? (
        <Layout>
          <Tools />
        </Layout>
      ) : (
        <Redirect to="/Login" />
      )
  },
  {
    path: '/check_herramientas/:checkId',
    key: 'CTOOLSEDIT',
    exact: true,
    component: ({ isAuthenticated }) =>
      isAuthenticated ? (
        <Layout>
          <EditTools />
        </Layout>
      ) : (
        <Redirect to="/Login" />
      )
  },
  {
    path: '/reporte_visita/:reportId',
    key: 'RVISIT',
    exact: true,
    component: ({ isAuthenticated }) =>
      isAuthenticated ? (
        <Layout>
          <VisitReport />
        </Layout>
      ) : (
        <Redirect to="/Login" />
      )
  },
  {
    path: '/calidad/:controlId',
    key: 'QUALITY',
    exact: true,
    component: ({ isAuthenticated }) =>
      isAuthenticated ? (
        <Layout>
          <QualityControl />
        </Layout>
      ) : (
        <Redirect to="/Login" />
      )
  },
  {
    path: '/diario',
    key: 'DAILY',
    exact: true,
    component: ({ isAuthenticated }) =>
      isAuthenticated ? (
        <Layout>
          <DailyActivities />
        </Layout>
      ) : (
        <Redirect to="/Login" />
      )
  },
  {
    path: '/diario/:dailyId',
    key: 'EDITDAILY',
    exact: true,
    component: ({ isAuthenticated }) =>
      isAuthenticated ? (
        <Layout>
          <EditDaily />
        </Layout>
      ) : (
        <Redirect to="/Login" />
      )
  },
  {
    path: '/usuarios',
    key: 'USERS',
    exact: true,
    component: ({ isAuthenticated }) =>
      isAuthenticated ? (
        <Layout>
          <Users />
        </Layout>
      ) : (
        <Redirect to="/Login" />
      )
  },
  {
    path: '/usuarios/nuevo',
    key: 'NEWUSER',
    exact: true,
    component: ({ isAuthenticated }) =>
      isAuthenticated ? (
        <Layout>
          <UserForm />
        </Layout>
      ) : (
        <Redirect to="/Login" />
      )
  },
  {
    path: '/usuarios/:userId',
    key: 'EDITUSER',
    exact: true,
    component: ({ isAuthenticated }) =>
      isAuthenticated ? (
        <Layout>
          <EditUser />
        </Layout>
      ) : (
        <Redirect to="/Login" />
      )
  },
  {
    path: '/documentos',
    key: 'DOCUMENTS',
    exact: true,
    component: ({ isAuthenticated }) =>
      isAuthenticated ? (
        <Layout>
          <Documents />
        </Layout>
      ) : (
        <Redirect to="/Login" />
      )
  },
  {
    path: '/reportabilidad',
    key: 'REPORTABILITY',
    exact: true,
    component: ({ isAuthenticated }) =>
      isAuthenticated ? (
        <Layout>
          <Reportability />
        </Layout>
      ) : (
        <Redirect to="/Login" />
      )
  },
  {
    path: '/perfil/:userId',
    key: 'PROFILE',
    exact: true,
    component: ({ isAuthenticated }) =>
      isAuthenticated ? (
        <Layout>
          <Profile />
        </Layout>
      ) : (
        <Redirect to="/Login" />
      )
  }
]

export default routes
