import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  Flex,
  Stack,
  HStack,
  Heading,
  Button,
  useToast,
  Skeleton,
  Grid,
  Input,
  FormControl,
  FormLabel
} from '@chakra-ui/react'
import Select from 'react-select'
import { AddIcon } from '@chakra-ui/icons'

import PreVisits from '../../components/Visits/PreVisits'
import { useVisits, useWeeklyDocs } from '../../hooks/visits'
import VisitsTable from '../../components/Visits/VisitsTable'
import Pagination from '../../components/Shared/Pagination'
import { updateFilterList } from '../../store/slices/visits'
import { useAllClients } from '../../hooks/clients'

// Página de visitas
const Visits = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const toast = useToast()
  const { user } = useSelector((state) => state.auth)
  const { filterList, client } = useSelector((state) => state.visits)
  const {
    data: clients,
    isLoading: isLoadingClients,
    isError: isErrorClients
  } = useAllClients()
  const [docsState, setDocsState] = useState(false)
  const [vehiclePlate, setVehiclePlate] = useState('')
  const [clientsSelect, setClientsSelect] = useState([])
  const {
    data: visits,
    isLoading: loadingVisits,
    error: errorVisits,
    refetch
  } = useVisits(filterList.page, {
    startDate: filterList.startDate,
    endDate: filterList.endDate,
    clientId: filterList.clientId
  })
  const {
    data: docs,
    isLoading: loadingDocs,
    error: errorDocs
  } = useWeeklyDocs({ userId: user.id, plate: vehiclePlate })

  useEffect(() => {
    if (errorVisits) {
      toast({
        title: `Error al cargar visitas`,
        status: 'error',
        isClosable: true
      })
    } else {
      toast.closeAll()
    }
  }, [errorVisits])

  const handleClick = () => {
    history.push(`/visitas/nuevo`)
  }

  // Verifica que se hayan completado los documentos semanales
  const checkDocs = () => {
    if (docs.vehicle && docs.tools && docs.epp) {
      setDocsState(true)
    } else {
      setDocsState(false)
    }
  }

  useEffect(() => {
    if (docs) {
      checkDocs()
    }
  }, [docs])

  const currentDate = () => {
    const newDate = new Date().toISOString().slice(0, -14)
    return newDate
  }

  const handleChangeFilterDate = (type, value) => {
    dispatch(
      updateFilterList({
        page: 1,
        [type]: value
      })
    )
  }

  useEffect(() => {
    async function fetch() {
      await refetch()
    }
    fetch()
  }, [filterList])

  useEffect(() => {
    if (client === '') {
      dispatch(
        updateFilterList({
          page: 1
        })
      )
    }
  }, [])

  //formateo para el select autocomplete
  useEffect(() => {
    if (clients) {
      const format = clients?.map((item) => ({
        value: item?.id,
        label: item.company_name || 'Sin nombre'
      }))

      setClientsSelect([{ value: '', label: 'TODOS' }, ...format])
    }
  }, [clients])

  return (
    <Stack spacing={8} px={6}>
      <Flex bg={'#FFF'}>
        <HStack spacing={8} justify={'space-between'} align={'center'}>
          <Stack align={'left'}>
            <Heading fontSize={'4xl'} color={'#36355F'}>
              Visitas
            </Heading>
          </Stack>
          <Stack spacing={10} align={'right'}>
            <Button
              onClick={handleClick}
              bg={'teal.500'}
              color={'white'}
              _hover={{
                bg: 'teal.600'
              }}
              leftIcon={<AddIcon />}
            >
              Nueva Visita
            </Button>
          </Stack>
        </HStack>
      </Flex>
      {!docsState && docs && (
        <PreVisits
          docs={docs}
          vehiclePlate={vehiclePlate}
          setVehiclePlate={setVehiclePlate}
        />
      )}
      {visits && (docsState || user.id_role === 1) ? (
        <>
          <Flex spacing="10px" align="left" justify="space-between" gap={2}>
            <FormControl id="area" flex="2">
              <FormLabel>Cliente</FormLabel>

              <Select
                className="basic-single"
                classNamePrefix="select"
                defaultValue={clientsSelect[0]}
                isDisabled={false}
                isClearable={false}
                isRtl={false}
                isSearchable={true}
                name="clientes"
                options={clientsSelect}
                isLoading={isLoadingClients}
                isError={isErrorClients}
                onChange={(item) =>
                  handleChangeFilterDate('clientId', item.value)
                }
              />
            </FormControl>
            {/* --- */}
            <FormControl id="startDate" flex="2">
              <FormLabel>Desde</FormLabel>
              <Input
                name="startDate"
                value={filterList.startDate}
                onChange={(e) =>
                  handleChangeFilterDate('startDate', e.target.value)
                }
                type="date"
                max={currentDate()}
              />
            </FormControl>
            <FormControl id="endDate" flex="2">
              <FormLabel>Hasta</FormLabel>
              <Input
                name="endDate"
                value={filterList.endDate}
                onChange={(e) =>
                  handleChangeFilterDate('endDate', e.target.value)
                }
                type="date"
                max={currentDate()}
              />
            </FormControl>
          </Flex>
          <VisitsTable data={visits.items} />
          <Grid m="auto" pt="2%" alignSelf={'center'}>
            <Pagination
              totalPages={Math.ceil(visits.total / 10)}
              actualPage={visits.page}
              setPage={(page) => {
                dispatch(
                  updateFilterList({
                    page
                  })
                )
              }}
            />
          </Grid>
        </>
      ) : (
        <Stack>
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
        </Stack>
      )}
    </Stack>
  )
}

export default Visits
