import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Image,
  Text,
  Textarea
} from '@chakra-ui/react'
import { DeleteIcon } from '../../components/Shared/Icons'
import _ from 'lodash'

const RenderImageContainer = ({
  images,
  haveComments = true,
  getNewArray = () => {},
  getNewArrayDelete = () => {}
}) => {
  //Obtener nombre de imagen
  const getFilename = (img) => {
    const index = img.lastIndexOf('/') + 1
    let filename = img.substr(index)
    const ext = filename.match(/\.([0-9a-z]+)(?:[\?#]|$)/i)[1] || '..'
    if (filename.length > 22) {
      filename = `${filename.slice(0, 20)}..`
    }

    return `${filename}${ext}`
  }

  //Actualiza los valores de la lista de imagenes
  const handleChangeImages = (event, position) => {
    const { name, value } = event.target
    const newArray = _.cloneDeep(images)
    newArray[position] = {
      ...newArray[position],
      [name]: value
    }
    getNewArray(newArray)
  }

  const handleDeleteImages = (position) => {
    const newArray = _.cloneDeep(images)
    newArray.splice(position, 1)
    getNewArrayDelete(newArray)
  }

  return (
    <Flex
      direction="column"
      justify="center"
      alignItems="center"
      gap={2}
      w="100%"
    >
      {images.map((img, idx) => (
        <Box w="100%" pb={5} borderRadius={'lg'} boxShadow={'md'} key={img.url}>
          <Flex gap={2} justify="space-between" p={2}>
            <HStack>
              <Image
                src={img.url}
                alt="Imagen"
                height={'20px'}
                width={'40px'}
                onClick={() => window.open(img.url, '_blank')}
                cursor="pointer"
              />
              <Text>{getFilename(img.url)}</Text>
            </HStack>
            <IconButton
              icon={<Icon fontSize="22" as={DeleteIcon} />}
              onClick={() => handleDeleteImages(idx)}
            />
          </Flex>
          {haveComments && (
            <FormControl id="observation">
              <FormLabel>Observaciones</FormLabel>
              <Textarea
                name="observation"
                value={img.observation}
                onChange={(event) => handleChangeImages(event, idx)}
                placeholder="Observaciones"
              />
            </FormControl>
          )}
        </Box>
      ))}
    </Flex>
  )
}

export default RenderImageContainer
