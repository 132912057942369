import { useQuery, useMutation, useQueryClient } from 'react-query'
import { getAllFiles, deleteFiles } from '../api/s3Files'

const getAction = (type) => {
  if (type === 'DELETE') return deleteFiles
  return null
}

export function useAllFiles(params) {
  return useQuery(['filesAll', params], () => getAllFiles(params), {
    onError: (err) => {
      console.log(err.response)
    }
  })
}

export function useMutateFiles(type) {
  const queryClient = useQueryClient()
  const actionFn = getAction(type)
  return useMutation(actionFn, {
    onSuccess: () => {
      queryClient.invalidateQueries('filesAll')
    },
    onError: (err) => {
      console.log(err.response)
    }
  })
}
