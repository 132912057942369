import { createSlice } from '@reduxjs/toolkit'

const token = window.localStorage.getItem('token') || null

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isAuthenticated: !!token,
    user: null
  },
  reducers: {
    login_success: (state, action) => {
      state.isAuthenticated = true
      state.user = action.payload.user
    },
    logout: (state) => {
      state.isAuthenticated = false
      state.user = null
    },
    get_authentication: (state, action) => {
      state.isAuthenticated = true
      state.user = action.payload
    }
  }
})
export const { login_success, logout, get_authentication } = authSlice.actions
/* --- */
export default authSlice.reducer
/* --- */
export const loginUser = (loginData) => (dispatch) => {
  window.localStorage.setItem('token', loginData.accessToken)
  window.localStorage.setItem('refreshToken', loginData.refreshToken)
  dispatch(login_success(loginData))
}
export const logoutUser = () => (dispatch) => {
  localStorage.removeItem('token')
  localStorage.removeItem('refreshToken')
  localStorage.clear()
  /* window.location.reload() */
  dispatch(logout())
}
export const loggedUser = (userData) => (dispatch) => {
  dispatch(get_authentication(userData))
}
