import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  Text,
  Button,
  Image,
  Grid
} from '@chakra-ui/react'
import Lottie from 'lottie-react'

import check from '../../assets/images/check.json'

// Modal de confirmación de guardado de cambios
const ConfirmModal = ({
  isOpen,
  onClose,
  route,
  confirm,
  setConfirm,
  handleSubmit,
  loading
}) => {
  const history = useHistory()

  useEffect(() => {
    setConfirm(false)
  }, [])

  const handleClick = () => {
    history.push(route)
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        {confirm ? (
          <>
            <ModalBody>
              <Text align="center" fontWeight={'bold'}>
                ¡Se han guardado los cambios!
              </Text>
              <Grid textAlign="center" w="80%" m="auto">
                <Grid m="auto" style={{ width: 150, height: 150 }}>
                  <Lottie
                    animationData={check}
                    loop={false}
                    autoPlay={confirm}
                  />
                </Grid>
              </Grid>
            </ModalBody>
            <ModalFooter>
              <Button
                align="center"
                bg="#F2F1FB"
                color="#59BABA"
                mr={5}
                onClick={() => window.location.reload()}
              >
                Cerrar
              </Button>
              <Button
                align="center"
                bg={'teal.500'}
                color={'white'}
                _hover={{
                  bg: 'teal.600'
                }}
                onClick={() => handleClick()}
                loading={loading}
                disabled={loading}
              >
                Ir a documentos
              </Button>
            </ModalFooter>
          </>
        ) : (
          <>
            <ModalBody>
              <Text align="center" fontWeight={'bold'}>
                ¿Estás seguro de querer guardar los cambios?
              </Text>
            </ModalBody>
            <ModalFooter>
              <Button
                align="center"
                bg="#F2F1FB"
                color="#59BABA"
                mr={5}
                onClick={onClose}
                loading={loading}
                disabled={loading}
              >
                Cancelar
              </Button>
              <Button
                align="center"
                bg={'teal.500'}
                color={'white'}
                _hover={{
                  bg: 'teal.600'
                }}
                onClick={handleSubmit}
                loading={loading}
                disabled={loading}
              >
                Guardar
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}

export default ConfirmModal
