import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerContent,
  DrawerCloseButton,
  Flex,
  Text,
  useDisclosure,
  Icon,
  Avatar,
  VStack,
  Box
} from '@chakra-ui/react'

import { LogOutIcon } from './MenuIcons'
import GreyLogo from '../../assets/images/greyLogo.svg'
import { logoutUser } from '../../store/slices/auth'

import Menu from './Menu'
import Header from './Header'

const LeftDrawer = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { user } = useSelector((state) => state.auth)

  const logout = () => {
    dispatch(logoutUser())
  }

  const handleProfile = () => {
    history.push(`/perfil/${user.id}`)
  }

  return (
    <>
      <Header onOpen={onOpen} />

      <Drawer placement="right" isOpen={isOpen} onClose={onClose}>
        <DrawerContent
          borderTopLeftRadius="40"
          borderBottomLeftRadius="40"
          bgColor="#FFFFFF"
        >
          <DrawerCloseButton />
          <DrawerHeader alignItems="center">
            <Flex h="10" alignItems="center" justifyContent="center">
              <img src={GreyLogo} width="100" />
            </Flex>
          </DrawerHeader>
          <DrawerBody>
            <Menu />
          </DrawerBody>
          <DrawerFooter display={'flex'} justifyContent={'center'}>
            <VStack display={'flex'} justifyContent={'center'} spacing={1}>
              <Box as="button" onClick={handleProfile}>
                <Flex
                  h="20"
                  alignItems="center"
                  mx="auto"
                  justifyContent="center"
                >
                  <Avatar
                    name="Avatar"
                    src={
                      'https://www.kindpng.com/picc/m/207-2074624_white-gray-circle-avatar-png-transparent-png.png'
                    }
                  />{' '}
                  <VStack pl={5}>
                    <Text
                      fontSize={['13px', '20px']}
                      color="#36355F"
                      fontWeight="bold"
                    >
                      {user.name}
                    </Text>
                    <Text fontSize={['9px', '15px']} color="#36355F">
                      {user.email}
                    </Text>
                  </VStack>
                </Flex>
              </Box>
              <Box
                border="1px"
                borderRadius={'lg'}
                borderColor="#cff7d3"
                p={2}
                as={'button'}
                onClick={logout}
              >
                <Flex alignItems="center" mx="auto" justifyContent="center">
                  <Icon mr="4" fontSize="22" as={LogOutIcon} />
                  <Text
                    fontSize="16px"
                    color="#365531"
                    fontWeight="bold"
                    paddingLeft="2"
                  >
                    Cerrar sesión
                  </Text>
                </Flex>
              </Box>
            </VStack>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default LeftDrawer
