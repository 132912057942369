import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
  Flex,
  Stack,
  Heading,
  Button,
  FormControl,
  FormLabel,
  Select,
  FormErrorMessage,
  Spacer,
  Input
} from '@chakra-ui/react'
import * as Yup from 'yup'
import { useFormik } from 'formik'

import { useAllClients } from '../../hooks/clients'
import { useMutateVisit } from '../../hooks/visits'
import SimpleSpinner from '../../components/Shared/loaders/SimpleSpinner'
import CheckInput from '../../components/Shared/inputs/CheckInput'
import SimpleInput from '../../components/Shared/inputs/SimpleInput'
import { TODAY_ISOSTRING } from '../../components/Shared/const'

const validationSchema = Yup.object().shape({
  id_client: Yup.string().required('Ingrese cliente'),
  id_branch: Yup.string().nullable(),
  area: Yup.string(),
  requiredCreatedAt: Yup.boolean(),
  customCreatedAt: undefined
})

// Formulario nueva visita
const NewVisit = () => {
  const history = useHistory()
  const { user } = useSelector((state) => state.auth)
  const { data: clients, isLoading: loadingGet, error } = useAllClients()
  const { mutate, isLoading: loadingPost, reset } = useMutateVisit('CREATE')

  const formik = useFormik({
    validationSchema,
    initialValues: {
      id_client: '',
      id_branch: null,
      area: '',
      created_by: user.id,
      requiredCreatedAt: false,
      customCreatedAt: new Date()
    },
    onSubmit: (values) => {
      if (!values.id_branch) {
        delete values.id_branch
      }
      mutate(values, {
        onSuccess: () => {
          reset()
          formik.resetForm()
          history.push(`/visitas`)
        },
        onError: (err) => {
          reset()
          console.log(err)
        }
      })
    }
  })
  const handleBack = () => {
    history.push(`/visitas`)
  }

  // Obtener lista de sucursales
  const branchs = (client) => {
    const branchsList = clients.find((data) => data.id.toString() === client)
    return branchsList ? branchsList.branchs : []
  }

  useEffect(() => {
    if (!formik.values.requiredCreatedAt) {
      formik.setFieldValue('customCreatedAt', undefined)
    }
  }, [formik.values.requiredCreatedAt])

  return (
    <Stack spacing={8} py={12} px={6}>
      <Stack align={'left'}>
        <Heading fontSize={'4xl'} color={'#36355F'}>
          Nueva Visita
        </Heading>
      </Stack>
      <Stack spacing={10} align={'center'}>
        {!loadingGet ? (
          <FormControl
            id="id_client"
            isInvalid={
              formik.touched.id_client && Boolean(formik.errors.id_client)
            }
          >
            <FormLabel>Cliente</FormLabel>
            <Select
              placeholder="Seleccione"
              name="id_client"
              onChange={formik.handleChange}
            >
              {clients.map((client) => (
                <option value={client.id}>
                  {client.company_name || client.name}
                </option>
              ))}
            </Select>
            <FormErrorMessage>
              {formik.touched.id_client && formik.errors.id_client}
            </FormErrorMessage>
          </FormControl>
        ) : (
          <SimpleSpinner />
        )}
        {formik.values.id_client ? (
          <>
            <FormControl
              id="id_branch"
              isInvalid={
                formik.touched.id_branch && Boolean(formik.errors.id_branch)
              }
            >
              <FormLabel>Sucursal</FormLabel>
              <Select
                placeholder="Seleccione"
                name="id_branch"
                onChange={formik.handleChange}
              >
                {branchs(formik.values.id_client).map((branch) => (
                  <option value={branch.id}>{branch.name}</option>
                ))}
              </Select>
              <FormErrorMessage>
                {formik.touched.id_branch && formik.errors.id_branch}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              id="area"
              isInvalid={formik.touched.area && Boolean(formik.errors.area)}
            >
              <FormLabel>Área del cliente</FormLabel>
              <Input
                name="area"
                value={formik.values.area}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Área del cliente"
              />
              <FormErrorMessage>
                {formik.touched.area && formik.errors.area}
              </FormErrorMessage>
            </FormControl>
            <CheckInput
              name="requiredCreatedAt"
              label="¿Desea Establecer una fecha de creación específica?"
              value={formik.values.requiredCreatedAt}
              onChange={formik.handleChange}
            />
            {formik.values.requiredCreatedAt && (
              <SimpleInput
                label="Fecha de Creación"
                name="customCreatedAt"
                type="date"
                max={TODAY_ISOSTRING}
                value={formik.values.customCreatedAt}
                errors={Boolean(formik.errors.customCreatedAt)}
                errorMsg={formik.errors.customCreatedAt}
                onChange={formik.handleChange}
              />
            )}
          </>
        ) : null}
      </Stack>
      <Flex spacing={10} align="right" pt="15px">
        <Spacer />
        <Button
          mr="5px"
          align="left"
          onClick={handleBack}
          bg="#F2F1FB"
          color="#59BABA"
          _hover={{
            bg: 'grey.800'
          }}
        >
          Cancelar
        </Button>
        <Button
          ml="5px"
          align="right"
          isLoading={loadingPost}
          onClick={formik.handleSubmit}
          bg="#59BABA"
          color={'white'}
          _hover={{
            bg: 'teal.600'
          }}
          disabled={loadingPost}
        >
          Siguiente
        </Button>
      </Flex>
    </Stack>
  )
}

export default NewVisit
