import React from 'react'

import { ChakraProvider, theme } from '@chakra-ui/react'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'
import store from './store'
import RenderRoutes from './routes/RenderRoutes'

const persistor = persistStore(store)

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ChakraProvider theme={theme}>
          <Router>
            <RenderRoutes style={{ zIndex: 1000 }} />
          </Router>
        </ChakraProvider>
      </PersistGate>
    </Provider>
  )
}

export default App
