import { useState } from 'react'
import {
  Flex,
  Box,
  HStack,
  Button,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Text,
  VStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Icon,
  useDisclosure,
  useToast
} from '@chakra-ui/react'

import { OptionsIcon } from '../Shared/Icons'
import { useMutateFiles } from '../../hooks/s3Files'
import DeleteModal from '../Shared/DeleteModal'

const DOCUMENTS_TYPE = [
  {
    value: 'ort',
    label: 'ORT'
  },
  {
    value: 'rodents_registry',
    label: 'Registro estación control de roedores'
  },
  {
    value: 'tuv',
    label: 'TUV para insectos voladores'
  },
  {
    value: 'quality_control',
    label: 'Control de calidad'
  },
  {
    value: 'visit',
    label: 'Reporte de visita'
  }
]

// Tabla de lista de clientes
const DocumentsTable = ({ data }) => {
  const toast = useToast()
  const [selectedClient, setSelectedClient] = useState(null)
  const { mutate } = useMutateFiles('DELETE')
  const {
    isOpen: openDelete,
    onOpen: onOpenDelete,
    onClose: closeDelete
  } = useDisclosure()

  // Eliminar usuario
  const handleDelete = (clientId) => {
    mutate(clientId, {
      onSuccess: () => {
        toast({
          title: `Archivo eliminado`,
          status: 'success',
          isClosable: true
        })
      },
      onError: () => {
        toast({
          title: `Error al eliminar archivo`,
          status: 'error',
          isClosable: true
        })
      }
    })
    closeDelete()
  }

  const handleClickSelect = (client) => {
    window.open(client.url)
  }

  return (
    <Flex bg={'#FFF'}>
      <Box w="100%">
        <TableContainer>
          <Table variant="simple" size="sm">
            <Thead>
              <Tr>
                <Th color="#36355F" fontWeight={'normal'}>
                  Tipo de documento
                </Th>
                <Th color="#36355F" fontWeight={'normal'}>
                  Area
                </Th>
                <Th color="#36355F" fontWeight={'normal'}>
                  Fecha de creación
                </Th>
                <Th color="#36355F" fontWeight={'normal'}>
                  Opciones
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.map((client) => (
                <Tr key={client.id}>
                  <Td style={{ whiteSpace: 'pre-wrap' }}>
                    <Text fontSize={['13px', '20px']} color="#36355F">
                      {
                        DOCUMENTS_TYPE.find(
                          (item) => item.value === client.type
                        ).label
                      }
                    </Text>
                  </Td>
                  <Td style={{ whiteSpace: 'pre-wrap' }}>
                    <Text fontSize={['13px', '20px']} color="#36355F">
                      {client.areaName}
                    </Text>
                  </Td>
                  <Td style={{ whiteSpace: 'pre-wrap' }}>
                    <Text fontSize={['13px', '20px']} color="#36355F">
                      {new Date(client.created_at).toLocaleDateString('es-ES')}
                    </Text>
                  </Td>
                  <Td>
                    <Menu>
                      <MenuButton
                        as={Button}
                        bg="#FFFFFF"
                        leftIcon={
                          <Icon
                            fontSize="22"
                            as={OptionsIcon}
                            color="#374957"
                          />
                        }
                      />
                      <MenuList>
                        <MenuItem
                          disabled={!client.pdf}
                          onClick={() => window.open(client.pdf)}
                        >
                          Descargar
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Td>
                </Tr>
              ))}

              {data.length === 0 && (
                <Tr>
                  <Td colSpan={4}>
                    <VStack>
                      <Text fontSize={['13px', '20px']} color="#36355F">
                        No hay archivos
                      </Text>
                    </VStack>
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
      {openDelete && (
        <DeleteModal
          isOpen={openDelete}
          onClose={closeDelete}
          message="¿Esta seguro de querer eliminar el archivo?"
          handleDelete={handleDelete}
          selected={selectedClient}
        />
      )}
    </Flex>
  )
}

export default DocumentsTable
