import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import {
  Stack,
  useToast,
  Skeleton,
  FormControl,
  FormLabel,
  Select as ChakraSelect,
  Input
} from '@chakra-ui/react'

import { useDocuments } from '../../hooks/clients'
import DocumentsTable from './DocumentsTable'

const DOCUMENTS_TYPE = [
  {
    value: 'ort',
    label: 'ORT'
  },
  {
    value: 'rodents_registry',
    label: 'Registro estación control de roedores'
  },
  {
    value: 'tuv',
    label: 'TUV para insectos voladores'
  },
  {
    value: 'quality_control',
    label: 'Control de calidad'
  },
  {
    value: 'visit',
    label: 'Reporte de visita'
  }
]

const startOfMonth = new Date(
  new Date().getFullYear(),
  new Date().getMonth(),
  1
)

// Listado de visitas de un cliente en específico
const Documents = () => {
  const { clientId } = useParams()
  const toast = useToast()
  const [typeFilter, setTypeFilter] = useState('')
  const [dateFilter, setDateFilter] = useState(
    `${startOfMonth.getFullYear()}-${(startOfMonth.getMonth() + 1)
      .toString()
      .padStart(2, '0')}`
  )
  const {
    data: documents,
    isLoading,
    error
  } = useDocuments({ clientId, dateFilter })

  useEffect(() => {
    if (error) {
      toast({
        title: `Error al cargar documentos`,
        status: 'error',
        isClosable: true
      })
    }
  }, [error])

  const applyFilter = (data) => {
    if (!data) return []
    if (typeFilter === '') return data
    return data.filter((item) => item.type === typeFilter)
  }

  const listItems = applyFilter(documents)

  return (
    <Stack spacing={8} px={6}>
      <Stack direction="row" spacing={4}>
        <FormControl>
          <FormLabel>Fecha</FormLabel>
          <Input
            value={dateFilter}
            onChange={(e) => setDateFilter(e.target.value)}
            type="month"
          />
        </FormControl>
        <FormControl>
          <FormLabel>Tipo de documento</FormLabel>
          <ChakraSelect
            placeholder="TODOS"
            onChange={(e) => setTypeFilter(e.target.value)}
          >
            {DOCUMENTS_TYPE?.map((b) => (
              <option value={b.value} key={b.value}>
                {b.label}
              </option>
            ))}
          </ChakraSelect>
        </FormControl>
      </Stack>
      {isLoading ? (
        <Stack>
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
        </Stack>
      ) : (
        <DocumentsTable data={listItems} />
      )}
    </Stack>
  )
}

export default Documents
