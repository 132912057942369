import { useQuery, useQueryClient, useMutation } from 'react-query'
import {
  getAllProducts,
  getPagProducts,
  createProducts,
  updateProducts,
  deleteProducts,
  getOneProduct
} from '../api/products'

const getAction = (type) => {
  if (type === 'CREATE') return createProducts
  if (type === 'UPDATE') return updateProducts
  if (type === 'DELETE') return deleteProducts
  return null
}

export function useAllProducts() {
  return useQuery(['productsAll'], () => getAllProducts(), {
    onError: (err) => {
      console.log(err.response)
    }
  })
}

export function useProduct(clientId) {
  return useQuery(['products', clientId], () => getOneProduct(clientId), {
    onError: (err) => {
      console.log(err.response)
    }
  })
}

export function usePaginatedProducts(page, formData) {
  return useQuery(['productsPaginated'], () => getPagProducts(page, formData), {
    onError: (err) => {
      console.log(err.response)
    }
  })
}

export function useMutateClient(type) {
  const queryClient = useQueryClient()
  const actionFn = getAction(type)
  return useMutation(actionFn, {
    onSuccess: () => {
      queryClient.invalidateQueries('productsAll')
      queryClient.invalidateQueries('productsPaginated')
    },
    onError: (err) => {
      console.log(err.response)
    }
  })
}
