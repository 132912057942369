import axios from '../Axios'

export const getOrts = async (page = 1, size = 10) => {
  const { data } = await axios.get(`ort/all?page=${page}&size=${size}`)
  return data
}

export const getOneOrt = async (ortId) => {
  const { data } = await axios.get(`ort/get-one?id_ort=${ortId}`)
  return data
}

export const createOrt = async (formData) => {
  const { data } = await axios.post(`ort/`, formData)
  return data
}

export const updateOrt = async (formData) => {
  const { data } = await axios.patch(`ort/`, formData)
  return data
}

export const deleteOrt = async (ortId) => {
  const { data } = await axios.delete(`ort/?id=${ortId}`)
  return data
}
