import { useEffect, useState } from 'react'
import { Switch, useHistory, useLocation } from 'react-router-dom'
import { renderRoutes } from 'react-router-config'
import { useSelector, useDispatch } from 'react-redux'

import { logoutUser } from '../store/slices/auth'
import { useMutateLogged } from '../hooks/login'
import routes from './routes'

//slice
import { resetVisits } from '../store/slices/visits'
import { resetClient } from '../store/slices/client'
import { resetUser } from '../store/slices/user'
import { resetDocument } from '../store/slices/document'

function RenderRoutes() {
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const { isAuthenticated } = useSelector((state) => state.auth)
  const { mutate } = useMutateLogged()
  const [token, setToken] = useState(window.localStorage.getItem('token'))

  useEffect(() => {
    setToken(window.localStorage.getItem('token'))
  }, [window.localStorage.getItem('token')])

  useEffect(() => {
    if (
      !location.pathname?.match(/visitas|ort|recr|tuv|reporte_visita|calidad/)
    ) {
      dispatch(resetVisits())
    }
    if (!location.pathname?.includes('clientes')) {
      dispatch(resetClient())
    }
    if (!location.pathname?.includes('usuarios')) {
      dispatch(resetUser())
    }
    if (
      !location.pathname?.match(
        /documentos|check_vehiculo|diario|check_herramientas|epp/
      )
    ) {
      dispatch(resetDocument())
    }
  }, [location.pathname])

  useEffect(() => {
    if (token && isAuthenticated) {
      mutate()
    } else if ((!token && isAuthenticated) || (token && !isAuthenticated)) {
      dispatch(logoutUser())
      window.localStorage.clear()
      history.push('/login')
    } else {
      window.localStorage.clear()
    }
  }, [])

  return <Switch>{renderRoutes(routes, { isAuthenticated })}</Switch>
}

export default RenderRoutes
