import { useState } from 'react'

const usePaginateTable = ({ totalItems = 0, limitPerPage = 10 }) => {
  const [page, setPage] = useState(0)
  const maxPage = Math.ceil(totalItems / limitPerPage)

  const onNextPage = () => setPage(page + 1)
  const onPrevPage = () => setPage(page - 1)

  const reset = () => setPage(0)

  return {
    page,
    limitPerPage,
    maxPage,
    onNextPage,
    onPrevPage,
    reset
  }
}

export default usePaginateTable
