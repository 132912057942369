import axios from '../Axios'

export const getClients = async (page = 1, formData, size = 10) => {
  const { data } = await axios.get(
    `client/all?page=${page}&size=${size}${
      formData.search ? `&search=${formData.search}` : ''
    }${formData.category ? `&category=${formData.category}` : ''}${
      formData.type ? `&type=${formData.type}` : ''
    }`
  )
  return data
}

export const getAllClients = async () => {
  const { data } = await axios.get(`client/all-no-pag`)
  return data
}

export const getOneClient = async (clientId) => {
  const { data } = await axios.get(`client/get-one?id_client=${clientId}`)
  return data
}

export const createClient = async (formData) => {
  const { data } = await axios.post(`client/`, formData)
  return data
}

export const updateClient = async (formData) => {
  const { data } = await axios.patch(`client/`, formData)
  return data
}

export const deleteClient = async (clientId) => {
  const { data } = await axios.delete(`client/?id=${clientId}`)
  return data
}

export const dashClient = async (formData) => {
  const { data } = await axios.get(
    `client/dash-client?id_client=${formData.clientId}${
      formData.year ? `&year=${formData.year}` : ''
    }${formData.month ? `&month=${formData.month}` : ''}${
      formData.branch ? `&id_branch=${formData.branch}` : ''
    }${formData.area ? `&area=${formData.area}` : ''}`
  )
  return data
}

export const xlsxToxic = async (formData) => {
  const { data } = await axios.post(
    `client/xlsx-rodents-toxic?id_client=${formData.clientId}${
      formData.startDate ? `&year=${formData.year}` : ''
    }${formData.endDate ? `&month=${formData.month}` : ''}${
      formData.branch ? `&id_branch=${formData.branch}` : ''
    }${formData.area ? `&area=${formData.area}` : ''}`
  )
  return data
}

export const xlsxNonToxic = async (formData) => {
  const { data } = await axios.post(
    `client/xlsx-rodents-non-toxic?id_client=${formData.clientId}${
      formData.startDate ? `&year=${formData.year}` : ''
    }${formData.endDate ? `&month=${formData.month}` : ''}${
      formData.branch ? `&id_branch=${formData.branch}` : ''
    }${formData.area ? `&area=${formData.area}` : ''}`
  )
  return data
}

export const xlsxDoves = async (formData) => {
  const { data } = await axios.post(
    `client/xlsx-doves?id_client=${formData.clientId}${
      formData.startDate ? `&year=${formData.year}` : ''
    }${formData.endDate ? `&month=${formData.month}` : ''}${
      formData.branch ? `&id_branch=${formData.branch}` : ''
    }${formData.area ? `&area=${formData.area}` : ''}`
  )
  return data
}

export const xlsxInsects = async (formData) => {
  const { data } = await axios.post(
    `client/xlsx-insects?id_client=${formData.clientId}${
      formData.startDate ? `&year=${formData.year}` : ''
    }${formData.endDate ? `&month=${formData.month}` : ''}${
      formData.branch ? `&id_branch=${formData.branch}` : ''
    }${formData.area ? `&area=${formData.area}` : ''}`
  )
  return data
}

export const getAllBranches = async (clientId) => {
  const { data } = await axios.get(`branch/all-no-pag?id_client=${clientId}`)
  return data
}

export const getAreas = async (clientId) => {
  const { data } = await axios.get(`client/areas?id_client=${clientId}`)
  return data
}

export const getVisits = async (clientId, page = 1, size = 10) => {
  const { data } = await axios.get(
    `client/visits?id_client=${clientId}&page=${page}&size=${size}`
  )
  return data
}

export const getDocuments = async ({ clientId, dateFilter }) => {
  const { data } = await axios.get(`client/documents`, {
    params: {
      id_client: clientId,
      start_date: dateFilter
    }
  })

  return data
}

export const createMassive = async (formData) => {
  const { data } = await axios.post(`client/massive`, formData)
  return data
}
