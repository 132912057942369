const categories = [
  {
    id: 1,
    name: 'Categoría 1'
  },
  {
    id: 2,
    name: 'Categoría 2'
  },
  {
    id: 3,
    name: 'Categoría 3'
  },
  {
    id: 4,
    name: 'Categoría 4'
  },
  {
    id: 5,
    name: 'Categoría 5'
  },
  {
    id: 6,
    name: 'Categoría 6'
  }
]

export default categories
