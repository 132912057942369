import axios from '../Axios'
import { useQuery } from 'react-query'

const getData = async (url) => {
  try {
    const { data } = await axios.get(`${url}`)
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const useGetRequest = (url, options = {}) => {
  return useQuery([url], () => getData(url), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    cacheTime: 0,
    ...options
  })
}
