import { Flex } from '@chakra-ui/react'

import Recover from '../../components/Login/Recover'

// Pantalla de recuperación de contraseña
const RecoverPass = () => {
  return (
    <Flex minH={'100vh'} align={'center'} justify={'center'} bg={'#FFF'}>
      <Recover />
    </Flex>
  )
}

export default RecoverPass
