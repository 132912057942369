import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input
} from '@chakra-ui/react'
import { formatRut } from '../../../utils/formatRut'

const RutInput = ({
  label,
  value,
  isInvalid,
  errorMessage,
  fieldName,
  formik,
  ...propsInput
}) => {
  const FormatRut = (value) => {
    const newValue = value.replace(/[^0-9Kk]/g, '')

    if (newValue.length > 12) {
      return newValue.length - 1
    }
    const result = formatRut(newValue)
    formik.setFieldValue(fieldName, result)
  }

  return (
    <FormControl id={fieldName} isInvalid={isInvalid}>
      <FormLabel>{label}</FormLabel>
      <Input
        name={fieldName}
        value={value}
        onChange={(e) => FormatRut(e.target.value)}
        {...propsInput}
      />
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  )
}

export default RutInput
