import {
  Bar,
  Cell,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  LabelList,
  Legend
} from 'recharts'

// Gráfico de insectos: barras
const InsectsGraph = ({ data, x }) => (
  <ResponsiveContainer width="100%" aspect={2}>
    <BarChart
      width={600}
      height={300}
      data={data}
      barSize={50}
      margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
    >
      <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
      <XAxis dataKey={'date'} />
      <YAxis
        domain={[0, (dataMax) => Math.ceil(dataMax * 1.1)]}
        allowDecimals={false}
      />
      <Legend
        layout="horizontal"
        verticalAlign="bottom"
        align="center"
        formatter={(value, entry, index) => (
          <span style={{ color: 'black' }}>{value}</span>
        )}
      />
      <Tooltip formatter={(value) => value} />
      <Bar dataKey="Mosca (Musca común)" fill="#80DAEC">
        <LabelList dataKey="Mosca (Musca común)" position="top" />
      </Bar>
      <Bar dataKey="Drosophila melanogaster (Mosca menor)" fill="#F3F3F7">
        <LabelList
          dataKey="Drosophila melanogaster (Mosca menor)"
          position="top"
        />
      </Bar>
      <Bar dataKey="Culex pipiens (Zancudos)" fill="#8181AF">
        <LabelList dataKey="Culex pipiens (Zancudos)" position="top" />
      </Bar>
      <Bar dataKey="Tenia pellionella (Polillas)" fill="#C7F0F7">
        <LabelList dataKey="Tenia pellionella (Polillas)" position="top" />
      </Bar>
      <Bar dataKey="Vespula Germanica (Avispas)" fill="#80BBDB">
        <LabelList dataKey="Vespula Germanica (Avispas)" position="top" />
      </Bar>
      <Bar dataKey="Apis mellifera (Abejas)" fill="#DDDDE5">
        <LabelList dataKey="Apis mellifera (Abejas)" position="top" />
      </Bar>
    </BarChart>
  </ResponsiveContainer>
)

export default InsectsGraph
