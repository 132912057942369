import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  lastTabDash: 0,
  filterList: {
    page: 1,
    search: '',
    type: '',
    role: null
  }
}

export const clientSlice = createSlice({
  name: 'client',
  initialState: initialState,
  reducers: {
    updateTabDatsh: (state, action) => {
      state.lastTabDash = action.payload.lastTabDash
    },
    updateFilterList: (state, action) => {
      state.filterList = {
        ...state.filterList,
        ...action.payload
      }
    },
    resetClient: (state) => {
      state.lastTabDash = initialState.lastTabDash
      state.filterList.page = initialState.filterList.page
      state.filterList.search = initialState.filterList.search
      state.filterList.type = initialState.filterList.type
      state.filterList.role = initialState.filterList.role
    }
  }
})
export const { updateTabDatsh, updateFilterList, resetClient } =
  clientSlice.actions
/* --- */
export default clientSlice.reducer
/* --- */
