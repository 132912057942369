import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  Flex,
  Stack,
  VStack,
  HStack,
  Box,
  Button,
  Icon,
  Spacer,
  Text,
  Textarea,
  Checkbox,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Grid,
  useDisclosure,
  Select
} from '@chakra-ui/react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import _ from 'lodash'

import { EPPIcon } from '../../components/Shared/Icons'
import WorkerForm from './WorkerForm'
import ConfirmModal from '../Shared/ConfirmModal'
import { useMutateEPP } from '../../hooks/epp'

const validationSchema = Yup.object().shape({
  workers: Yup.number().required('Ingrese trabajadores')
})

const EPP = () => {
  const history = useHistory()
  const [workersExtensions, setWorkersExtensions] = useState([])
  const [confirm, setConfirm] = useState(false)
  const { user } = useSelector((state) => state.auth)
  const { mutate, isLoading, reset } = useMutateEPP('CREATE')
  const {
    isOpen: openConfirm,
    onOpen: onOpenConfirm,
    onClose: closeConfirm
  } = useDisclosure()

  const formik = useFormik({
    validationSchema,
    initialValues: {
      workers: 0
    },
    onSubmit: (values) => {
      const formData = {
        workers: values.workers,
        created_by: user.id,
        inspected_workers: workersExtensions
      }
      mutate(formData, {
        onSuccess: () => {
          reset()
          formik.resetForm()
          setConfirm(true)
        },
        onError: (err) => {
          reset()
          console.log(err)
        }
      })
    }
  })

  // Modifica la lista de empleados de acuerdo a la cantidad indicada
  useEffect(() => {
    if (formik.values.workers > 0) {
      if (workersExtensions.length === 0) {
        const arr = Array.from({ length: formik.values.workers }, (v) => {
          return {
            worker_name: '',
            area: 0,
            job: 0,
            type: 0,
            monitored_helmet: false,
            status_helmet: '',
            image_helmet: '',
            monitored_helmet_harness: false,
            status_helmet_harness: '',
            image_helmet_harness: '',
            monitored_clear_eyewear: false,
            status_clear_eyewear: '',
            image_clear_eyewear: '',
            monitored_grey_eyewear: false,
            status_grey_eyewear: '',
            image_grey_eyewear: '',
            monitored_full_face: false,
            status_full_face: '',
            image_full_face: '',
            monitored_gas_filter: false,
            status_gas_filter: '',
            image_gas_filter: '',
            monitored_half_face: false,
            status_half_face: '',
            image_half_face: '',
            monitored_particles_filter: false,
            status_particles_filter: '',
            image_particles_filter: '',
            monitored_parachute_harness: false,
            status_parachute_harness: '',
            image_parachute_harness: '',
            monitored_life_line: false,
            status_life_line: '',
            image_life_line: '',
            monitored_lift_truck: false,
            status_lift_truck: '',
            image_lift_truck: '',
            monitored_multipurpose_glove: false,
            status_multipurpose_glove: '',
            image_multipurpose_glove: '',
            monitored_disposable_glove: false,
            status_disposable_glove: '',
            image_disposable_glove: '',
            monitored_nitrile_glove: false,
            status_nitrile_glove: '',
            image_nitrile_glove: '',
            monitored_disposable_sweatshop: false,
            status_disposable_sweatshop: '',
            image_disposable_sweatshop: '',
            monitored_gabardine_overall: false,
            status_gabardine_overall: '',
            image_gabardine_overall: '',
            monitored_footwear: false,
            status_footwear: '',
            image_footwear: '',
            monitored_geologist_vest: false,
            status_geologist_vest: '',
            image_geologist_vest: '',
            monitored_shock_absolver: false,
            status_shock_absolver: '',
            image_shock_absolver: '',
            monitored_legionary: false,
            status_legionary: '',
            image_legionary: '',
            monitored_boot: false,
            status_boot: '',
            image_boot: '',
            monitored_attachable_headset: false,
            status_attachable_headset: '',
            image_attachable_headset: '',
            monitored_cargo_pant: false,
            status_cargo_pant: '',
            image_cargo_pant: '',
            monitored_micropolar: false,
            status_micropolar: '',
            image_micropolar: '',
            monitored_long_sleeve_shirt: false,
            status_long_sleeve_shirt: '',
            image_long_sleeve_shirt: '',
            monitored_mask_support: false,
            status_mask_support: '',
            image_mask_support: '',
            monitored_face_shield_rub: false,
            status_face_shield_rub: '',
            image_face_shield_rub: '',
            monitored_road_overall: false,
            status_road_overall: '',
            image_road_overall: '',
            monitored_cabrette_glove: false,
            status_cabrette_glove: '',
            image_cabrette_glove: '',
            monitored_harness_brushcutter: false,
            status_harness_brushcutter: '',
            image_harness_brushcutter: '',
            monitored_transparent_mask: false,
            status_transparent_mask: '',
            image_transparent_mask: '',
            monitored_overall: false,
            status_overall: '',
            image_overall: '',
            monitored_winter_vest: false,
            status_winter_vest: '',
            image_winter_vest: '',
            monitored_winter_pants: false,
            status_winter_pants: '',
            image_winter_pants: '',
            monitored_first_layer: false,
            status_first_layer: '',
            image_first_layer: '',
            monitored_white_helmet: false,
            status_white_helmet: '',
            image_white_helmet: ''
          }
        })
        setWorkersExtensions(arr)
      } else {
        let dif = formik.values.workers - workersExtensions.length
        if (dif > 0) {
          const newArr = Array.from({ length: dif }, (v) => {
            return {
              worker_name: '',
              area: 0,
              job: 0,
              type: 0,
              monitored_helmet: false,
              status_helmet: '',
              image_helmet: '',
              monitored_helmet_harness: false,
              status_helmet_harness: '',
              image_helmet_harness: '',
              monitored_clear_eyewear: false,
              status_clear_eyewear: '',
              image_clear_eyewear: '',
              monitored_grey_eyewear: false,
              status_grey_eyewear: '',
              image_grey_eyewear: '',
              monitored_full_face: false,
              status_full_face: '',
              image_full_face: '',
              monitored_gas_filter: false,
              status_gas_filter: '',
              image_gas_filter: '',
              monitored_half_face: false,
              status_half_face: '',
              image_half_face: '',
              monitored_particles_filter: false,
              status_particles_filter: '',
              image_particles_filter: '',
              monitored_parachute_harness: false,
              status_parachute_harness: '',
              image_parachute_harness: '',
              monitored_life_line: false,
              status_life_line: '',
              image_life_line: '',
              monitored_lift_truck: false,
              status_lift_truck: '',
              image_lift_truck: '',
              monitored_multipurpose_glove: false,
              status_multipurpose_glove: '',
              image_multipurpose_glove: '',
              monitored_disposable_glove: false,
              status_disposable_glove: '',
              image_disposable_glove: '',
              monitored_nitrile_glove: false,
              status_nitrile_glove: '',
              image_nitrile_glove: '',
              monitored_disposable_sweatshop: false,
              status_disposable_sweatshop: '',
              image_disposable_sweatshop: '',
              monitored_gabardine_overall: false,
              status_gabardine_overall: '',
              image_gabardine_overall: '',
              monitored_footwear: false,
              status_footwear: '',
              image_footwear: '',
              monitored_geologist_vest: false,
              status_geologist_vest: '',
              image_geologist_vest: '',
              monitored_shock_absolver: false,
              status_shock_absolver: '',
              image_shock_absolver: '',
              monitored_legionary: false,
              status_legionary: '',
              image_legionary: '',
              monitored_boot: false,
              status_boot: '',
              image_boot: '',
              monitored_attachable_headset: false,
              status_attachable_headset: '',
              image_attachable_headset: '',
              monitored_cargo_pant: false,
              status_cargo_pant: '',
              image_cargo_pant: '',
              monitored_micropolar: false,
              status_micropolar: '',
              image_micropolar: '',
              monitored_long_sleeve_shirt: false,
              status_long_sleeve_shirt: '',
              image_long_sleeve_shirt: '',
              monitored_mask_support: false,
              status_mask_support: '',
              image_mask_support: '',
              monitored_face_shield_rub: false,
              status_face_shield_rub: '',
              image_face_shield_rub: '',
              monitored_road_overall: false,
              status_road_overall: '',
              image_road_overall: '',
              monitored_cabrette_glove: false,
              status_cabrette_glove: '',
              image_cabrette_glove: '',
              monitored_harness_brushcutter: false,
              status_harness_brushcutter: '',
              image_harness_brushcutter: '',
              monitored_transparent_mask: false,
              status_transparent_mask: '',
              image_transparent_mask: '',
              monitored_overall: false,
              status_overall: '',
              image_overall: '',
              monitored_winter_vest: false,
              status_winter_vest: '',
              image_winter_vest: '',
              monitored_winter_pants: false,
              status_winter_pants: '',
              image_winter_pants: '',
              monitored_first_layer: false,
              status_first_layer: '',
              image_first_layer: '',
              monitored_white_helmet: false,
              status_white_helmet: '',
              image_white_helmet: '',
              signature: '',
              ruc: ''
            }
          })
          const otherArr = [...workersExtensions]
          setWorkersExtensions(otherArr.concat(newArr))
        } else {
          let otherArr = [...workersExtensions]
          dif = Math.abs(dif)
          const newArr = _.dropRight(otherArr, dif)
          setWorkersExtensions(newArr)
        }
      }
    }
  }, [formik.values.workers])

  // Actualizar un atributo de un empleado inspeccionado
  const handleChange = (event, position) => {
    const { name, value } = event.target
    const newArray = [...workersExtensions]
    newArray[position] = {
      ...newArray[position],
      [name]: value
    }
    setWorkersExtensions(newArray)
  }

  // Actualizar lista de checks seleccionados
  const handleChangeCheck = (value, attr, position) => {
    const newArray = [...workersExtensions]
    newArray[position] = {
      ...newArray[position],
      [attr]: value
    }
    setWorkersExtensions(newArray)
  }

  const handleClickConfirm = () => {
    onOpenConfirm()
  }

  const handleBack = () => {
    history.goBack()
  }

  return (
    <Stack spacing={8} py={12} px={6}>
      <Flex>
        <Stack spacing={10} align={'center'}>
          <Button
            onClick={handleBack}
            bg="#FFFFFF"
            color="#666587"
            border="1px"
            borderStyle="solid"
            borderRadius="lg"
            borderColor="#F2F1FB"
          >
            Regresar
          </Button>
        </Stack>
      </Flex>
      <Box w="100%" bg="#F2F1FB" pb={5}>
        <Flex spacing={10} align="left" pt="15px">
          <Stack spacing={2} pl={30}>
            <Text fontSize="15px" color="#36355F">
              <Icon mr="4" fontSize="22" as={EPPIcon} color="#36355F" />
              Inspección EPP
            </Text>
          </Stack>
          <Spacer />
        </Flex>
      </Box>
      <FormControl
        id="workers"
        isInvalid={formik.touched.workers && Boolean(formik.errors.workers)}
      >
        <FormLabel>Cantidad de trabajadores</FormLabel>
        <Input
          name="workers"
          value={formik.values.workers}
          onChange={formik.handleChange}
          placeholder="Cantidad de trabajadores"
        />
        <FormErrorMessage>
          {formik.touched.workers && formik.errors.workers}
        </FormErrorMessage>
      </FormControl>
      {workersExtensions.map((area, idx) => (
        <WorkerForm
          key={idx}
          index={idx}
          values={area}
          handleChange={handleChange}
          handleChangeCheck={handleChangeCheck}
        />
      ))}
      <Stack spacing={10} align={'center'}>
        <Button
          align="center"
          isLoading={isLoading}
          onClick={handleClickConfirm}
          bg="#FFFFFF"
          color="#59BABA"
          border="1px"
          borderColor="#59BABA"
          disabled={isLoading}
        >
          Guardar documento
        </Button>
        {openConfirm && (
          <ConfirmModal
            isOpen={openConfirm}
            onClose={closeConfirm}
            route={`/visitas`}
            confirm={confirm}
            setConfirm={setConfirm}
            handleSubmit={formik.handleSubmit}
            loading={isLoading}
          />
        )}
      </Stack>
    </Stack>
  )
}

export default EPP
