import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  HStack,
  Box,
  Text,
  Textarea,
  FormControl,
  FormErrorMessage,
  Switch,
  Image,
  IconButton,
  Icon,
  useToast
} from '@chakra-ui/react'

import UploadImage from '../Shared/UploadImage'
import { getUrlImage } from '../../store/slices/fileUpload'
import { DeleteIcon } from '../Shared/Icons'
import CustomToast from '../Shared/CustomToast'

// Módulo de check para EPP
const CheckEPP = ({
  title,
  setValues,
  switchName,
  monitored,
  status,
  statusName,
  image,
  imageName,
  index
}) => {
  const toast = useToast()
  const dispatch = useDispatch()

  // Controlador de colores para botones
  const colors = (monitored, status) => {
    if (!monitored) {
      return {
        backgroudGood: '#E6E6EF',
        fontGood: '#FFFFFF',
        backgroudRegular: '#E6E6EF',
        fontRegular: '#FFFFFF',
        backgroudBad: '#E6E6EF',
        fontBad: '#FFFFFF'
      }
    }
    if (status === 'Bueno') {
      return {
        backgroudGood: '#8CBB59',
        fontGood: '#FFFFFF',
        backgroudRegular: '#FFF4DC',
        fontRegular: '#F5B40A',
        backgroudBad: '#FFCCC1',
        fontBad: '#E25C5C'
      }
    }
    if (status === 'Regular') {
      return {
        backgroudGood: '#E5FDCB',
        fontGood: '#8CBB59',
        backgroudRegular: '#FFBF37',
        fontRegular: '#FFFFFF',
        backgroudBad: '#FFCCC1',
        fontBad: '#E25C5C'
      }
    }
    if (status === 'Malo') {
      return {
        backgroudGood: '#E5FDCB',
        fontGood: '#8CBB59',
        backgroudRegular: '#FFF4DC',
        fontRegular: '#F5B40A',
        backgroudBad: '#E25C5C',
        fontBad: '#FFFFFF'
      }
    }
    return {
      backgroudGood: '#E5FDCB',
      fontGood: '#8CBB59',
      backgroudRegular: '#FFF4DC',
      fontRegular: '#F5B40A',
      backgroudBad: '#FFCCC1',
      fontBad: '#E25C5C'
    }
  }

  //Deja la url de la imagen la variable correspondiente
  const handleImage = (url) => {
    setValues(url, imageName, index)
  }
  //Lógica de subida de
  const [isLoadingUploadFile, setIsLoadingUploadFile] = useState(false)
  const imgUpload = async (formImage) => {
    try {
      setIsLoadingUploadFile(true)
      const response = await dispatch(getUrlImage(formImage))
      if (response) {
        handleImage(response.url)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoadingUploadFile(false)
    }
  }

  //Obtener nombre de imagen
  const getFilename = (img) => {
    const index = img.lastIndexOf('/') + 1
    const filename = img.substr(index)
    return filename
  }

  useEffect(() => {
    if (isLoadingUploadFile) {
      toast({
        duration: 9000 * 9000,
        isClosable: true,
        position: 'bottom-right',
        render: ({ onClose }) => (
          <CustomToast
            text={'Cargando Imagen...'}
            status={'info'}
            onClose={onClose}
          />
        )
      })
      return
    }
    toast.closeAll()
  }, [isLoadingUploadFile])

  return (
    <Box rounded={'lg'} bg={'#FFF'} w={['90%', '30%']} boxShadow={'md'}>
      <HStack justify={'space-between'} align="center" p={2}>
        <Text fontSize="14px" fontWeight="bold" color="#36355F">
          {title}
        </Text>
        <Switch
          size="md"
          colorScheme={'cyan'}
          name={switchName}
          isChecked={monitored}
          onChange={() => setValues(!monitored, switchName, index)}
        ></Switch>
      </HStack>
      <HStack justify={'space-between'} align="center" my={7} p={2}>
        <Box
          as="button"
          px={3}
          py={1}
          bg={colors(monitored, status).backgroudGood}
          borderRadius="lg"
          //   key={data.id}
          display="flex"
          disabled={!monitored}
          onClick={() => setValues('Bueno', statusName, index)}
        >
          <Text
            fontSize="13px"
            color={colors(monitored, status).fontGood}
            m="auto"
          >
            Bueno
          </Text>
        </Box>
        <Box
          as="button"
          px={3}
          py={1}
          bg={colors(monitored, status).backgroudRegular}
          borderRadius="lg"
          //   key={data.id}
          display="flex"
          disabled={!monitored}
          onClick={() => setValues('Regular', statusName, index)}
        >
          <Text
            fontSize="13px"
            color={colors(monitored, status).fontRegular}
            m="auto"
          >
            Regular
          </Text>
        </Box>
        <Box
          as="button"
          px={3}
          py={1}
          bg={colors(monitored, status).backgroudBad}
          borderRadius="lg"
          borderStyle={''}
          //   key={data.id}
          display="flex"
          disabled={!monitored}
          onClick={() => setValues('Malo', statusName, index)}
        >
          <Text
            fontSize="13px"
            color={colors(monitored, status).fontBad}
            m="auto"
          >
            Malo
          </Text>
        </Box>
      </HStack>
      {monitored ? (
        image ? (
          <Box w="100%" pb={5} borderRadius={'lg'} boxShadow={'md'}>
            <HStack px={2}>
              <Image src={image} alt="Imagen" height={'20px'} width={'40px'} />
              <Text isTruncated>{getFilename(image)}</Text>
              <IconButton
                icon={<Icon fontSize="22" as={DeleteIcon} />}
                onClick={() => handleImage('')}
              />
            </HStack>
          </Box>
        ) : (
          <UploadImage imgUpload={imgUpload} />
        )
      ) : null}
    </Box>
  )
}

export default CheckEPP
