import { Stack, Heading } from '@chakra-ui/react'

import CreateForm from '../../components/Products/CreateForm'

// Formulario de cliente
const ProductForm = () => {
  return (
    <Stack spacing={8} py={12} px={6}>
      <Stack align={'left'}>
        <Heading fontSize={'4xl'} color={'#36355F'}>
          Agregar producto
        </Heading>
      </Stack>
      <Stack spacing={8} py={12} px={6}>
        <CreateForm />
      </Stack>
    </Stack>
  )
}

export default ProductForm
