import axios from '../Axios'

export const getAllProducts = async () => {
  const { data } = await axios.get(`products/all-no-pag`)
  return data
}

export const getPagProducts = async (page = 1, filters) => {
  const { data } = await axios.get(`products/all`, {
    params: {
      page,
      search: filters?.search,
      service: filters?.service
    }
  })
  return data
}

export const getOneProduct = async (clientId) => {
  const { data } = await axios.get(`products/get-one?id_product=${clientId}`)
  return data
}

export const createProducts = async (formData) => {
  const { data } = await axios.post(`products/`, formData)
  return data
}

export const updateProducts = async (formData) => {
  const { data } = await axios.patch(`products/`, formData)
  return data
}

export const deleteProducts = async (clientId) => {
  const { data } = await axios.delete(`products/?id=${clientId}`)
  return data
}
