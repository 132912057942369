import axios from '../Axios'

export const getSuppliesDashboard = async (values) => {
  const { data } = await axios.get(
    `reportability/dash?${
      values.startDate ? `&start_date=${values.startDate}` : ''
    }${values.endDate ? `&end_date=${values.endDate}` : ''}${
      values.type ? `&service=${values.type}` : ''
    }${values.product ? `&product=${values.product}` : ''}${
      values.client > 0 ? `&client=${values.client}` : ''
    }`
  )
  return data
}

export const getSuppliesXlsx = async (values) => {
  const { data } = await axios.get(
    `reportability/excel?${
      values.startDate ? `&start_date=${values.startDate}` : ''
    }${values.endDate ? `&end_date=${values.endDate}` : ''}${
      values.type ? `&service=${values.type}` : ''
    }${values.product > 0 ? `&product=${values.product}` : ''}${
      values.client > 0 ? `&client=${values.client}` : ''
    }`
  )
  return data
}
