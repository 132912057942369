import { useQuery, useMutation, useQueryClient } from 'react-query'
import {
  getRegistries,
  getOneRegistry,
  createRegistry,
  updateRegistry,
  deleteRegistry
} from '../api/rodentsRegistry'

const getAction = (type) => {
  if (type === 'CREATE') return createRegistry
  if (type === 'UPDATE') return updateRegistry
  if (type === 'DELETE') return deleteRegistry
  return null
}

export function useRegistries(page, props = {}) {
  return useQuery(['registries', page], () => getRegistries(page), {
    ...props,
    onError: (err) => {
      console.log(err.response)
    }
  })
}

export function useRegistry(registryId) {
  return useQuery(['registry', registryId], () => getOneRegistry(registryId), {
    onError: (err) => {
      console.log(err.response)
    }
  })
}

export function useMutateRegistry(type) {
  const queryClient = useQueryClient()
  const actionFn = getAction(type)
  return useMutation(actionFn, {
    onSuccess: () => {
      queryClient.invalidateQueries('registry')
      queryClient.invalidateQueries('visit')
    },
    onError: (err) => {
      console.log(err.response)
    }
  })
}
