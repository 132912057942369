import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  filterList: {
    page: 1,
    search: '',
    role: null
  }
}

export const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    updateFilterList: (state, action) => {
      state.filterList = {
        ...state.filterList,
        ...action.payload
      }
    },
    resetUser: (state) => {
      state.filterList.page = initialState.filterList.page
      state.filterList.search = initialState.filterList.search
      state.filterList.role = initialState.filterList.role
    }
  }
})
export const { updateFilterList, resetUser } = userSlice.actions
/* --- */
export default userSlice.reducer
/* --- */
