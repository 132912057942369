import { useQuery, useMutation, useQueryClient } from 'react-query'
import {
  getUsers,
  getOneUser,
  createUser,
  updateUser,
  updateUserProfile,
  deleteUser
} from '../api/users'

const getAction = (type) => {
  if (type === 'CREATE') return createUser
  if (type === 'UPDATE') return updateUser
  if (type === 'PROFILE') return updateUserProfile
  if (type === 'DELETE') return deleteUser
  return null
}

export function useUsers(page, formData, props = {}) {
  return useQuery(['users', page], () => getUsers(page, formData), {
    ...props,
    onError: (err) => {
      console.log(err.response)
    }
  })
}

export function useUser(userId) {
  return useQuery(['user', userId], () => getOneUser(userId), {
    onError: (err) => {
      console.log(err.response)
    }
  })
}

export function useMutateUser(type) {
  const queryClient = useQueryClient()
  const actionFn = getAction(type)
  return useMutation(actionFn, {
    onSuccess: () => {
      queryClient.invalidateQueries('user')
    },
    onError: (err) => {
      console.log(err.response)
    }
  })
}
