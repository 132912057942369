import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Flex,
  Stack,
  Heading,
  Skeleton,
  Grid,
  GridItem,
  Button,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  useDisclosure,
  Alert,
  AlertIcon
} from '@chakra-ui/react'
import { useAllFiles } from '../../hooks/s3Files'
import { updateFilterList } from '../../store/slices/s3Files'
import { CalendarIcon } from '../../components/Shared/Icons'
import FilesTable from './FilesTable'

const types = [
  { name: 'Imagenes 1', id: 'IMAGES/' },
  { name: 'Imagenes 2', id: 'images/' },
  { name: 'Reportabilidad', id: 'reportability/' },
  { name: 'Reportabilidad excel', id: 'reportability_xslx/' }
]

function InitialFocus({ isOpen, onClose }) {
  const [date, setDate] = useState('')
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Eliminar archivos masivo</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <FormControl>
            <FormLabel>Fecha</FormLabel>
            <Input
              value={date}
              onChange={(event) => setDate(event.target.value)}
              type="date"
              Icon={<CalendarIcon />}
            />
          </FormControl>
          <Alert status="warning" mt={4}>
            <AlertIcon />
            Tenga en cuenta que todos los registros anteriores a la fecha
            seleccionada serán eliminados, esta acción no se podra deshacer.
          </Alert>
        </ModalBody>

        <ModalFooter>
          <Button onClick={onClose}>Cancel</Button>
          <Button colorScheme="blue" ml={3}>
            Confirmar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

// Página de principal de  lista de clientes
const S3Files = () => {
  const dispatch = useDispatch()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { filterList } = useSelector((state) => state.s3Files)
  const { data: files, refetch } = useAllFiles(filterList)

  const handleChangeGlobalState = (fieldName, value) => {
    dispatch(
      updateFilterList({
        [fieldName]: value
      })
    )
  }

  useEffect(() => {
    refetch()
  }, [filterList])

  return (
    <Stack spacing={8} px={6}>
      <Flex bg={'#FFF'}>
        <Stack spacing={8} py={12} px={6}>
          <Stack align={'left'}>
            <Heading fontSize={'4xl'} color={'#36355F'}>
              Archivos S3
            </Heading>
          </Stack>
          <Stack spacing={10} align={'left'}>
            <Button
              onClick={onOpen}
              bg={'teal.500'}
              color={'white'}
              _hover={{
                bg: 'teal.600'
              }}
            >
              Eliminar por fecha
            </Button>
          </Stack>
        </Stack>
      </Flex>
      <Grid display="flex">
        <GridItem display="flex" gap={3} w="100%">
          <Input
            value={filterList?.date || ''}
            onChange={(event) =>
              handleChangeGlobalState('date', event.target.value)
            }
            placeContent="Fecha"
            type="date"
            Icon={<CalendarIcon />}
          />
          <Select
            placeholder="Carpeta"
            name="folder_name"
            onChange={(e) =>
              handleChangeGlobalState('folder_name', e.currentTarget.value)
            }
            value={filterList?.folder_name}
          >
            {types.map((t) => (
              <option value={t.id}>{t.name}</option>
            ))}
          </Select>
        </GridItem>
      </Grid>
      {files ? (
        <FilesTable data={files} />
      ) : (
        <Stack>
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
        </Stack>
      )}
      <InitialFocus isOpen={isOpen} onClose={onClose} />
    </Stack>
  )
}

export default S3Files
