import { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Stack,
  Button,
  Link,
  Flex,
  Image,
  Skeleton
} from '@chakra-ui/react'

import { useMutateUser, useUser } from '../../hooks/users'
import Logo from '../../assets/images/logo.svg'

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Ingrese nombre'),
  email: Yup.string().email('Ingrese email válido').required('Ingrese correo')
})

// Perfil de usuario
const ProfileForm = () => {
  const history = useHistory()
  const { userId } = useParams()
  const {
    data: user,
    isFetching,
    isLoading: loadingGet,
    error
  } = useUser(userId)
  const { mutate, isLoading: loadingUpdate, reset } = useMutateUser('PROFILE')

  const formik = useFormik({
    validationSchema,
    enableReinitialize: true,
    initialValues: {
      id: userId,
      name: user?.name,
      email: user?.email
    },
    onSubmit: (values) => {
      mutate(values, {
        onSuccess: () => {
          reset()
          formik.resetForm()
        },
        onError: (err) => {
          console.log(err)
        }
      })
    }
  })

  const handleBack = () => {
    history.push(`/home`)
  }

  return (
    <Stack spacing={8} mx={'auto'} py={12} px={6}>
      <Box rounded={'lg'} bg={'#FFF'}>
        <Stack spacing={10} align={'center'}>
          <Button
            onClick={handleBack}
            bg="#F2F1FB"
            color="#59BABA"
            border="1px"
            borderStyle="solid"
            borderRadius="lg"
            borderColor="#F2F1FB"
          >
            Regresar
          </Button>
        </Stack>
        {user ? (
          <Stack spacing={4} minW={'xs'}>
            <FormControl
              id="name"
              isRequired
              isInvalid={formik.touched.name && Boolean(formik.errors.name)}
            >
              <FormLabel>Nombre Completo</FormLabel>
              <Input
                borderRadius="30px"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <FormErrorMessage>
                {formik.touched.name && formik.errors.name}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              id="email"
              isRequired
              isInvalid={formik.touched.email && Boolean(formik.errors.email)}
            >
              <FormLabel>Correo Electrónico</FormLabel>
              <Input
                borderRadius="30px"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled
              />
              <FormErrorMessage>
                {formik.touched.email && formik.errors.email}
              </FormErrorMessage>
            </FormControl>
            <Stack spacing={10} align={'center'}>
              <Button
                isLoading={loadingUpdate}
                onClick={formik.handleSubmit}
                bg={'teal.500'}
                color={'white'}
                _hover={{
                  bg: 'teal.600'
                }}
                disabled={loadingUpdate}
              >
                Editar
              </Button>
            </Stack>
          </Stack>
        ) : (
          <Stack>
            <Skeleton height="20px" />
            <Skeleton height="20px" />
            <Skeleton height="20px" />
          </Stack>
        )}
      </Box>
    </Stack>
  )
}

export default ProfileForm
