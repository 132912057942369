import { useQuery, useMutation, useQueryClient } from 'react-query'
import {
  getReports,
  getOneReport,
  createReport,
  updateReport,
  deleteReport
} from '../api/visitReport'

const getAction = (type) => {
  if (type === 'CREATE') return createReport
  if (type === 'UPDATE') return updateReport
  if (type === 'DELETE') return deleteReport
  return null
}

export function useReports(page, props = {}) {
  return useQuery(['reports', page], () => getReports(page), {
    ...props,
    onError: (err) => {
      console.log(err.response)
    }
  })
}

export function useReport(controlId) {
  return useQuery(['report', controlId], () => getOneReport(controlId), {
    onError: (err) => {
      console.log(err.response)
    }
  })
}

export function useMutateReport(type) {
  const queryClient = useQueryClient()
  const actionFn = getAction(type)
  return useMutation(actionFn, {
    onSuccess: () => {
      queryClient.invalidateQueries('report')
      queryClient.invalidateQueries('visit')
    },
    onError: (err) => {
      console.log(err.response)
    }
  })
}
