import { Flex } from '@chakra-ui/react'

import PassForm from '../../components/Login/PassForm'

// Pantalla de cambio de contraseña
const NewPass = () => {
  return (
    <Flex minH={'100vh'} align={'center'} justify={'center'} bg={'#FFF'}>
      <PassForm />
    </Flex>
  )
}

export default NewPass
