import { Button, Flex, Grid, GridItem } from '@chakra-ui/react'
import { AiOutlinePlusCircle as PlusIcon } from 'react-icons/ai'
import EmailItem from './EmailItem'
import { generateId } from '../../../utils/generateId'

const EmailsContainerForms = ({ emails, setEmails, errors }) => {
  const addEmail = () => {
    if (!errors) {
      const newEmails = [...emails]
      const newKey = generateId()
      newEmails.push({
        email: `nuevo@correo.cl`,
        key: newKey
      })
      setEmails(newEmails)
    }
  }

  const updateEmail = (key, newEmail) => {
    const newEmails = [...emails]
    const index = newEmails.findIndex((emailObj) => emailObj.key === key)
    if (index !== -1) {
      newEmails[index].email = newEmail
      setEmails(newEmails)
    }
  }

  const deleteEmail = (key) => {
    const newEmails = emails?.filter((email) => email?.key !== key)
    setEmails(newEmails)
  }

  return (
    <Grid templateColumns={'repeat(1, 1fr)'} marginBlock={4} gap={2}>
      <GridItem>
        <Flex justify="flex-end" align="center">
          <Button
            size="sm"
            align="center"
            bg="#FFFFFF"
            color="#59BABA"
            border="1px"
            borderColor="#59BABA"
            leftIcon={<PlusIcon />}
            onClick={addEmail}
            disabled={Boolean(errors)}
          >
            Agregar Correo electrónico
          </Button>
        </Flex>
      </GridItem>
      {/* --- */}
      {emails?.map((email, index) => (
        <GridItem
          key={email.key}
          border="1px solid #59BABA"
          borderRadius={8}
          p={2}
          marginInline={{
            lg: 20
          }}
        >
          <Flex justify="flex-end" align="center">
            <EmailItem
              email={email}
              error={errors?.at(index)}
              updateEmail={updateEmail}
              deleteEmail={deleteEmail}
            />
          </Flex>
        </GridItem>
      ))}
    </Grid>
  )
}

export default EmailsContainerForms
