import { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  Heading,
  Text,
  Select,
  Box,
  Stack,
  HStack,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  InputGroup,
  InputLeftAddon,
  Flex,
  Button,
  Spacer,
  useToast,
  Skeleton
} from '@chakra-ui/react'
import * as Yup from 'yup'
import { useFormik } from 'formik'

import Roles from '../../resources/Roles'
import { useMutateUser, useUser } from '../../hooks/users'
import generatePassword from '../../validators/generatePassword'

const validationSchema = Yup.object().shape({
  id_role: Yup.number().required('Ingrese rol'),
  name: Yup.string().required('Ingrese nombre y apellido'),
  email: Yup.string()
    .required('Ingrese correo')
    .email('Debe ser un correo válido')
})

// Formulario para editar usuario
const EditUser = () => {
  const history = useHistory()
  const toast = useToast()
  const { userId } = useParams()
  const {
    data: user,
    isFetching,
    isLoading: loadingGet,
    error
  } = useUser(userId)
  const { mutate, isLoading, reset } = useMutateUser('UPDATE')

  const formik = useFormik({
    validationSchema,
    enableReinitialize: true,
    initialValues: {
      id: user?.id,
      id_role: user?.id_role,
      name: user?.name,
      email: user?.email,
      password: ''
    },
    onSubmit: (values) => {
      mutate(values, {
        onSuccess: () => {
          reset()
          formik.resetForm()
          toast({
            title: `Usuario editado`,
            status: 'success',
            isClosable: true
          })
          history.push(`/usuarios`)
        },
        onError: (err) => {
          reset()
          console.log(err)
          toast({
            title: `Error al editar usuario`,
            status: 'error',
            isClosable: true
          })
        }
      })
    }
  })
  return (
    <Stack spacing={8} py={12} px={6}>
      <Stack align={'left'}>
        <Heading fontSize={'4xl'} color={'#36355F'}>
          Agregar nuevo usuario
        </Heading>
      </Stack>
      {user ? (
        <>
          <Stack spacing={8} py={12} px={6}>
            <FormControl
              id="id_role"
              isInvalid={
                formik.touched.id_role && Boolean(formik.errors.id_role)
              }
            >
              <FormLabel>Rol</FormLabel>
              <Select
                placeholder="Seleccione"
                name="id_role"
                onChange={formik.handleChange}
                value={formik.values.id_role}
              >
                {Roles.map((role) => (
                  <option value={role.id}>{role.name}</option>
                ))}
              </Select>
              <FormErrorMessage>
                {formik.touched.role && formik.errors.role}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              id="name"
              isInvalid={formik.touched.name && Boolean(formik.errors.name)}
            >
              <FormLabel>Nombre y apellido</FormLabel>
              <Input
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                placeholder="Nombre y apellido"
              />
              <FormErrorMessage>
                {formik.touched.name && formik.errors.name}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              id="email"
              isInvalid={formik.touched.email && Boolean(formik.errors.email)}
            >
              <FormLabel>Correo</FormLabel>
              <Input
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                placeholder="Correo"
              />
              <FormErrorMessage>
                {formik.touched.email && formik.errors.email}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              id="password"
              isInvalid={
                formik.touched.password && Boolean(formik.errors.password)
              }
            >
              <FormLabel>Contraseña</FormLabel>
              <Input
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                placeholder="Nueva contraseña"
              />
              <FormErrorMessage>
                {formik.touched.password && formik.errors.password}
              </FormErrorMessage>
            </FormControl>
          </Stack>
          <Flex spacing={10} align="right" pt="15px">
            <Spacer />
            <Button
              align="right"
              isLoading={isLoading}
              onClick={formik.handleSubmit}
              bg={'teal.500'}
              color={'white'}
              _hover={{
                bg: 'teal.600'
              }}
              disabled={isLoading}
            >
              Guardar
            </Button>
          </Flex>
        </>
      ) : (
        <Stack>
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
        </Stack>
      )}
    </Stack>
  )
}

export default EditUser
