import { useMutation } from 'react-query'
import { forgetPassword, recoverPassword, changePassword } from '../api/auth'

const getAction = (type) => {
  if (type === 'RESET') return forgetPassword
  if (type === 'RECOVER') return recoverPassword
  if (type === 'CHANGE') return changePassword
  return null
}

export function useMutateAuth(type) {
  const actionFn = getAction(type)
  return useMutation(actionFn, {
    onSuccess: () => {
      console.log('ok')
    },
    onError: (err) => {
      console.log(err.response)
    }
  })
}
