//@components
import { Flex, Square, Text, Tooltip } from '@chakra-ui/react'
//@utils
import { getRandomColor } from '../../../utils/getRandomColor'
import { truncateText } from '../../../utils/truncateText'

const Labels = ({ data = [] }) => {
  return (
    <Flex
      direction="column"
      borderRadius={8}
      bg="whiteAlpha.300"
      maxH={370}
      overflowY="auto"
    >
      {data?.map((item) => (
        <Flex
          gap={2}
          fontWeight={700}
          justify="space-between"
          alignItems="center"
          p={2}
        >
          <Flex gap={2} justify="space-between" alignItems="center">
            <Square
              size="20px"
              borderRadius={5}
              bg={getRandomColor()}
              border="1px solid"
            />
            <Tooltip hasArrow label={item?.area} placement="left">
              <Text>{truncateText(item?.area, 30)}</Text>
            </Tooltip>
          </Flex>
          <Text>{item?.value}</Text>
        </Flex>
      ))}
    </Flex>
  )
}

export default Labels
