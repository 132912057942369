import { useState, useEffect, useRef } from 'react'
import {
  Text,
  Box,
  SimpleGrid,
  Icon,
  HStack,
  useDisclosure
} from '@chakra-ui/react'

import { ImageIcon } from './Icons'
import PreviewImage from './PreviewImage'

// Subida de imagenes
const UploadImage = ({ imgUpload, multiple = false }) => {
  const inputRef = useRef()
  const { onOpen, onClose, isOpen } = useDisclosure()
  const [file, setFile] = useState(null)

  const createFormData = async (fileFormater) => {
    if (Array.isArray(fileFormater)) {
      imgUpload(fileFormater)
    } else {
      const formImage = new FormData()
      formImage.append('file', fileFormater)
      imgUpload(formImage)
    }
  }

  // Cuando se detecta la imagen se sube a un s3
  useEffect(() => {
    if (file) {
      onOpen()
    }
  }, [file])

  // Se limita a 1 imagen con peso máximo de 8MB
  const handleSetFile = (event) => {
    event.preventDefault()
    const { files } = event.target
    if (multiple) {
      if (files.length > 5) {
        alert('Se permiten maximo 5 archivos')
        return
      }
      setFile(Array.from(files))
    } else {
      if (Array.from(files).length > 1) {
        alert('Puedes subir 1 archivo a la vez')
        return
      }
      if (files[0].size > 8388608) {
        alert('Peso máximo 8MB')
        return
      }
      setFile(files[0])
    }
    event.target.value = null
  }

  return (
    <>
      <Box
        as="button"
        bg="#FFFFFF"
        mb={4}
        border="2px"
        borderStyle="dashed"
        borderRadius="lg"
        borderColor="#59BABA"
        alignItems="center"
        justifyContent="center"
        display="flex"
        onClick={() => inputRef.current.click()}
      >
        <input
          ref={inputRef}
          style={{ margin: 'auto', display: 'none' }}
          type="file"
          accept="image/*"
          onChange={(event) => handleSetFile(event)}
          multiple={multiple}
        ></input>
        <SimpleGrid columns={1} spacing={10} p={3}>
          <HStack>
            <Icon as={ImageIcon} m="auto" color="#59BABA" />
            <Text fontSize="15px" fontWeight="bold" color="#59BABA" m="auto">
              Agréga una foto desde tu dispositivo ó usa tu cámara
            </Text>
          </HStack>
          <Text fontSize="10px" color="#59BABA" m="auto">
            Peso máximo: 8MB
          </Text>
        </SimpleGrid>
      </Box>
      {file && (
        <PreviewImage
          isOpen={isOpen}
          onClose={onClose}
          file={file}
          callBackFile={createFormData}
        />
      )}
    </>
  )
}

export default UploadImage
