import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import {
  Select as ChakraSelect,
  Box,
  HStack,
  FormControl,
  FormLabel,
  Input,
  Flex,
  Button,
  Spacer,
  useToast,
  SimpleGrid,
  GridItem,
  Heading
} from '@chakra-ui/react'
import Select from 'react-select'
import { Search2Icon, DownloadIcon } from '@chakra-ui/icons'
import * as Yup from 'yup'
import { useFormik } from 'formik'

import {
  useDashClient,
  useMutateXlsx,
  useAllBranchs,
  useAreas
} from '../../hooks/clients'

//@data
import months from '../../data/months.json'

//@utils
import { getCurrentMonthName } from '../../utils/getCurrentMonth'
import { getYears } from '../../utils/getYears'

import RodentsGraph from './Graphs/RodentsGraph'
import DovesGraphs from './Graphs/DovesGraphs'
import InsectsGraph from './Graphs/InsectsGraph'
import CustomToast from '../Shared/CustomToast'

const validationSchema = Yup.object().shape({
  branch: Yup.number(),
  startDate: Yup.string(),
  endDate: Yup.string(),
  area: Yup.string()
})

// Dashboard de cliente
const Dashboard = () => {
  const toast = useToast()
  const { clientId } = useParams()
  const [formData, setFormData] = useState({
    clientId: clientId,
    branch: 0,
    year: new Date().getFullYear().toString(),
    month: getCurrentMonthName(),
    startDate: new Date(new Date().getFullYear(), 0, 1)
      .toLocaleString('en-us', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      })
      .replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2'),
    endDate: new Date()
      .toLocaleString('en-us', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      })
      .replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2'),
    area: ''
  })
  const [formatAreas, setFormatArea] = useState([])
  const [document, setDocument] = useState('')
  const {
    data: dash,
    isLoading: loadingDash,
    isFetching: fetchDash,
    refetch
  } = useDashClient(formData)
  const {
    data: branchs,
    isLoading: loadingBranchs,
    isFetching: fetchBranchs
  } = useAllBranchs(clientId)
  const {
    data: areas,
    isLoading: loadingAreas,
    isFetching: fetchAreas
  } = useAreas(clientId)

  const { mutate, isLoading: loadingXlsx, reset } = useMutateXlsx(document)

  const formik = useFormik({
    validationSchema,
    enableReinitialize: true,
    initialValues: {
      clientId: clientId,
      branch: 0,
      year: new Date().getFullYear().toString(),
      month: getCurrentMonthName(),
      startDate: new Date(new Date().getFullYear(), 0, 1)
        .toLocaleString('en-us', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        })
        .replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2'),
      endDate: new Date()
        .toLocaleString('en-us', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        })
        .replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2'),
      area: ''
    },
    onSubmit: async (values) => {
      console.log({ values })
      await setFormData(values)
    }
  })

  // Hace fetch al cambiar los valores de los filtros
  useEffect(() => {
    refetch()
  }, [formData])

  // Guarda el tipo de documento
  const handleXlsx = (doc) => {
    setDocument(doc)
  }

  // Al seleccionar el tipo de documento hace el fetch para la descarga
  useEffect(() => {
    if (document) {
      mutate(formData, {
        onSuccess: (data) => {
          reset()
          formik.resetForm()
          window.open(data)
        },
        onError: (err) => {
          reset()
          console.log(err)
          toast({
            title: `Error al crear reporte`,
            status: 'error',
            isClosable: true
          })
        }
      })
    }
  }, [document])

  //formateo para el select autocomplete
  useEffect(() => {
    if (areas) {
      const format = areas?.map((item) => ({
        value: item?.id,
        label: `${item?.area}/SERIAL: ${item?.id}`
      }))

      setFormatArea([{ value: '', label: 'TODO' }, ...format])
    }
  }, [areas])

  useEffect(() => {
    if (loadingXlsx || loadingDash) {
      toast({
        duration: 9000 * 9000,
        isClosable: true,
        position: 'bottom-right',
        render: ({ onClose }) => (
          <CustomToast
            text={
              loadingDash ? 'Cargando Graficos...' : 'Cargando Documento...'
            }
            status={'info'}
            onClose={onClose}
          />
        )
      })
      return
    }
    toast.closeAll()
  }, [loadingXlsx, loadingDash])

  // Fecha actual
  const currentDate = () => {
    const newDate = new Date().toISOString().slice(0, -14)
    return newDate
  }

  return (
    <>
      <Box mt={3} w="100%">
        <Flex spacing="10px" align="left">
          <FormControl id="branch" flex="2">
            <FormLabel>Sucursal</FormLabel>
            <ChakraSelect
              placeholder="TODO"
              name="branch"
              onChange={formik.handleChange}
            >
              {branchs?.map((b) => (
                <option value={b.id} key={b.id}>
                  {b.name}
                </option>
              ))}
            </ChakraSelect>
          </FormControl>
          <Spacer />
          <FormControl id="startDate" flex="2">
            <FormLabel>Año</FormLabel>
            {/* <Input
              name="startDate"
              value={formik.values.startDate}
              onChange={formik.handleChange}
              type="date"
              max={currentDate()}
            /> */}
            <ChakraSelect
              name="year"
              value={formik.values.year}
              onChange={formik.handleChange}
            >
              {getYears()?.map((a) => (
                <option value={a} key={a}>
                  {a}
                </option>
              ))}
            </ChakraSelect>
          </FormControl>
          <Spacer />
          <FormControl id="endDate" flex="2">
            <FormLabel>Mes</FormLabel>
            {/*             <Input
              name="endDate"
              value={formik.values.endDate}
              onChange={formik.handleChange}
              type="date"
              max={currentDate()}
            /> */}
            <ChakraSelect
              name="month"
              value={formik.values.month}
              onChange={formik.handleChange}
            >
              {[{ label: 'TODOS', value: '' }, ...months]?.map((m) => (
                <option value={m.value} key={m.value}>
                  {m.label}
                </option>
              ))}
            </ChakraSelect>
          </FormControl>
          <Spacer />
          <FormControl id="area" flex="2">
            <FormLabel>Área del cliente</FormLabel>
            {formatAreas?.length > 0 && (
              <Select
                className="basic-single"
                classNamePrefix="select"
                defaultValue={formatAreas[0]}
                isDisabled={false}
                isLoading={false}
                isClearable={false}
                isRtl={false}
                isSearchable={true}
                name="area"
                options={formatAreas}
                onChange={(item) => formik.setFieldValue('area', item.value)}
              />
            )}
          </FormControl>
          <Spacer />
          <Button
            onClick={formik.handleSubmit}
            bg={'teal.500'}
            color={'white'}
            _hover={{
              bg: 'teal.600'
            }}
            leftIcon={<Search2Icon />}
            flex="1"
            disabled={fetchDash}
          >
            Buscar
          </Button>
        </Flex>
        <Box backgroundColor="white" borderRadius="10px" p="15px">
          <SimpleGrid columns={12} spacing={3}>
            <GridItem colSpan={{ base: 12 }}>
              <HStack justify={'space-between'}>
                <Heading as="h3" size="lg" inLine>
                  Control de Roedores
                </Heading>
                <Button
                  onClick={() => handleXlsx('TOXIC')}
                  bg={'#D1F1F580'}
                  color={'#36355F'}
                  _hover={{
                    bg: '#D1F1F5'
                  }}
                  rightIcon={<DownloadIcon />}
                >
                  Descargar
                </Button>
              </HStack>
              <Heading as="h6" size="xs">
                Cebos consumidos
              </Heading>
            </GridItem>
            <GridItem colSpan={{ base: 12 }}>
              {dash && <RodentsGraph data={dash.toxic} type="TOXIC" />}
            </GridItem>
          </SimpleGrid>
        </Box>
        <Box backgroundColor="white" borderRadius="10px" p="15px">
          <SimpleGrid columns={12} spacing={3}>
            <GridItem colSpan={{ base: 12 }}>
              <HStack justify={'space-between'}>
                <Heading as="h3" size="lg">
                  Estaciones No tóxicas y HACCP
                </Heading>
                <Button
                  onClick={() => handleXlsx('NONTOXIC')}
                  bg={'#D1F1F580'}
                  color={'#36355F'}
                  _hover={{
                    bg: '#D1F1F5'
                  }}
                  rightIcon={<DownloadIcon />}
                >
                  Descargar
                </Button>
              </HStack>
              <Heading as="h6" size="xs">
                Capturas de ratones
              </Heading>
            </GridItem>
            <GridItem colSpan={{ base: 12 }}>
              {dash && <RodentsGraph data={dash.nonToxic} type="NONTOXIC" />}
            </GridItem>
          </SimpleGrid>
        </Box>
        <Box backgroundColor="white" borderRadius="10px" p="15px">
          <SimpleGrid columns={12} spacing={3}>
            <GridItem colSpan={{ base: 12 }}>
              <HStack justify={'space-between'}>
                <Heading as="h3" size="lg">
                  Control de Palomas
                </Heading>
                <Button
                  onClick={() => handleXlsx('DOVES')}
                  bg={'#D1F1F580'}
                  color={'#36355F'}
                  _hover={{
                    bg: '#D1F1F5'
                  }}
                  rightIcon={<DownloadIcon />}
                >
                  Descargar
                </Button>
              </HStack>
            </GridItem>
            <GridItem colSpan={{ base: 12 }}>
              {dash && <DovesGraphs data={dash.doves} />}
            </GridItem>
          </SimpleGrid>
        </Box>
        <Box backgroundColor="white" borderRadius="10px" p="15px">
          <SimpleGrid columns={12} spacing={3}>
            <GridItem colSpan={{ base: 12 }}>
              <HStack justify={'space-between'}>
                <Heading as="h3" size="lg">
                  Atrape de Insectos Voladores
                </Heading>
                <Button
                  onClick={() => handleXlsx('INSECTS')}
                  bg={'#D1F1F580'}
                  color={'#36355F'}
                  _hover={{
                    bg: '#D1F1F5'
                  }}
                  rightIcon={<DownloadIcon />}
                >
                  Descargar
                </Button>
              </HStack>
              {/* <Heading as="h6" size="xs">
                Cebos consumidos
              </Heading> */}
            </GridItem>
            <GridItem colSpan={{ base: 12 }}>
              {dash && <InsectsGraph data={dash.insects} type="TOXIC" />}
            </GridItem>
          </SimpleGrid>
        </Box>
      </Box>
    </>
  )
}

export default Dashboard
