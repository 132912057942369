import { Flex, Image, IconButton, Spacer } from '@chakra-ui/react'
import { HamburgerIcon } from '@chakra-ui/icons'

import Logo from '../../assets/images/logo.svg'

const Header = ({ onOpen }) => {
  return (
    <Flex align="left" padding="1.5rem">
      <Spacer />
      <Image src={Logo} alt="Logo" />
      <Spacer />
      <IconButton
        variant="outline"
        colorScheme="black"
        aria-label="Abrir menú"
        onClick={onOpen}
        borderColor="#FFF"
        icon={<HamburgerIcon />}
      />
    </Flex>
  )
}

export default Header
