import axios from '../Axios'

export const getEPPs = async (page = 1, formData, size = 10) => {
  const { data } = await axios.get(
    `epps/all?page=${page}&size=${size}${
      formData.userId ? `&id_user=${formData.userId}` : ''
    }${formData.startDate ? `&start_date=${formData.startDate}` : ''}${
      formData.endDate ? `&end_date=${formData.endDate}` : ''
    }`
  )
  return data
}

export const getOneEPP = async (eppId) => {
  const { data } = await axios.get(`epps/get-one?id_epp=${eppId}`)
  return data
}

export const createEPP = async (formData) => {
  const { data } = await axios.post(`epps/`, formData)
  return data
}

export const updateEPP = async (formData) => {
  const { data } = await axios.patch(`epps/`, formData)
  return data
}

export const deleteEPP = async (eppId) => {
  const { data } = await axios.delete(`epps/?id=${eppId}`)
  return data
}
