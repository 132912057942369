import { useState, useEffect } from 'react'
import {
  Flex,
  Stack,
  VStack,
  HStack,
  Box,
  Button,
  Icon,
  Spacer,
  Text,
  Textarea,
  Checkbox,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Grid,
  useDisclosure,
  Select,
  Switch,
  Circle
} from '@chakra-ui/react'
import { CloseIcon, CheckIcon } from '@chakra-ui/icons'
import _ from 'lodash'

// Formulario de estación TUV
const TUVStations = ({
  values,
  handleChangeStation,
  handleChangeStationBools,
  handleDeleteStation,
  index1,
  index2
}) => {
  // Calcular total de insectos
  const total = () => {
    const sum =
      parseInt(values.flys || 0, 10) +
      parseInt(values.minorFlys || 0, 10) +
      parseInt(values.mosquitoes || 0, 10) +
      parseInt(values.moths || 0, 10) +
      parseInt(values.wasps || 0, 10) +
      parseInt(values.bees || 0, 10) +
      parseInt(values.amount || 0, 10)
    return sum
  }

  return (
    <VStack spacing={8} py={12} px={6}>
      <FormControl id="stationName" isInvalid={values.stationName === ''}>
        <FormLabel>Ubicación T.U.V.</FormLabel>
        <Input
          name="stationName"
          value={values.stationName}
          onChange={handleChangeStation}
          placeholder="Nombre de la estación"
        />
        <FormErrorMessage>Ingrese nombre</FormErrorMessage>
      </FormControl>
      <HStack justify={'space-between'} w={['100%', '15%']}>
        <Text fontSize="14px" fontWeight="bold" color="#36355F">
          ¿Monitoreada?
        </Text>
        <Switch
          size="md"
          colorScheme={'cyan'}
          name="monitored"
          isChecked={values.monitored}
          onChange={() =>
            handleChangeStationBools(
              !values.monitored,
              'monitored',
              index1,
              index2
            )
          }
        ></Switch>
      </HStack>
      <HStack justify={'space-between'} w={['100%', '20%']}>
        <Text
          fontSize="14px"
          fontWeight="bold"
          color={values.monitored ? '#36355F' : '#E6E6EF'}
        >
          Esta Funcional 100%
        </Text>
        <HStack justify={'space-between'} w={['30%', '20%']}>
          <Circle
            size="30px"
            bg={
              !values.monitored
                ? '#E6E6EF'
                : values.functional
                ? '#E6E6EF'
                : 'red'
            }
            color="white"
            as="button"
            onClick={() =>
              handleChangeStationBools(false, 'functional', index1, index2)
            }
            disabled={!values.monitored}
          >
            <CloseIcon />
          </Circle>
          <Circle
            size="30px"
            bg={
              !values.monitored
                ? '#E6E6EF'
                : values.functional
                ? 'green'
                : '#E6E6EF'
            }
            color="white"
            as="button"
            onClick={() =>
              handleChangeStationBools(true, 'functional', index1, index2)
            }
            disabled={!values.monitored}
          >
            <CheckIcon />
          </Circle>
        </HStack>
      </HStack>
      <HStack justify={'space-between'} w={['100%', '20%']}>
        <Text
          fontSize="14px"
          fontWeight="bold"
          color={values.monitored ? '#36355F' : '#E6E6EF'}
        >
          Queda Funcional 100%
        </Text>
        <HStack justify={'space-between'} w={['30%', '20%']}>
          <Circle
            size="30px"
            bg={
              !values.monitored ? '#E6E6EF' : values.working ? '#E6E6EF' : 'red'
            }
            color="white"
            as="button"
            onClick={() =>
              handleChangeStationBools(false, 'working', index1, index2)
            }
            disabled={!values.monitored}
          >
            <CloseIcon />
          </Circle>
          <Circle
            size="30px"
            bg={
              !values.monitored
                ? '#E6E6EF'
                : values.working
                ? 'green'
                : '#E6E6EF'
            }
            color="white"
            as="button"
            onClick={() =>
              handleChangeStationBools(true, 'working', index1, index2)
            }
            disabled={!values.monitored}
          >
            <CheckIcon />
          </Circle>
        </HStack>
      </HStack>
      <HStack justify={'space-between'} w={['100%', '20%']}>
        <Text
          fontSize="14px"
          fontWeight="bold"
          color={values.monitored ? '#36355F' : '#E6E6EF'}
        >
          Cambio Placa
        </Text>
        <HStack justify={'space-between'} w={['30%', '20%']}>
          <Circle
            size="30px"
            bg={
              !values.monitored ? '#E6E6EF' : values.plate ? '#E6E6EF' : 'red'
            }
            color="white"
            as="button"
            onClick={() =>
              handleChangeStationBools(false, 'plate', index1, index2)
            }
            disabled={!values.monitored}
          >
            <CloseIcon />
          </Circle>
          <Circle
            size="30px"
            bg={
              !values.monitored ? '#E6E6EF' : values.plate ? 'green' : '#E6E6EF'
            }
            color="white"
            as="button"
            onClick={() =>
              handleChangeStationBools(true, 'plate', index1, index2)
            }
            disabled={!values.monitored}
          >
            <CheckIcon />
          </Circle>
        </HStack>
      </HStack>
      <FormControl id="observations">
        <FormLabel>Observaciones</FormLabel>
        <Textarea
          name="observations"
          value={values.observations}
          onChange={handleChangeStation}
          placeholder="Observaciones"
          h="20%"
        />
      </FormControl>
      <HStack spacing={8} w="100%">
        <FormControl
          id="flys"
          isInvalid={values.flys < 0 || values.flys === ''}
        >
          <FormLabel>Mosca (Musca común)</FormLabel>
          <Input
            name="flys"
            value={values.flys}
            onChange={handleChangeStation}
            placeholder="Moscas"
            type={'number'}
          />
          <FormErrorMessage>N° debe ser mayor o igual a 0</FormErrorMessage>
        </FormControl>
        <FormControl
          id="minorFlys"
          isInvalid={values.minorFlys < 0 || values.minorFlys === ''}
        >
          <FormLabel>Drosophila melanogaster (Mosca menor)</FormLabel>
          <Input
            name="minorFlys"
            value={values.minorFlys}
            onChange={handleChangeStation}
            placeholder="Moscas menores"
            type={'number'}
          />
          <FormErrorMessage>N° debe ser mayor o igual a 0</FormErrorMessage>
        </FormControl>
      </HStack>
      <HStack spacing={8} w="100%">
        <FormControl
          id="mosquitoes"
          isInvalid={values.mosquitoes < 0 || values.mosquitoes === ''}
        >
          <FormLabel>Culex pipiens (Zancudos)</FormLabel>
          <Input
            name="mosquitoes"
            value={values.mosquitoes}
            onChange={handleChangeStation}
            placeholder="Zancudos"
            type={'number'}
          />
          <FormErrorMessage>N° debe ser mayor o igual a 0</FormErrorMessage>
        </FormControl>
        <FormControl
          id="moths"
          isInvalid={values.moths < 0 || values.moths === ''}
        >
          <FormLabel>Tenia pellionella (Polillas)</FormLabel>
          <Input
            name="moths"
            value={values.moths}
            onChange={handleChangeStation}
            placeholder="Polillas"
            type={'number'}
          />
          <FormErrorMessage>N° debe ser mayor o igual a 0</FormErrorMessage>
        </FormControl>
      </HStack>
      <HStack spacing={8} w="100%">
        <FormControl
          id="wasps"
          isInvalid={values.wasps < 0 || values.wasps === ''}
        >
          <FormLabel>Vespula Germanica (Avispas)</FormLabel>
          <Input
            name="wasps"
            value={values.wasps}
            onChange={handleChangeStation}
            placeholder="Avispas"
            type={'number'}
          />
          <FormErrorMessage>N° debe ser mayor o igual a 0</FormErrorMessage>
        </FormControl>
        <FormControl
          id="bees"
          isInvalid={values.bees < 0 || values.bees === ''}
        >
          <FormLabel>Apis mellifera (Abejas)</FormLabel>
          <Input
            name="bees"
            value={values.bees}
            onChange={handleChangeStation}
            placeholder="Abejas"
            type={'number'}
          />
          <FormErrorMessage>N° debe ser mayor o igual a 0</FormErrorMessage>
        </FormControl>
      </HStack>
      <HStack justify={'space-between'} w={['100%', '15%']}>
        <Text fontSize="14px" fontWeight="bold" color="#36355F">
          Otro
        </Text>
        <Checkbox
          size="md"
          colorScheme="blue"
          name="other"
          isChecked={values.other}
          onChange={() =>
            handleChangeStationBools(!values.other, 'other', index1, index2)
          }
          borderColor="grey"
        ></Checkbox>
      </HStack>
      {values.other && (
        <HStack spacing={8} w="100%">
          <FormControl id="otherName">
            <FormLabel>Nombre</FormLabel>
            <Input
              name="otherName"
              value={values.otherName}
              onChange={handleChangeStation}
              placeholder="Otro"
            />
          </FormControl>
          <FormControl
            id="amount"
            isInvalid={values.amount < 0 || values.amount === ''}
          >
            <FormLabel>Cantidad</FormLabel>
            <Input
              name="amount"
              value={values.amount}
              onChange={handleChangeStation}
              placeholder="Cantidad"
              type={'number'}
            />
            <FormErrorMessage>N° debe ser mayor o igual a 0</FormErrorMessage>
          </FormControl>
        </HStack>
      )}
      <Box w="100%" bg="#F2F1FB" pb={5}>
        <Flex spacing={10} align="left" pt="15px">
          <Stack spacing={2} pl={30}>
            <Text fontSize="15px" color="#36355F" fontWeight={'bold'}>
              Total de insectos en estación:
            </Text>
          </Stack>
          <Spacer />
          <Stack spacing={2} pr={30}>
            <Text fontSize="20px" color="#BABACB" fontWeight={'bold'}>
              {total()}
            </Text>
          </Stack>
        </Flex>
      </Box>
      <Stack spacing={10} align={'center'}>
        <Button
          align="center"
          onClick={() => handleDeleteStation(index1, index2)}
          bg="#F2F1FB"
          color="#59BABA"
        >
          Eliminar Estación
        </Button>
      </Stack>
    </VStack>
  )
}

export default TUVStations
