import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Flex,
  Stack,
  Heading,
  Button,
  Box,
  Grid,
  Text,
  FormLabel,
  FormControl,
  Select,
  Input,
  Spacer,
  Skeleton,
  HStack,
  VStack
} from '@chakra-ui/react'
import { Search2Icon, DownloadIcon } from '@chakra-ui/icons'
import * as Yup from 'yup'
import { useFormik } from 'formik'

import { useAllProducts } from '../../hooks/products'
import { useAllClients } from '../../hooks/clients'
import {
  useSuppliesDashboard,
  useSuppliesXlsx
} from '../../hooks/reportability'
import BarGraph from '../../components/Dashboard/BarGraph'

const validationSchema = Yup.object().shape({
  client: Yup.number(),
  type: Yup.string(),
  product: Yup.number(),
  startDate: Yup.string(),
  endDate: Yup.string()
})

const types = [
  { id: 1, name: 'Desratización' },
  { id: 2, name: 'Desinsectación' },
  { id: 3, name: 'Sanitización' }
]

// Pantalla de reportabilidad
const Reportability = () => {
  const history = useHistory()
  const [formData, setFormData] = useState({
    client: 0,
    type: '',
    product: 0,
    startDate: '',
    endDate: ''
  })
  const {
    data: products,
    isFetching: fetchProducts,
    isLoading: loadingProducts,
    error: errorProducts
  } = useAllProducts()

  const {
    data: clients,
    isFetching: fetchClients,
    isLoading: loadingClients,
    error: errorClients
  } = useAllClients()

  const {
    data: dashData,
    isFetching: fetchDash,
    isLoading: loadingDash,
    error: errorDash,
    refetch: refetchDash
  } = useSuppliesDashboard(formData)

  const {
    data: xlsxData,
    isFetching: fetchXlsx,
    isLoading: loadingXlsx,
    error: errorXlsx,
    refetch: refetchXlsx
  } = useSuppliesXlsx(formData)

  const formik = useFormik({
    validationSchema,
    enableReinitialize: true,
    initialValues: {
      client: 0,
      type: '',
      product: 0,
      startDate: '',
      endDate: ''
    },
    onSubmit: async (values) => {
      await setFormData(values)
    }
  })

  // Fecha actual
  const currentDate = () => {
    const newDate = new Date().toISOString().slice(0, -14)
    return newDate
  }

  // Fetch del archivo xlsx
  const handleXlsx = () => {
    refetchXlsx()
  }

  // Fetch de datos de dashboard
  useEffect(() => {
    refetchDash()
  }, [formData])

  // Iniciar descarga de xlsx
  useEffect(() => {
    if (xlsxData) {
      window.open(xlsxData)
    }
  }, [xlsxData])

  // Obtener producto
  const getProduct = (id) => {
    const product = products.find((prod) => {
      return prod.id.toString() === id
    })
    return product.commercial
  }

  // Obtener cliente
  const getClient = (id) => {
    const client = clients.find((cli) => {
      return cli.id.toString() === id
    })
    return client.company_name || client.name
  }

  return (
    <Stack spacing={8} px={6}>
      <Flex bg={'#FFF'}>
        <Stack spacing={8} py={12} px={6}>
          <Stack align={'left'}>
            <Heading fontSize={'4xl'} color={'#36355F'}>
              Reportabilidad
            </Heading>
            <Text fontSize="20px" fontWeight={'bold'} color="#36355F">
              Insumos usados
            </Text>
            <Text fontSize="15px" color="#36355F">
              Se recomienda usar un computador para una mejor vizualización
            </Text>
          </Stack>
        </Stack>
      </Flex>
      <Box w="100%">
        <Flex spacing="10px" align="left">
          <FormControl id="type" flex="2">
            <FormLabel>Cliente</FormLabel>
            <Select
              placeholder="Cliente"
              name="client"
              onChange={formik.handleChange}
            >
              {clients
                ? clients.map((t) => (
                    <option value={t.id} key={t.id}>
                      {t.company_name || t.name}
                    </option>
                  ))
                : []}
            </Select>
          </FormControl>
          <Spacer />
          <FormControl id="type" flex="2">
            <FormLabel>Tipo de Servicio</FormLabel>
            <Select
              placeholder="Servicio"
              name="type"
              onChange={formik.handleChange}
            >
              {types.map((t) => (
                <option value={t.name} key={t.id}>
                  {t.name}
                </option>
              ))}
            </Select>
          </FormControl>
          <Spacer />
          <FormControl id="product" flex="2">
            <FormLabel>Nombre Comercial</FormLabel>
            <Select
              placeholder="Producto"
              name="product"
              onChange={formik.handleChange}
            >
              {products
                ? products.map((p) => (
                    <option value={p.id} key={p.id}>
                      {p.commercial}
                    </option>
                  ))
                : []}
            </Select>
          </FormControl>
          <Spacer />
          <FormControl id="startDate" flex="2">
            <FormLabel>Desde</FormLabel>
            <Input
              name="startDate"
              value={formik.values.startDate}
              onChange={formik.handleChange}
              type="date"
              max={currentDate()}
            />
          </FormControl>
          <Spacer />
          <FormControl id="endDate" flex="2">
            <FormLabel>Hasta</FormLabel>
            <Input
              name="endDate"
              value={formik.values.endDate}
              onChange={formik.handleChange}
              type="date"
              max={currentDate()}
            />
          </FormControl>
          <Spacer />
          <Button
            onClick={formik.handleSubmit}
            bg={'teal.500'}
            color={'white'}
            _hover={{
              bg: 'teal.600'
            }}
            leftIcon={<Search2Icon />}
            flex="1"
            disabled={fetchDash}
          >
            Buscar
          </Button>
        </Flex>
      </Box>
      {dashData ? (
        <Box w="100%">
          <Flex mb={5}>
            <VStack>
              <Grid display="inline" float="left">
                <Text fontSize="17px" color="#36355F" as="span">
                  Cliente:
                </Text>
                <Text
                  fontSize="17px"
                  fontWeight="bold"
                  color="#36355F"
                  as="span"
                >
                  {formData.client ? getClient(formData.client) : '-'}
                </Text>
              </Grid>
              <Grid display="inline" float="left">
                <Text fontSize="17px" color="#36355F" as="span">
                  Tipo de servicio:
                </Text>
                <Text
                  fontSize="17px"
                  fontWeight="bold"
                  color="#36355F"
                  as="span"
                >
                  {formData.type ? formData.type : '-'}
                </Text>
              </Grid>
              <Grid display="inline" float="left">
                <Text fontSize="17px" color="#36355F" as="span">
                  Insumo:
                </Text>
                <Text
                  fontSize="17px"
                  fontWeight="bold"
                  color="#36355F"
                  as="span"
                >
                  {formData.product - 1 >= 0
                    ? getProduct(formData.product)
                    : '-'}
                </Text>
              </Grid>
              <Grid display="inline" float="left">
                <Text fontSize="17px" color="#36355F" as="span">
                  Periodo:
                </Text>
                <Text
                  fontSize="17px"
                  fontWeight="bold"
                  color="#36355F"
                  as="span"
                >
                  {formData.startDate} - {formData.endDate}
                </Text>
              </Grid>
            </VStack>
            <Spacer />
            <Spacer />
            <Spacer />
            <Spacer />
            <Spacer />
            <Button
              onClick={handleXlsx}
              bg={'#D1F1F580'}
              color={'#36355F'}
              _hover={{
                bg: '#D1F1F5'
              }}
              rightIcon={<DownloadIcon />}
              flex="1"
            >
              Descargar
            </Button>
          </Flex>
          <Box pb={50}>
            <BarGraph data={dashData} x={'name'} />
          </Box>
        </Box>
      ) : (
        <Stack>
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
        </Stack>
      )}
    </Stack>
  )
}

export default Reportability
