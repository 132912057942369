import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Flex,
  Box,
  Stack,
  HStack,
  Heading,
  Button,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Text,
  Avatar,
  VStack,
  Grid,
  GridItem,
  Input,
  IconButton,
  Icon,
  Select,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useToast,
  useDisclosure
} from '@chakra-ui/react'

import { OptionsIcon } from '../../components/Shared/Icons'
import formatDate from '../../utils/formatDate'
import { useMutateVisit } from '../../hooks/visits'
import DeleteModal from '../Shared/DeleteModal'
import StatusTag from '../Shared/StatusTag'

// Tabla de visitas
const VisitsTable = ({ data }) => {
  const history = useHistory()
  const toast = useToast()
  const [selectedVisit, setSelectedVisit] = useState(null)
  const { mutate } = useMutateVisit('DELETE')
  const {
    isOpen: openDelete,
    onOpen: onOpenDelete,
    onClose: closeDelete
  } = useDisclosure()

  // Eliminar visita
  const handleDelete = (visitId) => {
    mutate(visitId, {
      onSuccess: () => {
        toast({
          title: `Visita eliminada`,
          status: 'success',
          isClosable: true
        })
      },
      onError: () => {
        toast({
          title: `Error al eliminar visita`,
          status: 'error',
          isClosable: true
        })
      }
    })
    closeDelete()
  }

  const handleClickDelete = (visit) => {
    setSelectedVisit(visit)
    onOpenDelete()
  }

  const handleClickView = (visit) => {
    history.push(`/visitas/${visit.id}`)
  }

  return (
    <Flex bg={'#FFF'}>
      <Box w="100%">
        <TableContainer maxWidth={'100%'}>
          <Table variant="simple" size="sm">
            <Thead bg={'#F2F1FB'} sx={{ borderCollapse: 'separate' }}>
              <Tr>
                <Th color="#36355F" fontWeight={'normal'}>
                  Fecha
                </Th>
                <Th color="#36355F" fontWeight={'normal'}>
                  Cliente
                </Th>
                <Th color="#36355F" fontWeight={'normal'}>
                  Creado por
                </Th>
                <Th color="#36355F" fontWeight={'normal'}>
                  Estado
                </Th>
                <Th color="#36355F" fontWeight={'normal'}>
                  Opciones
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {data?.map((visit) => (
                <Tr>
                  <Td>
                    <Text
                      fontSize={['12px', '18px']}
                      fontWeight={'bold'}
                      overflowWrap="break-word"
                    >
                      {formatDate(visit.created_at)}
                    </Text>
                  </Td>
                  <Td>
                    <Text
                      fontSize={['12px', '18px']}
                      fontWeight={'bold'}
                      overflowWrap="break-word"
                    >
                      {visit.clients.company_name || visit.clients.name}
                    </Text>
                  </Td>
                  <Td>
                    <Text
                      fontSize={['12px', '18px']}
                      fontWeight={'bold'}
                      overflowWrap="break-word"
                    >
                      {visit.users.name}
                    </Text>
                  </Td>
                  <Td>
                    <StatusTag status={visit.status} />
                  </Td>
                  <Td>
                    <Menu>
                      <MenuButton
                        as={Button}
                        bg="#FFFFFF"
                        leftIcon={
                          <Icon
                            fontSize="22"
                            as={OptionsIcon}
                            color="#374957"
                          />
                        }
                      />
                      <MenuList>
                        <MenuItem onClick={() => handleClickView(visit)}>
                          Abrir
                        </MenuItem>
                        <MenuItem onClick={() => handleClickDelete(visit)}>
                          Eliminar
                        </MenuItem>
                        {/* <MenuItem>Descargar</MenuItem> */}
                      </MenuList>
                    </Menu>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
      {openDelete && (
        <DeleteModal
          isOpen={openDelete}
          onClose={closeDelete}
          message="¿Esta seguro de querer eliminar la visita?"
          handleDelete={handleDelete}
          selected={selectedVisit}
        />
      )}
    </Flex>
  )
}

export default VisitsTable
