/* import { saveAs } from 'file-saver' */
import axios from '../Axios'
import formatQuery from './helpers'

const getAll = async ({ endPoint, querys }) => {
  const queryObj = formatQuery(querys)

  try {
    const { data } = await axios.get(`${endPoint}`, { params: queryObj })

    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

const getOne = async ({ endPoint, id }) => {
  try {
    const { data } = await axios.get(`${endPoint}/${id}`)
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

const createOne = async ({ endPoint, body, extraConfigAxios = {} }) => {
  try {
    const config = {
      ...extraConfigAxios
    }
    const { data } = await axios.post(`${endPoint}`, body, config)
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

const updateOne = async ({ endPoint, id, body }) => {
  try {
    const { data } = await axios.put(`${endPoint}/${id}`, body)
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

const removeOne = async ({ endPoint, id }) => {
  try {
    const { data } = await axios.delete(`${endPoint}/${id}`)
    return {
      ...data,
      id
    }
  } catch (error) {
    return Promise.reject(error)
  }
}

/* const generateFileToDownload = async ({
  endPoint,
  filters,
  fileName,
  extension
}) => {
  const { data } = await axios.post(
    `${endPoint}`,
    { ...filters },
    {
      responseType: 'arraybuffer'
    }
  )

  const extension_is_xlsx = extensionDict.EXCEL.includes(extension)
  const extension_is_pdf = extensionDict.PDF.includes(extension)

  const fileName_ =
    fileName ||
    `file${
      extension_is_xlsx ? extensionDict.EXCEL.at(0) : extensionDict.PDF.at(0)
    }`

  let blob = null

  if (extension_is_xlsx) {
    blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    })
  }
  if (extension_is_pdf) {
    blob = new Blob([data], {
      type: 'application/pdf'
    })
  }
  saveAs(blob, fileName_)
  return data
} */

const ApiService = {
  getAll,
  getOne,
  createOne,
  updateOne,
  removeOne
  /* generateFileToDownload */
}
export default ApiService
