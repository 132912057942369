import { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Text, Box, SimpleGrid, Image, HStack } from '@chakra-ui/react'

import addFileIcon from '../../assets/images/addFile.svg'

// Subida de pdf
const UploadImage = ({ pdfUpload }) => {
  const inputRef = useRef()
  const [file, setFile] = useState(null)

  // Cuando se detecta el pdf se sube a un s3
  useEffect(() => {
    if (file) {
      const formPDF = new FormData()
      formPDF.append('file', file)

      pdfUpload(formPDF)
    }
  }, [file])

  // Se limita a 1 archivo
  const handleSetFile = (event) => {
    event.preventDefault()
    const { files } = event.target
    if (Array.from(files).length > 1) {
      alert('Puedes subir 1 archivo a la vez')
      return
    }
    setFile(files[0])
  }

  return (
    <Box
      as="button"
      // h="50px"
      //   w="90%"
      bg="#FFFFFF"
      mb={4}
      border="2px"
      borderStyle="dashed"
      borderRadius="lg"
      borderColor="#59BABA"
      alignItems="center"
      justifyContent="center"
      // key={data.id}
      display="flex"
      onClick={() => inputRef.current.click()}
    >
      <input
        ref={inputRef}
        style={{ margin: 'auto', display: 'none' }}
        type="file"
        accept="application/pdf"
        onChange={(event) => handleSetFile(event)}
      ></input>
      <SimpleGrid columns={1} spacing={10} p={3}>
        <HStack>
          <Image src={addFileIcon} m="auto" />
          <Text fontSize="15px" fontWeight="bold" color="#59BABA" m="auto">
            Agréga un mapa
          </Text>
        </HStack>
      </SimpleGrid>
    </Box>
  )
}

export default UploadImage
