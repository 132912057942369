import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Textarea
} from '@chakra-ui/react'

const TextAreaInput = ({
  name,
  label,
  errors,
  errorMsg,
  value,
  onChange,
  ...props
}) => {
  return (
    <FormControl id={name} isInvalid={errors}>
      <FormLabel>{label}</FormLabel>
      <Textarea
        name={name}
        value={value}
        onChange={onChange}
        h="20%"
        {...props}
      />
      <FormErrorMessage>{errorMsg}</FormErrorMessage>
    </FormControl>
  )
}

export default TextAreaInput
