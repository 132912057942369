import { useQuery, useMutation, useQueryClient } from 'react-query'
import { getOrts, getOneOrt, createOrt, updateOrt, deleteOrt } from '../api/ort'

const getAction = (type) => {
  if (type === 'CREATE') return createOrt
  if (type === 'UPDATE') return updateOrt
  if (type === 'DELETE') return deleteOrt
  return null
}

export function useOrts(page, props = {}) {
  return useQuery(['orts', page], () => getOrts(page), {
    ...props,
    onError: (err) => {
      console.log(err.response)
    }
  })
}

export function useOrt(ortId) {
  return useQuery(['ort', ortId], () => getOneOrt(ortId), {
    onError: (err) => {
      console.log(err.response)
    }
  })
}

export function useMutateOrt(type) {
  const queryClient = useQueryClient()
  const actionFn = getAction(type)
  return useMutation(actionFn, {
    onSuccess: () => {
      queryClient.invalidateQueries('ort')
      queryClient.invalidateQueries('visit')
    },
    onError: (err) => {
      console.log(err.response)
    }
  })
}
