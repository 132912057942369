import { useQuery, useMutation, useQueryClient } from 'react-query'
import { getEPPs, getOneEPP, createEPP, updateEPP, deleteEPP } from '../api/epp'

const getAction = (type) => {
  if (type === 'CREATE') return createEPP
  if (type === 'UPDATE') return updateEPP
  if (type === 'DELETE') return deleteEPP
  return null
}

export function useEPPs(page, formData, props = {}) {
  return useQuery(
    ['epps', page],
    () => getEPPs(page, formData),
    { enabled: false },
    {
      ...props,
      onError: (err) => {
        console.log(err.response)
      }
    }
  )
}

export function useEPP(eppId) {
  return useQuery(['epp', eppId], () => getOneEPP(eppId), {
    onError: (err) => {
      console.log(err.response)
    }
  })
}

export function useMutateEPP(type) {
  const queryClient = useQueryClient()
  const actionFn = getAction(type)
  return useMutation(actionFn, {
    onSuccess: () => {
      queryClient.invalidateQueries('epps')
    },
    onError: (err) => {
      console.log(err.response)
    }
  })
}
