import { useState } from 'react'
import { Stack, HStack, Box, Icon, Text } from '@chakra-ui/react'

import {
  OTRIcon,
  RatIcon,
  InsectIcon,
  QualityIcon,
  FlagIcon
} from '../../components/Shared/Icons'
import Can from '../../auth/Can'
import { useMutateOrt } from '../../hooks/ort'
import { useMutateRegistry } from '../../hooks/rodentsRegistry'
import { useMutateTuv } from '../../hooks/tuv'
import { useMutateControl } from '../../hooks/qualityControl'
import { useMutateReport } from '../../hooks/visitReport'

// Tipo de documento
const DocumentsTypes = ({ visitId, user, setNewDocument }) => {
  const [category, setCategory] = useState(0)
  const {
    mutate: mutateOrt,
    isLoading: loadingOrt,
    reset: resetOrt
  } = useMutateOrt('CREATE')
  const {
    mutate: mutateRegistry,
    isLoading: loadingRegistry,
    reset: resetRegistry
  } = useMutateRegistry('CREATE')
  const {
    mutate: mutateTuv,
    isLoading: loadingTuv,
    reset: resetTuv
  } = useMutateTuv('CREATE')
  const {
    mutate: mutateControl,
    isLoading: loadingControl,
    reset: resetControl
  } = useMutateControl('CREATE')
  const {
    mutate: mutateReport,
    isLoading: loadingReport,
    reset: resetReport
  } = useMutateReport('CREATE')

  const selectCategory = (id) => {
    setCategory(id)
  }

  // Crear documento ORT
  const createOrt = () => {
    selectCategory(1)
    const formData = { id_visit: visitId, id_user: user }
    mutateOrt(formData, {
      onSuccess: () => {
        resetOrt()
        setNewDocument(false)
        selectCategory(0)
      },
      onError: (err) => {
        resetOrt()
        console.log(err)
      }
    })
  }

  // Crear documento registro de roedores
  const createRegistry = () => {
    selectCategory(2)
    const formData = { id_visit: visitId, id_user: user }
    mutateRegistry(formData, {
      onSuccess: () => {
        resetRegistry()
        setNewDocument(false)
        selectCategory(0)
      },
      onError: (err) => {
        resetRegistry()
        console.log(err)
      }
    })
  }

  // Crear documento TUV
  const createTuv = () => {
    selectCategory(3)
    const formData = { id_visit: visitId, id_user: user }
    mutateTuv(formData, {
      onSuccess: () => {
        resetTuv()
        setNewDocument(false)
        selectCategory(0)
      },
      onError: (err) => {
        resetTuv()
        console.log(err)
      }
    })
  }

  // Crear documento control de calidad
  const createControl = () => {
    selectCategory(4)
    const formData = { id_visit: visitId, id_user: user }
    mutateControl(formData, {
      onSuccess: () => {
        resetControl()
        setNewDocument(false)
        selectCategory(0)
      },
      onError: (err) => {
        resetControl()
        console.log(err)
      }
    })
  }

  // Crear documento reporte diario
  const createReport = () => {
    selectCategory(5)
    const formData = { id_visit: visitId, id_user: user }
    mutateReport(formData, {
      onSuccess: () => {
        resetReport()
        setNewDocument(false)
        selectCategory(0)
      },
      onError: (err) => {
        resetReport()
        console.log(err)
      }
    })
  }

  return (
    <>
      <Stack spacing={2} pl={30}>
        <Text fontSize="10px" color="#36355F">
          Documentos de visita
        </Text>
        <Text fontSize="25px" color="#36355F" fontWeight="bold">
          Selecciona un tipo de documento
        </Text>
      </Stack>
      <HStack
        spacing="24px"
        overflowX={'scroll'}
        css={{
          '&::-webkit-scrollbar': {
            display: 'none'
          }
        }}
        w="full"
      >
        <Can
          availableTo={[1, 2]}
          yes={() => (
            <Box
              as="button"
              minH={['20vh', '20vh', '12vh']}
              minW={['30vw', '30vw', '10vw']}
              bg={category === 1 ? '#59BABA' : '#D1F1F5'}
              borderRadius="lg"
              display="flex"
              onClick={createOrt}
            >
              <Text
                fontSize="15px"
                fontWeight="bold"
                color={category === 1 ? '#FFFFFF' : '#59BABA'}
                m="auto"
              >
                <Icon
                  mr="4"
                  fontSize="22"
                  as={OTRIcon}
                  color={category === 1 ? '#FFFFFF' : '#59BABA'}
                />
                ORT
              </Text>
            </Box>
          )}
        />
        <Can
          availableTo={[1, 2]}
          yes={() => (
            <Box
              as="button"
              minH={['20vh', '20vh', '12vh']}
              minW={['30vw', '30vw', '10vw']}
              bg={category === 2 ? '#5241AB' : '#E9E5FE'}
              borderRadius="lg"
              display="flex"
              onClick={createRegistry}
            >
              <Text
                fontSize="15px"
                fontWeight="bold"
                color={category === 2 ? '#FFFFFF' : '#5241AB'}
                m="auto"
              >
                <Icon
                  mr="4"
                  fontSize="22"
                  as={RatIcon}
                  color={category === 2 ? '#FFFFFF' : '#5241AB'}
                />
                Registro estación control de roedores
              </Text>
            </Box>
          )}
        />
        <Can
          availableTo={[1, 2]}
          yes={() => (
            <Box
              as="button"
              minH={['20vh', '20vh', '12vh']}
              minW={['30vw', '30vw', '10vw']}
              bg={category === 3 ? '#EC8032' : '#FFEFDA'}
              borderRadius="lg"
              display="flex"
              onClick={createTuv}
            >
              <Text
                fontSize="15px"
                fontWeight="bold"
                color={category === 3 ? '#FFFFFF' : '#EC8032'}
                m="auto"
              >
                <Icon
                  mr="4"
                  fontSize="22"
                  as={InsectIcon}
                  color={category === 3 ? '#FFFFFF' : '#EC8032'}
                />
                TUV para insectos voladores
              </Text>
            </Box>
          )}
        />
        <Can
          availableTo={[1, 3]}
          yes={() => (
            <Box
              as="button"
              minH={['20vh', '20vh', '12vh']}
              minW={['30vw', '30vw', '10vw']}
              bg={category === 4 ? '#FA9595' : '#FCE2E2'}
              borderRadius="lg"
              display="flex"
              onClick={createControl}
            >
              <Text
                fontSize="15px"
                fontWeight="bold"
                color={category === 4 ? '#FFFFFF' : '#FF7676'}
                m="auto"
              >
                <Icon
                  mr="4"
                  fontSize="22"
                  as={QualityIcon}
                  color={category === 4 ? '#FFFFFF' : '#FF7676'}
                />
                Control de calidad
              </Text>
            </Box>
          )}
        />
        <Can
          availableTo={[1, 3]}
          yes={() => (
            <Box
              as="button"
              minH={['20vh', '20vh', '12vh']}
              minW={['30vw', '30vw', '10vw']}
              bg={category === 5 ? '#C0C4E6' : '#EDEFFF'}
              borderRadius="lg"
              display="flex"
              onClick={createReport}
            >
              <Text
                fontSize="15px"
                fontWeight="bold"
                color={category === 5 ? '#FFFFFF' : '#838FFD'}
                m="auto"
              >
                <Icon
                  mr="4"
                  fontSize="22"
                  as={FlagIcon}
                  color={category === 5 ? '#FFFFFF' : '#838FFD'}
                />
                Reporte de visita
              </Text>
            </Box>
          )}
        />
      </HStack>
    </>
  )
}

export default DocumentsTypes
