import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  filterList: {
    page: 1,
    userId: '',
    document: '',
    startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1)
      .toLocaleString('en-us', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      })
      .replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2'),
    endDate: new Date()
      .toLocaleString('en-us', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      })
      .replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2')
  },
  currentList: {
    items: [],
    total: 0,
    page: 1
  }
}

export const documentSlice = createSlice({
  name: 'document',
  initialState: initialState,
  reducers: {
    updateFilterList: (state, action) => {
      state.filterList = {
        ...state.filterList,
        ...action.payload
      }
    },
    updateCurrentList: (state, action) => {
      state.currentList = {
        ...state.currentList,
        ...action.payload
      }
    },
    resetDocument: (state) => {
      state.filterList.page = initialState.filterList.page
      state.filterList.userId = initialState.filterList.userId
      state.filterList.document = initialState.filterList.document
      state.filterList.startDate = initialState.filterList.startDate
      state.filterList.endDate = initialState.filterList.endDate
      state.currentList.items = initialState.currentList.items
      state.currentList.total = initialState.currentList.total
      state.currentList.page = initialState.currentList.page
    }
  }
})
export const { updateFilterList, resetDocument, updateCurrentList } =
  documentSlice.actions
/* --- */
export default documentSlice.reducer
/* --- */
