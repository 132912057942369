import { useRef, useState, useEffect } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  Text,
  Button,
  Image,
  Box,
  IconButton,
  VStack,
  useDisclosure,
  SimpleGrid,
  Flex
} from '@chakra-ui/react'
import SignatureCanvas from 'react-signature-canvas'

import { ReloadIcon } from '../../../components/Shared/Icons'
import { RutInput, SimpleInput } from '../inputs'

const initialValues = {
  ruc: null,
  fullName: null,
  signature: null
}

const initialFieldsNames = {
  ruc: '',
  fullName: '',
  signature: ''
}

const initialFieldRequired = {
  ruc: true,
  fullName: false,
  signature: true
}

// Modal de firma
const SignatureModal = ({
  label,
  formik,
  fieldsNames = initialFieldsNames,
  values = initialValues,
  fieldRequired = initialFieldRequired
}) => {
  const fieldRequiredUnion = { ...initialFieldRequired, ...fieldRequired }
  const { isOpen, onClose, onOpen } = useDisclosure()
  const [fielsToRender, setFielsToRender] = useState(initialValues)
  const sigCanvas = useRef({})

  const clear = () => sigCanvas.current.clear()

  const isCanvasEmpty = (canvas) => {
    // Obtiene el contexto de dibujo 2D
    const ctx = canvas.getContext('2d')

    // Verifica el contenido del contexto de dibujo
    const isCanvasEmpty = ctx
      .getImageData(0, 0, canvas.width, canvas.height)
      .data.every((pixel) => pixel === 0)

    return isCanvasEmpty
  }

  const save = () => {
    const isEmpty = isCanvasEmpty(sigCanvas.current.getCanvas())

    if (!isEmpty) {
      const signatureUrl = sigCanvas.current
        .getTrimmedCanvas()
        .toDataURL('image/png')

      formik.setFieldValue(fieldsNames.signature, signatureUrl)
    }
    onClose()
  }

  const validateField = (buttonType) => {
    const isValidAllField = Boolean(
      !formik.errors[fieldsNames?.ruc] &&
        !formik.errors[fieldsNames?.signature] &&
        !formik.errors[fieldsNames?.fullName]
    )

    const isButtonSave = buttonType === 'SAVE'

    if (isButtonSave && isValidAllField) return save()
    if (!isButtonSave && isValidAllField) return onClose()
    return null
  }

  useEffect(() => {
    if (!isOpen) {
      const { fullName, ruc } = values
      setFielsToRender((prev) => ({
        ...prev,
        fullName: fullName ? fullName : null,
        ruc: ruc ? ruc : null
      }))
    }
  }, [isOpen, values])

  return (
    <Box
      bg="#FFFFFF"
      border="2px"
      borderStyle="solid"
      borderRadius="lg"
      borderColor="#59BABA"
      alignItems="center"
      justifyContent="center"
      display="flex"
      w="100%"
    >
      <SimpleGrid columns={1} spacing={10} py={3}>
        <Text fontSize="15px" fontWeight="bold" color="#36355F" m="auto">
          {label}
        </Text>
        {values?.signature && (
          <Box bg="white" border="1px solid #d1d1d1" borderRadius={8}>
            <Image src={values?.signature} alt="Firma" />
          </Box>
        )}
        <Flex direction="column" justify="center" alignItems="center">
          {fielsToRender?.ruc && <Text>{fielsToRender?.ruc}</Text>}
          {fielsToRender?.fullName && <Text>{fielsToRender?.fullName}</Text>}
        </Flex>

        <Button
          align="center"
          onClick={onOpen}
          bg="#59BABA"
          color="#FFFFFF"
          border="1px"
          borderColor="#59BABA"
        >
          {values?.signature ? 'Cambiar Firma' : 'Añadir firma'}
        </Button>

        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalBody>
              <Text align="center" fontWeight={'bold'}>
                {values?.signature ? 'Cambiar Firma' : 'Añadir firma'}
              </Text>
              <Box
                bg="#FFFFFF"
                border="1px"
                borderStyle="solid"
                borderRadius="lg"
                borderColor="#E6E6EF"
                alignItems="center"
                justifyContent="center"
                display="flex"
                p={2}
              >
                <VStack w="100%">
                  <IconButton
                    aria-label="Clear"
                    icon={<ReloadIcon />}
                    onClick={clear}
                    bg="#FFFFFF"
                  />
                  <Box bg="white" border="1px solid #d1d1d1" borderRadius={8}>
                    <SignatureCanvas
                      ref={sigCanvas}
                      penColor="black"
                      canvasProps={{
                        width: '300',
                        height: '240%'
                      }}
                    />
                  </Box>
                  {fieldRequiredUnion.ruc && (
                    <RutInput
                      label="Rut"
                      value={values?.ruc}
                      fieldName={fieldsNames?.ruc}
                      formik={formik}
                      isInvalid={Boolean(formik.errors[fieldsNames?.ruc])}
                      errorMessage={formik.errors[fieldsNames?.ruc]}
                    />
                  )}
                  {fieldRequiredUnion.fullName && (
                    <SimpleInput
                      label="Nombre"
                      name={fieldsNames?.fullName}
                      value={values?.fullName}
                      errors={Boolean(formik.errors[fieldsNames?.fullName])}
                      errorMsg={formik.errors[fieldsNames?.fullName]}
                      onChange={(e) =>
                        formik.setFieldValue(
                          fieldsNames?.fullName,
                          e.target.value
                        )
                      }
                    />
                  )}
                </VStack>
              </Box>
            </ModalBody>
            <ModalFooter>
              <Button
                align="center"
                bg="#F2F1FB"
                color="#59BABA"
                mr={5}
                onClick={() => validateField('CANCEL')}
              >
                Cancelar
              </Button>
              <Button
                align="center"
                bg={'teal.500'}
                color={'white'}
                _hover={{
                  bg: 'teal.600'
                }}
                onClick={() => validateField('SAVE')}
              >
                Aceptar
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </SimpleGrid>
    </Box>
  )
}

export default SignatureModal
