import { useState, useEffect } from 'react'
import {
  Flex,
  Stack,
  VStack,
  HStack,
  Box,
  Button,
  Icon,
  Spacer,
  Text,
  Textarea,
  Checkbox,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Grid,
  useDisclosure,
  Select,
  Switch,
  SimpleGrid,
  Image
} from '@chakra-ui/react'

import UploadFile from '../Shared/UploadFile'
import SignatureModal from '../Shared/SignatureModal'
import CheckEPP from './CheckEPP'

const Areas = [
  {
    id: 1,
    area: 'Control de plagas',
    positions: [
      { id: 1, position: 'Técnico' },
      { id: 2, position: 'Director Técnico' },
      { id: 3, position: 'Control de calidad' },
      { id: 4, position: 'Comercial' }
    ]
  },
  {
    id: 2,
    area: 'CRP57',
    positions: [
      { id: 5, position: 'Jornal' },
      { id: 6, position: 'Jornal Conductor' },
      { id: 7, position: 'Administrativo' },
      { id: 8, position: 'Capataz' },
      { id: 9, position: 'Supervisor Tramo' },
      { id: 10, position: 'Auxiliar aseo' }
    ]
  },
  {
    id: 3,
    area: 'CRP60',
    positions: [
      { id: 5, position: 'Jornal' },
      { id: 6, position: 'Jornal Conductor' },
      { id: 7, position: 'Administrativo' },
      { id: 8, position: 'Capataz' },
      { id: 9, position: 'Supervisor Tramo' },
      { id: 10, position: 'Auxiliar aseo' }
    ]
  },
  {
    id: 4,
    area: 'Renca',
    positions: [{ id: 11, position: 'Conductor' }]
  },
  {
    id: 5,
    area: 'El colorado',
    positions: [
      { id: 11, position: 'Conductor' },
      { id: 5, position: 'Jornal' }
    ]
  },
  {
    id: 6,
    area: 'Aes Gener',
    positions: [
      { id: 12, position: 'Supervisor' },
      { id: 13, position: 'Jardinero' },
      { id: 14, position: 'Auxiliar aseo' }
    ]
  },
  {
    id: 7,
    area: 'Maipo',
    positions: [{ id: 11, position: 'Conductor' }]
  },
  {
    id: 8,
    area: 'Scada',
    positions: [
      { id: 8, position: 'Capataz' },
      { id: 5, position: 'Jornal' }
    ]
  }
]

const Types = [
  { id: 1, name: 'Plagas' },
  { id: 2, name: 'Autopista' },
  { id: 3, name: 'Aes Gener' },
  { id: 4, name: 'Supervisores' }
]

// Formulario de empleado
const WorkerForm = ({ index, values, handleChange, handleChangeCheck }) => {
  const [imageURL, setImageURL] = useState(null)
  const {
    isOpen: openSignature,
    onOpen: onOpenSignature,
    onClose: closeSignature
  } = useDisclosure()

  // Guardar en el estado la firma si ya existe
  useEffect(() => {
    if (values.signature) {
      setImageURL(values.signature)
    }
  }, [values.signature])

  // Guardar en el estado la firma nueva
  useEffect(() => {
    if (imageURL) {
      handleChangeCheck(imageURL, 'signature', index)
    }
  }, [imageURL])

  const handleClickSignature = () => {
    onOpenSignature()
  }

  // Lista de posiciones del área seleccionada
  const positions = (area) => {
    const positionsList = Areas.find((data) => data.id.toString() === area)
    return positionsList ? positionsList.positions : []
  }

  const pests = () => {
    return (
      <>
        <HStack spacing="24px" overflowX={'scroll'} py={3} px={2}>
          <CheckEPP
            title={'Casco'}
            setValues={handleChangeCheck}
            switchName={'monitored_helmet'}
            monitored={values.monitored_helmet}
            status={values.status_helmet}
            statusName={'status_helmet'}
            image={values.image_helmet}
            imageName={'image_helmet'}
            index={index}
          />
          <CheckEPP
            title={'Arnes Casco'}
            setValues={handleChangeCheck}
            switchName={'monitored_helmet_harness'}
            monitored={values.monitored_helmet_harness}
            status={values.status_helmet_harness}
            statusName={'status_helmet_harness'}
            image={values.image_helmet_harness}
            imageName={'image_helmet_harness'}
            index={index}
          />
          <CheckEPP
            title={'Lente Seguridad Claro'}
            setValues={handleChangeCheck}
            switchName={'monitored_clear_eyewear'}
            monitored={values.monitored_clear_eyewear}
            status={values.status_clear_eyewear}
            statusName={'status_clear_eyewear'}
            image={values.image_clear_eyewear}
            imageName={'image_clear_eyewear'}
            index={index}
          />
          <CheckEPP
            title={'Lente Seguridad Gris'}
            setValues={handleChangeCheck}
            switchName={'monitored_grey_eyewear'}
            monitored={values.monitored_grey_eyewear}
            status={values.status_grey_eyewear}
            statusName={'status_grey_eyewear'}
            image={values.image_grey_eyewear}
            imageName={'image_grey_eyewear'}
            index={index}
          />
          <CheckEPP
            title={'Full Face'}
            setValues={handleChangeCheck}
            switchName={'monitored_full_face'}
            monitored={values.monitored_full_face}
            status={values.status_full_face}
            statusName={'status_full_face'}
            image={values.image_full_face}
            imageName={'image_full_face'}
            index={index}
          />
          <CheckEPP
            title={'Filtro Gases y Vapores Orgánicos'}
            setValues={handleChangeCheck}
            switchName={'monitored_gas_filter'}
            monitored={values.monitored_gas_filter}
            status={values.status_gas_filter}
            statusName={'status_gas_filter'}
            image={values.image_gas_filter}
            imageName={'image_gas_filter'}
            index={index}
          />
          <CheckEPP
            title={'Medio Rostro'}
            setValues={handleChangeCheck}
            switchName={'monitored_half_face'}
            monitored={values.monitored_half_face}
            status={values.status_half_face}
            statusName={'status_half_face'}
            image={values.image_half_face}
            imageName={'image_half_face'}
            index={index}
          />
          <CheckEPP
            title={'Filtro Partículas'}
            setValues={handleChangeCheck}
            switchName={'monitored_particles_filter'}
            monitored={values.monitored_particles_filter}
            status={values.status_particles_filter}
            statusName={'status_particles_filter'}
            image={values.image_particles_filter}
            imageName={'image_particles_filter'}
            index={index}
          />
          <CheckEPP
            title={'Arnes tipo Paracaidas'}
            setValues={handleChangeCheck}
            switchName={'monitored_parachute_harness'}
            monitored={values.monitored_parachute_harness}
            status={values.status_parachute_harness}
            statusName={'status_parachute_harness'}
            image={values.image_parachute_harness}
            imageName={'image_parachute_harness'}
            index={index}
          />
          <CheckEPP
            title={'Cabo de Vida Moqueton'}
            setValues={handleChangeCheck}
            switchName={'monitored_life_line'}
            monitored={values.monitored_life_line}
            status={values.status_life_line}
            statusName={'status_life_line'}
            image={values.image_life_line}
            imageName={'image_life_line'}
            index={index}
          />
          <CheckEPP
            title={'Carro de Ascenso'}
            setValues={handleChangeCheck}
            switchName={'monitored_lift_truck'}
            monitored={values.monitored_lift_truck}
            status={values.status_lift_truck}
            statusName={'status_lift_truck'}
            image={values.image_lift_truck}
            imageName={'image_lift_truck'}
            index={index}
          />
          <CheckEPP
            title={'Guantes multiuso'}
            setValues={handleChangeCheck}
            switchName={'monitored_multipurpose_glove'}
            monitored={values.monitored_multipurpose_glove}
            status={values.status_multipurpose_glove}
            statusName={'status_multipurpose_glove'}
            image={values.image_multipurpose_glove}
            imageName={'image_multipurpose_glove'}
            index={index}
          />
          <CheckEPP
            title={'Guante Desechable'}
            setValues={handleChangeCheck}
            switchName={'monitored_disposable_glove'}
            monitored={values.monitored_disposable_glove}
            status={values.status_disposable_glove}
            statusName={'status_disposable_glove'}
            image={values.image_disposable_glove}
            imageName={'image_disposable_glove'}
            index={index}
          />
          <CheckEPP
            title={'Guante Nitrilo Focado'}
            setValues={handleChangeCheck}
            switchName={'monitored_nitrile_glove'}
            monitored={values.monitored_nitrile_glove}
            status={values.status_nitrile_glove}
            statusName={'status_nitrile_glove'}
            image={values.image_nitrile_glove}
            imageName={'image_nitrile_glove'}
            index={index}
          />
          <CheckEPP
            title={'Buzo Desechable'}
            setValues={handleChangeCheck}
            switchName={'monitored_disposable_sweatshop'}
            monitored={values.monitored_disposable_sweatshop}
            status={values.status_disposable_sweatshop}
            statusName={'status_disposable_sweatshop'}
            image={values.image_disposable_sweatshop}
            imageName={'image_disposable_sweatshop'}
            index={index}
          />
          <CheckEPP
            title={'Overol Gabardina'}
            setValues={handleChangeCheck}
            switchName={'monitored_gabardine_overall'}
            monitored={values.monitored_gabardine_overall}
            status={values.status_gabardine_overall}
            statusName={'status_gabardine_overall'}
            image={values.image_gabardine_overall}
            imageName={'image_gabardine_overall'}
            index={index}
          />
          <CheckEPP
            title={'Calzado'}
            setValues={handleChangeCheck}
            switchName={'monitored_footwear'}
            monitored={values.monitored_footwear}
            status={values.status_footwear}
            statusName={'status_footwear'}
            image={values.image_footwear}
            imageName={'image_footwear'}
            index={index}
          />
          <CheckEPP
            title={'Chaleco Geologo'}
            setValues={handleChangeCheck}
            switchName={'monitored_geologist_vest'}
            monitored={values.monitored_geologist_vest}
            status={values.status_geologist_vest}
            statusName={'status_geologist_vest'}
            image={values.image_geologist_vest}
            imageName={'image_geologist_vest'}
            index={index}
          />
          <CheckEPP
            title={'Amortiguador de Caida'}
            setValues={handleChangeCheck}
            switchName={'monitored_shock_absolver'}
            monitored={values.monitored_shock_absolver}
            status={values.status_shock_absolver}
            statusName={'status_shock_absolver'}
            image={values.image_shock_absolver}
            imageName={'image_shock_absolver'}
            index={index}
          />
          <CheckEPP
            title={'Legionario'}
            setValues={handleChangeCheck}
            switchName={'monitored_legionary'}
            monitored={values.monitored_legionary}
            status={values.status_legionary}
            statusName={'status_legionary'}
            image={values.image_legionary}
            imageName={'image_legionary'}
            index={index}
          />
          <CheckEPP
            title={'Bota Agua Punta y Plantilla de Acero'}
            setValues={handleChangeCheck}
            switchName={'monitored_boot'}
            monitored={values.monitored_boot}
            status={values.status_boot}
            statusName={'status_boot'}
            image={values.image_boot}
            imageName={'image_boot'}
            index={index}
          />
          <CheckEPP
            title={'Fono Adosable'}
            setValues={handleChangeCheck}
            switchName={'monitored_attachable_headset'}
            monitored={values.monitored_attachable_headset}
            status={values.status_attachable_headset}
            statusName={'status_attachable_headset'}
            image={values.image_attachable_headset}
            imageName={'image_attachable_headset'}
            index={index}
          />
          <CheckEPP
            title={'Pantalón Cargo'}
            setValues={handleChangeCheck}
            switchName={'monitored_cargo_pant'}
            monitored={values.monitored_cargo_pant}
            status={values.status_cargo_pant}
            statusName={'status_cargo_pant'}
            image={values.image_cargo_pant}
            imageName={'image_cargo_pant'}
            index={index}
          />
          <CheckEPP
            title={'Micropolar'}
            setValues={handleChangeCheck}
            switchName={'monitored_micropolar'}
            monitored={values.monitored_micropolar}
            status={values.status_micropolar}
            statusName={'status_micropolar'}
            image={values.image_micropolar}
            imageName={'image_micropolar'}
            index={index}
          />
          <CheckEPP
            title={'Polera Manga Larga'}
            setValues={handleChangeCheck}
            switchName={'monitored_long_sleeve_shirt'}
            monitored={values.monitored_long_sleeve_shirt}
            status={values.status_long_sleeve_shirt}
            statusName={'status_long_sleeve_shirt'}
            image={values.image_long_sleeve_shirt}
            imageName={'image_long_sleeve_shirt'}
            index={index}
          />
        </HStack>
      </>
    )
  }

  const highway = () => {
    return (
      <>
        <HStack spacing="24px" overflowX={'scroll'} py={3} px={2}>
          <CheckEPP
            title={'Casco'}
            setValues={handleChangeCheck}
            switchName={'monitored_helmet'}
            monitored={values.monitored_helmet}
            status={values.status_helmet}
            statusName={'status_helmet'}
            image={values.image_helmet}
            imageName={'image_helmet'}
            index={index}
          />
          <CheckEPP
            title={'Arnes Casco'}
            setValues={handleChangeCheck}
            switchName={'monitored_helmet_harness'}
            monitored={values.monitored_helmet_harness}
            status={values.status_helmet_harness}
            statusName={'status_helmet_harness'}
            image={values.image_helmet_harness}
            imageName={'image_helmet_harness'}
            index={index}
          />
          <CheckEPP
            title={'Soporte de Careta'}
            setValues={handleChangeCheck}
            switchName={'monitored_mask_support'}
            monitored={values.monitored_mask_support}
            status={values.status_mask_support}
            statusName={'status_mask_support'}
            image={values.image_mask_support}
            imageName={'image_mask_support'}
            index={index}
          />
          <CheckEPP
            title={'Lente Seguridad Claro'}
            setValues={handleChangeCheck}
            switchName={'monitored_clear_eyewear'}
            monitored={values.monitored_clear_eyewear}
            status={values.status_clear_eyewear}
            statusName={'status_clear_eyewear'}
            image={values.image_clear_eyewear}
            imageName={'image_clear_eyewear'}
            index={index}
          />
          <CheckEPP
            title={'Careta Roce'}
            setValues={handleChangeCheck}
            switchName={'monitored_face_shield_rub'}
            monitored={values.monitored_face_shield_rub}
            status={values.status_face_shield_rub}
            statusName={'status_face_shield_rub'}
            image={values.image_face_shield_rub}
            imageName={'image_face_shield_rub'}
            index={index}
          />
          <CheckEPP
            title={'Lente Seguridad Gris'}
            setValues={handleChangeCheck}
            switchName={'monitored_grey_eyewear'}
            monitored={values.monitored_grey_eyewear}
            status={values.status_grey_eyewear}
            statusName={'status_grey_eyewear'}
            image={values.image_grey_eyewear}
            imageName={'image_grey_eyewear'}
            index={index}
          />
          <CheckEPP
            title={'Overol Vial'}
            setValues={handleChangeCheck}
            switchName={'monitored_road_overall'}
            monitored={values.monitored_road_overall}
            status={values.status_road_overall}
            statusName={'status_road_overall'}
            image={values.image_road_overall}
            imageName={'image_road_overall'}
            index={index}
          />
          <CheckEPP
            title={'Calzado'}
            setValues={handleChangeCheck}
            switchName={'monitored_footwear'}
            monitored={values.monitored_footwear}
            status={values.status_footwear}
            statusName={'status_footwear'}
            image={values.image_footwear}
            imageName={'image_footwear'}
            index={index}
          />
          <CheckEPP
            title={'Guante Cabretilla'}
            setValues={handleChangeCheck}
            switchName={'monitored_cabrette_glove'}
            monitored={values.monitored_cabrette_glove}
            status={values.status_cabrette_glove}
            statusName={'status_cabrette_glove'}
            image={values.image_cabrette_glove}
            imageName={'image_cabrette_glove'}
            index={index}
          />
          <CheckEPP
            title={'Arnes Desbrozadora'}
            setValues={handleChangeCheck}
            switchName={'monitored_harness_brushcutter'}
            monitored={values.monitored_harness_brushcutter}
            status={values.status_harness_brushcutter}
            statusName={'status_harness_brushcutter'}
            image={values.image_harness_brushcutter}
            imageName={'image_harness_brushcutter'}
            index={index}
          />
          <CheckEPP
            title={'Legionario'}
            setValues={handleChangeCheck}
            switchName={'monitored_legionary'}
            monitored={values.monitored_legionary}
            status={values.status_legionary}
            statusName={'status_legionary'}
            image={values.image_legionary}
            imageName={'image_legionary'}
            index={index}
          />
          <CheckEPP
            title={'Fono Adosable'}
            setValues={handleChangeCheck}
            switchName={'monitored_attachable_headset'}
            monitored={values.monitored_attachable_headset}
            status={values.status_attachable_headset}
            statusName={'status_attachable_headset'}
            image={values.image_attachable_headset}
            imageName={'image_attachable_headset'}
            index={index}
          />
          <CheckEPP
            title={'Micropolar'}
            setValues={handleChangeCheck}
            switchName={'monitored_micropolar'}
            monitored={values.monitored_micropolar}
            status={values.status_micropolar}
            statusName={'status_micropolar'}
            image={values.image_micropolar}
            imageName={'image_micropolar'}
            index={index}
          />
        </HStack>
      </>
    )
  }

  const aes = () => {
    return (
      <>
        <HStack spacing="24px" overflowX={'scroll'} py={3} px={2}>
          <CheckEPP
            title={'Casco'}
            setValues={handleChangeCheck}
            switchName={'monitored_helmet'}
            monitored={values.monitored_helmet}
            status={values.status_helmet}
            statusName={'status_helmet'}
            image={values.image_helmet}
            imageName={'image_helmet'}
            index={index}
          />
          <CheckEPP
            title={'Arnes Casco'}
            setValues={handleChangeCheck}
            switchName={'monitored_helmet_harness'}
            monitored={values.monitored_helmet_harness}
            status={values.status_helmet_harness}
            statusName={'status_helmet_harness'}
            image={values.image_helmet_harness}
            imageName={'image_helmet_harness'}
            index={index}
          />
          <CheckEPP
            title={'Soporte de Careta'}
            setValues={handleChangeCheck}
            switchName={'monitored_mask_support'}
            monitored={values.monitored_mask_support}
            status={values.status_mask_support}
            statusName={'status_mask_support'}
            image={values.image_mask_support}
            imageName={'image_mask_support'}
            index={index}
          />
          <CheckEPP
            title={'Careta Transparente'}
            setValues={handleChangeCheck}
            switchName={'monitored_transparent_mask'}
            monitored={values.monitored_transparent_mask}
            status={values.status_transparent_mask}
            statusName={'status_transparent_mask'}
            image={values.image_transparent_mask}
            imageName={'image_transparent_mask'}
            index={index}
          />
          <CheckEPP
            title={'Lente Seguridad Claro'}
            setValues={handleChangeCheck}
            switchName={'monitored_clear_eyewear'}
            monitored={values.monitored_clear_eyewear}
            status={values.status_clear_eyewear}
            statusName={'status_clear_eyewear'}
            image={values.image_clear_eyewear}
            imageName={'image_clear_eyewear'}
            index={index}
          />
          <CheckEPP
            title={'Lente Seguridad Gris'}
            setValues={handleChangeCheck}
            switchName={'monitored_grey_eyewear'}
            monitored={values.monitored_grey_eyewear}
            status={values.status_grey_eyewear}
            statusName={'status_grey_eyewear'}
            image={values.image_grey_eyewear}
            imageName={'image_grey_eyewear'}
            index={index}
          />
          <CheckEPP
            title={'Overol'}
            setValues={handleChangeCheck}
            switchName={'monitored_overall'}
            monitored={values.monitored_overall}
            status={values.status_overall}
            statusName={'status_overall'}
            image={values.image_overall}
            imageName={'image_overall'}
            index={index}
          />
          <CheckEPP
            title={'Guante Cabretilla'}
            setValues={handleChangeCheck}
            switchName={'monitored_cabrette_glove'}
            monitored={values.monitored_cabrette_glove}
            status={values.status_cabrette_glove}
            statusName={'status_cabrette_glove'}
            image={values.image_cabrette_glove}
            imageName={'image_cabrette_glove'}
            index={index}
          />
          <CheckEPP
            title={'Guante Nitrilo Focado'}
            setValues={handleChangeCheck}
            switchName={'monitored_nitrile_glove'}
            monitored={values.monitored_nitrile_glove}
            status={values.status_nitrile_glove}
            statusName={'status_nitrile_glove'}
            image={values.image_nitrile_glove}
            imageName={'image_nitrile_glove'}
            index={index}
          />
          <CheckEPP
            title={'Chaqueta Invierno'}
            setValues={handleChangeCheck}
            switchName={'monitored_winter_vest'}
            monitored={values.monitored_winter_vest}
            status={values.status_winter_vest}
            statusName={'status_winter_vest'}
            image={values.image_winter_vest}
            imageName={'image_winter_vest'}
            index={index}
          />
          <CheckEPP
            title={'Pantalón Invierno'}
            setValues={handleChangeCheck}
            switchName={'monitored_winter_pants'}
            monitored={values.monitored_winter_pants}
            status={values.status_winter_pants}
            statusName={'status_winter_pants'}
            image={values.image_winter_pants}
            imageName={'image_winter_pants'}
            index={index}
          />
          <CheckEPP
            title={'Primera Capa'}
            setValues={handleChangeCheck}
            switchName={'monitored_first_layer'}
            monitored={values.monitored_first_layer}
            status={values.status_first_layer}
            statusName={'status_first_layer'}
            image={values.image_first_layer}
            imageName={'image_first_layer'}
            index={index}
          />
          <CheckEPP
            title={'Micropolar'}
            setValues={handleChangeCheck}
            switchName={'monitored_micropolar'}
            monitored={values.monitored_micropolar}
            status={values.status_micropolar}
            statusName={'status_micropolar'}
            image={values.image_micropolar}
            imageName={'image_micropolar'}
            index={index}
          />
          <CheckEPP
            title={'Calzado'}
            setValues={handleChangeCheck}
            switchName={'monitored_footwear'}
            monitored={values.monitored_footwear}
            status={values.status_footwear}
            statusName={'status_footwear'}
            image={values.image_footwear}
            imageName={'image_footwear'}
            index={index}
          />
          <CheckEPP
            title={'Chaleco Geologo'}
            setValues={handleChangeCheck}
            switchName={'monitored_geologist_vest'}
            monitored={values.monitored_geologist_vest}
            status={values.status_geologist_vest}
            statusName={'status_geologist_vest'}
            image={values.image_geologist_vest}
            imageName={'image_geologist_vest'}
            index={index}
          />
        </HStack>
      </>
    )
  }

  const supervisor = () => {
    return (
      <>
        <HStack spacing="24px" overflowX={'scroll'} py={3} px={2}>
          <CheckEPP
            title={'Casco'}
            setValues={handleChangeCheck}
            switchName={'monitored_helmet'}
            monitored={values.monitored_helmet}
            status={values.status_helmet}
            statusName={'status_helmet'}
            image={values.image_helmet}
            imageName={'image_helmet'}
            index={index}
          />
          <CheckEPP
            title={'Arnes Casco'}
            setValues={handleChangeCheck}
            switchName={'monitored_helmet_harness'}
            monitored={values.monitored_helmet_harness}
            status={values.status_helmet_harness}
            statusName={'status_helmet_harness'}
            image={values.image_helmet_harness}
            imageName={'image_helmet_harness'}
            index={index}
          />
          <CheckEPP
            title={'Soporte de Careta'}
            setValues={handleChangeCheck}
            switchName={'monitored_mask_support'}
            monitored={values.monitored_mask_support}
            status={values.status_mask_support}
            statusName={'status_mask_support'}
            image={values.image_mask_support}
            imageName={'image_mask_support'}
            index={index}
          />
          <CheckEPP
            title={'Careta Transparente'}
            setValues={handleChangeCheck}
            switchName={'monitored_transparent_mask'}
            monitored={values.monitored_transparent_mask}
            status={values.status_transparent_mask}
            statusName={'status_transparent_mask'}
            image={values.image_transparent_mask}
            imageName={'image_transparent_mask'}
            index={index}
          />
          <CheckEPP
            title={'Lente Seguridad Claro'}
            setValues={handleChangeCheck}
            switchName={'monitored_clear_eyewear'}
            monitored={values.monitored_clear_eyewear}
            status={values.status_clear_eyewear}
            statusName={'status_clear_eyewear'}
            image={values.image_clear_eyewear}
            imageName={'image_clear_eyewear'}
            index={index}
          />
          <CheckEPP
            title={'Lente Seguridad Gris'}
            setValues={handleChangeCheck}
            switchName={'monitored_grey_eyewear'}
            monitored={values.monitored_grey_eyewear}
            status={values.status_grey_eyewear}
            statusName={'status_grey_eyewear'}
            image={values.image_grey_eyewear}
            imageName={'image_grey_eyewear'}
            index={index}
          />
          <CheckEPP
            title={'Chaleco Geologo'}
            setValues={handleChangeCheck}
            switchName={'monitored_geologist_vest'}
            monitored={values.monitored_geologist_vest}
            status={values.status_geologist_vest}
            statusName={'status_geologist_vest'}
            image={values.image_geologist_vest}
            imageName={'image_geologist_vest'}
            index={index}
          />
          <CheckEPP
            title={'Guantes multiuso'}
            setValues={handleChangeCheck}
            switchName={'monitored_multipurpose_glove'}
            monitored={values.monitored_multipurpose_glove}
            status={values.status_multipurpose_glove}
            statusName={'status_multipurpose_glove'}
            image={values.image_multipurpose_glove}
            imageName={'image_multipurpose_glove'}
            index={index}
          />
          <CheckEPP
            title={'Calzado'}
            setValues={handleChangeCheck}
            switchName={'monitored_footwear'}
            monitored={values.monitored_footwear}
            status={values.status_footwear}
            statusName={'status_footwear'}
            image={values.image_footwear}
            imageName={'image_footwear'}
            index={index}
          />
          <CheckEPP
            title={'Fono Adosable'}
            setValues={handleChangeCheck}
            switchName={'monitored_attachable_headset'}
            monitored={values.monitored_attachable_headset}
            status={values.status_attachable_headset}
            statusName={'status_attachable_headset'}
            image={values.image_attachable_headset}
            imageName={'image_attachable_headset'}
            index={index}
          />
        </HStack>
      </>
    )
  }

  const eppType = (type) => {
    switch (type.toString()) {
      case '1':
        return pests()
      case '2':
        return highway()
      case '3':
        return aes()
      case '4':
        return supervisor()
      default:
        return null
    }
  }

  console.log(values.job)

  return (
    <>
      <Box w="100%" bg="#D1F1F5" pb={5} borderRadius="20px">
        <Flex spacing={10} align="left" pt="15px">
          <Stack spacing={2} pl={30}>
            <Text fontSize="17px" fontWeight="bold" color="#36355F">
              Trabajador {index + 1}
            </Text>
          </Stack>
        </Flex>
      </Box>
      <FormControl id="worker_name">
        <FormLabel>Nombre de trabajador</FormLabel>
        <Input
          name="worker_name"
          value={values.worker_name}
          onChange={(e) => handleChange(e, index)}
          placeholder="Nombre de trabajador"
        />
      </FormControl>
      <FormControl id="area">
        <FormLabel>Área</FormLabel>
        <Select
          placeholder="Seleccione"
          name="area"
          onChange={(e) => handleChange(e, index)}
          value={values.area}
        >
          {Areas.map((area) => (
            <option value={area.id} key={area.id}>
              {area.area}
            </option>
          ))}
        </Select>
      </FormControl>
      {values.area && (
        <FormControl id="job">
          <FormLabel>Cargo</FormLabel>
          <Select
            placeholder="Seleccione"
            name="job"
            onChange={(e) => handleChange(e, index)}
            value={values.job}
          >
            {positions(values.area.toString()).map((position) => (
              <option value={position.id} key={position.id}>
                {position.position}
              </option>
            ))}
          </Select>
        </FormControl>
      )}
      <FormControl id="type">
        <FormLabel>Tipo</FormLabel>
        <Select
          placeholder="Seleccione"
          name="type"
          onChange={(e) => handleChange(e, index)}
          value={values.type}
        >
          {Types.map((type) => (
            <option value={type.id} key={type.id}>
              {type.name}
            </option>
          ))}
        </Select>
      </FormControl>
      {eppType(values.type)}
      <Box
        // h="50px"
        // w="90%"
        bg="#FFFFFF"
        border="2px"
        borderStyle="solid"
        borderRadius="lg"
        borderColor="#59BABA"
        alignItems="center"
        justifyContent="center"
        // key={data.id}
        display="flex"
      >
        <SimpleGrid columns={1} spacing={10} py={3}>
          <Text fontSize="15px" fontWeight="bold" color="#36355F" m="auto">
            Firma del trabajador
          </Text>
          {imageURL ? (
            <Image src={imageURL} alt="Firma" />
          ) : (
            <Button
              align="center"
              onClick={handleClickSignature}
              bg="#59BABA"
              color="#FFFFFF"
              border="1px"
              borderColor="#59BABA"
            >
              + Añadir firma
            </Button>
          )}
        </SimpleGrid>
      </Box>
      {openSignature && (
        <SignatureModal
          isOpen={openSignature}
          onClose={closeSignature}
          setImageURL={setImageURL}
          field={'ruc'}
          ruc={values.ruc}
          handleRuc={(e) => handleChange(e, index)}
        />
      )}
    </>
  )
}

export default WorkerForm
