import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  Flex,
  Stack,
  Heading,
  Button,
  Skeleton,
  Grid,
  GridItem,
  Input,
  Select
} from '@chakra-ui/react'
import { AddIcon } from '@chakra-ui/icons'

import { usePaginatedProducts } from '../../hooks/products'
import ProductsTable from '../../components/Products/ProductsTable'
import Pagination from '../../components/Shared/Pagination'
import { updateFilterList } from '../../store/slices/products'

const categories = [
  {
    id: 'Desratización',
    name: 'Desratización'
  },
  {
    id: 'Desinsectación',
    name: 'Desinsectación'
  },
  {
    id: 'Sanitización',
    name: 'Sanitización'
  }
]

// Página de principal de  lista de clientes
const Products = () => {
  const dispatch = useDispatch()
  const { filterList } = useSelector((state) => state.products)
  const history = useHistory()
  const {
    data: clients,
    isLoading,
    refetch
  } = usePaginatedProducts(filterList.page, {
    search: filterList.search,
    service: filterList.service
  })

  const handleClick = () => {
    history.push(`/productos/nuevo`)
  }

  const handleChangeGlobalState = (fieldName, value) => {
    dispatch(
      updateFilterList({
        page: 1,
        [fieldName]: value
      })
    )
  }

  useEffect(() => {
    refetch()
  }, [filterList])

  return (
    <Stack spacing={8} px={6}>
      <Flex bg={'#FFF'}>
        <Stack spacing={8} py={12} px={6}>
          <Stack align={'left'}>
            <Heading fontSize={'4xl'} color={'#36355F'}>
              Productos
            </Heading>
          </Stack>
          <Stack spacing={10} align={'left'}>
            <Button
              onClick={handleClick}
              bg={'teal.500'}
              color={'white'}
              _hover={{
                bg: 'teal.600'
              }}
              leftIcon={<AddIcon />}
            >
              Agregar producto
            </Button>
          </Stack>
        </Stack>
      </Flex>
      <Grid display="flex">
        <GridItem display="flex" gap={3} w="100%">
          <Input
            borderRadius="15px"
            value={filterList?.search}
            onChange={(e) =>
              handleChangeGlobalState('search', e.currentTarget.value)
            }
            marginRight={3}
            placeholder="Nombre"
          />
          <Select
            placeholder="Servicio"
            name="service"
            onChange={(e) =>
              handleChangeGlobalState('service', e.currentTarget.value)
            }
            value={filterList?.service}
          >
            {categories.map((cat) => (
              <option value={cat.id}>{cat.name}</option>
            ))}
          </Select>
        </GridItem>
      </Grid>
      {clients ? (
        <>
          <ProductsTable clients={clients.items} />
          <Grid m="auto" pt="2%" alignSelf={'center'}>
            <Pagination
              totalPages={Math.ceil(clients.total / 10)}
              actualPage={clients.page}
              setPage={(page) => {
                dispatch(
                  updateFilterList({
                    page
                  })
                )
              }}
            />
          </Grid>
        </>
      ) : (
        <Stack>
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
        </Stack>
      )}
    </Stack>
  )
}

export default Products
