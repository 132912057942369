import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import {
  Flex,
  Stack,
  Heading,
  Button,
  Skeleton,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels
} from '@chakra-ui/react'
import { updateTabDatsh } from '../../store/slices/client'

import { useClient } from '../../hooks/clients'
import Dashboard from '../../components/Clients/Dashboard'
import Visits from '../../components/Clients/Visits'
import Documents from '../../components/Clients/Documents'

// Página de clientes
const Clients = () => {
  const history = useHistory()
  const { clientId } = useParams()
  const { user } = useSelector((state) => state.auth)
  const { lastTabDash } = useSelector((state) => state.client)
  const { data: client } = useClient(clientId)
  const dispatch = useDispatch()
  const handleBack = () => {
    history.push(`/clientes/`)
  }

  const selectedTab = (tabIndex) => {
    dispatch(
      updateTabDatsh({
        lastTabDash: tabIndex
      })
    )
  }

  return (
    <Stack spacing={8} px={6}>
      <Flex bg={'#FFF'}>
        <Stack spacing={10} align={'center'}>
          <Button
            onClick={handleBack}
            bg="#FFFFFF"
            color="#666587"
            border="1px"
            borderStyle="solid"
            borderRadius="lg"
            borderColor="#F2F1FB"
          >
            Regresar
          </Button>
        </Stack>
        <Stack spacing={8} py={12} px={6} w="100%">
          <Stack align={'left'}>
            {client ? (
              <>
                <Heading fontSize={'4xl'} color={'#36355F'}>
                  {client.company_name || client.name}
                </Heading>
                <Stack spacing={8} py={12} px={6}>
                  <Tabs isFitted variant="line" defaultIndex={lastTabDash}>
                    <TabList mb="1em">
                      {user.id_role === 1 && (
                        <Tab onClick={() => selectedTab(0)}>Dashboard</Tab>
                      )}
                      <Tab onClick={() => selectedTab(1)}>
                        Historial de visitas
                      </Tab>
                      <Tab onClick={() => selectedTab(2)}>
                        Documentos generados
                      </Tab>
                    </TabList>
                    <TabPanels>
                      {user.id_role === 1 && (
                        <TabPanel>
                          <Dashboard />
                        </TabPanel>
                      )}
                      <TabPanel>
                        <Visits />
                      </TabPanel>
                      <TabPanel>
                        <Documents />
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                </Stack>
              </>
            ) : (
              <Stack>
                <Skeleton height="20px" />
                <Skeleton height="20px" />
                <Skeleton height="20px" />
              </Stack>
            )}
          </Stack>
        </Stack>
      </Flex>
    </Stack>
  )
}

export default Clients
