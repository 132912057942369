import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Heading,
  Text,
  Select,
  Box,
  Stack,
  HStack,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  InputGroup,
  InputLeftAddon,
  Flex,
  Button,
  Spacer,
  useToast
} from '@chakra-ui/react'
import * as Yup from 'yup'
import { useFormik } from 'formik'

import Roles from '../../resources/Roles'
import { useMutateUser } from '../../hooks/users'
import generatePassword from '../../validators/generatePassword'

const validationSchema = Yup.object().shape({
  id_role: Yup.number().required('Ingrese rol'),
  name: Yup.string().required('Ingrese nombre y apellido'),
  email: Yup.string()
    .required('Ingrese correo')
    .email('Debe ser un correo válido')
})

// Crear usuario
const UserForm = () => {
  const history = useHistory()
  const toast = useToast()
  const { mutate, isLoading, reset } = useMutateUser('CREATE')

  const formik = useFormik({
    validationSchema,
    initialValues: {
      id_role: null,
      name: '',
      email: '',
      password: ''
    },
    onSubmit: (values) => {
      values.password = generatePassword()
      mutate(values, {
        onSuccess: () => {
          reset()
          formik.resetForm()
          toast({
            title: `Usuario creado`,
            status: 'success',
            isClosable: true
          })
          history.push(`/usuarios`)
        },
        onError: (err) => {
          reset()
          console.log(err)
          toast({
            title: `Error al crear usuario`,
            status: 'error',
            isClosable: true
          })
        }
      })
    }
  })
  return (
    <Stack spacing={8} py={12} px={6}>
      <Stack align={'left'}>
        <Heading fontSize={'4xl'} color={'#36355F'}>
          Agregar nuevo usuario
        </Heading>
      </Stack>
      <Stack spacing={8} py={12} px={6}>
        <FormControl
          id="id_role"
          isInvalid={formik.touched.id_role && Boolean(formik.errors.id_role)}
        >
          <FormLabel>Rol</FormLabel>
          <Select
            placeholder="Seleccione"
            name="id_role"
            onChange={formik.handleChange}
          >
            {Roles.map((role) => (
              <option value={role.id}>{role.name}</option>
            ))}
          </Select>
          <FormErrorMessage>
            {formik.touched.role && formik.errors.role}
          </FormErrorMessage>
        </FormControl>
        <FormControl
          id="name"
          isInvalid={formik.touched.name && Boolean(formik.errors.name)}
        >
          <FormLabel>Nombre y apellido</FormLabel>
          <Input
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            placeholder="Nombre y apellido"
          />
          <FormErrorMessage>
            {formik.touched.name && formik.errors.name}
          </FormErrorMessage>
        </FormControl>
        <FormControl
          id="email"
          isInvalid={formik.touched.email && Boolean(formik.errors.email)}
        >
          <FormLabel>Correo</FormLabel>
          <Input
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            placeholder="Correo"
          />
          <FormErrorMessage>
            {formik.touched.email && formik.errors.email}
          </FormErrorMessage>
        </FormControl>
        <FormControl
          id="password"
          isInvalid={formik.touched.password && Boolean(formik.errors.password)}
        >
          <FormLabel>Contraseña</FormLabel>
          <Input
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            placeholder="Nueva contraseña"
          />
          <FormErrorMessage>
            {formik.touched.password && formik.errors.password}
          </FormErrorMessage>
        </FormControl>
      </Stack>
      <Flex spacing={10} align="right" pt="15px">
        <Spacer />
        <Button
          align="right"
          isLoading={isLoading}
          onClick={formik.handleSubmit}
          bg={'teal.500'}
          color={'white'}
          _hover={{
            bg: 'teal.600'
          }}
          disabled={isLoading}
        >
          Guardar
        </Button>
      </Flex>
    </Stack>
  )
}

export default UserForm
