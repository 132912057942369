import { useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Text,
  Box,
  Stack,
  HStack,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Flex,
  Button,
  Spacer,
  useDisclosure,
  useToast
} from '@chakra-ui/react'
import * as Yup from 'yup'
import { useFormik } from 'formik'

import { useMutateClient } from '../../hooks/products'
import ConfirmModal from '../Shared/ConfirmModal'

const categories = [
  {
    id: 'Desratización',
    name: 'Desratización'
  },
  {
    id: 'Desinsectación',
    name: 'Desinsectación'
  },
  {
    id: 'Sanitización',
    name: 'Sanitización'
  }
]

const validationSchema = Yup.object().shape({
  commercial: Yup.string().required('Campo requerido'),
  ISP: Yup.string().required('Campo requerido'),
  ingredient: Yup.string().required('Campo requerido'),
  concentration: Yup.string().required('Campo requerido'),
  formulation: Yup.string().required('Campo requerido'),
  weight: Yup.string().required('Campo requerido'),
  unit: Yup.string().required('Campo requerido'),
  service: Yup.string().required('Campo requerido')
})

// FOrmulario de creación de cliente tipo
const CreateForm = () => {
  const toast = useToast()
  const [confirm, setConfirm] = useState(false)
  const { user } = useSelector((state) => state.auth)
  const { mutate, isLoading, reset } = useMutateClient('CREATE')
  const {
    isOpen: openConfirm,
    onOpen: onOpenConfirm,
    onClose: closeConfirm
  } = useDisclosure()

  const formik = useFormik({
    validationSchema,
    initialValues: {
      commercial: '',
      service: '',
      ingredient: '',
      concentration: '',
      formulation: '',
      weight: '',
      ISP: '',
      unit: ''
    },
    onSubmit: (values) => {
      mutate(
        { ...values, createdBy: user.id },
        {
          onSuccess: () => {
            reset()
            formik.resetForm()
            setConfirm(true)
          },
          onError: (err) => {
            reset()
            console.log(err)
            toast({
              title: `Error al crear cliente`,
              status: 'error',
              isClosable: true
            })
          }
        }
      )
    }
  })

  const selectCategory = (id) => {
    formik.setFieldValue('service', id)
  }

  const handleClickConfirm = () => {
    onOpenConfirm()
  }

  return (
    <>
      <Text fontSize="20px" fontWeight="bold" color="#36355F">
        Seleccione un servicio
      </Text>
      <HStack
        spacing="24px"
        overflowX={'scroll'}
        css={{
          '&::-webkit-scrollbar': {
            display: 'none'
          }
        }}
      >
        {categories.map((data) => (
          <Box
            as="button"
            h="50px"
            w="150%"
            bg={formik.values.service === data.id ? '#A4D4DA' : '#D1F1F5'}
            borderRadius="lg"
            key={data.id}
            display="flex"
            onClick={() => selectCategory(data.id)}
          >
            <Text
              fontSize="15px"
              fontWeight={formik.values.service === data.id ? 'bold' : ''}
              color="#36355F"
              m="auto"
            >
              {data.name}
            </Text>
          </Box>
        ))}
      </HStack>
      <Text fontSize="14px" color="red" pl={2}>
        {formik.values.service === '' && 'Seleccione un servicio'}
      </Text>
      <FormControl
        id="commercial"
        isInvalid={
          formik.touched.commercial && Boolean(formik.errors.commercial)
        }
      >
        <FormLabel>Nombre comercial</FormLabel>
        <Input
          name="commercial"
          value={formik.values.commercial}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder="Nombre comercial"
        />
        <FormErrorMessage>
          {formik.touched.commercial && formik.errors.commercial}
        </FormErrorMessage>
      </FormControl>

      <FormControl
        id="ISP"
        isInvalid={formik.touched.ISP && Boolean(formik.errors.ISP)}
      >
        <FormLabel>Registro ISP</FormLabel>
        <Input
          name="ISP"
          value={formik.values.ISP}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder="Registro ISP"
        />
        <FormErrorMessage>
          {formik.touched.ISP && formik.errors.ISP}
        </FormErrorMessage>
      </FormControl>

      <FormControl
        id="ingredient"
        isInvalid={
          formik.touched.ingredient && Boolean(formik.errors.ingredient)
        }
      >
        <FormLabel>Ingrediente activo</FormLabel>
        <Input
          name="ingredient"
          value={formik.values.ingredient}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder="Ingrediente activo"
        />
        <FormErrorMessage>
          {formik.touched.ingredient && formik.errors.ingredient}
        </FormErrorMessage>
      </FormControl>

      <FormControl
        id="concentration"
        isInvalid={
          formik.touched.concentration && Boolean(formik.errors.concentration)
        }
      >
        <FormLabel>Concentración</FormLabel>
        <Input
          name="concentration"
          value={formik.values.concentration}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder="Concentración"
        />
        <FormErrorMessage>
          {formik.touched.concentration && formik.errors.concentration}
        </FormErrorMessage>
      </FormControl>

      <FormControl
        id="formulation"
        isInvalid={
          formik.touched.formulation && Boolean(formik.errors.formulation)
        }
      >
        <FormLabel>Formulación</FormLabel>
        <Input
          name="formulation"
          value={formik.values.formulation}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder="Formulación"
        />
        <FormErrorMessage>
          {formik.touched.formulation && formik.errors.formulation}
        </FormErrorMessage>
      </FormControl>

      <FormControl
        id="unit"
        isInvalid={formik.touched.unit && Boolean(formik.errors.unit)}
      >
        <FormLabel>Unidad</FormLabel>
        <Input
          name="unit"
          value={formik.values.unit}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder="Unidad"
        />
        <FormErrorMessage>
          {formik.touched.unit && formik.errors.unit}
        </FormErrorMessage>
      </FormControl>

      <FormControl
        id="weight"
        isInvalid={formik.touched.weight && Boolean(formik.errors.weight)}
      >
        <FormLabel>Peso</FormLabel>
        <Input
          name="weight"
          value={formik.values.weight}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder="Peso"
        />
        <FormErrorMessage>
          {formik.touched.weight && formik.errors.weight}
        </FormErrorMessage>
      </FormControl>

      <Flex spacing={10} align="right" pt="15px">
        <Spacer />
        <Stack spacing={10} align={'center'}>
          <Button
            align="center"
            isLoading={isLoading}
            onClick={handleClickConfirm}
            bg="#FFFFFF"
            color="#59BABA"
            border="1px"
            borderColor="#59BABA"
            disabled={isLoading}
          >
            Crear producto
          </Button>
          {openConfirm && (
            <ConfirmModal
              isOpen={openConfirm}
              onClose={closeConfirm}
              route={`/productos`}
              confirm={confirm}
              setConfirm={setConfirm}
              handleSubmit={formik.handleSubmit}
              loading={isLoading}
            />
          )}
        </Stack>
      </Flex>
    </>
  )
}

export default CreateForm
