import axios from '../Axios'

export const getReports = async (page = 1, size = 10) => {
  const { data } = await axios.get(`visit-report/all?page=${page}&size=${size}`)
  return data
}

export const getOneReport = async (reportId) => {
  const { data } = await axios.get(`visit-report/get-one?id_report=${reportId}`)
  return data
}

export const createReport = async (formData) => {
  const { data } = await axios.post(`visit-report/`, formData)
  return data
}

export const updateReport = async (formData) => {
  console.log(formData)
  const { data } = await axios.patch(`visit-report/`, formData)
  return data
}

export const deleteReport = async (reportId) => {
  const { data } = await axios.delete(`visit-report/?id=${reportId}`)
  return data
}
