import {
  Stack,
  Heading,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels
} from '@chakra-ui/react'

import SingleClient from '../../components/Clients/SingleClient'
import UploadFile from '../../components/Shared/UploadFile'

// Formulario de cliente
const ClientForm = () => {
  return (
    <Stack spacing={8} py={12} px={6}>
      <Stack align={'left'}>
        <Heading fontSize={'4xl'} color={'#36355F'}>
          Agregar cliente
        </Heading>
      </Stack>
      <Stack spacing={8} py={12} px={6}>
        <Tabs isFitted variant="line">
          <TabList mb="1em">
            <Tab>Crear Cliente</Tab>
            <Tab>Subir masivamente</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <SingleClient />
            </TabPanel>
            <TabPanel>
              <UploadFile />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Stack>
    </Stack>
  )
}

export default ClientForm
