import { Flex, Text } from '@chakra-ui/react'
import { FaInfoCircle } from 'react-icons/fa'

const Nodata = ({ customMsg }) => {
  return (
    <Flex
      justify="center"
      alignItems="center"
      gap={2}
      borderRadius={8}
      border="1px dashed #38B2AC"
      bg="#e7fffe"
      color="#38B2AC"
      py={4}
    >
      <FaInfoCircle />
      <Text fontWeight={700}>
        {customMsg || 'Sin Información para Mostrar.'}
      </Text>
    </Flex>
  )
}

export default Nodata
