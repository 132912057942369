export const initialValuesOrt = {
  otherDescriptionService: '',
  rodenticideStations: 0,
  moldyBaits: 0,
  gnawedBaits: 0,
  snailBaits: 0,
  otherBaits: 0,
  adhesiveStations: 0,
  adhesiveStationsCaptures: 0,
  liveStations: 0,
  liveStationsCaptures: 0,
  doveCages: 0,
  doveCagesCaptures: 0,
  uvEquipment: 0,
  flyTraps: 0,
  drSerial: '',
  dsCrawling: '',
  dsCockroach: '',
  dsFlyers: '',
  snSerial: '',
  newRodenticideStations: 0,
  newTriangularDome: 0,
  newLiveCapture: 0,
  newStickyPlate: 0,
  newUV: 0,
  newOthers: 0,
  newStickyTraps: 0,
  observations: '',
  ruc: '',
  signature: null,
  rodenticides: [],
  incecticides: [],
  sanitizers: [],
  start_time: '',
  end_time: '',
  fullName: ''
}
