import { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Stack,
  Button,
  Link,
  Text,
  Flex,
  Image,
  useToast
} from '@chakra-ui/react'

import Logo from '../../assets/images/logo.svg'
import newPasswordValidator from '../../validators/newPassword'
import { useMutateAuth } from '../../hooks/auth'

const validationSchema = Yup.object().shape({
  password1: Yup.string().required('Ingrese contraseña'),
  password2: Yup.string().required('Ingrese contraseña')
})
// Formulario de cambio de contraseña
const PassForm = () => {
  const history = useHistory()
  const toast = useToast()
  const [error, setError] = useState('')
  const { token } = useParams()
  const { mutate, reset, isLoading } = useMutateAuth('RECOVER')

  const formik = useFormik({
    validationSchema,
    initialValues: {
      token: token,
      password1: '',
      password2: ''
    },
    onSubmit: (values) => {
      setError('')
      const validate = newPasswordValidator(values.password1)
      if (!validate) {
        setError('La contraseña no cumple con las condiciones')
        formik.setSubmitting(false)
      } else if (values.password1 !== values.password2) {
        setError('Las contraseñas deben ser iguales')
        formik.setSubmitting(false)
      } else {
        mutate(values, {
          onSuccess: () => {
            reset()
            formik.resetForm()
            toast({
              title: `Cambio de contraseña exitoso`,
              status: 'success',
              isClosable: true
            })
            formik.setSubmitting(false)
            history.push(`/login`)
          },
          onError: (err) => {
            reset()
            console.log(err)
            toast({
              title: `Error al cambiar contraseña`,
              status: 'error',
              isClosable: true
            })
            formik.setSubmitting(false)
          }
        })
      }
    }
  })

  return (
    <Stack spacing={8} mx={'auto'} py={12} px={6}>
      <Flex h="10" alignItems="center" justifyContent="center" mb={20}>
        <Image src={Logo} alt="Logo" boxSize="250px" />
      </Flex>
      <Stack align={'left'}>
        <Heading fontSize={'4xl'}>Crea una nueva contraseña</Heading>
      </Stack>
      <Box rounded={'lg'} bg={'#FFF'}>
        <Stack spacing={4}>
          <Box my={2}>
            <Text fontSize="17px" color="#36355F">
              Debes ingresar una nueva contraseña para poder iniciar sesión. Tu
              contraseña debe contener al menos 8 caracteres, al menos 1 número,
              al 1 una letra mayúscula, al 1 una letra minúscula y al menos 1
              caracter especial.
            </Text>
          </Box>
          <FormControl
            id="password1"
            isRequired
            isInvalid={
              formik.touched.password1 && Boolean(formik.errors.password1)
            }
          >
            <FormLabel>Contraseña</FormLabel>
            <Input
              borderRadius="30px"
              name="password1"
              type="password"
              value={formik.values.password1}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <FormErrorMessage>
              {formik.touched.password1 && formik.errors.password1}
            </FormErrorMessage>
          </FormControl>
          <FormControl
            isRequired
            isInvalid={
              formik.touched.password2 && Boolean(formik.errors.password2)
            }
          >
            <FormLabel>Confirmar contraseña</FormLabel>
            <Input
              borderRadius="30px"
              type="password"
              name="password2"
              value={formik.values.password2}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              onKeyDown={(e) => {
                const { key } = e
                if (key === 'Enter' && formik.isValid) {
                  formik.submitForm()
                }
              }}
            />
            <FormErrorMessage>
              {formik.touched.password2 && formik.errors.password2}
            </FormErrorMessage>
          </FormControl>
          {error && (
            <Box my={2}>
              <Text fontSize="17px" color="red">
                {error}
              </Text>
            </Box>
          )}
          <Stack spacing={10} align={'center'}>
            <Button
              isLoading={isLoading}
              onClick={formik.handleSubmit}
              bg={'teal.500'}
              color={'white'}
              _hover={{
                bg: 'teal.600'
              }}
              disabled={isLoading}
              // leftIcon={success && <SuccessIcon />}
            >
              Guardar cambios
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Stack>
  )
}

export default PassForm
