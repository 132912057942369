import {
  HStack,
  Box,
  Text,
  Textarea,
  FormControl,
  FormErrorMessage,
  Switch
} from '@chakra-ui/react'

// Módulo de check para vehículos
const CheckVehicle = ({
  title,
  setValues,
  switchName,
  monitored,
  status,
  statusName,
  observation,
  observationSwitchName,
  touched,
  errors,
  handleChange,
  value,
  textName
}) => {
  const colors = (monitored, status) => {
    if (!monitored) {
      return {
        backgroudGood: '#E6E6EF',
        fontGood: '#FFFFFF',
        backgroudRegular: '#E6E6EF',
        fontRegular: '#FFFFFF',
        backgroudBad: '#E6E6EF',
        fontBad: '#FFFFFF'
      }
    }
    if (status === 'Bueno') {
      return {
        backgroudGood: '#8CBB59',
        fontGood: '#FFFFFF',
        backgroudRegular: '#FFF4DC',
        fontRegular: '#F5B40A',
        backgroudBad: '#FFCCC1',
        fontBad: '#E25C5C'
      }
    }
    if (status === 'Regular') {
      return {
        backgroudGood: '#E5FDCB',
        fontGood: '#8CBB59',
        backgroudRegular: '#FFBF37',
        fontRegular: '#FFFFFF',
        backgroudBad: '#FFCCC1',
        fontBad: '#E25C5C'
      }
    }
    if (status === 'Malo') {
      return {
        backgroudGood: '#E5FDCB',
        fontGood: '#8CBB59',
        backgroudRegular: '#FFF4DC',
        fontRegular: '#F5B40A',
        backgroudBad: '#E25C5C',
        fontBad: '#FFFFFF'
      }
    }
    return {
      backgroudGood: '#E5FDCB',
      fontGood: '#8CBB59',
      backgroudRegular: '#FFF4DC',
      fontRegular: '#F5B40A',
      backgroudBad: '#FFCCC1',
      fontBad: '#E25C5C'
    }
  }
  return (
    <Box rounded={'lg'} bg={'#FFF'} w={['90%', '30%']} boxShadow={'md'}>
      <HStack justify={'space-between'} align="center" p={2}>
        <Text fontSize="14px" fontWeight="bold" color="#36355F">
          {title}
        </Text>
        <Switch
          size="md"
          colorScheme={'cyan'}
          name={switchName}
          isChecked={monitored}
          onChange={() => setValues(switchName, !monitored)}
        ></Switch>
      </HStack>
      <HStack justify={'space-between'} align="center" my={7} p={2}>
        <Box
          as="button"
          px={3}
          py={1}
          bg={colors(monitored, status).backgroudGood}
          borderRadius="lg"
          //   key={data.id}
          display="flex"
          disabled={!monitored}
          onClick={() => setValues(statusName, 'Bueno')}
        >
          <Text
            fontSize="13px"
            color={colors(monitored, status).fontGood}
            m="auto"
          >
            Bueno
          </Text>
        </Box>
        <Box
          as="button"
          px={3}
          py={1}
          bg={colors(monitored, status).backgroudRegular}
          borderRadius="lg"
          //   key={data.id}
          display="flex"
          disabled={!monitored}
          onClick={() => setValues(statusName, 'Regular')}
        >
          <Text
            fontSize="13px"
            color={colors(monitored, status).fontRegular}
            m="auto"
          >
            Regular
          </Text>
        </Box>
        <Box
          as="button"
          px={3}
          py={1}
          bg={colors(monitored, status).backgroudBad}
          borderRadius="lg"
          borderStyle={''}
          //   key={data.id}
          display="flex"
          disabled={!monitored}
          onClick={() => setValues(statusName, 'Malo')}
        >
          <Text
            fontSize="13px"
            color={colors(monitored, status).fontBad}
            m="auto"
          >
            Malo
          </Text>
        </Box>
      </HStack>
      {monitored && (
        <HStack justify={'space-between'} align="center" p={2}>
          <Text fontSize="14px" fontWeight="bold" color="#36355F">
            Observaciones
          </Text>
          <Switch
            size="md"
            colorScheme={'cyan'}
            isChecked={observation}
            name={observationSwitchName}
            onChange={() => setValues(observationSwitchName, !observation)}
          ></Switch>
        </HStack>
      )}
      {observation && monitored && (
        <FormControl
          id={textName}
          isInvalid={touched && Boolean(errors)}
          mb={2}
          px={2}
        >
          <Textarea
            name={textName}
            value={value}
            onChange={handleChange}
            placeholder="Observaciones"
            h="20%"
          />
          <FormErrorMessage>{touched && errors}</FormErrorMessage>
        </FormControl>
      )}
    </Box>
  )
}

export default CheckVehicle
