import { useState } from 'react'
import {
  Flex,
  Box,
  HStack,
  Button,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Text,
  VStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Icon,
  useDisclosure,
  useToast
} from '@chakra-ui/react'

import { OptionsIcon } from '../../components/Shared/Icons'
import { useMutateFiles } from '../../hooks/s3Files'
import DeleteModal from '../../components/Shared/DeleteModal'

// Tabla de lista de clientes
const FilesTable = ({ data }) => {
  const toast = useToast()
  const [selectedClient, setSelectedClient] = useState(null)
  const { mutate } = useMutateFiles('DELETE')
  const {
    isOpen: openDelete,
    onOpen: onOpenDelete,
    onClose: closeDelete
  } = useDisclosure()

  // Eliminar usuario
  const handleDelete = (clientId) => {
    mutate(clientId, {
      onSuccess: () => {
        toast({
          title: `Archivo eliminado`,
          status: 'success',
          isClosable: true
        })
      },
      onError: () => {
        toast({
          title: `Error al eliminar archivo`,
          status: 'error',
          isClosable: true
        })
      }
    })
    closeDelete()
  }

  const handleClickDelete = (client) => {
    setSelectedClient({ id: client.key, ...client })
    onOpenDelete()
  }

  const handleClickSelect = (client) => {
    window.open(client.url)
  }

  return (
    <Flex bg={'#FFF'}>
      <Box w="100%">
        <TableContainer>
          <Table variant="simple" size="sm">
            <Thead>
              <Tr>
                <Th color="#36355F" fontWeight={'normal'}>
                  Fecha
                </Th>
                <Th color="#36355F" fontWeight={'normal'}>
                  Nombre
                </Th>
                <Th color="#36355F" fontWeight={'normal'}>
                  Tamaño
                </Th>
                <Th color="#36355F" fontWeight={'normal'}>
                  Carpeta
                </Th>
                <Th color="#36355F" fontWeight={'normal'}>
                  URL
                </Th>
                <Th color="#36355F" fontWeight={'normal'}>
                  Opciones
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.map((client) => (
                <Tr>
                  <Td style={{ whiteSpace: 'pre-wrap' }}>
                    <Text fontSize={['13px', '20px']} color="#36355F">
                      {new Date(client.date).toLocaleDateString('es-ES')}
                    </Text>
                  </Td>
                  <Td style={{ whiteSpace: 'pre-wrap' }}>
                    <Text fontSize={['13px', '20px']} color="#36355F">
                      {client.key.split('/')[1]}
                    </Text>
                  </Td>
                  <Td style={{ whiteSpace: 'pre-wrap' }}>
                    <Text fontSize={['13px', '20px']} color="#36355F">
                      {client.size.toLocaleString()} Mb
                    </Text>
                  </Td>
                  <Td style={{ whiteSpace: 'pre-wrap' }}>
                    <Text fontSize={['13px', '20px']} color="#36355F">
                      {client.key.split('/')[0]}
                    </Text>
                  </Td>
                  <Td style={{ whiteSpace: 'pre-wrap' }}>
                    <Text
                      fontSize={['13px', '20px']}
                      color="#36355F"
                      lineHeight="normal"
                    >
                      {client.url}
                    </Text>
                  </Td>
                  <Td>
                    <Menu>
                      <MenuButton
                        as={Button}
                        bg="#FFFFFF"
                        leftIcon={
                          <Icon
                            fontSize="22"
                            as={OptionsIcon}
                            color="#374957"
                          />
                        }
                      />
                      <MenuList>
                        <MenuItem onClick={() => handleClickSelect(client)}>
                          Ver
                        </MenuItem>
                        <MenuItem onClick={() => handleClickDelete(client)}>
                          Eliminar
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
      {openDelete && (
        <DeleteModal
          isOpen={openDelete}
          onClose={closeDelete}
          message="¿Esta seguro de querer eliminar el archivo?"
          handleDelete={handleDelete}
          selected={selectedClient}
        />
      )}
    </Flex>
  )
}

export default FilesTable
