import { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Flex,
  Image as CImage,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  HStack
} from '@chakra-ui/react'
import { MdRotateRight } from 'react-icons/md'

import Resizer from 'react-image-file-resizer'

const alwaysPortrait = (file) =>
  new Promise((resolve) => {
    const portraitImage = new Image()
    portraitImage.src = URL.createObjectURL(file)
    portraitImage.onload = () => {
      if (portraitImage.width > portraitImage.height) {
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        canvas.width = portraitImage.height
        canvas.height = portraitImage.width
        ctx.translate(portraitImage.height / 2, portraitImage.width / 2)
        ctx.rotate((270 * Math.PI) / 180)
        ctx.drawImage(
          portraitImage,
          -portraitImage.width / 2,
          -portraitImage.height / 2,
          portraitImage.width,
          portraitImage.height
        )
        canvas.toBlob(
          (blob) => {
            resolve(blob)
          },
          'image/jpeg',
          1
        )
      } else {
        resolve(file)
      }
    }
  })

const blobToFile = (theBlob, fileName) => {
  if (theBlob.lastModifiedDate) return theBlob
  theBlob.lastModifiedDate = new Date()
  theBlob.name = fileName
  return theBlob
}

const resizeFile = (file, grades) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      650,
      650,
      'JPEG',
      100,
      grades,
      (uri) => {
        resolve(uri)
      },
      'file',
      650,
      650
    )
  })

const PreviewImage = ({ file = null, callBackFile, isOpen, onClose }) => {
  const [newImageUri, setWewImageUri] = useState(null)
  const [multipleImages, setMultipleImages] = useState([])
  const [grades, setGrades] = useState(360)

  const changeGrades = async () => {
    const nextGrade = grades + 90
    const newValue = nextGrade > 360 ? 90 : nextGrade
    const reformatedImage = await resizeFile(newImageUri, newValue)
    const newMultipleImages = multipleImages.map((fileItem) => {
      if (newImageUri.name === fileItem.name) {
        return reformatedImage
      }
      return fileItem
    })
    setWewImageUri(reformatedImage)
    setMultipleImages(newMultipleImages)
    setGrades(newValue)
  }

  const sendFile = () => {
    if (callBackFile) {
      if (multipleImages.length) {
        callBackFile(multipleImages)
      } else {
        callBackFile(newImageUri)
      }
    }
    onClose()
  }

  useEffect(() => {
    const newImage = async () => {
      if (file && !Array.isArray(file)) {
        const rotatedImage = await alwaysPortrait(file)
        const formatedfile = blobToFile(rotatedImage, file.name)
        const uri = await resizeFile(formatedfile, 0)
        setWewImageUri(uri)
        return
      }
      if (file && Array.isArray(file) && file.length) {
        const allImages = []
        for await (const fileItem of file) {
          const rotatedImage = await alwaysPortrait(fileItem)
          const formatedfile = blobToFile(rotatedImage, fileItem.name)
          const uri = await resizeFile(formatedfile, 0)
          allImages.push(uri)
        }
        console.log(allImages)
        setMultipleImages(allImages)
        setWewImageUri(allImages[0])
        return
      }
    }
    newImage()
  }, [file])

  const selectPhoto = (selectedFile) => {
    setWewImageUri(selectedFile)
    setGrades(360)
  }

  return (
    <Modal isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Editar Orientación de imagen:</ModalHeader>

        <ModalBody>
          <Flex
            boxSize="sm"
            justify="center"
            alignItems="center"
            gap={2}
            direction="column"
            border="1px solid #59BABA"
            borderRadius={8}
            w="100%"
          >
            {newImageUri && (
              <Box>
                <CImage
                  src={URL.createObjectURL(newImageUri)}
                  maxH={300}
                  maxW={300}
                  alt="preview"
                  objectFit="contain"
                />
              </Box>
            )}
            <Box>
              <Button
                bg={'yellow.500'}
                onClick={changeGrades}
                rightIcon={<MdRotateRight />}
              >
                Rotar
              </Button>
            </Box>
          </Flex>
          <HStack mt={3} sx={{ overflowX: 'auto' }}>
            {multipleImages.map((fileItem) => (
              <CImage
                key={fileItem.name}
                src={URL.createObjectURL(fileItem)}
                boxSize="100px"
                objectFit="contain"
                border="1px solid #59BABA"
                borderRadius={8}
                onClick={() => selectPhoto(fileItem)}
                cursor="pointer"
              />
            ))}
          </HStack>
        </ModalBody>

        <ModalFooter>
          <Button bg={'teal.500'} color={'white'} onClick={sendFile}>
            Confirmar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default PreviewImage
