import { configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import thunk from 'redux-thunk'

import auth from './slices/auth'
import visits from './slices/visits'
import client from './slices/client'
import s3Files from './slices/s3Files'
import user from './slices/user'
import document from './slices/document'
import products from './slices/products'

const reducers = combineReducers({
  auth,
  visits,
  client,
  user,
  document,
  s3Files,
  products
})

const persistConfig = {
  key: 'root',
  storage
}

const persistedReducer = persistReducer(persistConfig, reducers)

export default configureStore({
  reducer: persistedReducer,
  devTools: process.env.REACT_APP_NODE_ENV !== 'production',
  middleware: [thunk]
})
