import { Box } from '@chakra-ui/react'

import LeftDrawer from '../Navigation/LeftDrawer'

const Layout = ({ children }) => (
  <Box minH="100vh" bg="#FFF">
    <LeftDrawer display={{ base: 'none', md: 'block', xs: 'none' }} />
    <Box p="4">{children}</Box>
  </Box>
)

export default Layout
