import { Flex, IconButton } from '@chakra-ui/react'
import { AiOutlineDelete as DeleteIcon } from 'react-icons/ai'
import { SimpleInput } from '../../Shared/inputs'

const EmailItem = ({ email, updateEmail, deleteEmail, error }) => {
  return (
    <Flex gap={2} w="100%" align="flex-start">
      <SimpleInput
        name={`${email.key}-email`}
        label={'Correo electrónico'}
        value={email?.email}
        onChange={(e) => updateEmail(email.key, e.target.value)}
        errorMsg={error?.email}
        errors={Boolean(error?.email)}
      />
      {/* --- */}
      <IconButton
        variant="outline"
        colorScheme="teal"
        aria-label="Call Sage"
        fontSize="20px"
        size="xs"
        icon={<DeleteIcon />}
        onClick={() => deleteEmail(email.key)}
      />
    </Flex>
  )
}

export default EmailItem
