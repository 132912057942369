import { useState, useEffect } from 'react'
import { useToast } from '@chakra-ui/react'
import { useDispatch } from 'react-redux'

//@components
import CustomToast from '../components/Shared/CustomToast'

//@utils
import { getUrlImage } from '../store/slices/fileUpload'

const useUploadImage = (initialValuesImages) => {
  const toast = useToast()
  const dispatch = useDispatch()
  const [images, setImages] = useState(initialValuesImages)
  const [isLoadingUploadFile, setIsLoadingUploadFile] = useState(false)

  const activateToast = (type, propsToast = {}, propsComponents = {}) => {
    const dict = {
      'UPLOAD-IMG': { text: 'Cargando Imagen...', status: 'info' },
      ERROR: { text: 'Hubo un error', status: 'error' }
    }

    const props = {
      ...propsToast,
      duration: 9000 * 9000,
      isClosable: true,
      position: 'bottom-right',
      render: ({ onClose }) => (
        <CustomToast
          text={dict[type].text}
          status={dict[type].status}
          onClose={onClose}
          {...propsComponents}
        />
      )
    }
    toast(props)
  }

  //seteo estado de imagenes
  const setImagenObj = (arrName, newArr) => {
    setImages((prevImages) => ({
      ...prevImages,
      [arrName]: newArr
    }))
  }
  const setImagenSimple = (newArr) => {
    setImages(newArr)
  }

  //Maneja la subida de imagen a un elemento de la lista
  const handleImage = (url, arrName = null) => {
    const newItem = {
      url: url.url,
      filename: url.filename,
      observation: ''
    }

    if (arrName) {
      const newArray = [...images[arrName], newItem]
      setImagenObj(arrName, newArray)
    }
    if (!arrName) {
      const newArray = [...images, newItem]
      setImagenSimple(newArray)
    }
  }

  const imgUpload = async (formImage, arrName = null) => {
    try {
      setIsLoadingUploadFile(true)
      const response = await dispatch(getUrlImage(formImage))
      if (response) {
        handleImage(response, arrName)
      }
    } catch (error) {
      activateToast('ERROR')
      setTimeout(toast.closeAll(), 4000)
    } finally {
      setIsLoadingUploadFile(false)
    }
  }

  useEffect(() => {
    if (isLoadingUploadFile) {
      activateToast('UPLOAD-IMG')
      return
    }
    toast.closeAll()
  }, [isLoadingUploadFile])

  return {
    setImagenObj,
    setImagenSimple,
    handleImage,
    imgUpload,
    isLoadingUploadFile,
    images
  }
}

export default useUploadImage
