import { useEffect } from 'react'
import {
  Checkbox,
  Flex,
  IconButton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  Textarea,
  Icon,
  Box,
  Text
} from '@chakra-ui/react'
import { ArrowLeftIcon, ArrowRightIcon } from '@chakra-ui/icons'
import { DeleteIcon } from '../Shared/Icons'
import usePaginateTable from '../../hooks/usePaginateTable'

const ToxicTable = ({
  stations_data = [],
  stations,
  handleChangeCheck,
  handleChangeStation,
  handleDeleteStation,
  index
}) => {
  const { page, limitPerPage, maxPage, onNextPage, onPrevPage, reset } =
    usePaginateTable({ totalItems: stations_data?.length, limitPerPage: 5 })

  useEffect(() => {
    reset()
  }, [stations])

  return (
    <>
      <TableContainer maxWidth={'100%'} overflowX="unset" overflowY="unset">
        <Table variant="simple" size="sm">
          <Thead
            bg={'#F2F1FB'}
            sx={{ borderCollapse: 'separate' }}
            position="sticky"
            top={0}
          >
            <Tr>
              <Th color="#36355F" fontWeight={'normal'}>
                N°
              </Th>
              <Th color="#36355F" fontWeight={'normal'}>
                Activo
              </Th>
              <Th color="#36355F" fontWeight={'normal'}>
                <Tooltip label="Consumo Total" fontSize="md">
                  C/T
                </Tooltip>
              </Th>
              <Th color="#36355F" fontWeight={'normal'}>
                <Tooltip label="Consumo Parcial" fontSize="md">
                  C/P
                </Tooltip>
              </Th>
              <Th color="#36355F" fontWeight={'normal'}>
                <Tooltip label="Deterioro Humedad" fontSize="md">
                  D/H
                </Tooltip>
              </Th>
              <Th color="#36355F" fontWeight={'normal'}>
                <Tooltip label="Deterioro caracol" fontSize="md">
                  D/Car
                </Tooltip>
              </Th>
              <Th color="#36355F" fontWeight={'normal'}>
                <Tooltip label="Deterioro Calor" fontSize="md">
                  D/Cal
                </Tooltip>
              </Th>
              <Th color="#36355F" fontWeight={'normal'}>
                <Tooltip label="No monitoreado" fontSize="md">
                  N/M
                </Tooltip>
              </Th>
              <Th color="#36355F" fontWeight={'normal'}>
                <Tooltip label="Cambio de Cebo" fontSize="md">
                  C/C
                </Tooltip>
              </Th>
              <Th color="#36355F" fontWeight={'normal'}>
                Observaciones
              </Th>
              <Th color="#36355F" fontWeight={'normal'}>
                Eliminar
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {stations_data
              ?.slice(page * limitPerPage, limitPerPage * (page + 1))
              ?.map((location, idx) => (
                <Tr key={idx}>
                  <Td>{page * limitPerPage + idx + 1}</Td>
                  <Td>
                    <Checkbox
                      size="md"
                      colorScheme="blue"
                      name="Active"
                      isChecked={location.checks.includes('Active')}
                      onChange={(e) =>
                        handleChangeCheck(
                          e,
                          location.checks,
                          page * limitPerPage + idx
                        )
                      }
                      borderColor="grey"
                    />
                  </Td>
                  <Td>
                    <Checkbox
                      size="md"
                      colorScheme="blue"
                      name="TotalConsume"
                      isChecked={location.checks.includes('TotalConsume')}
                      onChange={(e) =>
                        handleChangeCheck(
                          e,
                          location.checks,
                          page * limitPerPage + idx
                        )
                      }
                      borderColor="grey"
                    />
                  </Td>
                  <Td>
                    <Checkbox
                      size="md"
                      colorScheme="blue"
                      name="PartialConsume"
                      isChecked={location.checks.includes('PartialConsume')}
                      onChange={(e) =>
                        handleChangeCheck(
                          e,
                          location.checks,
                          page * limitPerPage + idx
                        )
                      }
                      borderColor="grey"
                    />
                  </Td>
                  <Td>
                    <Checkbox
                      size="md"
                      colorScheme="blue"
                      name="Humidity"
                      isChecked={location.checks.includes('Humidity')}
                      onChange={(e) =>
                        handleChangeCheck(
                          e,
                          location.checks,
                          page * limitPerPage + idx
                        )
                      }
                      borderColor="grey"
                    />
                  </Td>
                  <Td>
                    <Checkbox
                      size="md"
                      colorScheme="blue"
                      name="Snails"
                      isChecked={location.checks.includes('Snails')}
                      onChange={(e) =>
                        handleChangeCheck(
                          e,
                          location.checks,
                          page * limitPerPage + idx
                        )
                      }
                      borderColor="grey"
                    />
                  </Td>
                  <Td>
                    <Checkbox
                      size="md"
                      colorScheme="blue"
                      name="Heat"
                      isChecked={location.checks.includes('Heat')}
                      onChange={(e) =>
                        handleChangeCheck(
                          e,
                          location.checks,
                          page * limitPerPage + idx
                        )
                      }
                      borderColor="grey"
                    />
                  </Td>
                  <Td>
                    <Checkbox
                      size="md"
                      colorScheme="blue"
                      name="NotMonitored"
                      isChecked={location.checks.includes('NotMonitored')}
                      onChange={(e) =>
                        handleChangeCheck(
                          e,
                          location.checks,
                          page * limitPerPage + idx
                        )
                      }
                      borderColor="grey"
                    />
                  </Td>
                  <Td>
                    <Checkbox
                      size="md"
                      colorScheme="blue"
                      name="Bait"
                      isChecked={location.checks.includes('Bait')}
                      onChange={(e) =>
                        handleChangeCheck(
                          e,
                          location.checks,
                          page * limitPerPage + idx
                        )
                      }
                      borderColor="grey"
                    />
                  </Td>
                  <Td>
                    <Textarea
                      name="observations"
                      value={location.observations}
                      onChange={(e) =>
                        handleChangeStation(e, index, page * limitPerPage + idx)
                      }
                      placeholder="Observaciones"
                      h="20%"
                    />
                  </Td>
                  <Td>
                    <IconButton
                      icon={<Icon fontSize="22" as={DeleteIcon} />}
                      onClick={() =>
                        handleDeleteStation(index, page * limitPerPage + idx)
                      }
                    />
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </TableContainer>
      {/* --- */}
      <Box textAlign="end">
        <Text>Paginación estaciones</Text>
        <Flex justify="flex-end" alignItems="center" gap={2}>
          <IconButton
            icon={<Icon fontSize="22" as={ArrowLeftIcon} />}
            onClick={() => onPrevPage()}
            disabled={page === 0}
          />
          <IconButton
            icon={<Icon fontSize="22" as={ArrowRightIcon} />}
            onClick={() => onNextPage()}
            disabled={page === maxPage - 1}
          />
        </Flex>
      </Box>
    </>
  )
}

export default ToxicTable
