import generator from 'generate-password'
import newPasswordValidator from './newPassword'

const passwordGen = () => {
  const password = generator.generate({
    length: 10,
    numbers: true,
    lowercase: true,
    uppercase: true,
    symbols: true,
    strict: true
  })
  if (!newPasswordValidator(password)) {
    return passwordGen()
  }
  return password
}

export default passwordGen
