export default function formatQuery(obj = {}) {
  const formattedObj = {}

  if (!obj) {
    return formattedObj
  }

  Object.keys(obj).forEach((propName) => {
    const propValue = obj[propName]

    if (propValue !== null && propValue !== undefined && propValue !== '') {
      formattedObj[propName] = propValue
    }
  })

  return formattedObj
}

export const extensionDict = {
  EXCEL: ['.xlsx', 'EXCEL'],
  PDF: ['.pdf', 'PDF']
}
