import { useEffect } from 'react'
import {
  Checkbox,
  Flex,
  IconButton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  Textarea,
  Icon
} from '@chakra-ui/react'
import { ArrowLeftIcon, ArrowRightIcon } from '@chakra-ui/icons'
import { DeleteIcon } from '../Shared/Icons'
import usePaginateTable from '../../hooks/usePaginateTable'

const HaccpTable = ({
  stations_data = [],
  stations,
  handleChangeCheck,
  handleChangeStation,
  handleDeleteStation,
  index
}) => {
  const { page, limitPerPage, maxPage, onNextPage, onPrevPage, reset } =
    usePaginateTable({ totalItems: stations_data?.length, limitPerPage: 5 })

  useEffect(() => {
    reset()
  }, [stations])

  return (
    <>
      <TableContainer maxWidth={'100%'} overflowX="unset" overflowY="unset">
        <Table variant="simple" size="sm">
          <Thead
            bg={'#F2F1FB'}
            sx={{ borderCollapse: 'separate' }}
            position="sticky"
            top={0}
          >
            <Tr>
              <Th color="#36355F" fontWeight={'normal'}>
                N°
              </Th>
              <Th color="#36355F" fontWeight={'normal'}>
                Activa
              </Th>
              <Th color="#36355F" fontWeight={'normal'}>
                Inactiva
              </Th>
              <Th color="#36355F" fontWeight={'normal'}>
                Captura
              </Th>
              <Th color="#36355F" fontWeight={'normal'}>
                <Tooltip label="Deterioro Humedad" fontSize="md">
                  D/H
                </Tooltip>
              </Th>
              <Th color="#36355F" fontWeight={'normal'}>
                <Tooltip label="Deterioro Mecánico" fontSize="md">
                  D/M
                </Tooltip>
              </Th>
              <Th color="#36355F" fontWeight={'normal'}>
                <Tooltip label="Deterioro acumulación de Polvo" fontSize="md">
                  D/P
                </Tooltip>
              </Th>
              <Th color="#36355F" fontWeight={'normal'}>
                <Tooltip label="Deterioro por Temperatura" fontSize="md">
                  D/T
                </Tooltip>
              </Th>
              <Th color="#36355F" fontWeight={'normal'}>
                <Tooltip label="No monitoreado" fontSize="md">
                  N/M
                </Tooltip>
              </Th>
              <Th color="#36355F" fontWeight={'normal'}>
                <Tooltip label="Cambio de Trampa" fontSize="md">
                  C/T
                </Tooltip>
              </Th>
              <Th color="#36355F" fontWeight={'normal'}>
                <Tooltip
                  label="Cambio unidad contenedora plástica"
                  fontSize="md"
                >
                  C/U
                </Tooltip>
              </Th>
              <Th color="#36355F" fontWeight={'normal'}>
                Observaciones
              </Th>
              <Th color="#36355F" fontWeight={'normal'}>
                Eliminar
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {stations_data
              ?.slice(page * limitPerPage, limitPerPage * (page + 1))
              ?.map((location, idx) => (
                <Tr key={idx}>
                  <Td>{page * limitPerPage + idx + 1}</Td>
                  <Td>
                    <Checkbox
                      size="md"
                      colorScheme="blue"
                      name="Active"
                      isChecked={location.checks.includes('Active')}
                      onChange={(e) =>
                        handleChangeCheck(
                          e,
                          location.checks,
                          page * limitPerPage + idx
                        )
                      }
                      borderColor="grey"
                    />
                  </Td>
                  <Td>
                    <Checkbox
                      size="md"
                      colorScheme="blue"
                      name="Inactive"
                      isChecked={location.checks.includes('Inactive')}
                      onChange={(e) =>
                        handleChangeCheck(
                          e,
                          location.checks,
                          page * limitPerPage + idx
                        )
                      }
                      borderColor="grey"
                    />
                  </Td>
                  <Td>
                    <Checkbox
                      size="md"
                      colorScheme="blue"
                      name="Capture"
                      isChecked={location.checks.includes('Capture')}
                      onChange={(e) =>
                        handleChangeCheck(
                          e,
                          location.checks,
                          page * limitPerPage + idx
                        )
                      }
                      borderColor="grey"
                    />
                  </Td>
                  <Td>
                    <Checkbox
                      size="md"
                      colorScheme="blue"
                      name="Humidity"
                      isChecked={location.checks.includes('Humidity')}
                      onChange={(e) =>
                        handleChangeCheck(
                          e,
                          location.checks,
                          page * limitPerPage + idx
                        )
                      }
                      borderColor="grey"
                    />
                  </Td>
                  <Td>
                    <Checkbox
                      size="md"
                      colorScheme="blue"
                      name="Mechanical"
                      isChecked={location.checks.includes('Mechanical')}
                      onChange={(e) =>
                        handleChangeCheck(
                          e,
                          location.checks,
                          page * limitPerPage + idx
                        )
                      }
                      borderColor="grey"
                    />
                  </Td>
                  <Td>
                    <Checkbox
                      size="md"
                      colorScheme="blue"
                      name="Dust"
                      isChecked={location.checks.includes('Dust')}
                      onChange={(e) =>
                        handleChangeCheck(
                          e,
                          location.checks,
                          page * limitPerPage + idx
                        )
                      }
                      borderColor="grey"
                    />
                  </Td>
                  <Td>
                    <Checkbox
                      size="md"
                      colorScheme="blue"
                      name="Heat"
                      isChecked={location.checks.includes('Heat')}
                      onChange={(e) =>
                        handleChangeCheck(
                          e,
                          location.checks,
                          page * limitPerPage + idx
                        )
                      }
                      borderColor="grey"
                    />
                  </Td>
                  <Td>
                    <Checkbox
                      size="md"
                      colorScheme="blue"
                      name="NotMonitored"
                      isChecked={location.checks.includes('NotMonitored')}
                      onChange={(e) =>
                        handleChangeCheck(
                          e,
                          location.checks,
                          page * limitPerPage + idx
                        )
                      }
                      borderColor="grey"
                    />
                  </Td>
                  <Td>
                    <Checkbox
                      size="md"
                      colorScheme="blue"
                      name="changeTrap"
                      isChecked={location.checks.includes('changeTrap')}
                      onChange={(e) =>
                        handleChangeCheck(
                          e,
                          location.checks,
                          page * limitPerPage + idx
                        )
                      }
                      borderColor="grey"
                    />
                  </Td>
                  <Td>
                    <Checkbox
                      size="md"
                      colorScheme="blue"
                      name="changeContainer"
                      isChecked={location.checks.includes('changeContainer')}
                      onChange={(e) =>
                        handleChangeCheck(
                          e,
                          location.checks,
                          page * limitPerPage + idx
                        )
                      }
                      borderColor="grey"
                    />
                  </Td>
                  <Td>
                    <Textarea
                      name="observations"
                      value={location.observations}
                      onChange={(e) =>
                        handleChangeStation(e, index, page * limitPerPage + idx)
                      }
                      placeholder="Observaciones"
                      h="20%"
                    />
                  </Td>
                  <Td>
                    <IconButton
                      icon={<Icon fontSize="22" as={DeleteIcon} />}
                      onClick={() =>
                        handleDeleteStation(index, page * limitPerPage + idx)
                      }
                    />
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </TableContainer>
      {/* --- */}
      <Flex justify="center" alignItems="center" gap={2}>
        <IconButton
          icon={<Icon fontSize="22" as={ArrowLeftIcon} />}
          onClick={() => onPrevPage()}
          disabled={page === 0}
        />
        <IconButton
          icon={<Icon fontSize="22" as={ArrowRightIcon} />}
          onClick={() => onNextPage()}
          disabled={page === maxPage - 1}
        />
      </Flex>
    </>
  )
}

export default HaccpTable
