import { createSlice } from '@reduxjs/toolkit'
import Axios from '../../../Axios'
import AxiosNode from '../../../AxiosNode'

export const authSlice = createSlice({
  name: 'fileUpload',
  initialState: { imgUrl: '', pdfUrl: '' },
  reducers: {
    get_url_img: (state, action) => {
      state.imgUrl = action.payload.url
    },
    get_url_pdf: (state, action) => {
      state.pdfUrl = action.payload.url
    }
  }
})
export const { get_url_img, get_url_pdf } = authSlice.actions
/* --- */
export default authSlice.reducer
/* --- */
export const getUrlImage = (values) => (dispatch) =>
  new Promise((resolve, reject) => {
    const fastApiPath = `/files/upload-image`
    const nodeApiPath = `/upload-file`

    AxiosNode.post(nodeApiPath, values, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then((response) => {
        const { data } = response
        console.log(data)
        dispatch(get_url_img(data))
        resolve(data)
      })
      .catch((error) => {
        console.log(error)
        reject(error)
      })
  })

export const getUrlPdf = (values) => (dispatch) =>
  new Promise((resolve, reject) => {
    Axios.post(`/files/upload-pdf`, values)
      .then((response) => {
        const { data } = response
        dispatch(get_url_pdf(data))
        resolve(data)
      })
      .catch((error) => {
        reject(error)
      })
  })
