import axios from '../Axios'

export const getAllTools = async () => {
  const { data } = await axios.get(`tools/all-no-pag`)
  return data
}

export const getOneTool = async (toolId) => {
  const { data } = await axios.get(`tools/get-one?id_tool=${toolId}`)
  return data
}
