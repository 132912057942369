import { createSlice } from '@reduxjs/toolkit'

const initialValues = {
  client: '',
  branch: '',
  filterList: {
    page: 1,
    clientId: '',
    startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1)
      .toLocaleString('en-us', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      })
      .replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2'),
    endDate: new Date()
      .toLocaleString('en-us', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      })
      .replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2')
  }
}

export const visitsSlice = createSlice({
  name: 'visits',
  initialState: initialValues,
  reducers: {
    clientData: (state, action) => {
      state.client = action.payload.client
      state.branch = action.payload.branch
    },
    filterList: (state, action) => {
      state.filterList = {
        ...state.filterList,
        ...action.payload
      }
    },
    initialValuesVisits: (state) => {
      state.branch = initialValues.branch
      state.client = initialValues.client
      state.filterList = initialValues.filterList
    }
  }
})
export const { clientData, filterList, initialValuesVisits } =
  visitsSlice.actions
/* --- */
export default visitsSlice.reducer
/* --- */
export const getClientData = (data) => (dispatch) => {
  dispatch(clientData(data))
}

export const updateFilterList = (data) => (dispatch) => {
  dispatch(filterList(data))
}

export const resetVisits = () => (dispatch) => {
  dispatch(initialValuesVisits())
}
