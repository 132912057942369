import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Flex,
  Stack,
  HStack,
  Box,
  Icon,
  Spacer,
  Text,
  FormControl,
  FormLabel,
  Input,
  Select,
  IconButton,
  useToast
} from '@chakra-ui/react'
import { SearchIcon } from '@chakra-ui/icons'

import { DeleteIcon, CalendarIcon } from '../../components/Shared/Icons'
import CheckTool from './CheckTool'
import CheckOther from './CheckOther'
import UploadImage from '../Shared/UploadImage'
import { getUrlImage } from '../../store/slices/fileUpload'
import CustomToast from '../Shared/CustomToast'
import RenderImageContainer from '../Shared/RenderImageContainer'

// Formulario de checklist de una herramienta
const ToolForm = ({ handleChange, index, values, tools }) => {
  const toast = useToast()
  const dispatch = useDispatch()
  const [tool, setTool] = useState(null)

  // Obtener herramienta por id
  const getTool = (id) => {
    if (tools) {
      const newTool = tools.find((tool) => {
        return tool.id === id
      })
      return JSON.stringify(newTool)
    }
    return null
  }

  // Guardar en estado la herramienta seleccionada
  useEffect(() => {
    if (values?.tool !== 0) {
      const newTool = getTool(values?.tool)
      if (newTool) {
        const val = JSON.parse(newTool)
        setTool(val)
      } else {
        setTool(newTool)
      }
    }
  }, [values, tools])

  //Deja la url de la imagen la variable correspondiente
  const handleImage = (type, value) => {
    console.log(type)
    if (type === 'upload') {
      handleChange('image', value, index)
      return
    }
    if (type === 'deleteImg') {
      handleChange('deleteImg', value, index)
      return
    }
    if (type === 'observation') {
      handleChange('observation', value, index)
      return
    }
  }

  //Lógica de subida de imagen
  const [isLoadingUploadFile, setIsLoadingUploadFile] = useState(false)
  const imgUpload = async (formImage) => {
    try {
      setIsLoadingUploadFile(true)
      const response = await dispatch(getUrlImage(formImage))
      if (response) {
        handleImage('upload', response.url)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoadingUploadFile(false)
    }
  }

  //Obtener nombre de imagen
  const getFilename = (img) => {
    const index = img.lastIndexOf('/') + 1
    const filename = img.substr(index)
    return filename
  }

  // Guardar herramienta
  const handleTool = (value) => {
    const val = JSON.parse(value)
    setTool(val)
    handleChange('tool', val.id, index)
  }

  // Formulario motosierra
  const chainsaw = () => {
    return (
      <>
        <Box w="100%" bg="#F2F1FB" pb={5}>
          <Flex spacing={10} align="left" pt="15px">
            <Stack spacing={2} pl={30}>
              <Text fontSize="15px" fontWeight={'bold'} color="#36355F">
                Motosierra
              </Text>
            </Stack>
          </Flex>
        </Box>
        <HStack
          spacing="24px"
          overflowX={'scroll'}
          py={3}
          px={2}
          css={{
            '&::-webkit-scrollbar': {
              width: '4px'
            },
            '&::-webkit-scrollbar-track': {
              width: '6px'
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#59BABA',
              borderRadius: '15px'
            }
          }}
        >
          <CheckTool
            title={'Deposito Mezcla'}
            switchName={'mixing_tank_monitored'}
            monitored={values.mixing_tank_monitored}
            status={values.mixing_tank_status}
            statusName={'mixing_tank_status'}
            idDate={'mixing_tank_date'}
            date={values.mixing_tank_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckTool
            title={'Filtro'}
            switchName={'filter_monitored'}
            monitored={values.filter_monitored}
            status={values.filter_status}
            statusName={'filter_status'}
            idDate={'filter_date'}
            date={values.filter_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckTool
            title={'Bujia'}
            switchName={'plug_monitored'}
            monitored={values.plug_monitored}
            status={values.plug_status}
            statusName={'plug_status'}
            idDate={'plug_date'}
            date={values.plug_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckTool
            title={'Piola de Arranque'}
            switchName={'starter_pin_monitored'}
            monitored={values.starter_pin_monitored}
            status={values.starter_pin_status}
            statusName={'starter_pin_status'}
            idDate={'starter_pin_date'}
            date={values.starter_pin_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckTool
            title={'Conector Bujia'}
            switchName={'plug_connector_monitored'}
            monitored={values.plug_connector_monitored}
            status={values.plug_connector_status}
            statusName={'plug_connector_status'}
            idDate={'plug_connector_date'}
            date={values.plug_connector_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckTool
            title={'Deposito Aceite de Cadena'}
            switchName={'chain_oil_monitored'}
            monitored={values.chain_oil_monitored}
            status={values.chain_oil_status}
            statusName={'chain_oil_status'}
            idDate={'chain_oil_date'}
            date={values.chain_oil_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckTool
            title={'Estado de la Cadena'}
            switchName={'chain_status_monitored'}
            monitored={values.chain_status_monitored}
            status={values.chain_status_status}
            statusName={'chain_status_status'}
            idDate={'chain_status_date'}
            date={values.chain_status_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckTool
            title={'Estado de la Espada'}
            switchName={'blade_status_monitored'}
            monitored={values.blade_status_monitored}
            status={values.blade_status_status}
            statusName={'blade_status_status'}
            idDate={'blade_status_date'}
            date={values.blade_status_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckTool
            title={'Estado General Maquina'}
            switchName={'general_status_monitored'}
            monitored={values.general_status_monitored}
            status={values.general_status_status}
            statusName={'general_status_status'}
            idDate={'general_status_date'}
            date={values.general_status_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckOther
            title={'Otro'}
            switchName={'other_monitored'}
            monitored={values.other_monitored}
            status={values.other_status}
            statusName={'other_status'}
            observationSwitch={values.other_observation_check}
            observationSwitchName={'other_observation_check'}
            textName={'other_observation'}
            value={values.other_observation}
            handleChange={handleChange}
            index={index}
          />
        </HStack>
        {values.image ? (
          <Box w="100%" pb={5} borderRadius={'lg'} boxShadow={'md'} p={2}>
            <RenderImageContainer
              images={[
                {
                  url: values.image,
                  observation: values.observation
                }
              ]}
              getNewArray={(arr) => {
                const img = arr?.at(0)
                handleImage('observation', img.observation)
              }}
              getNewArrayDelete={() => {
                handleImage('deleteImg', '')
              }}
            />
          </Box>
        ) : (
          <UploadImage imgUpload={imgUpload} />
        )}
      </>
    )
  }

  // Formulario motosierra en altura
  const highChainsaw = () => {
    return (
      <>
        <Box w="100%" bg="#F2F1FB" pb={5}>
          <Flex spacing={10} align="left" pt="15px">
            <Stack spacing={2} pl={30}>
              <Text fontSize="15px" fontWeight={'bold'} color="#36355F">
                Motosierra
              </Text>
            </Stack>
          </Flex>
        </Box>
        <HStack
          spacing="24px"
          overflowX={'scroll'}
          py={3}
          px={2}
          css={{
            '&::-webkit-scrollbar': {
              width: '4px'
            },
            '&::-webkit-scrollbar-track': {
              width: '6px'
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#59BABA',
              borderRadius: '15px'
            }
          }}
        >
          <CheckTool
            title={'Deposito Mezcla'}
            switchName={'mixing_tank_monitored'}
            monitored={values.mixing_tank_monitored}
            status={values.mixing_tank_status}
            statusName={'mixing_tank_status'}
            idDate={'mixing_tank_date'}
            date={values.mixing_tank_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckTool
            title={'Filtro'}
            switchName={'filter_monitored'}
            monitored={values.filter_monitored}
            status={values.filter_status}
            statusName={'filter_status'}
            idDate={'filter_date'}
            date={values.filter_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckTool
            title={'Bujia'}
            switchName={'plug_monitored'}
            monitored={values.plug_monitored}
            status={values.plug_status}
            statusName={'plug_status'}
            idDate={'plug_date'}
            date={values.plug_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckTool
            title={'Piola de Arranque'}
            switchName={'starter_pin_monitored'}
            monitored={values.starter_pin_monitored}
            status={values.starter_pin_status}
            statusName={'starter_pin_status'}
            idDate={'starter_pin_date'}
            date={values.starter_pin_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckTool
            title={'Conector Bujia'}
            switchName={'plug_connector_monitored'}
            monitored={values.plug_connector_monitored}
            status={values.plug_connector_status}
            statusName={'plug_connector_status'}
            idDate={'plug_connector_date'}
            date={values.plug_connector_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckTool
            title={'Deposito Aceite de Cadena'}
            switchName={'chain_oil_monitored'}
            monitored={values.chain_oil_monitored}
            status={values.chain_oil_status}
            statusName={'chain_oil_status'}
            idDate={'chain_oil_date'}
            date={values.chain_oil_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckTool
            title={'Estado de la Cadena'}
            switchName={'chain_status_monitored'}
            monitored={values.chain_status_monitored}
            status={values.chain_status_status}
            statusName={'chain_status_status'}
            idDate={'chain_status_date'}
            date={values.chain_status_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckTool
            title={'Estado de la Espada'}
            switchName={'blade_status_monitored'}
            monitored={values.blade_status_monitored}
            status={values.blade_status_status}
            statusName={'blade_status_status'}
            idDate={'blade_status_date'}
            date={values.blade_status_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckTool
            title={'Estado General Maquina'}
            switchName={'general_status_monitored'}
            monitored={values.general_status_monitored}
            status={values.general_status_status}
            statusName={'general_status_status'}
            idDate={'general_status_date'}
            date={values.general_status_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckTool
            title={'Brazo telescópico'}
            switchName={'telescopic_arm_monitored'}
            monitored={values.telescopic_arm_monitored}
            status={values.telescopic_arm_status}
            statusName={'telescopic_arm_status'}
            idDate={'telescopic_arm_date'}
            date={values.telescopic_arm_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckOther
            title={'Otro'}
            switchName={'other_monitored'}
            monitored={values.other_monitored}
            status={values.other_status}
            statusName={'other_status'}
            observationSwitch={values.other_observation_check}
            observationSwitchName={'other_observation_check'}
            textName={'other_observation'}
            value={values.other_observation}
            handleChange={handleChange}
            index={index}
          />
        </HStack>
        {values.image ? (
          <Box w="100%" pb={5} borderRadius={'lg'} boxShadow={'md'} p={2}>
            <RenderImageContainer
              images={[
                {
                  url: values.image,
                  observation: values.observation
                }
              ]}
              getNewArray={(arr) => {
                const img = arr?.at(0)
                handleImage('observation', img.observation)
              }}
              getNewArrayDelete={() => {
                handleImage('deleteImg', '')
              }}
            />
          </Box>
        ) : (
          <UploadImage imgUpload={imgUpload} />
        )}
      </>
    )
  }

  // Formulario cortasetos
  const hedgeTrimmers = () => {
    return (
      <>
        <Box w="100%" bg="#F2F1FB" pb={5}>
          <Flex spacing={10} align="left" pt="15px">
            <Stack spacing={2} pl={30}>
              <Text fontSize="15px" fontWeight={'bold'} color="#36355F">
                Cortasetos
              </Text>
            </Stack>
          </Flex>
        </Box>
        <HStack
          spacing="24px"
          overflowX={'scroll'}
          py={3}
          px={2}
          css={{
            '&::-webkit-scrollbar': {
              width: '4px'
            },
            '&::-webkit-scrollbar-track': {
              width: '6px'
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#59BABA',
              borderRadius: '15px'
            }
          }}
        >
          <CheckTool
            title={'Deposito Mezcla'}
            switchName={'mixing_tank_monitored'}
            monitored={values.mixing_tank_monitored}
            status={values.mixing_tank_status}
            statusName={'mixing_tank_status'}
            idDate={'mixing_tank_date'}
            date={values.mixing_tank_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckTool
            title={'Filtro'}
            switchName={'filter_monitored'}
            monitored={values.filter_monitored}
            status={values.filter_status}
            statusName={'filter_status'}
            idDate={'filter_date'}
            date={values.filter_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckTool
            title={'Bujia'}
            switchName={'plug_monitored'}
            monitored={values.plug_monitored}
            status={values.plug_status}
            statusName={'plug_status'}
            idDate={'plug_date'}
            date={values.plug_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckTool
            title={'Piola de Arranque'}
            switchName={'starter_pin_monitored'}
            monitored={values.starter_pin_monitored}
            status={values.starter_pin_status}
            statusName={'starter_pin_status'}
            idDate={'starter_pin_date'}
            date={values.starter_pin_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckTool
            title={'Conector Bujia'}
            switchName={'plug_connector_monitored'}
            monitored={values.plug_connector_monitored}
            status={values.plug_connector_status}
            statusName={'plug_connector_status'}
            idDate={'plug_connector_date'}
            date={values.plug_connector_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckTool
            title={'Estado de la Cadena'}
            switchName={'chain_status_monitored'}
            monitored={values.chain_status_monitored}
            status={values.chain_status_status}
            statusName={'chain_status_status'}
            idDate={'chain_status_date'}
            date={values.chain_status_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckTool
            title={'Estado General Maquina'}
            switchName={'general_status_monitored'}
            monitored={values.general_status_monitored}
            status={values.general_status_status}
            statusName={'general_status_status'}
            idDate={'general_status_date'}
            date={values.general_status_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckOther
            title={'Otro'}
            switchName={'other_monitored'}
            monitored={values.other_monitored}
            status={values.other_status}
            statusName={'other_status'}
            observationSwitch={values.other_observation_check}
            observationSwitchName={'other_observation_check'}
            textName={'other_observation'}
            value={values.other_observation}
            handleChange={handleChange}
            index={index}
          />
        </HStack>
        {values.image ? (
          <Box w="100%" pb={5} borderRadius={'lg'} boxShadow={'md'} p={2}>
            <RenderImageContainer
              images={[
                {
                  url: values.image,
                  observation: values.observation
                }
              ]}
              getNewArray={(arr) => {
                const img = arr?.at(0)
                handleImage('observation', img.observation)
              }}
              getNewArrayDelete={() => {
                handleImage('deleteImg', '')
              }}
            />
          </Box>
        ) : (
          <UploadImage imgUpload={imgUpload} />
        )}
      </>
    )
  }

  // Formulario ahoyadora
  const auger = () => {
    return (
      <>
        <Box w="100%" bg="#F2F1FB" pb={5}>
          <Flex spacing={10} align="left" pt="15px">
            <Stack spacing={2} pl={30}>
              <Text fontSize="15px" fontWeight={'bold'} color="#36355F">
                Ahoyadora
              </Text>
            </Stack>
          </Flex>
        </Box>
        <HStack
          spacing="24px"
          overflowX={'scroll'}
          py={3}
          px={2}
          css={{
            '&::-webkit-scrollbar': {
              width: '4px'
            },
            '&::-webkit-scrollbar-track': {
              width: '6px'
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#59BABA',
              borderRadius: '15px'
            }
          }}
        >
          <CheckTool
            title={'Deposito Mezcla'}
            switchName={'mixing_tank_monitored'}
            monitored={values.mixing_tank_monitored}
            status={values.mixing_tank_status}
            statusName={'mixing_tank_status'}
            idDate={'mixing_tank_date'}
            date={values.mixing_tank_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckTool
            title={'Filtro'}
            switchName={'filter_monitored'}
            monitored={values.filter_monitored}
            status={values.filter_status}
            statusName={'filter_status'}
            idDate={'filter_date'}
            date={values.filter_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckTool
            title={'Bujia'}
            switchName={'plug_monitored'}
            monitored={values.plug_monitored}
            status={values.plug_status}
            statusName={'plug_status'}
            idDate={'plug_date'}
            date={values.plug_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckTool
            title={'Piola de Arranque'}
            switchName={'starter_pin_monitored'}
            monitored={values.starter_pin_monitored}
            status={values.starter_pin_status}
            statusName={'starter_pin_status'}
            idDate={'starter_pin_date'}
            date={values.starter_pin_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckTool
            title={'Conector Bujia'}
            switchName={'plug_connector_monitored'}
            monitored={values.plug_connector_monitored}
            status={values.plug_connector_status}
            statusName={'plug_connector_status'}
            idDate={'plug_connector_date'}
            date={values.plug_connector_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckTool
            title={'Estado del barreno'}
            switchName={'auger_status_monitored'}
            monitored={values.auger_status_monitored}
            status={values.auger_status_status}
            statusName={'auger_status_status'}
            idDate={'auger_status_date'}
            date={values.auger_status_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckTool
            title={'Estado General Maquina'}
            switchName={'general_status_monitored'}
            monitored={values.general_status_monitored}
            status={values.general_status_status}
            statusName={'general_status_status'}
            idDate={'general_status_date'}
            date={values.general_status_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckOther
            title={'Otro'}
            switchName={'other_monitored'}
            monitored={values.other_monitored}
            status={values.other_status}
            statusName={'other_status'}
            observationSwitch={values.other_observation_check}
            observationSwitchName={'other_observation_check'}
            textName={'other_observation'}
            value={values.other_observation}
            handleChange={handleChange}
            index={index}
          />
        </HStack>
        {values.image ? (
          <Box w="100%" pb={5} borderRadius={'lg'} boxShadow={'md'} p={2}>
            <RenderImageContainer
              images={[
                {
                  url: values.image,
                  observation: values.observation
                }
              ]}
              getNewArray={(arr) => {
                const img = arr?.at(0)
                handleImage('observation', img.observation)
              }}
              getNewArrayDelete={() => {
                handleImage('deleteImg', '')
              }}
            />
          </Box>
        ) : (
          <UploadImage imgUpload={imgUpload} />
        )}
      </>
    )
  }

  // Formulario desbrozadora
  const brushcutter = () => {
    return (
      <>
        <Box w="100%" bg="#F2F1FB" pb={5}>
          <Flex spacing={10} align="left" pt="15px">
            <Stack spacing={2} pl={30}>
              <Text fontSize="15px" fontWeight={'bold'} color="#36355F">
                Desbrozadora
              </Text>
            </Stack>
          </Flex>
        </Box>
        <HStack
          spacing="24px"
          overflowX={'scroll'}
          py={3}
          px={2}
          css={{
            '&::-webkit-scrollbar': {
              width: '4px'
            },
            '&::-webkit-scrollbar-track': {
              width: '6px'
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#59BABA',
              borderRadius: '15px'
            }
          }}
        >
          <CheckTool
            title={'Deposito Mezcla'}
            switchName={'mixing_tank_monitored'}
            monitored={values.mixing_tank_monitored}
            status={values.mixing_tank_status}
            statusName={'mixing_tank_status'}
            idDate={'mixing_tank_date'}
            date={values.mixing_tank_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckTool
            title={'Filtro'}
            switchName={'filter_monitored'}
            monitored={values.filter_monitored}
            status={values.filter_status}
            statusName={'filter_status'}
            idDate={'filter_date'}
            date={values.filter_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckTool
            title={'Bujia'}
            switchName={'plug_monitored'}
            monitored={values.plug_monitored}
            status={values.plug_status}
            statusName={'plug_status'}
            idDate={'plug_date'}
            date={values.plug_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckTool
            title={'Piola de Arranque'}
            switchName={'starter_pin_monitored'}
            monitored={values.starter_pin_monitored}
            status={values.starter_pin_status}
            statusName={'starter_pin_status'}
            idDate={'starter_pin_date'}
            date={values.starter_pin_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckTool
            title={'Conector Bujia'}
            switchName={'plug_connector_monitored'}
            monitored={values.plug_connector_monitored}
            status={values.plug_connector_status}
            statusName={'plug_connector_status'}
            idDate={'plug_connector_date'}
            date={values.plug_connector_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckTool
            title={'Estado del cabezal'}
            switchName={'spindle_status_monitored'}
            monitored={values.spindle_status_monitored}
            status={values.spindle_status_status}
            statusName={'spindle_status_status'}
            idDate={'spindle_status_date'}
            date={values.spindle_status_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckTool
            title={'Manillar'}
            switchName={'handlebar_monitored'}
            monitored={values.handlebar_monitored}
            status={values.handlebar_status}
            statusName={'handlebar_status'}
            idDate={'handlebar_date'}
            date={values.handlebar_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckTool
            title={'Piola aceleración'}
            switchName={'acceleration_pin_monitored'}
            monitored={values.acceleration_pin_monitored}
            status={values.acceleration_pin_status}
            statusName={'acceleration_pin_status'}
            idDate={'acceleration_pin_date'}
            date={values.acceleration_pin_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckTool
            title={'Protector de proyección'}
            switchName={'projection_shield_monitored'}
            monitored={values.projection_shield_monitored}
            status={values.projection_shield_status}
            statusName={'projection_shield_status'}
            idDate={'projection_shield_date'}
            date={values.projection_shield_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckTool
            title={'Arnes'}
            switchName={'harness_monitored'}
            monitored={values.harness_monitored}
            status={values.harness_status}
            statusName={'harness_status'}
            idDate={'harness_date'}
            date={values.harness_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckTool
            title={'Estado General Maquina'}
            switchName={'general_status_monitored'}
            monitored={values.general_status_monitored}
            status={values.general_status_status}
            statusName={'general_status_status'}
            idDate={'general_status_date'}
            date={values.general_status_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckTool
            title={'Estado del Eje'}
            switchName={'shaft_status_monitored'}
            monitored={values.shaft_status_monitored}
            status={values.shaft_status_status}
            statusName={'shaft_status_status'}
            idDate={'shaft_status_date'}
            date={values.shaft_status_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckTool
            title={'Manguera'}
            switchName={'hog_monitored'}
            monitored={values.hog_monitored}
            status={values.hog_status}
            statusName={'hog_status'}
            idDate={'hog_date'}
            date={values.hog_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckOther
            title={'Otro'}
            switchName={'other_monitored'}
            monitored={values.other_monitored}
            status={values.other_status}
            statusName={'other_status'}
            observationSwitch={values.other_observation_check}
            observationSwitchName={'other_observation_check'}
            textName={'other_observation'}
            value={values.other_observation}
            handleChange={handleChange}
            index={index}
          />
        </HStack>
        {values.image ? (
          <Box w="100%" pb={5} borderRadius={'lg'} boxShadow={'md'} p={2}>
            <RenderImageContainer
              images={[
                {
                  url: values.image,
                  observation: values.observation
                }
              ]}
              getNewArray={(arr) => {
                const img = arr?.at(0)
                handleImage('observation', img.observation)
              }}
              getNewArrayDelete={() => {
                handleImage('deleteImg', '')
              }}
            />
          </Box>
        ) : (
          <UploadImage imgUpload={imgUpload} />
        )}
      </>
    )
  }

  // Formulario Rotovator
  const rotovator = () => {
    return (
      <>
        <Box w="100%" bg="#F2F1FB" pb={5}>
          <Flex spacing={10} align="left" pt="15px">
            <Stack spacing={2} pl={30}>
              <Text fontSize="15px" fontWeight={'bold'} color="#36355F">
                Rotovator
              </Text>
            </Stack>
          </Flex>
        </Box>
        <HStack
          spacing="24px"
          overflowX={'scroll'}
          py={3}
          px={2}
          css={{
            '&::-webkit-scrollbar': {
              width: '4px'
            },
            '&::-webkit-scrollbar-track': {
              width: '6px'
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#59BABA',
              borderRadius: '15px'
            }
          }}
        >
          <CheckTool
            title={'Deposito Combustible'}
            switchName={'fuel_tank_monitored'}
            monitored={values.fuel_tank_monitored}
            status={values.fuel_tank_status}
            statusName={'fuel_tank_status'}
            idDate={'fuel_tank_date'}
            date={values.fuel_tank_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckTool
            title={'Piola de Arranque'}
            switchName={'starter_pin_monitored'}
            monitored={values.starter_pin_monitored}
            status={values.starter_pin_status}
            statusName={'starter_pin_status'}
            idDate={'starter_pin_date'}
            date={values.starter_pin_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckTool
            title={'Manillar'}
            switchName={'handlebar_monitored'}
            monitored={values.handlebar_monitored}
            status={values.handlebar_status}
            statusName={'handlebar_status'}
            idDate={'handlebar_date'}
            date={values.handlebar_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckTool
            title={'Selector de marchas'}
            switchName={'gear_selector_monitored'}
            monitored={values.gear_selector_monitored}
            status={values.gear_selector_status}
            statusName={'gear_selector_status'}
            idDate={'gear_selector_date'}
            date={values.gear_selector_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckTool
            title={'Filtro'}
            switchName={'filter_monitored'}
            monitored={values.filter_monitored}
            status={values.filter_status}
            statusName={'filter_status'}
            idDate={'filter_date'}
            date={values.filter_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckTool
            title={'Correa'}
            switchName={'strap_monitored'}
            monitored={values.strap_monitored}
            status={values.strap_status}
            statusName={'strap_status'}
            idDate={'strap_date'}
            date={values.strap_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckTool
            title={'Estado de cuchillas'}
            switchName={'blade_status_monitored'}
            monitored={values.blade_status_monitored}
            status={values.blade_status_status}
            statusName={'blade_status_status'}
            idDate={'blade_status_date'}
            date={values.blade_status_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckTool
            title={'Pasador seguridad cuchillas'}
            switchName={'blade_safety_monitored'}
            monitored={values.blade_safety_monitored}
            status={values.blade_safety_status}
            statusName={'blade_safety_status'}
            idDate={'blade_safety_date'}
            date={values.blade_safety_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckTool
            title={'Bujia'}
            switchName={'plug_monitored'}
            monitored={values.plug_monitored}
            status={values.plug_status}
            statusName={'plug_status'}
            idDate={'plug_date'}
            date={values.plug_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckTool
            title={'Arnes'}
            switchName={'harness_monitored'}
            monitored={values.harness_monitored}
            status={values.harness_status}
            statusName={'harness_status'}
            idDate={'harness_date'}
            date={values.harness_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckTool
            title={'Estado General Maquina'}
            switchName={'general_status_monitored'}
            monitored={values.general_status_monitored}
            status={values.general_status_status}
            statusName={'general_status_status'}
            idDate={'general_status_date'}
            date={values.general_status_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckTool
            title={'Estado del Eje'}
            switchName={'shaft_status_monitored'}
            monitored={values.shaft_status_monitored}
            status={values.shaft_status_status}
            statusName={'shaft_status_status'}
            idDate={'shaft_status_date'}
            date={values.shaft_status_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckOther
            title={'Otro'}
            switchName={'other_monitored'}
            monitored={values.other_monitored}
            status={values.other_status}
            statusName={'other_status'}
            observationSwitch={values.other_observation_check}
            observationSwitchName={'other_observation_check'}
            textName={'other_observation'}
            value={values.other_observation}
            handleChange={handleChange}
            index={index}
          />
        </HStack>
        {values.image ? (
          <Box w="100%" pb={5} borderRadius={'lg'} boxShadow={'md'} p={2}>
            <RenderImageContainer
              images={[
                {
                  url: values.image,
                  observation: values.observation
                }
              ]}
              getNewArray={(arr) => {
                const img = arr?.at(0)
                handleImage('observation', img.observation)
              }}
              getNewArrayDelete={() => {
                handleImage('deleteImg', '')
              }}
            />
          </Box>
        ) : (
          <UploadImage imgUpload={imgUpload} />
        )}
      </>
    )
  }

  // Formulario motopulverizador
  const motopulverizer = () => {
    return (
      <>
        <Box w="100%" bg="#F2F1FB" pb={5}>
          <Flex spacing={10} align="left" pt="15px">
            <Stack spacing={2} pl={30}>
              <Text fontSize="15px" fontWeight={'bold'} color="#36355F">
                Motopulverizador
              </Text>
            </Stack>
          </Flex>
        </Box>
        <HStack
          spacing="24px"
          overflowX={'scroll'}
          py={3}
          px={2}
          css={{
            '&::-webkit-scrollbar': {
              width: '4px'
            },
            '&::-webkit-scrollbar-track': {
              width: '6px'
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#59BABA',
              borderRadius: '15px'
            }
          }}
        >
          <CheckTool
            title={'Deposito Mezcla'}
            switchName={'mixing_tank_monitored'}
            monitored={values.mixing_tank_monitored}
            status={values.mixing_tank_status}
            statusName={'mixing_tank_status'}
            idDate={'mixing_tank_date'}
            date={values.mixing_tank_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckTool
            title={'Filtro'}
            switchName={'filter_monitored'}
            monitored={values.filter_monitored}
            status={values.filter_status}
            statusName={'filter_status'}
            idDate={'filter_date'}
            date={values.filter_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckTool
            title={'Bujia'}
            switchName={'plug_monitored'}
            monitored={values.plug_monitored}
            status={values.plug_status}
            statusName={'plug_status'}
            idDate={'plug_date'}
            date={values.plug_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckTool
            title={'Piola de Arranque'}
            switchName={'starter_pin_monitored'}
            monitored={values.starter_pin_monitored}
            status={values.starter_pin_status}
            statusName={'starter_pin_status'}
            idDate={'starter_pin_date'}
            date={values.starter_pin_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckTool
            title={'Conector Bujia'}
            switchName={'plug_connector_monitored'}
            monitored={values.plug_connector_monitored}
            status={values.plug_connector_status}
            statusName={'plug_connector_status'}
            idDate={'plug_connector_date'}
            date={values.plug_connector_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckTool
            title={'Sello superior estanque'}
            switchName={'top_seal_monitored'}
            monitored={values.top_seal_monitored}
            status={values.top_seal_status}
            statusName={'top_seal_status'}
            idDate={'top_seal_date'}
            date={values.top_seal_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckTool
            title={'Manillar'}
            switchName={'handlebar_monitored'}
            monitored={values.handlebar_monitored}
            status={values.handlebar_status}
            statusName={'handlebar_status'}
            idDate={'handlebar_date'}
            date={values.handlebar_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckTool
            title={'Piola aceleración'}
            switchName={'acceleration_pin_monitored'}
            monitored={values.acceleration_pin_monitored}
            status={values.acceleration_pin_status}
            statusName={'acceleration_pin_status'}
            idDate={'acceleration_pin_date'}
            date={values.acceleration_pin_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckOther
            title={'Otro'}
            switchName={'other_monitored'}
            monitored={values.other_monitored}
            status={values.other_status}
            statusName={'other_status'}
            observationSwitch={values.other_observation_check}
            observationSwitchName={'other_observation_check'}
            textName={'other_observation'}
            value={values.other_observation}
            handleChange={handleChange}
            index={index}
          />
        </HStack>
        {values.image ? (
          <Box w="100%" pb={5} borderRadius={'lg'} boxShadow={'md'} p={2}>
            <RenderImageContainer
              images={[
                {
                  url: values.image,
                  observation: values.observation
                }
              ]}
              getNewArray={(arr) => {
                const img = arr?.at(0)
                handleImage('observation', img.observation)
              }}
              getNewArrayDelete={() => {
                handleImage('deleteImg', '')
              }}
            />
          </Box>
        ) : (
          <UploadImage imgUpload={imgUpload} />
        )}
      </>
    )
  }

  // Formulario hurrican
  const hurrican = () => {
    return (
      <>
        <Box w="100%" bg="#F2F1FB" pb={5}>
          <Flex spacing={10} align="left" pt="15px">
            <Stack spacing={2} pl={30}>
              <Text fontSize="15px" fontWeight={'bold'} color="#36355F">
                Hurrican
              </Text>
            </Stack>
          </Flex>
        </Box>
        <HStack
          spacing="24px"
          overflowX={'scroll'}
          py={3}
          px={2}
          css={{
            '&::-webkit-scrollbar': {
              width: '4px'
            },
            '&::-webkit-scrollbar-track': {
              width: '6px'
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#59BABA',
              borderRadius: '15px'
            }
          }}
        >
          <CheckTool
            title={'Toma Corriente'}
            switchName={'power_socket_monitored'}
            monitored={values.power_socket_monitored}
            status={values.power_socket_status}
            statusName={'power_socket_status'}
            idDate={'power_socket_date'}
            date={values.power_socket_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckTool
            title={'Estado deposito de producto'}
            switchName={'deposit_status_monitored'}
            monitored={values.deposit_status_monitored}
            status={values.deposit_status_status}
            statusName={'deposit_status_status'}
            idDate={'deposit_status_date'}
            date={values.deposit_status_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckTool
            title={'Pulverizador'}
            switchName={'pulverizer_monitored'}
            monitored={values.pulverizer_monitored}
            status={values.pulverizer_status}
            statusName={'pulverizer_status'}
            idDate={'pulverizer_date'}
            date={values.pulverizer_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckTool
            title={'Sellos'}
            switchName={'seals_monitored'}
            monitored={values.seals_monitored}
            status={values.seals_status}
            statusName={'seals_status'}
            idDate={'seals_date'}
            date={values.seals_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckOther
            title={'Otro'}
            switchName={'other_monitored'}
            monitored={values.other_monitored}
            status={values.other_status}
            statusName={'other_status'}
            observationSwitch={values.other_observation_check}
            observationSwitchName={'other_observation_check'}
            textName={'other_observation'}
            value={values.other_observation}
            handleChange={handleChange}
            index={index}
          />
        </HStack>
        {values.image ? (
          <Box w="100%" pb={5} borderRadius={'lg'} boxShadow={'md'} p={2}>
            <RenderImageContainer
              images={[
                {
                  url: values.image,
                  observation: values.observation
                }
              ]}
              getNewArray={(arr) => {
                const img = arr?.at(0)
                handleImage('observation', img.observation)
              }}
              getNewArrayDelete={() => {
                handleImage('deleteImg', '')
              }}
            />
          </Box>
        ) : (
          <UploadImage imgUpload={imgUpload} />
        )}
      </>
    )
  }

  // Formulario herbicida
  const herbicide = () => {
    return (
      <>
        <Box w="100%" bg="#F2F1FB" pb={5}>
          <Flex spacing={10} align="left" pt="15px">
            <Stack spacing={2} pl={30}>
              <Text fontSize="15px" fontWeight={'bold'} color="#36355F">
                Herbicida
              </Text>
            </Stack>
          </Flex>
        </Box>
        <HStack
          spacing="24px"
          overflowX={'scroll'}
          py={3}
          px={2}
          css={{
            '&::-webkit-scrollbar': {
              width: '4px'
            },
            '&::-webkit-scrollbar-track': {
              width: '6px'
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#59BABA',
              borderRadius: '15px'
            }
          }}
        >
          <CheckTool
            title={'Bomba trasbacije'}
            switchName={'overhead_pump_monitored'}
            monitored={values.overhead_pump_monitored}
            status={values.overhead_pump_status}
            statusName={'overhead_pump_status'}
            idDate={'overhead_pump_date'}
            date={values.overhead_pump_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckTool
            title={'Filtro'}
            switchName={'filter_monitored'}
            monitored={values.filter_monitored}
            status={values.filter_status}
            statusName={'filter_status'}
            idDate={'filter_date'}
            date={values.filter_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckTool
            title={'Bujia'}
            switchName={'plug_monitored'}
            monitored={values.plug_monitored}
            status={values.plug_status}
            statusName={'plug_status'}
            idDate={'plug_date'}
            date={values.plug_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckTool
            title={'Manguera'}
            switchName={'hog_monitored'}
            monitored={values.hog_monitored}
            status={values.hog_status}
            statusName={'hog_status'}
            idDate={'hog_date'}
            date={values.hog_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckTool
            title={'Conector Bujia'}
            switchName={'plug_connector_monitored'}
            monitored={values.plug_connector_monitored}
            status={values.plug_connector_status}
            statusName={'plug_connector_status'}
            idDate={'plug_connector_date'}
            date={values.plug_connector_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckTool
            title={'Estado de estanques'}
            switchName={'tank_status_monitored'}
            monitored={values.tank_status_monitored}
            status={values.tank_status_status}
            statusName={'tank_status_status'}
            idDate={'tank_status_date'}
            date={values.tank_status_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckOther
            title={'Otro'}
            switchName={'other_monitored'}
            monitored={values.other_monitored}
            status={values.other_status}
            statusName={'other_status'}
            observationSwitch={values.other_observation_check}
            observationSwitchName={'other_observation_check'}
            textName={'other_observation'}
            value={values.other_observation}
            handleChange={handleChange}
            index={index}
          />
        </HStack>
        {values.image ? (
          <Box w="100%" pb={5} borderRadius={'lg'} boxShadow={'md'} p={2}>
            <RenderImageContainer
              images={[
                {
                  url: values.image,
                  observation: values.observation
                }
              ]}
              getNewArray={(arr) => {
                const img = arr?.at(0)
                handleImage('observation', img.observation)
              }}
              getNewArrayDelete={() => {
                handleImage('deleteImg', '')
              }}
            />
          </Box>
        ) : (
          <UploadImage imgUpload={imgUpload} />
        )}
      </>
    )
  }

  // Formulario bomba espalda
  const backPump = () => {
    return (
      <>
        <Box w="100%" bg="#F2F1FB" pb={5}>
          <Flex spacing={10} align="left" pt="15px">
            <Stack spacing={2} pl={30}>
              <Text fontSize="15px" fontWeight={'bold'} color="#36355F">
                Bomba espalda
              </Text>
            </Stack>
          </Flex>
        </Box>
        <HStack
          spacing="24px"
          overflowX={'scroll'}
          py={3}
          px={2}
          css={{
            '&::-webkit-scrollbar': {
              width: '4px'
            },
            '&::-webkit-scrollbar-track': {
              width: '6px'
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#59BABA',
              borderRadius: '15px'
            }
          }}
        >
          <CheckTool
            title={'Estado deposito de producto'}
            switchName={'deposit_status_monitored'}
            monitored={values.deposit_status_monitored}
            status={values.deposit_status_status}
            statusName={'deposit_status_status'}
            idDate={'deposit_status_date'}
            date={values.deposit_status_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckTool
            title={'Estado bomba manual'}
            switchName={'pump_status_monitored'}
            monitored={values.pump_status_monitored}
            status={values.pump_status_status}
            statusName={'pump_status_status'}
            idDate={'pump_status_date'}
            date={values.pump_status_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckTool
            title={'Estado de boquillas'}
            switchName={'nozzle_status_monitored'}
            monitored={values.nozzle_status_monitored}
            status={values.nozzle_status_status}
            statusName={'nozzle_status_status'}
            idDate={'nozzle_status_date'}
            date={values.nozzle_status_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckTool
            title={'Estado de arnes'}
            switchName={'harness_status_monitored'}
            monitored={values.harness_status_monitored}
            status={values.harness_status_status}
            statusName={'harness_status_status'}
            idDate={'harness_status_date'}
            date={values.harness_status_date}
            handleChange={handleChange}
            index={index}
          />
          <CheckOther
            title={'Otro'}
            switchName={'other_monitored'}
            monitored={values.other_monitored}
            status={values.other_status}
            statusName={'other_status'}
            observationSwitch={values.other_observation_check}
            observationSwitchName={'other_observation_check'}
            textName={'other_observation'}
            value={values.other_observation}
            handleChange={handleChange}
            index={index}
          />
        </HStack>
        {values.image ? (
          <Box w="100%" pb={5} borderRadius={'lg'} boxShadow={'md'} p={2}>
            <RenderImageContainer
              images={[
                {
                  url: values.image,
                  observation: values.observation
                }
              ]}
              getNewArray={(arr) => {
                const img = arr?.at(0)
                handleImage('observation', img.observation)
              }}
              getNewArrayDelete={() => {
                handleImage('deleteImg', '')
              }}
            />
          </Box>
        ) : (
          <UploadImage imgUpload={imgUpload} />
        )}
      </>
    )
  }

  // Controlador de formularios
  const checks = (type) => {
    switch (type) {
      case 'Motosierra':
        return chainsaw()
        break
      case 'Motosierra en Altura':
        return highChainsaw()
        break
      case 'Cortasetos':
        return hedgeTrimmers()
      case 'Ahoyadora':
        return auger()
        break
      case 'Desbrozadora':
        return brushcutter()
        break
      case 'Rotovator':
        return rotovator()
        break
      case 'Motopulverizador':
        return motopulverizer()
        break
      case 'Hurrican':
        return hurrican()
        break
      case 'Herbicida':
        return herbicide()
        break
      case 'Bomba espalda':
        return backPump()
        break
      default:
        return null
    }
  }

  useEffect(() => {
    if (isLoadingUploadFile) {
      toast({
        duration: 9000 * 9000,
        isClosable: true,
        position: 'bottom-right',
        render: ({ onClose }) => (
          <CustomToast
            text={'Cargando Imagen...'}
            status={'info'}
            onClose={onClose}
          />
        )
      })
      return
    }
    toast.closeAll()
  }, [isLoadingUploadFile])

  return (
    <>
      {tool ? (
        <>
          <Box w="100%" bg="#D1F1F5" pb={5}>
            <Flex spacing={10} align="left" pt="15px">
              <Stack spacing={2} pl={30}>
                <Text fontSize="15px" color="#36355F" fontWeight="bold">
                  Herramienta
                </Text>
                <Text fontSize="25px" color="#36355F">
                  {tool.tool}
                </Text>
              </Stack>
              <Spacer />
              <IconButton
                icon={
                  <Icon
                    fontSize="22"
                    as={DeleteIcon}
                    color="#59BABA"
                    bg="#D1F1F5"
                  />
                }
                bg="#D1F1F5"
                onClick={() => setTool(null)}
              />
            </Flex>
            <Flex spacing={10} align="left" pt="15px">
              <Stack spacing={2} pl={30}>
                <Text fontSize="10px" color="#36355F" fontWeight="bold">
                  Marca
                </Text>
                <Text fontSize="17px" color="#36355F">
                  {tool.brand}
                </Text>
              </Stack>
            </Flex>
          </Box>
          <FormControl id="maintenance">
            <FormLabel>Última mantención</FormLabel>
            <Input
              name="maintenance"
              value={values.maintenance}
              onChange={(event) =>
                handleChange('maintenance', event.target.value, index)
              }
              type="date"
              Icon={<CalendarIcon />}
            />
          </FormControl>
          {checks(tool.tool)}
        </>
      ) : (
        <FormControl id="tool">
          <FormLabel>Seleccione Herramienta</FormLabel>
          <Select
            placeholder="Buscar"
            name="tool"
            onChange={(e) => {
              handleTool(e.target.value)
            }}
            icon={<SearchIcon />}
          >
            {tools
              ? tools.map((t) => (
                  <option value={JSON.stringify(t)} key={t.id}>
                    {t.tool} {t.brand}
                  </option>
                ))
              : []}
          </Select>
        </FormControl>
      )}
    </>
  )
}

export default ToolForm
