const months = [
  'Ene',
  'Feb',
  'Mar',
  'Abr',
  'May',
  'Jun',
  'Jul',
  'Ago',
  'Sep',
  'Oct',
  'Nov',
  'Dic'
]

const formatDate = (date) => {
  const newDate = `${date.substr(8, 2)} ${
    months[parseInt(date.substr(5, 2)) - 1]
  } ${date.substr(0, 4)}`
  return newDate
}

export default formatDate
