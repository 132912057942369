import { useEffect, useState } from 'react'
import { Flex, Text } from '@chakra-ui/react'

const RenderErrorsFormList = ({ errors }) => {
  const [formaterErrors, setFormaterErrors] = useState([])

  useEffect(() => {
    const arr = Object.values(errors)
    setFormaterErrors(arr)
  }, [errors])

  return (
    <Flex direction="column" gap={2} justify="center" alignItems="center">
      {formaterErrors?.map((err) => (
        <Text color="red.500" key={err}>
          {err}
        </Text>
      ))}
    </Flex>
  )
}

export default RenderErrorsFormList
