import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  Flex,
  Stack,
  VStack,
  HStack,
  Box,
  Button,
  Icon,
  Spacer,
  Text,
  Textarea,
  Checkbox,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Grid,
  useDisclosure,
  Center,
  Circle,
  Divider,
  Select,
  Accordion,
  SimpleGrid,
  Image
} from '@chakra-ui/react'
import { AddIcon, InfoIcon } from '@chakra-ui/icons'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import _ from 'lodash'

import { ReportIcon, CalendarIcon } from '../../components/Shared/Icons'
import ActivityForm from './ActivityForm'
import SignatureModal from '../Shared/SignatureModal'
import ConfirmModal from '../Shared/ConfirmModal'
import { useMutateDaily } from '../../hooks/dailyActivities'
import { useVehicle } from '../../hooks/checkVehicles'

const validationSchema = Yup.object().shape({
  area: Yup.number().required('Ingrese área'),
  startDate: Yup.string().required('Ingrese fecha de inicio'),
  startTime: Yup.string().required('Ingrese hora de inicio'),
  ruc: Yup.string().required('Ingrese firma')
})

const Areas = [
  { id: 1, name: 'Área 1' },
  { id: 2, name: 'Área 2' },
  { id: 3, name: 'Área 3' },
  { id: 4, name: 'Área 4' },
  { id: 5, name: 'Área 5' }
]

// Creación de reporte de actividades darias
const DailyActivities = () => {
  const [start, setStart] = useState('AM')
  const [activitiesList, setActivitiesList] = useState([])
  const [imageURL, setImageURL] = useState(null)
  const [confirm, setConfirm] = useState(false)
  const { user } = useSelector((state) => state.auth)
  const { mutate, isLoading, reset } = useMutateDaily('CREATE')
  const {
    data: vehicle,
    isFetching,
    isLoading: loadingGet,
    error
  } = useVehicle(user.id)
  const {
    isOpen: openSignature,
    onOpen: onOpenSignature,
    onClose: closeSignature
  } = useDisclosure()
  const {
    isOpen: openConfirm,
    onOpen: onOpenConfirm,
    onClose: closeConfirm
  } = useDisclosure()

  const formik = useFormik({
    validationSchema,
    validateOnMount: true,
    initialValues: {
      startDate: '',
      startTime: '00:00',
      area: '',
      ruc: ''
    },
    onSubmit: (values) => {
      const formData = {
        date_start: values.startDate,
        time_start: values.startTime,
        period: start,
        area: values.area,
        vehicle: vehicle.id,
        created_by: user.id,
        signature: imageURL,
        ruc: values.ruc,
        activities: activitiesList
      }
      mutate(formData, {
        onSuccess: () => {
          reset()
          formik.resetForm()
          setConfirm(true)
        },
        onError: (err) => {
          reset()
          console.log(err)
        }
      })
    }
  })

  // Agregar una nueva actividad a la lista de actividades
  const handleAdd = () => {
    const newList = _.cloneDeep(activitiesList)
    newList.push({
      description: '',
      amount: 0,
      tool: 1,
      tools: [],
      startKM: '',
      endKM: '',
      activityStatus: '',
      images: []
    })
    setActivitiesList(newList)
  }

  //Actualiza los valores de las actividades, con excepción del número de personas
  const handleChange = (event, position) => {
    const { name, value } = event.target
    const newArray = [...activitiesList]
    newArray[position] = {
      ...newArray[position],
      [name]: value
    }
    setActivitiesList(newArray)
  }

  //Actualiza el valor en los campos que devuelven el valor directo en lugar del event
  const handleChangeValues = (value, attr, position) => {
    const newArray = [...activitiesList]
    newArray[position] = {
      ...newArray[position],
      [attr]: value
    }
    setActivitiesList(newArray)
  }

  //Actualiza el valor en la lista de herramientas
  const handleChangeList = (value, position) => {
    const newArray = [...activitiesList]
    let tempCheck = [...newArray[position].tools, value]
    if (newArray[position].tools.includes(value)) {
      tempCheck = tempCheck.filter((per) => per !== value)
    }
    newArray[position] = {
      ...newArray[position],
      tools: tempCheck
    }
    setActivitiesList(newArray)
  }

  const handleClickSignature = () => {
    onOpenSignature()
  }

  const handleClickConfirm = () => {
    onOpenConfirm()
  }

  //Borra una actividad de la lista
  const handleDeleteActivity = (position) => {
    const newArray = _.cloneDeep(activitiesList)
    newArray.splice(position, 1)
    setActivitiesList(newArray)
  }

  //Maneja la subida de imagen a un elemento de la lista
  const handleImage = (position, url) => {
    const newArray = [...activitiesList]
    const newImage = [...activitiesList[position].images]
    newImage.push({
      url: url.url,
      filename: url.filename,
      observation: ''
    })
    newArray[position] = {
      ...newArray[position],
      images: newImage
    }
    setActivitiesList(newArray)
  }

  //Actualiza los valores de la lista de imagenes
  const handleChangeImages = (value, position1, position2) => {
    let newArray = _.cloneDeep(activitiesList)

    if (value === 'deleteImg') {
      newArray[position1].images.splice(position2, 1)
      setActivitiesList(newArray)
      return
    }

    newArray[position1].images[position2] = {
      ...newArray[position1].images[position2],
      ['observation']: value
    }
    setActivitiesList(newArray)
  }

  return (
    <Stack spacing={8} py={12} px={6}>
      <Box w="100%" bg="#F2F1FB" pb={5}>
        <Flex spacing={10} align="left" pt="15px">
          <Stack spacing={2} pl={30}>
            <Text fontSize="15px" color="#36355F">
              <Icon mr="4" fontSize="22" as={ReportIcon} color="#36355F" />
              Registro de actividades diarias
            </Text>
          </Stack>
        </Flex>
      </Box>
      <FormControl
        id="startDate"
        isInvalid={formik.touched.startDate && Boolean(formik.errors.startDate)}
      >
        <FormLabel>Ingrese Fecha</FormLabel>
        <Input
          name="startDate"
          value={formik.values.startDate}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          type="date"
          Icon={<CalendarIcon />}
        />
        <FormErrorMessage>
          {formik.touched.startDate && formik.errors.startDate}
        </FormErrorMessage>
      </FormControl>
      <FormControl
        id="startTime"
        isInvalid={formik.touched.startTime && Boolean(formik.errors.startTime)}
      >
        <FormLabel>Hora de inicio</FormLabel>
        <Input
          name="startTime"
          value={formik.values.startTime}
          onChange={formik.handleChange}
          type="time"
          onBlur={formik.handleBlur}
        />
        <FormErrorMessage>
          {formik.touched.startTime && formik.errors.startTime}
        </FormErrorMessage>
      </FormControl>
      <FormControl
        id="area"
        isInvalid={formik.touched.area && Boolean(formik.errors.area)}
      >
        <FormLabel>Área</FormLabel>
        <Select
          placeholder="Seleccione"
          name="area"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        >
          {Areas.map((area) => (
            <option value={area.id} key={area.id}>
              {area.name}
            </option>
          ))}
        </Select>
        <FormErrorMessage>
          {formik.touched.area && formik.errors.area}
        </FormErrorMessage>
      </FormControl>
      <FormControl>
        <FormLabel>Móvil asignado</FormLabel>
        {vehicle ? (
          <Text fontSize="15px" color="#36355F">
            {vehicle.plate} {vehicle.model} {vehicle.brand}
          </Text>
        ) : (
          <>
            <Text fontSize="15px" color="#36355F">
              <Icon mr="4" fontSize="22" as={InfoIcon} color="red" />
              Movil no asignado
            </Text>
            <Text fontSize="15px" color="#36355F">
              Completar Checklist de Vehículo
            </Text>
          </>
        )}
      </FormControl>
      <Accordion allowMultiple>
        {activitiesList.map((activity, idx) => (
          <ActivityForm
            key={idx}
            index={idx}
            values={activity}
            handleChange={(event) => handleChange(event, idx)}
            handleChangeValues={handleChangeValues}
            handleDeleteActivity={() => handleDeleteActivity(idx)}
            handleImage={handleImage}
            handleChangeImages={handleChangeImages}
            handleChangeList={handleChangeList}
          />
        ))}
      </Accordion>
      <Stack spacing={10} align={'center'}>
        <Button
          align="center"
          onClick={handleAdd}
          bg={'teal.500'}
          color={'white'}
          _hover={{
            bg: 'teal.600'
          }}
          leftIcon={<AddIcon />}
        >
          Agregar actividad
        </Button>
      </Stack>
      <Box
        // h="50px"
        // w="90%"
        bg="#FFFFFF"
        border="2px"
        borderStyle="solid"
        borderRadius="lg"
        borderColor="#59BABA"
        alignItems="center"
        justifyContent="center"
        display="flex"
      >
        <SimpleGrid columns={1} spacing={10} py={3}>
          <Text fontSize="15px" fontWeight="bold" color="#36355F" m="auto">
            Firma del supervisor
          </Text>
          {imageURL ? (
            <Image src={imageURL} alt="Firma" />
          ) : (
            <FormControl
              id="ruc"
              isInvalid={formik.touched.ruc && Boolean(formik.errors.ruc)}
            >
              <Button
                align="center"
                onClick={handleClickSignature}
                bg="#59BABA"
                color="#FFFFFF"
                border="1px"
                borderColor="#59BABA"
              >
                + Añadir firma
              </Button>
              <FormErrorMessage>
                {formik.touched.ruc && formik.errors.ruc}
              </FormErrorMessage>
            </FormControl>
          )}
        </SimpleGrid>
      </Box>
      <Stack spacing={10} align={'center'}>
        <Button
          align="center"
          isLoading={isLoading}
          onClick={handleClickConfirm}
          bg="#FFFFFF"
          color="#59BABA"
          border="1px"
          borderColor="#59BABA"
          disabled={isLoading || !formik.isValid || !vehicle}
        >
          Guardar documento
        </Button>
        {openConfirm && (
          <ConfirmModal
            isOpen={openConfirm}
            onClose={closeConfirm}
            route={`/documentos`}
            confirm={confirm}
            setConfirm={setConfirm}
            handleSubmit={formik.handleSubmit}
            loading={isLoading}
          />
        )}
      </Stack>
      {openSignature && (
        <SignatureModal
          isOpen={openSignature}
          onClose={closeSignature}
          setImageURL={setImageURL}
          field={'ruc'}
          ruc={formik.values.ruc}
          handleRuc={formik.handleChange}
        />
      )}
    </Stack>
  )
}

export default DailyActivities
