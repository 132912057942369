import axios from '../Axios'

export const login = async (formData) => {
  const { data } = await axios.post(`auth/login`, formData)
  return data
}

export const get_authentication = async () => {
  const { data } = await axios.get(`auth/me`)
  return data
}
