import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  Flex,
  Stack,
  VStack,
  HStack,
  Box,
  Button,
  Icon,
  Spacer,
  Text,
  Textarea,
  Checkbox,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Grid,
  useDisclosure,
  Select,
  IconButton,
  Switch
} from '@chakra-ui/react'
import { AddIcon } from '@chakra-ui/icons'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import _ from 'lodash'

import { ToolIcon, DeleteIcon } from '../../components/Shared/Icons'
import ToolForm from './ToolForm'
import ConfirmModal from '../Shared/ConfirmModal'
import { useMutateChecklist } from '../../hooks/checklistTools'
import { useAllTools } from '../../hooks/tools'

const months = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre'
]

// Crear checklist de herramientas
const Tools = () => {
  const history = useHistory()
  const [toolsList, setToolsList] = useState([])
  const [confirm, setConfirm] = useState(false)
  const { user } = useSelector((state) => state.auth)
  const { mutate, isLoading, reset } = useMutateChecklist('CREATE')
  const {
    isOpen: openConfirm,
    onOpen: onOpenConfirm,
    onClose: closeConfirm
  } = useDisclosure()
  const {
    data: tools,
    isFetching: fetchTools,
    isLoading: loadingTools,
    error: errorTools
  } = useAllTools()

  // Fecha actual
  const currentDate = () => {
    const newDate = new Date()
    const today = `${newDate.getDate()} ${months[newDate.getMonth()].substring(
      0,
      3
    )} ${newDate.getFullYear()}`
    return today
  }

  const formik = useFormik({
    initialValues: {},
    onSubmit: () => {
      const formData = { tools_status: toolsList, created_by: user.id }
      mutate(formData, {
        onSuccess: () => {
          reset()
          // formik.resetForm()
          setConfirm(true)
        },
        onError: (err) => {
          reset()
          console.log(err)
        }
      })
    }
  })

  // Agregar una nueva herramienta al listado
  const handleClick = () => {
    const newList = _.cloneDeep(toolsList)
    newList.push({
      tool: 0,
      maintenance: '',
      mixing_tank_monitored: false,
      mixing_tank_status: '',
      mixing_tank_date: '',
      filter_monitored: false,
      filter_status: '',
      filter_date: '',
      plug_monitored: false,
      plug_status: '',
      plug_date: '',
      starter_pin_monitored: false,
      starter_pin_status: '',
      starter_pin_date: '',
      plug_connector_monitored: false,
      plug_connector_status: '',
      plug_connector_date: '',
      chain_oil_monitored: false,
      chain_oil_status: '',
      chain_oil_date: '',
      chain_status_monitored: false,
      chain_status_status: '',
      chain_status_date: '',
      blade_status_monitored: false,
      blade_status_status: '',
      blade_status_date: '',
      general_status_monitored: false,
      general_status_status: '',
      general_status_date: '',
      other_monitored: false,
      other_status: '',
      other_observation_check: false,
      other_observation: '',
      telescopic_arm_monitored: false,
      telescopic_arm_status: '',
      telescopic_arm_date: '',
      auger_status_monitored: false,
      auger_status_status: '',
      auger_status_date: '',
      spindle_status_monitored: false,
      spindle_status_status: '',
      spindle_status_date: '',
      handlebar_monitored: false,
      handlebar_status: '',
      handlebar_date: '',
      acceleration_pin_monitored: false,
      acceleration_pin_status: '',
      acceleration_pin_date: '',
      projection_shield_monitored: false,
      projection_shield_status: '',
      projection_shield_date: '',
      harness_monitored: false,
      harness_status: '',
      harness_date: '',
      shaft_status_monitored: false,
      shaft_status_status: '',
      shaft_status_date: '',
      fuel_tank_monitored: false,
      fuel_tank_status: '',
      fuel_tank_date: '',
      gear_selector_monitored: false,
      gear_selector_status: '',
      gear_selector_date: '',
      strap_monitored: false,
      strap_status: '',
      strap_date: '',
      blade_safety_monitored: false,
      blade_safety_status: '',
      blade_safety_date: '',
      top_seal_monitored: false,
      top_seal_status: '',
      top_seal_date: '',
      power_socket_monitored: false,
      power_socket_status: '',
      power_socket_date: '',
      deposit_status_monitored: false,
      deposit_status_status: '',
      deposit_status_date: '',
      pulverizer_monitored: false,
      pulverizer_status: '',
      pulverizer_date: '',
      seals_monitored: false,
      seals_status: '',
      seals_date: '',
      overhead_pump_monitored: false,
      overhead_pump_status: '',
      overhead_pump_date: '',
      hog_monitored: false,
      hog_status: '',
      hog_date: '',
      tank_status_monitored: false,
      tank_status_status: '',
      tank_status_date: '',
      pump_status_monitored: false,
      pump_status_status: '',
      pump_status_date: '',
      nozzle_status_monitored: false,
      nozzle_status_status: '',
      nozzle_status_date: '',
      harness_status_monitored: false,
      harness_status_status: '',
      harness_status_date: '',
      image: '',
      observation: ''
    })
    setToolsList(newList)
  }

  const handleClickConfirm = () => {
    onOpenConfirm()
  }

  // Actualizar valor de un atributo
  const handleChange = (attr, value, position) => {
    const newArray = [...toolsList]

    if (attr === 'deleteImg') {
      newArray[position] = {
        ...newArray[position],
        ['image']: '',
        ['observation']: ''
      }
      setToolsList(newArray)
      return
    }

    newArray[position] = {
      ...newArray[position],
      [attr]: value
    }
    setToolsList(newArray)
  }

  const handleBack = () => {
    history.goBack()
  }

  return (
    <Stack spacing={8} py={12} px={6}>
      <Flex>
        <Stack spacing={10} align={'center'}>
          <Button
            onClick={handleBack}
            bg="#FFFFFF"
            color="#666587"
            border="1px"
            borderStyle="solid"
            borderRadius="lg"
            borderColor="#F2F1FB"
          >
            Regresar
          </Button>
        </Stack>
      </Flex>
      <Box w="100%" bg="#F2F1FB" pb={5}>
        <Flex spacing={10} align="left" pt="15px">
          <Stack spacing={2} pl={30}>
            <Text fontSize="15px" color="#36355F">
              <Icon mr="4" fontSize="22" as={ToolIcon} color="#36355F" />
              Checklist de herramientas
            </Text>
          </Stack>
          <Spacer />
          <Stack spacing={2} pr={30}>
            <Text fontSize="10px" color="#36355F">
              {currentDate()}
            </Text>
          </Stack>
        </Flex>
      </Box>
      {toolsList.map((tool, idx) => (
        <ToolForm
          handleChange={handleChange}
          index={idx}
          values={tool}
          tools={tools}
        />
      ))}
      <Stack spacing={10} align={'center'}>
        <Button
          align="center"
          onClick={() => handleClick()}
          bg={'teal.500'}
          color={'white'}
          _hover={{
            bg: 'teal.600'
          }}
          leftIcon={<AddIcon />}
        >
          Agregar Herramienta
        </Button>
      </Stack>
      <Stack spacing={10} align={'center'}>
        <Button
          align="center"
          isLoading={isLoading}
          onClick={handleClickConfirm}
          bg="#FFFFFF"
          color="#59BABA"
          border="1px"
          borderColor="#59BABA"
          disabled={isLoading}
        >
          Guardar documento
        </Button>
        {openConfirm && (
          <ConfirmModal
            isOpen={openConfirm}
            onClose={closeConfirm}
            route={`/visitas`}
            confirm={confirm}
            setConfirm={setConfirm}
            handleSubmit={formik.handleSubmit}
            loading={isLoading}
          />
        )}
      </Stack>
    </Stack>
  )
}

export default Tools
