import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  Flex,
  Stack,
  Heading,
  Button,
  Skeleton,
  Grid,
  GridItem,
  Input,
  Select
} from '@chakra-ui/react'
import { AddIcon } from '@chakra-ui/icons'

import { useClients } from '../../hooks/clients'
import ClientsTable from '../../components/Clients/ClientsTable'
import Pagination from '../../components/Shared/Pagination'
import { updateFilterList } from '../../store/slices/client'

const categories = [
  {
    id: 1,
    name: 'Categoría 1'
  },
  {
    id: 2,
    name: 'Categoría 2'
  },
  {
    id: 3,
    name: 'Categoría 3'
  },
  {
    id: 4,
    name: 'Categoría 4'
  },
  {
    id: 5,
    name: 'Categoría 5'
  },
  {
    id: 6,
    name: 'Categoría 6'
  }
]

const types = [
  { name: 'Empresa', id: 'COMPANY' },
  { name: 'Persona', id: 'PERSON' }
]

// Página de principal de  lista de clientes
const Clients = () => {
  const dispatch = useDispatch()
  const { filterList } = useSelector((state) => state.client)
  const history = useHistory()
  const {
    data: clients,
    isLoading,
    refetch
  } = useClients(filterList.page, {
    search: filterList.search,
    role: filterList.role,
    type: filterList.type
  })

  const handleClick = () => {
    history.push(`/clientes/nuevo`)
  }

  const handleChangeGlobalState = (fieldName, value) => {
    dispatch(
      updateFilterList({
        page: 1,
        [fieldName]: value
      })
    )
  }

  useEffect(() => {
    refetch()
  }, [filterList])

  return (
    <Stack spacing={8} px={6}>
      <Flex bg={'#FFF'}>
        <Stack spacing={8} py={12} px={6}>
          <Stack align={'left'}>
            <Heading fontSize={'4xl'} color={'#36355F'}>
              Clientes
            </Heading>
          </Stack>
          <Stack spacing={10} align={'left'}>
            <Button
              onClick={handleClick}
              bg={'teal.500'}
              color={'white'}
              _hover={{
                bg: 'teal.600'
              }}
              leftIcon={<AddIcon />}
            >
              Agregar cliente
            </Button>
          </Stack>
        </Stack>
      </Flex>
      <Grid display="flex">
        <GridItem display="flex" gap={3} w="100%">
          <Input
            borderRadius="15px"
            value={filterList?.search}
            onChange={(e) =>
              handleChangeGlobalState('search', e.currentTarget.value)
            }
            marginRight={3}
            placeholder="Nombre"
          />
          <Select
            placeholder="Categoría"
            name="category"
            onChange={(e) =>
              handleChangeGlobalState('role', e.currentTarget.value)
            }
            value={filterList?.role}
          >
            {categories.map((cat) => (
              <option value={cat.id}>{cat.name}</option>
            ))}
          </Select>
          <Select
            placeholder="Tipo"
            name="type"
            onChange={(e) =>
              handleChangeGlobalState('type', e.currentTarget.value)
            }
            value={filterList?.type}
          >
            {types.map((t) => (
              <option value={t.id}>{t.name}</option>
            ))}
          </Select>
          {/* <IconButton
            backgroundColor="#59BABA"
            icon={<SearchIcon color="white" />}
            onClick={handleSearch}
          /> */}
        </GridItem>
      </Grid>
      {clients ? (
        <>
          <ClientsTable clients={clients.items} />
          <Grid m="auto" pt="2%" alignSelf={'center'}>
            <Pagination
              totalPages={Math.ceil(clients.total / 10)}
              actualPage={clients.page}
              setPage={(page) => {
                dispatch(
                  updateFilterList({
                    page
                  })
                )
              }}
            />
          </Grid>
        </>
      ) : (
        <Stack>
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
        </Stack>
      )}
    </Stack>
  )
}

export default Clients
