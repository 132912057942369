import { Stack } from '@chakra-ui/react'

import ProfileForm from '../../components/Profile/ProfileForm'
import ChangePassword from '../../components/Profile/ChangePassword'

// Pantalla de perfil de usuario
const Profile = () => {
  return (
    <Stack spacing={5} py={12} px={6}>
      <ProfileForm />
      <ChangePassword />
    </Stack>
  )
}

export default Profile
