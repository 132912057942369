// prettier-ignore
import { useQuery } from 'react-query'
import ApiService from '../../services/Api'

const adapterParamsGetAll = ({ key, endPoint, isPaginated }) => {
  const formatKey = key || endPoint
  const formatEndPoint = isPaginated ? `${endPoint}/all` : endPoint

  return {
    formatKey,
    formatEndPoint
  }
}

async function fetchAllItems({ endPoint, querys, adapterData }) {
  try {
    const data = await ApiService.getAll({
      endPoint,
      querys
    })

    return adapterData ? adapterData(data) : data
  } catch (error) {
    throw new Error('No se pudieron cargar los elementos.')
  }
}

export default function useGetAll({
  key,
  getAllProps,
  isPaginated,
  queryConfig,
  adapterData
}) {
  const { endPoint } = getAllProps
  const { formatKey, formatEndPoint } = adapterParamsGetAll({
    key,
    endPoint,
    isPaginated
  })

  const formatGetAllProps = {
    ...getAllProps,
    adapterData,
    endPoint: formatEndPoint
  }

  const queryKey = [formatKey, formatGetAllProps]

  return useQuery(queryKey, () => fetchAllItems(formatGetAllProps), queryConfig)
}
