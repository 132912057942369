import axios from '../Axios'

export const getChecklists = async (page = 1, formData, size = 10) => {
  const { data } = await axios.get(
    `check-vehicles/all?page=${page}&size=${size}${
      formData.userId ? `&id_user=${formData.userId}` : ''
    }${formData.startDate ? `&start_date=${formData.startDate}` : ''}${
      formData.endDate ? `&end_date=${formData.endDate}` : ''
    }`
  )
  return data
}

export const getOneChecklist = async (checkId) => {
  const { data } = await axios.get(`check-vehicles/get-one?id_check=${checkId}`)
  return data
}

export const createChecklist = async (formData) => {
  const { data } = await axios.post(`check-vehicles/`, formData)
  return data
}

export const updateChecklist = async (formData) => {
  const { data } = await axios.patch(`check-vehicles/`, formData)
  return data
}

export const deleteChecklist = async (checkId) => {
  const { data } = await axios.delete(`check-vehicles/?id=${checkId}`)
  return data
}

export const getVehicle = async (userId) => {
  const { data } = await axios.get(`check-vehicles/vehicle?id_user=${userId}`)
  return data
}
