import { Flex, Spinner } from '@chakra-ui/react'

const SimpleSpinner = ({ ...props }) => {
  return (
    <Flex w="100%" justify="center" alignItems="center">
      <Spinner
        thickness="8px"
        speed="0.65s"
        emptyColor="teal.200"
        color="teal.500"
        size="xl"
        {...props}
      />
    </Flex>
  )
}

export default SimpleSpinner
