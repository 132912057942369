import { useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  Text,
  Button,
  Image,
  Grid,
  Box,
  IconButton,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  VStack
} from '@chakra-ui/react'
import SignatureCanvas from 'react-signature-canvas'

import { ReloadIcon } from '../../components/Shared/Icons'
import rutValidator from '../../validators/validateRut'

// Modal de firma
const SignatureModal = ({
  isOpen,
  onClose,
  setImageURL,
  field,
  ruc,
  handleRuc
}) => {
  const sigCanvas = useRef({})

  const clear = () => sigCanvas.current.clear()
  const save = () => {
    setImageURL(sigCanvas.current.getTrimmedCanvas().toDataURL('image/png'))
    onClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <Text align="center" fontWeight={'bold'}>
            Añadir firma
          </Text>
          <Box
            // h="50px"
            // w="90%"
            bg="#FFFFFF"
            border="1px"
            borderStyle="solid"
            borderRadius="lg"
            borderColor="#E6E6EF"
            alignItems="center"
            justifyContent="center"
            display="flex"
            py={5}
          >
            <VStack>
              <IconButton
                aria-label="Clear"
                icon={<ReloadIcon />}
                onClick={clear}
                bg="#FFFFFF"
              />
              <SignatureCanvas
                ref={sigCanvas}
                penColor="black"
                canvasProps={{
                  width: '300',
                  height: '240%'
                }}
              />
              <FormControl id={field} isInvalid={!rutValidator(ruc)}>
                <FormLabel>RUT</FormLabel>
                <Input
                  name={field}
                  value={ruc}
                  onChange={handleRuc}
                  placeholder="RUT"
                  minLength={9}
                  maxLength={10}
                />
                <FormErrorMessage>
                  Rut inválido, formato aceptado 11111111-1
                </FormErrorMessage>
              </FormControl>
            </VStack>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button
            align="center"
            bg="#F2F1FB"
            color="#59BABA"
            mr={5}
            onClick={onClose}
          >
            Cancelar
          </Button>
          <Button
            align="center"
            bg={'teal.500'}
            color={'white'}
            _hover={{
              bg: 'teal.600'
            }}
            onClick={save}
            disabled={!rutValidator(ruc)}
          >
            Guardar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default SignatureModal
