import axios from '../Axios'

export const getChecklists = async (page = 1, formData, size = 10) => {
  const { data } = await axios.get(
    `check-tools/all?page=${page}&size=${size}${
      formData.userId ? `&id_user=${formData.userId}` : ''
    }${formData.startDate ? `&start_date=${formData.startDate}` : ''}${
      formData.endDate ? `&end_date=${formData.endDate}` : ''
    }`
  )
  return data
}

export const getOneChecklist = async (checkId) => {
  const { data } = await axios.get(`check-tools/get-one?id_check=${checkId}`)
  return data
}

export const createChecklist = async (formData) => {
  const { data } = await axios.post(`check-tools/`, formData)
  return data
}

export const updateChecklist = async (formData) => {
  const { data } = await axios.patch(`check-tools/`, formData)
  return data
}

export const deleteChecklist = async (checkId) => {
  const { data } = await axios.delete(`check-tools/?id=${checkId}`)
  return data
}
