import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  Text,
  Button,
  Image,
  Grid
} from '@chakra-ui/react'

// Modal de eliminación
const DeleteModal = ({ isOpen, onClose, message, handleDelete, selected }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <Text align="center" fontWeight={'bold'}>
            {message}
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button
            align="center"
            bg="#F2F1FB"
            color="#59BABA"
            mr={5}
            onClick={onClose}
          >
            Cancelar
          </Button>
          <Button
            align="center"
            bg={'teal.500'}
            color={'white'}
            _hover={{
              bg: 'teal.600'
            }}
            onClick={() => handleDelete(selected.id)}
          >
            Eliminar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default DeleteModal
