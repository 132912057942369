import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import {
  Flex,
  Stack,
  HStack,
  Box,
  Button,
  Icon,
  Spacer,
  Text,
  Textarea,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  useDisclosure,
  Circle,
  Skeleton,
  useToast,
  InputGroup,
  InputRightElement
} from '@chakra-ui/react'
import { ArrowBackIcon, CheckIcon, DeleteIcon } from '@chakra-ui/icons'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import _ from 'lodash'

import { QualityIcon } from '../../components/Shared/Icons'
import Score from './Score'
import { SignatureModal } from '../Shared/modals'
import ConfirmModal from '../Shared/ConfirmModal'
import { useMutateControl, useControl } from '../../hooks/qualityControl'
import UploadImage from '../Shared/UploadImage'
import RenderImageContainer from '../Shared/RenderImageContainer'
import useUploadImage from '../../hooks/useUploadImage'
import { TextAreaInput } from '../Shared/inputs'

const validationSchema = Yup.object().shape({
  admissionTime: Yup.string().required('Ingrese hora de ingreso'),
  departureTime: Yup.string()
    .required('Ingrese hora de salida')
    .when('admissionTime', (admissionTime, schema) => {
      return schema.test({
        test: (departureTime) => departureTime > admissionTime,
        message: 'Hora de salida debe ser mayor que hora de entrada'
      })
    }),
  ratControl: Yup.bool(),
  map: Yup.number()
    .required('Ingrese evaluación mapa')
    .min(1, 'Evaluación mínima 1'),
  stations: Yup.number()
    .required('Ingrese evaluación estaciones')
    .min(1, 'Evaluación mínima 1'),
  numeration: Yup.number()
    .required('Ingrese evaluación numeración')
    .min(1, 'Evaluación mínima 1'),
  exterior: Yup.number()
    .required('Ingrese evaluación anclaje exterior')
    .min(1, 'Evaluación mínima 1'),
  interior: Yup.number()
    .required('Ingrese evaluación anclaje interior')
    .min(1, 'Evaluación mínima 1'),
  seals: Yup.number()
    .required('Ingrese evaluación sellos')
    .min(1, 'Evaluación mínima 1'),
  cleanliness: Yup.number()
    .required('Ingrese evaluación limpieza')
    .min(1, 'Evaluación mínima 1'),
  capture: Yup.number()
    .required('Ingrese evaluación trampas de captura')
    .min(1, 'Evaluación mínima 1'),
  adhesive: Yup.number()
    .required('Ingrese evaluación trampas adhesivas')
    .min(1, 'Evaluación mínima 1'),
  bait: Yup.number()
    .required('Ingrese evaluación cebos')
    .min(1, 'Evaluación mínima 1'),
  insectControl: Yup.bool(),
  insecticide: Yup.number()
    .required('Ingrese evaluación uso insecticida')
    .min(1),
  uvCleanliness: Yup.number()
    .required('Ingrese evaluación estaciones uv')
    .min(1),
  film: Yup.number()
    .required('Ingrese evaluación láminas')
    .min(1, 'Evaluación mínima 1'),
  ins: Yup.number()
    .required('Ingrese evaluación trampas ins')
    .min(1, 'Evaluación mínima 1'),
  reEntry: Yup.number()
    .required('Ingrese evaluación reingreso')
    .min(1, 'Evaluación mínima 1'),
  insecticideAmount: Yup.number()
    .required('Ingrese evaluación cantidad insecticida')
    .min(0),
  stain: Yup.number()
    .required('Ingrese evaluación manchas')
    .min(1, 'Evaluación mínima 1'),
  sanitizationControl: Yup.bool(),
  sanitizerSeal: Yup.number()
    .required('Ingrese evaluación sellos sanitizantes')
    .min(1, 'Evaluación mínima 1'),
  date: Yup.number()
    .required('Ingrese evaluación sellos con fecha')
    .min(1, 'Evaluación mínima 1'),
  sealStatus: Yup.number()
    .required('Ingrese evaluación estado sellos')
    .min(1, 'Evaluación mínima 1'),
  bathroom: Yup.number()
    .required('Ingrese evaluación sellos en baños')
    .min(1, 'Evaluación mínima 1'),
  technicianControl: Yup.bool(),
  epp: Yup.number(),
  tools: Yup.number(),
  pumps: Yup.number(),
  proof: Yup.number(),
  rodentsActivity: Yup.number(),
  auditable: Yup.number(),
  presentation: Yup.number(),
  vehicleControl: Yup.bool(),
  vehicleCleanliness: Yup.number(),
  checklist: Yup.number(),
  waste: Yup.number(),
  spill: Yup.number(),
  materials: Yup.number(),
  clientControl: Yup.bool(),
  communication: Yup.number()
    .required('Ingrese evaluación comunicación')
    .min(1, 'Evaluación mínima 1'),
  timeliness: Yup.number()
    .required('Ingrese evaluación puntualidad')
    .min(1, 'Evaluación mínima 1'),
  cordiality: Yup.number()
    .required('Ingrese evaluación coordialidad')
    .min(1, 'Evaluación mínima 1'),
  service: Yup.number()
    .required('Ingrese evaluación servicio')
    .min(1, 'Evaluación mínima 1'),
  observations: Yup.string(),
  rucTechnician: Yup.string(),
  technicianName: Yup.string().max(
    30,
    'El nombre debe tener como máximo 30 caracteres'
  ),
  rucSupervisor: Yup.string(),
  generalObservations: Yup.string()
})

const initialValuesImages = {
  rat_control: [],
  insect_control: [],
  sanitization_control: [],
  technician_control: [],
  vehicle_control: []
}

// Formulario control de calidad
const QualityControl = () => {
  const { images, imgUpload, setImagenObj, setImagenSimple } =
    useUploadImage(initialValuesImages)
  const toast = useToast()
  const { controlId } = useParams()
  const history = useHistory()
  const { data: control } = useControl(controlId)
  const [confirm, setConfirm] = useState(false)
  const { client, branch } = useSelector((state) => state.visits)
  const { mutate, isLoading: loadingUpdate, reset } = useMutateControl('UPDATE')
  const {
    isOpen: openConfirm,
    onOpen: onOpenConfirm,
    onClose: closeConfirm
  } = useDisclosure()

  const formik = useFormik({
    validationSchema,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      id: control?.id,
      admissionTime: control?.admission_time,
      departureTime: control?.departure_time,
      admissionPeriod: control?.admission_period,
      departurePeriod: control?.departure_period,
      ratControl: control?.rat_control,
      map: control?.map,
      stations: control?.stations,
      numeration: control?.numeration,
      exterior: control?.exterior,
      interior: control?.interior,
      seals: control?.seals,
      cleanliness: control?.cleanliness,
      capture: control?.capture,
      adhesive: control?.adhesive,
      bait: control?.bait,
      insectControl: control?.insect_control,
      insecticide: control?.insecticide,
      uvCleanliness: control?.uv_cleanliness,
      film: control?.film,
      ins: control?.ins,
      reEntry: control?.re_entry,
      insecticideAmount: control?.insecticide_amount,
      stain: control?.stain,
      sanitizationControl: control?.sanitization_control,
      sanitizerSeal: control?.sanitizer_seal,
      date: control?.date,
      sealStatus: control?.seal_status,
      bathroom: control?.bathroom,
      technicianControl: control?.technician_control,
      epp: control?.epp,
      tools: control?.tools,
      pumps: control?.pumps,
      proof: control?.proof,
      rodentsActivity: control?.rodents_activity,
      auditable: control?.auditable,
      presentation: control?.presentation,
      vehicleControl: control?.vehicle_control,
      vehicleCleanliness: control?.vehicle_cleanliness,
      checklist: control?.checklist,
      waste: control?.waste,
      spill: control?.spill,
      materials: control?.materials,
      clientControl: control?.client_control,
      communication: control?.communication,
      timeliness: control?.timeliness,
      cordiality: control?.cordiality,
      service: control?.service,
      observations: control?.observations,
      rucTechnician: control?.ruc_technician,
      technicianName: control?.technician_name,
      rucSupervisor: control?.ruc_supervisor,
      generalObservations: control?.general_observations,
      technicianSignature: control?.technician_signature,
      supervisorSignature: control?.supervisor_signature,
      aditionalNames: control?.aditional_names || []
    },
    onSubmit: (values) => {
      let formData = {
        id: values.id,
        admission_time: values.admissionTime,
        admission_period: values.admissionPeriod,
        departure_time: values.departureTime,
        departure_period: values.departurePeriod,
        rat_control: values.ratControl,
        map: values.map,
        stations: values.stations,
        numeration: values.numeration,
        exterior: values.exterior,
        interior: values.interior,
        seals: values.seals,
        cleanliness: values.cleanliness,
        capture: values.capture,
        adhesive: values.adhesive,
        bait: values.bait,
        insect_control: values.insectControl,
        insecticide: values.insecticide,
        uv_cleanliness: values.uvCleanliness,
        film: values.film,
        ins: values.ins,
        re_entry: values.reEntry,
        insecticide_amount: values.insecticideAmount,
        stain: values.stain,
        sanitization_control: values.sanitizationControl,
        sanitizer_seal: values.sanitizerSeal,
        date: values.date,
        seal_status: values.sealStatus,
        bathroom: values.bathroom,
        technician_control: values.technicianControl,
        epp: values.epp,
        tools: values.tools,
        pumps: values.pumps,
        proof: values.proof,
        rodents_activity: values.rodentsActivity,
        auditable: values.auditable,
        presentation: values.presentation,
        vehicle_control: values.vehicleControl,
        vehicle_cleanliness: values.vehicleCleanliness,
        checklist: values.checklist,
        waste: values.waste,
        spill: values.spill,
        materials: values.materials,
        client_control: values.clientControl,
        communication: values.communication,
        timeliness: values.timeliness,
        cordiality: values.cordiality,
        service: values.service,
        technician_signature: values.technicianSignature,
        ruc_technician: values.rucTechnician,
        technician_name: values.technicianName,
        supervisor_signature: values.supervisorSignature,
        ruc_supervisor: values.rucSupervisor,
        observations: values.observations,
        general_observations: values.generalObservations,
        aditional_names: values.aditionalNames
      }

      Object.keys(images).forEach((key) => {
        if (images[key].length > 0) {
          formData[`${key}_images`] = images[key]
        }
      })

      mutate(formData, {
        onSuccess: () => {
          reset()
          formik.resetForm()
          setConfirm(true)
        },
        onError: (err) => {
          reset()
          console.log(err)
          if (err.request.response.includes('ERROR_SAVING_QUALITY_CONTROL')) {
            toast({
              title: `Error al guardar datos`,
              status: 'error',
              isClosable: true
            })
          } else if (err.request.response.includes('ERROR_GENERATING_PDF')) {
            toast({
              title: `Error al crear PDF, datos guardados sin problemas`,
              status: 'error',
              isClosable: true
            })
          } else {
            toast({
              title: `Error indefinido`,
              status: 'error',
              isClosable: true
            })
          }
        }
      })
    }
  })

  const handleBack = () => {
    history.push(`/visitas/${control.id_visit}`)
  }

  // Calcular promedio
  const avg = (values) => {
    const average = values.reduce((a, b) => a + b, 0) / values.length
    return average
  }

  const handleClickConfirm = () => {
    onOpenConfirm()
  }

  // Guardar firmas en el estado
  useEffect(() => {
    if (control) {
      setImagenSimple({
        rat_control:
          control?.rat_control_images || initialValuesImages.rat_control,
        insect_control:
          control?.insect_control_images || initialValuesImages.insect_control,
        sanitization_control:
          control?.sanitization_control_images ||
          initialValuesImages.sanitization_control,
        technician_control:
          control?.technician_control_images ||
          initialValuesImages.technician_control,
        vehicle_control:
          control?.vehicle_control_images || initialValuesImages.vehicle_control
      })
    }
  }, [control])

  const changeTime = (fieldName, value) => {
    const dict = {
      departureTime: 'departurePeriod',
      admissionTime: 'admissionPeriod'
    }

    const partsHours = value.split(':')
    const hourNum = parseInt(partsHours[0], 10)

    const isAm = Boolean(hourNum >= 0 && hourNum < 12)

    formik.setFieldValue(fieldName, value)
    formik.setFieldValue(dict[fieldName], isAm ? 'AM' : 'PM')
  }

  const addNewNameField = () => {
    formik.setFieldValue('aditionalNames', [
      ...formik.values.aditionalNames,
      { name: '' }
    ])
  }

  const removeAditionalName = (index) => {
    const newNames = formik.values.aditionalNames.filter((_, i) => i !== index)
    formik.setFieldValue('aditionalNames', newNames)
  }

  return (
    <Stack spacing={8} py={12} px={6}>
      <Flex spacing={10} align="right" pt="15px">
        <Button
          onClick={handleBack}
          bg="#FFFFFF"
          color="#666587"
          border="1px"
          borderStyle="solid"
          borderRadius="lg"
          borderColor="#F2F1FB"
          leftIcon={<ArrowBackIcon />}
        >
          Documentos de visita
        </Button>
      </Flex>
      <Box w="100%" bg="#F2F1FB80" pb={5}>
        <Flex spacing={10} align="left" pt="15px">
          <Stack spacing={2} pl={30}>
            <Text fontSize="10px" color="#36355F">
              Cliente
            </Text>
            <Text fontSize="17px" fontWeight="bold" color="#36355F">
              {client}
            </Text>
          </Stack>
          <Stack spacing={2} pl={30}>
            <Text fontSize="10px" color="#36355F">
              Sucursal
            </Text>
            <Text fontSize="17px" fontWeight="bold" color="#36355F">
              {branch}
            </Text>
          </Stack>
        </Flex>
      </Box>
      {control ? (
        <>
          <Box w="100%" bg="#F2F1FB" pb={5}>
            <Flex spacing={10} align="left" pt="15px">
              <Stack spacing={2} pl={30}>
                <Text fontSize="15px" color="#36355F">
                  <Icon mr="4" fontSize="22" as={QualityIcon} color="#36355F" />
                  Control de calidad
                </Text>
              </Stack>
              <Spacer />
              <Stack spacing={2} pr={30}>
                <Text fontSize="10px" color="#36355F">
                  Serial {control.id}
                </Text>
              </Stack>
            </Flex>
          </Box>
          <Box w="100%" bg="#D1F1F5" pb={5} borderRadius="20px">
            <Flex spacing={10} align="left" pt="15px">
              <Stack spacing={2} pl={30}>
                <Text fontSize="17px" fontWeight="bold" color="#36355F">
                  Datos generales
                </Text>
              </Stack>
            </Flex>
          </Box>
          <FormControl
            id="admissionTime"
            isInvalid={
              formik.touched.admissionTime &&
              Boolean(formik.errors.admissionTime)
            }
          >
            <FormLabel>Hora de ingreso</FormLabel>
            <Input
              name="admissionTime"
              value={formik.values.admissionTime}
              onChange={(e) => changeTime('admissionTime', e.target.value)}
              type="time"
              onBlur={formik.handleBlur}
            />
            <FormErrorMessage>
              {formik.touched.admissionTime && formik.errors.admissionTime}
            </FormErrorMessage>
          </FormControl>
          <FormControl
            id="departureTime"
            isInvalid={
              formik.touched.departureTime &&
              Boolean(formik.errors.departureTime)
            }
          >
            <FormLabel>Hora de salida</FormLabel>
            <Input
              name="departureTime"
              value={formik.values.departureTime}
              onChange={(e) => changeTime('departureTime', e.target.value)}
              type="time"
              onBlur={formik.handleBlur}
            />
            <FormErrorMessage>
              {formik.touched.departureTime && formik.errors.departureTime}
            </FormErrorMessage>
          </FormControl>
          <Box w="100%" bg="#D1F1F5" pb={5} borderRadius="20px">
            <Flex spacing={10} align="left" pt="15px" pr="5px">
              <Stack spacing={2} pl={30}>
                <Text fontSize="17px" fontWeight="bold" color="#36355F">
                  Evaluación servicio desratización
                </Text>
              </Stack>
              <Spacer />
              <Circle
                size="30px"
                bg={formik.values.ratControl ? 'green' : '#E6E6EF'}
                color="white"
                as="button"
                onClick={() =>
                  formik.setFieldValue('ratControl', !formik.values.ratControl)
                }
              >
                <CheckIcon />
              </Circle>
            </Flex>
          </Box>

          {formik.values.ratControl && (
            <Flex direction="column" gap={2}>
              <HStack spacing="24px" overflowX={'scroll'} py={3} px={2}>
                <Score
                  title={'Presentar mapa'}
                  score={formik.values.map}
                  setScore={formik.setFieldValue}
                  name={'map'}
                />
                <Score
                  title={'N° estaciones coincidente con el mapa'}
                  score={formik.values.stations}
                  setScore={formik.setFieldValue}
                  name={'stations'}
                />
                <Score
                  title={'Presentan numeración las estaciones'}
                  score={formik.values.numeration}
                  setScore={formik.setFieldValue}
                  name={'numeration'}
                />
                <Score
                  title={'Anclaje adecuado de estaciones exteriores'}
                  score={formik.values.exterior}
                  setScore={formik.setFieldValue}
                  name={'exterior'}
                />
                <Score
                  title={'Anclaje adecuado de estaciones interiores'}
                  score={formik.values.interior}
                  setScore={formik.setFieldValue}
                  name={'interior'}
                />
                <Score
                  title={'Sellos actualizados'}
                  score={formik.values.seals}
                  setScore={formik.setFieldValue}
                  name={'seals'}
                />
                <Score
                  title={'Limpieza de la estación'}
                  score={formik.values.cleanliness}
                  setScore={formik.setFieldValue}
                  name={'cleanliness'}
                />
                <Score
                  title={'Trampas de captura en buen estado'}
                  score={formik.values.capture}
                  setScore={formik.setFieldValue}
                  name={'capture'}
                />
                <Score
                  title={'Trampa adhesiva en buen estado'}
                  score={formik.values.adhesive}
                  setScore={formik.setFieldValue}
                  name={'adhesive'}
                />
                <Score
                  title={'Cebos rodenticidas en buen estado'}
                  score={formik.values.bait}
                  setScore={formik.setFieldValue}
                  name={'bait'}
                />
              </HStack>
              <Box
                w="100%"
                bg="#FFFFFF"
                pb={5}
                borderRadius="20px"
                border={'solid'}
                borderColor="#D1F1F5"
              >
                <Flex spacing={10} align="left" pt="15px" pr="5px">
                  <Stack spacing={2} pl={30}>
                    <Text fontSize="17px" fontWeight="bold" color="#36355F">
                      Promedio desratización
                    </Text>
                  </Stack>
                  <Spacer />
                  <Stack spacing={2} pr={10}>
                    <Text fontSize="25px" fontWeight="bold" color="#59BABA">
                      {avg([
                        formik.values.bait,
                        formik.values.adhesive,
                        formik.values.capture,
                        formik.values.cleanliness,
                        formik.values.seals,
                        formik.values.interior,
                        formik.values.exterior,
                        formik.values.numeration,
                        formik.values.stations
                      ]).toFixed(2)}
                    </Text>
                  </Stack>
                </Flex>
              </Box>
              <UploadImage
                imgUpload={(formImage) => imgUpload(formImage, 'rat_control')}
              />
              {images?.rat_control?.length > 0 && (
                <RenderImageContainer
                  images={images?.rat_control}
                  getNewArray={(arr) => setImagenObj('rat_control', arr)}
                  getNewArrayDelete={(arr) => setImagenObj('rat_control', arr)}
                />
              )}
            </Flex>
          )}

          <Box w="100%" bg="#D1F1F5" pb={5} borderRadius="20px">
            <Flex spacing={10} align="left" pt="15px" pr="5px">
              <Stack spacing={2} pl={30}>
                <Text fontSize="17px" fontWeight="bold" color="#36355F">
                  Evaluación Servicio de Desinsectación
                </Text>
              </Stack>
              <Spacer />
              <Circle
                size="30px"
                bg={formik.values.insectControl ? 'green' : '#E6E6EF'}
                color="white"
                as="button"
                onClick={() =>
                  formik.setFieldValue(
                    'insectControl',
                    !formik.values.insectControl
                  )
                }
              >
                <CheckIcon />
              </Circle>
            </Flex>
          </Box>
          {formik.values.insectControl && (
            <Flex direction="column" gap={2}>
              <HStack spacing="24px" overflowX={'scroll'} py={3} px={2}>
                <Score
                  title={'Adecuado uso de insecticida'}
                  score={formik.values.insecticide}
                  setScore={formik.setFieldValue}
                  name={'insecticide'}
                />
                <Score
                  title={'Presenta estaciones de monitoreo Equipos UV limpio'}
                  score={formik.values.uvCleanliness}
                  setScore={formik.setFieldValue}
                  name={'uvCleanliness'}
                />
                <Score
                  title={'Láminas de Equipo UV en buen estado'}
                  score={formik.values.film}
                  setScore={formik.setFieldValue}
                  name={'film'}
                />
                <Score
                  title={'Adecuada disposición trampas de monitoreo ins'}
                  score={formik.values.ins}
                  setScore={formik.setFieldValue}
                  name={'ins'}
                />
                <Score
                  title={'Informa tiempo de reingreso'}
                  score={formik.values.reEntry}
                  setScore={formik.setFieldValue}
                  name={'reEntry'}
                />
                <Score
                  title={'Informa cantidad de insecticida'}
                  score={formik.values.insecticideAmount}
                  setScore={formik.setFieldValue}
                  name={'insecticideAmount'}
                />
                <Score
                  title={'Manchas en superficie aplicada'}
                  score={formik.values.stain}
                  setScore={formik.setFieldValue}
                  name={'stain'}
                />
              </HStack>
              <Box
                w="100%"
                bg="#FFFFFF"
                pb={5}
                borderRadius="20px"
                border={'solid'}
                borderColor="#D1F1F5"
              >
                <Flex spacing={10} align="left" pt="15px" pr="5px">
                  <Stack spacing={2} pl={30}>
                    <Text fontSize="17px" fontWeight="bold" color="#36355F">
                      Promedio desinsectación
                    </Text>
                  </Stack>
                  <Spacer />
                  <Stack spacing={2} pr={10}>
                    <Text fontSize="25px" fontWeight="bold" color="#59BABA">
                      {avg([
                        formik.values.stain,
                        formik.values.insecticideAmount,
                        formik.values.reEntry,
                        formik.values.ins,
                        formik.values.film,
                        formik.values.uvCleanliness,
                        formik.values.insecticide
                      ]).toFixed(2)}
                    </Text>
                  </Stack>
                </Flex>
              </Box>
              <UploadImage
                imgUpload={(formImage) =>
                  imgUpload(formImage, 'insect_control')
                }
              />
              {images?.insect_control?.length > 0 && (
                <RenderImageContainer
                  images={images?.insect_control}
                  getNewArray={(arr) => setImagenObj('insect_control', arr)}
                  getNewArrayDelete={(arr) =>
                    setImagenObj('insect_control', arr)
                  }
                />
              )}
            </Flex>
          )}
          <Box w="100%" bg="#D1F1F5" pb={5} borderRadius="20px">
            <Flex spacing={10} align="left" pt="15px" pr="5px">
              <Stack spacing={2} pl={30}>
                <Text fontSize="17px" fontWeight="bold" color="#36355F">
                  Evaluación servicio de sanitización
                </Text>
              </Stack>
              <Spacer />
              <Circle
                size="30px"
                bg={formik.values.sanitizationControl ? 'green' : '#E6E6EF'}
                color="white"
                as="button"
                onClick={() =>
                  formik.setFieldValue(
                    'sanitizationControl',
                    !formik.values.sanitizationControl
                  )
                }
              >
                <CheckIcon />
              </Circle>
            </Flex>
          </Box>
          {formik.values.sanitizationControl && (
            <Flex direction="column" gap={2}>
              <HStack spacing="24px" overflowX={'scroll'} py={3} px={2}>
                <Score
                  title={'Presenta sellos sanitizantes'}
                  score={formik.values.sanitizerSeal}
                  setScore={formik.setFieldValue}
                  name={'sanitizerSeal'}
                />
                <Score
                  title={'Sellos con fecha actualizada'}
                  score={formik.values.date}
                  setScore={formik.setFieldValue}
                  name={'date'}
                />
                <Score
                  title={'Sellos en buen estado'}
                  score={formik.values.sealStatus}
                  setScore={formik.setFieldValue}
                  name={'sealStatus'}
                />
                <Score
                  title={'Sellos en todos los baños'}
                  score={formik.values.bathroom}
                  setScore={formik.setFieldValue}
                  name={'bathroom'}
                />
              </HStack>
              <Box
                w="100%"
                bg="#FFFFFF"
                pb={5}
                borderRadius="20px"
                border={'solid'}
                borderColor="#D1F1F5"
              >
                <Flex spacing={10} align="left" pt="15px" pr="5px">
                  <Stack spacing={2} pl={30}>
                    <Text fontSize="17px" fontWeight="bold" color="#36355F">
                      Promedio sanitización
                    </Text>
                  </Stack>
                  <Spacer />
                  <Stack spacing={2} pr={10}>
                    <Text fontSize="25px" fontWeight="bold" color="#59BABA">
                      {avg([
                        formik.values.bathroom,
                        formik.values.sealStatus,
                        formik.values.date,
                        formik.values.sanitizerSeal
                      ]).toFixed(2)}
                    </Text>
                  </Stack>
                </Flex>
              </Box>
              <UploadImage
                imgUpload={(formImage) =>
                  imgUpload(formImage, 'sanitization_control')
                }
              />
              {images?.sanitization_control?.length > 0 && (
                <RenderImageContainer
                  images={images?.sanitization_control}
                  getNewArray={(arr) =>
                    setImagenObj('sanitization_control', arr)
                  }
                  getNewArrayDelete={(arr) =>
                    setImagenObj('sanitization_control', arr)
                  }
                />
              )}
            </Flex>
          )}
          <Box w="100%" bg="#D1F1F5" pb={5} borderRadius="20px">
            <Flex spacing={10} align="left" pt="15px" pr="5px">
              <Stack spacing={2} pl={30}>
                <Text fontSize="17px" fontWeight="bold" color="#36355F">
                  Inspección Técnico
                </Text>
              </Stack>
              <Spacer />
              <Circle
                size="30px"
                bg={formik.values.technicianControl ? 'green' : '#E6E6EF'}
                color="white"
                as="button"
                onClick={() =>
                  formik.setFieldValue(
                    'technicianControl',
                    !formik.values.technicianControl
                  )
                }
              >
                <CheckIcon />
              </Circle>
            </Flex>
          </Box>
          {formik.values.technicianControl && (
            <Flex direction="column" gap={2}>
              <HStack spacing="24px" overflowX={'scroll'} py={3} px={2}>
                <Score
                  title={'Correcto uso de EPP'}
                  score={formik.values.epp}
                  setScore={formik.setFieldValue}
                  name={'epp'}
                />
                <Score
                  title={'Presenta Herramientas necesarias'}
                  score={formik.values.tools}
                  setScore={formik.setFieldValue}
                  name={'tools'}
                />
                <Score
                  title={'Equipo de trabajo en buen estado (bombas)'}
                  score={formik.values.pumps}
                  setScore={formik.setFieldValue}
                  name={'pumps'}
                />
                <Score
                  title={'Presenta comprobante'}
                  score={formik.values.proof}
                  setScore={formik.setFieldValue}
                  name={'proof'}
                />
                <Score
                  title={'Presenta planilla de actividad roedores'}
                  score={formik.values.rodentsActivity}
                  setScore={formik.setFieldValue}
                  name={'rodentsActivity'}
                />
                <Score
                  title={'Presenta planilla auditable'}
                  score={formik.values.auditable}
                  setScore={formik.setFieldValue}
                  name={'auditable'}
                />
                <Score
                  title={'Presentación personal'}
                  score={formik.values.presentation}
                  setScore={formik.setFieldValue}
                  name={'presentation'}
                />
              </HStack>
              <Box
                w="100%"
                bg="#FFFFFF"
                pb={5}
                borderRadius="20px"
                border={'solid'}
                borderColor="#D1F1F5"
              >
                <Flex spacing={10} align="left" pt="15px" pr="5px">
                  <Stack spacing={2} pl={30}>
                    <Text fontSize="17px" fontWeight="bold" color="#36355F">
                      Promedio técnico
                    </Text>
                  </Stack>
                  <Spacer />
                  <Stack spacing={2} pr={10}>
                    <Text fontSize="25px" fontWeight="bold" color="#59BABA">
                      {avg([
                        formik.values.presentation,
                        formik.values.auditable,
                        formik.values.rodentsActivity,
                        formik.values.proof,
                        formik.values.pumps,
                        formik.values.tools,
                        formik.values.epp
                      ]).toFixed(2)}
                    </Text>
                  </Stack>
                </Flex>
              </Box>
              <UploadImage
                imgUpload={(formImage) =>
                  imgUpload(formImage, 'technician_control')
                }
              />
              {images?.technician_control?.length > 0 && (
                <RenderImageContainer
                  images={images?.technician_control}
                  getNewArray={(arr) => setImagenObj('technician_control', arr)}
                  getNewArrayDelete={(arr) =>
                    setImagenObj('technician_control', arr)
                  }
                />
              )}
            </Flex>
          )}
          <Box w="100%" bg="#D1F1F5" pb={5} borderRadius="20px">
            <Flex spacing={10} align="left" pt="15px" pr="5px">
              <Stack spacing={2} pl={30}>
                <Text fontSize="17px" fontWeight="bold" color="#36355F">
                  Inspección vehiculo
                </Text>
              </Stack>
              <Spacer />
              <Circle
                size="30px"
                bg={formik.values.vehicleControl ? 'green' : '#E6E6EF'}
                color="white"
                as="button"
                onClick={() =>
                  formik.setFieldValue(
                    'vehicleControl',
                    !formik.values.vehicleControl
                  )
                }
              >
                <CheckIcon />
              </Circle>
            </Flex>
          </Box>
          {formik.values.vehicleControl && (
            <Flex direction="column" gap={2}>
              <HStack spacing="24px" overflowX={'scroll'} py={3} px={2}>
                <Score
                  title={'Limpieza del vehículo'}
                  score={formik.values.vehicleCleanliness}
                  setScore={formik.setFieldValue}
                  name={'vehicleCleanliness'}
                />
                <Score
                  title={'Checklist al día'}
                  score={formik.values.checklist}
                  setScore={formik.setFieldValue}
                  name={'checklist'}
                />
                <Score
                  title={'Contenedor residuos líquidos/sólidos'}
                  score={formik.values.waste}
                  setScore={formik.setFieldValue}
                  name={'waste'}
                />
                <Score
                  title={'Kit de derrame'}
                  score={formik.values.spill}
                  setScore={formik.setFieldValue}
                  name={'spill'}
                />
                <Score
                  title={'Materiales necesarios para el servicio'}
                  score={formik.values.materials}
                  setScore={formik.setFieldValue}
                  name={'materials'}
                />
              </HStack>
              <Box
                w="100%"
                bg="#FFFFFF"
                pb={5}
                borderRadius="20px"
                border={'solid'}
                borderColor="#D1F1F5"
              >
                <Flex spacing={10} align="left" pt="15px" pr="5px">
                  <Stack spacing={2} pl={30}>
                    <Text fontSize="17px" fontWeight="bold" color="#36355F">
                      Promedio vehiculo
                    </Text>
                  </Stack>
                  <Spacer />
                  <Stack spacing={2} pr={10}>
                    <Text fontSize="25px" fontWeight="bold" color="#59BABA">
                      {avg([
                        formik.values.materials,
                        formik.values.spill,
                        formik.values.waste,
                        formik.values.checklist,
                        formik.values.vehicleCleanliness
                      ]).toFixed(2)}
                    </Text>
                  </Stack>
                </Flex>
              </Box>
              <UploadImage
                imgUpload={(formImage) =>
                  imgUpload(formImage, 'vehicle_control')
                }
              />
              {images?.vehicle_control?.length > 0 && (
                <RenderImageContainer
                  images={images?.vehicle_control}
                  getNewArray={(arr) => setImagenObj('vehicle_control', arr)}
                  getNewArrayDelete={(arr) =>
                    setImagenObj('vehicle_control', arr)
                  }
                />
              )}
            </Flex>
          )}
          <Box w="100%" bg="#D1F1F5" pb={5} borderRadius="20px">
            <Flex spacing={10} align="left" pt="15px" pr="5px">
              <Stack spacing={2} pl={30}>
                <Text fontSize="17px" fontWeight="bold" color="#36355F">
                  Evaluación del cliente
                </Text>
              </Stack>
              <Spacer />
              <Circle
                size="30px"
                bg={formik.values.clientControl ? 'green' : '#E6E6EF'}
                color="white"
                as="button"
                onClick={() =>
                  formik.setFieldValue(
                    'clientControl',
                    !formik.values.clientControl
                  )
                }
              >
                <CheckIcon />
              </Circle>
            </Flex>
          </Box>
          {formik.values.clientControl && (
            <>
              <HStack spacing="24px" overflowX={'scroll'} py={3} px={2}>
                <Score
                  title={'El técnico comunica su trabajo'}
                  score={formik.values.communication}
                  setScore={formik.setFieldValue}
                  name={'communication'}
                />
                <Score
                  title={'El técnico llega puntual al servicio'}
                  score={formik.values.timeliness}
                  setScore={formik.setFieldValue}
                  name={'timeliness'}
                />
                <Score
                  title={'Cordialidad del técnico'}
                  score={formik.values.cordiality}
                  setScore={formik.setFieldValue}
                  name={'cordiality'}
                />
                <Score
                  title={'Evaluación del servicio'}
                  score={formik.values.service}
                  setScore={formik.setFieldValue}
                  name={'service'}
                />
              </HStack>
              <FormControl
                id="observations"
                isInvalid={
                  formik.touched.observations &&
                  Boolean(formik.errors.observations)
                }
              >
                <FormLabel>Observaciones</FormLabel>
                <Textarea
                  name="observations"
                  value={formik.values.observations}
                  onChange={formik.handleChange}
                  placeholder="Observaciones"
                  h="20%"
                />
                <FormErrorMessage>
                  {formik.touched.observations && formik.errors.observations}
                </FormErrorMessage>
              </FormControl>
            </>
          )}
          <Box w="100%" bg="#D1F1F5" pb={5} borderRadius="20px">
            <Flex spacing={10} align="left" pt="15px" pr="5px">
              <Stack spacing={2} pl={30}>
                <Text fontSize="17px" fontWeight="bold" color="#36355F">
                  Promedio de evaluación del cliente
                </Text>
              </Stack>
              <Spacer />
              <Stack spacing={2} pr={10}>
                <Text fontSize="25px" fontWeight="bold" color="#59BABA">
                  {avg([
                    formik.values.service,
                    formik.values.cordiality,
                    formik.values.timeliness,
                    formik.values.communication
                  ]).toFixed(2)}
                </Text>
              </Stack>
            </Flex>
          </Box>
          <Box w="100%" bg="#D1F1F5" pb={5} borderRadius="20px">
            <Flex spacing={10} align="left" pt="15px" pr="5px">
              <Stack spacing={2} pl={30}>
                <Text fontSize="17px" fontWeight="bold" color="#36355F">
                  Promedio de evaluación de supervisión
                </Text>
              </Stack>
              <Spacer />
              <Stack spacing={2} pr={10}>
                <Text fontSize="25px" fontWeight="bold" color="#59BABA">
                  {avg([
                    formik.values.materials,
                    formik.values.spill,
                    formik.values.waste,
                    formik.values.checklist,
                    formik.values.vehicleCleanliness,
                    formik.values.presentation,
                    formik.values.auditable,
                    formik.values.rodentsActivity,
                    formik.values.proof,
                    formik.values.pumps,
                    formik.values.tools,
                    formik.values.epp,
                    formik.values.bathroom,
                    formik.values.sealStatus,
                    formik.values.date,
                    formik.values.sanitizerSeal,
                    formik.values.bait,
                    formik.values.adhesive,
                    formik.values.capture,
                    formik.values.cleanliness,
                    formik.values.seals,
                    formik.values.interior,
                    formik.values.exterior,
                    formik.values.numeration,
                    formik.values.stations,
                    formik.values.stain,
                    formik.values.insecticideAmount,
                    formik.values.reEntry,
                    formik.values.ins,
                    formik.values.film,
                    formik.values.uvCleanliness,
                    formik.values.insecticide
                  ]).toFixed(2)}
                </Text>
              </Stack>
            </Flex>
          </Box>
          <Text fontSize="17px" fontWeight="bold" color="#36355F">
            Tecnicos evaluados
          </Text>
          {formik.values.aditionalNames.map((name, index) => (
            <Flex key={index} w="100%">
              <FormControl>
                <FormLabel>Nombre {index + 1}</FormLabel>
                <InputGroup size="md">
                  <Input
                    name="name"
                    autoFocus
                    value={formik.values.aditionalNames[index].name || ''}
                    onChange={(e) =>
                      formik.setFieldValue(
                        `aditionalNames.${index}.name`,
                        e.target.value
                      )
                    }
                  />
                  <InputRightElement>
                    <Button onClick={() => removeAditionalName(index)}>
                      <DeleteIcon />
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
            </Flex>
          ))}
          <Flex w="100%" justify="flex-end">
            <Button
              isFullWidth
              onClick={addNewNameField}
              disabled={formik.values.aditionalNames.length === 4}
            >
              Agregar nuevo nombre
            </Button>
          </Flex>
          {/* <SignatureModal
            label="Firma del técnico"
            formik={formik}
            values={{
              ruc: formik.values?.rucTechnician,
              fullName: formik.values?.technicianName,
              signature: formik.values?.technicianSignature
            }}
            fieldsNames={{
              ruc: 'rucTechnician',
              fullName: 'technicianName',
              signature: 'technicianSignature'
            }}
            fieldRequired={{
              fullName: true
            }}
          />
          <SignatureModal
            label="Firma del supervisor"
            formik={formik}
            values={{
              ruc: formik.values?.rucSupervisor,
              signature: formik.values?.supervisorSignature
            }}
            fieldsNames={{
              ruc: 'rucSupervisor',
              signature: 'supervisorSignature'
            }}
          /> */}
          <Flex w="100%">
            <TextAreaInput
              name="generalObservations"
              label="Observaciones Generales"
              placeholder="Observaciones generales"
              value={formik.values.generalObservations}
              onChange={formik.handleChange}
              errors={
                formik.touched.generalObservations &&
                Boolean(formik.errors.generalObservations)
              }
              errorMsg={formik.errors.generalObservations}
            />
          </Flex>
          <Stack spacing={10} align={'center'}>
            <Button
              align="center"
              isLoading={loadingUpdate}
              onClick={handleClickConfirm}
              bg="#FFFFFF"
              color="#59BABA"
              border="1px"
              borderColor="#59BABA"
              disabled={loadingUpdate || !formik.isValid}
            >
              Guardar documento
            </Button>
            {openConfirm && (
              <ConfirmModal
                isOpen={openConfirm}
                onClose={closeConfirm}
                route={`/visitas/${control.id_visit}`}
                confirm={confirm}
                setConfirm={setConfirm}
                handleSubmit={formik.handleSubmit}
                loading={loadingUpdate}
              />
            )}
          </Stack>
        </>
      ) : (
        <Stack>
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
        </Stack>
      )}
    </Stack>
  )
}

export default QualityControl
