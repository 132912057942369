import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import {
  Flex,
  Stack,
  VStack,
  HStack,
  Box,
  Button,
  Icon,
  Spacer,
  Text,
  Textarea,
  Checkbox,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Grid,
  useDisclosure,
  Select,
  IconButton,
  Switch,
  Image,
  Skeleton,
  useToast
} from '@chakra-ui/react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { SearchIcon } from '@chakra-ui/icons'

import { VehicleIcon, DeleteIcon } from '../Shared/Icons'
import CheckVehicle from './CheckVehicle'
import UploadImage from '../Shared/UploadImage'
import ConfirmModal from '../Shared/ConfirmModal'
import CheckDocsVehicle from './CheckDocsVehicle'
import { getUrlImage } from '../../store/slices/fileUpload'
import { useMutateChecklist, useChecklist } from '../../hooks/checkVehicles'
import { useAllVehicles } from '../../hooks/vehicles'
import CustomToast from '../Shared/CustomToast'
import RenderImageContainer from '../Shared/RenderImageContainer'

const months = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre'
]

const validationSchema = Yup.object().shape({
  mileage: Yup.number().required('Ingrese kilometraje'),
  observationsSeatBelt: Yup.string(),
  observationsSeats: Yup.string(),
  observationsWindshield: Yup.string(),
  observationsWindows: Yup.string(),
  observationsLifter: Yup.string(),
  observationsKnob: Yup.string(),
  observationsHeating: Yup.string(),
  observationsAirConditioning: Yup.string(),
  observationsDoors: Yup.string(),
  observationsCleanliness: Yup.string(),
  observationsFrontPressure: Yup.string(),
  observationsBackPressure: Yup.string(),
  observationsSparePressure: Yup.string(),
  observationsFrontDeep: Yup.string(),
  observationsBackDeep: Yup.string(),
  observationsSpareDeep: Yup.string(),
  observationsHighBeams: Yup.string(),
  observationsLowBeams: Yup.string(),
  observationsDashLights: Yup.string(),
  observationsInnerLight: Yup.string(),
  observationsPlateLight: Yup.string(),
  observationsParkingLights: Yup.string(),
  observationsEmergencyLights: Yup.string(),
  observationsBeacons: Yup.string(),
  observationsFRTurn: Yup.string(),
  observationsFLTurn: Yup.string(),
  observationsBRTurn: Yup.string(),
  observationsBLTurn: Yup.string(),
  observationsBrakeLights: Yup.string(),
  observationsReversingLights: Yup.string(),
  observationsFogLights: Yup.string(),
  observationsLenses: Yup.string(),
  dateCirculationLicense: Yup.string(),
  dateTechnicalReview: Yup.string(),
  dateInsurance: Yup.string(),
  observationsFootBrakes: Yup.string(),
  observationsHandBrake: Yup.string(),
  observationsBrakeLiquid: Yup.string(),
  observationsCoolant: Yup.string(),
  observationsMotorOil: Yup.string(),
  observationsHydraulicOil: Yup.string(),
  observationsWater: Yup.string(),
  observationsRightRearview: Yup.string(),
  observationsLeftRearview: Yup.string(),
  observationsCenterRearview: Yup.string(),
  observationsSpeedDial: Yup.string(),
  observationsRevolutionsDial: Yup.string(),
  observationsKmCounter: Yup.string(),
  observationsFuelDial: Yup.string(),
  observationsTemperatureDial: Yup.string(),
  observationsReflectiveVest: Yup.string(),
  observationsFirstAidKit: Yup.string(),
  observationsExtinguisher: Yup.string(),
  observationsTireLever: Yup.string(),
  observationsWheelWrenchs: Yup.string(),
  observationsToolbox: Yup.string(),
  observationsTriangle: Yup.string(),
  observationsPowerCord: Yup.string(),
  observationsAirbag: Yup.string(),
  observationsInnerAntiRollBar: Yup.string(),
  observationsOuterAntiRollBar: Yup.string(),
  observationsHorn: Yup.string(),
  observationsBackupAlarm: Yup.string(),
  observationsGearShift: Yup.string(),
  observationsRadio: Yup.string(),
  observationsRadioAntenna: Yup.string(),
  observationsWindshieldWipers: Yup.string(),
  observationsWiperRubbers: Yup.string(),
  observationsFlashlight: Yup.string(),
  observationsFloorRubbers: Yup.string(),
  observationsHandrail: Yup.string(),
  observationsCard: Yup.string(),
  observationsDirection: Yup.string(),
  observationsAbsorbers: Yup.string(),
  observationsPaint: Yup.string(),
  observationsCompanyLogo: Yup.string(),
  observationsBody: Yup.string(),
  observationsFootrests: Yup.string(),
  observationsPlatform: Yup.string(),
  observationsLadder: Yup.string(),
  observationsRailing: Yup.string(),
  observationsHose: Yup.string(),
  observationsFrontBumpers: Yup.string(),
  observationsBackBumpers: Yup.string(),
  observationsMudCovers: Yup.string(),
  observationsArrowSupport: Yup.string(),
  observationsLedArrow: Yup.string(),
  observationsBeaconSupport: Yup.string(),
  observationsBeacon2: Yup.string(),
  other: Yup.string(),
  observations: Yup.string()
})

// Editar checklist de vehículo
const EditVehicles = () => {
  const toast = useToast()
  const history = useHistory()
  const dispatch = useDispatch()
  const { checkId } = useParams()
  const {
    data: vehicles,
    isFetching: fetchVehicles,
    isLoading: loadingVehicles,
    error: errorVehicles
  } = useAllVehicles()
  const {
    data: checklist,
    isFetching,
    isLoading: loadingGet,
    error,
    refetch: refetchChecklist
  } = useChecklist(checkId)
  const [vehicle, setVehicle] = useState(null)
  const [check, setCheck] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [step, setStep] = useState(1)
  const { user } = useSelector((state) => state.auth)
  const { mutate, isLoading, reset } = useMutateChecklist('UPDATE')
  const {
    isOpen: openConfirm,
    onOpen: onOpenConfirm,
    onClose: closeConfirm
  } = useDisclosure()

  // Obtener datos vehículo según id
  const getVehicle = (id) => {
    const newVehicle = vehicles.find((car) => {
      return car.id === id
    })
    return JSON.stringify(newVehicle)
  }

  // Al cargar los datos del checklist se almacena en el stado el vehículo y el estado de otro
  useEffect(() => {
    if (checklist && vehicles) {
      const car = getVehicle(checklist?.vehicle)
      const val = JSON.parse(car)
      setVehicle(val)
      setCheck(checklist?.other_check)
    }
  }, [checklist])

  const formik = useFormik({
    validationSchema,
    enableReinitialize: true,
    initialValues: {
      id: checklist?.id,
      mileage: checklist?.mileage,
      monitoredSeatBelt: checklist?.seat_belt_monitored,
      statusSeatBelt: checklist?.seat_belt_status,
      observationsSeatBelt: checklist?.seat_belt_observation,
      observationsSeatBeltCheck: checklist?.seat_belt_observation_check,
      monitoredSeats: checklist?.seats_monitored,
      statusSeats: checklist?.seats_status,
      observationsSeats: checklist?.seats_observation,
      observationsSeatsCheck: checklist?.seats_observation_check,
      monitoredWindshield: checklist?.windshield_monitored,
      statusWindshield: checklist?.windshield_status,
      observationsWindshield: checklist?.windshield_observation,
      observationsWindshieldCheck: checklist?.windshield_observation_check,
      monitoredWindows: checklist?.windows_monitored,
      statusWindows: checklist?.windows_status,
      observationsWindows: checklist?.windows_observation,
      observationsWindowsCheck: checklist?.windows_observation_check,
      monitoredLifter: checklist?.lifter_monitored,
      statusLifter: checklist?.lifter_status,
      observationsLifter: checklist?.lifter_observation,
      observationsLifterCheck: checklist?.lifter_observation_check,
      monitoredKnob: checklist?.knob_monitored,
      statusKnob: checklist?.knob_status,
      observationsKnob: checklist?.knob_observation,
      observationsKnobCheck: checklist?.knob_observation_check,
      monitoredHeating: checklist?.heating_monitored,
      statusHeating: checklist?.heating_status,
      observationsHeating: checklist?.heating_observation,
      observationsHeatingCheck: checklist?.heating_observation_check,
      monitoredAirConditioning: checklist?.air_conditioning_monitored,
      statusAirConditioning: checklist?.air_conditioning_status,
      observationsAirConditioning: checklist?.air_conditioning_observation,
      observationsAirConditioningCheck:
        checklist?.air_conditioning_observation_check,
      monitoredDoors: checklist?.doors_monitored,
      statusDoors: checklist?.doors_status,
      observationsDoors: checklist?.doors_observation,
      observationsDoorsCheck: checklist?.doors_observation_check,
      monitoredCleanliness: checklist?.cleanliness_monitored,
      statusCleanliness: checklist?.cleanliness_status,
      observationsCleanliness: checklist?.cleanliness_observation,
      observationsCleanlinessCheck: checklist?.cleanliness_observation_check,
      monitoredFrontPressure: checklist?.front_pressure_monitored,
      statusFrontPressure: checklist?.front_pressure_status,
      observationsFrontPressure: checklist?.front_pressure_observation,
      observationsFrontPressureCheck:
        checklist?.front_pressure_observation_check,
      monitoredBackPressure: checklist?.back_pressure_monitored,
      statusBackPressure: checklist?.back_pressure_status,
      observationsBackPressure: checklist?.back_pressure_observation,
      observationsBackPressureCheck: checklist?.back_pressure_observation_check,
      monitoredSparePressure: checklist?.spare_pressure_monitored,
      statusSparePressure: checklist?.spare_pressure_status,
      observationsSparePressure: checklist?.spare_pressure_observation,
      observationsSparePressureCheck:
        checklist?.spare_pressure_observation_check,
      monitoredFrontDeep: checklist?.front_deep_monitored,
      statusFrontDeep: checklist?.front_deep_status,
      observationsFrontDeep: checklist?.front_deep_observation,
      observationsFrontDeepCheck: checklist?.front_deep_observation_check,
      monitoredBackDeep: checklist?.back_deep_monitored,
      statusBackDeep: checklist?.back_deep_status,
      observationsBackDeep: checklist?.back_deep_observation,
      observationsBackDeepCheck: checklist?.back_deep_observation_check,
      monitoredSpareDeep: checklist?.spare_deep_monitored,
      statusSpareDeep: checklist?.spare_deep_status,
      observationsSpareDeep: checklist?.spare_deep_observation,
      observationsSpareDeepCheck: checklist?.spare_deep_observation_check,
      monitoredHighBeams: checklist?.high_beams_monitored,
      statusHighBeams: checklist?.high_beams_status,
      observationsHighBeams: checklist?.high_beams_observation,
      observationsHighBeamsCheck: checklist?.high_beams_observation_check,
      monitoredLowBeams: checklist?.low_beams_monitored,
      statusLowBeams: checklist?.low_beams_status,
      observationsLowBeams: checklist?.low_beams_observation,
      observationsLowBeamsCheck: checklist?.low_beams_observation_check,
      monitoredDashLights: checklist?.dash_lights_monitored,
      statusDashLights: checklist?.dash_lights_status,
      observationsDashLights: checklist?.dash_lights_observation,
      observationsDashLightsCheck: checklist?.dash_lights_observation_check,
      monitoredInnerLight: checklist?.inner_light_monitored,
      statusInnerLight: checklist?.inner_light_status,
      observationsInnerLight: checklist?.inner_light_observation,
      observationsInnerLightCheck: checklist?.inner_light_observation_check,
      monitoredPlateLight: checklist?.plate_light_monitored,
      statusPlateLight: checklist?.plate_light_status,
      observationsPlateLight: checklist?.plate_light_observation,
      observationsPlateLightCheck: checklist?.plate_light_observation_check,
      monitoredParkingLights: checklist?.parking_lights_monitored,
      statusParkingLights: checklist?.parking_lights_status,
      observationsParkingLights: checklist?.parking_lights_observation,
      observationsParkingLightsCheck:
        checklist?.parking_lights_observation_check,
      monitoredEmergencyLights: checklist?.emergency_lights_monitored,
      statusEmergencyLights: checklist?.emergency_lights_status,
      observationsEmergencyLights: checklist?.emergency_lights_observation,
      observationsEmergencyLightsCheck:
        checklist?.emergency_lights_observation_check,
      monitoredBeacons: checklist?.beacons_monitored,
      statusBeacons: checklist?.beacons_status,
      observationsBeacons: checklist?.beacons_observation,
      observationsBeaconsCheck: checklist?.beacons_observation_check,
      monitoredFRTurn: checklist?.fr_turn_monitored,
      statusFRTurn: checklist?.fr_turn_status,
      observationsFRTurn: checklist?.fr_turn_observation,
      observationsFRTurnCheck: checklist?.fr_turn_observation_check,
      monitoredFLTurn: checklist?.fl_turn_monitored,
      statusFLTurn: checklist?.fl_turn_status,
      observationsFLTurn: checklist?.fl_turn_observation,
      observationsFLTurnCheck: checklist?.fl_turn_observation_check,
      monitoredBRTurn: checklist?.br_turn_monitored,
      statusBRTurn: checklist?.br_turn_status,
      observationsBRTurn: checklist?.br_turn_observation,
      observationsBRTurnCheck: checklist?.br_turn_observation_check,
      monitoredBLTurn: checklist?.bl_turn_monitored,
      statusBLTurn: checklist?.bl_turn_status,
      observationsBLTurn: checklist?.bl_turn_observation,
      observationsBLTurnCheck: checklist?.bl_turn_observation_check,
      monitoredBrakeLights: checklist?.brake_lights_monitored,
      statusBrakeLights: checklist?.brake_lights_status,
      observationsBrakeLights: checklist?.brake_lights_observation,
      observationsBrakeLightsCheck: checklist?.brake_lights_observation_check,
      monitoredReversingLights: checklist?.reversing_lights_monitored,
      statusReversingLights: checklist?.reversing_lights_status,
      observationsReversingLights: checklist?.reversing_lights_observation,
      observationsReversingLightsCheck:
        checklist?.reversing_lights_observation_check,
      monitoredFogLights: checklist?.fog_lights_monitored,
      statusFogLights: checklist?.fog_lights_status,
      observationsFogLights: checklist?.fog_lights_observation,
      observationsFogLightsCheck: checklist?.fog_lights_observation_check,
      monitoredLenses: checklist?.lenses_monitored,
      statusLenses: checklist?.lenses_status,
      observationsLenses: checklist?.lenses_observation,
      observationsLensesCheck: checklist?.lenses_observation_check,
      monitoredCirculationLicense: checklist?.circulation_licence_monitored,
      dateCirculationLicense: checklist?.circulation_licence_date,
      monitoredTechnicalReview: checklist?.technical_review_monitored,
      dateTechnicalReview: checklist?.technical_review_date,
      monitoredInsurance: checklist?.insurance_monitored,
      dateInsurance: checklist?.insurance_date,
      monitoredFootBrakes: checklist?.foot_brakes_monitored,
      statusFootBrakes: checklist?.foot_brakes_status,
      observationsFootBrakes: checklist?.foot_brakes_observation,
      observationsFootBrakesCheck: checklist?.foot_brakes_observation_check,
      monitoredHandBrake: checklist?.hand_brake_monitored,
      statusHandBrake: checklist?.hand_brake_status,
      observationsHandBrake: checklist?.hand_brake_observation,
      observationsHandBrakeCheck: checklist?.hand_brake_observation_check,
      monitoredBrakeLiquid: checklist?.brake_liquid_monitored,
      statusBrakeLiquid: checklist?.brake_liquid_status,
      observationsBrakeLiquid: checklist?.brake_liquid_observation,
      observationsBrakeLiquidCheck: checklist?.brake_liquid_observation_check,
      monitoredCoolant: checklist?.coolant_monitored,
      statusCoolant: checklist?.coolant_status,
      observationsCoolant: checklist?.coolant_observation,
      observationsCoolantCheck: checklist?.coolant_observation_check,
      monitoredMotorOil: checklist?.motor_oil_monitored,
      statusMotorOil: checklist?.motor_oil_status,
      observationsMotorOil: checklist?.motor_oil_observation,
      observationsMotorOilCheck: checklist?.motor_oil_observation_check,
      monitoredHydraulicOil: checklist?.hydraulic_oil_monitored,
      statusHydraulicOil: checklist?.hydraulic_oil_status,
      observationsHydraulicOil: checklist?.hydraulic_oil_observation,
      observationsHydraulicOilCheck: checklist?.hydraulic_oil_observation_check,
      monitoredWater: checklist?.water_monitored,
      statusWater: checklist?.water_status,
      observationsWater: checklist?.water_observation,
      observationsWaterCheck: checklist?.water_observation_check,
      monitoredRightRearview: checklist?.right_rearview_monitored,
      statusRightRearview: checklist?.right_rearview_status,
      observationsRightRearview: checklist?.right_rearview_observation,
      observationsRightRearviewCheck:
        checklist?.right_rearview_observation_check,
      monitoredLeftRearview: checklist?.left_rearview_monitored,
      statusLeftRearview: checklist?.left_rearview_status,
      observationsLeftRearview: checklist?.left_rearview_observation,
      observationsLeftRearviewCheck: checklist?.left_rearview_observation_check,
      monitoredCenterRearview: checklist?.center_rearview_monitored,
      statusCenterRearview: checklist?.center_rearview_status,
      observationsCenterRearview: checklist?.center_rearview_observation,
      observationsCenterRearviewCheck:
        checklist?.center_rearview_observation_check,
      monitoredSpeedDial: checklist?.speed_dial_monitored,
      statusSpeedDial: checklist?.speed_dial_status,
      observationsSpeedDial: checklist?.speed_dial_observation,
      observationsSpeedDialCheck: checklist?.speed_dial_observation_check,
      monitoredRevolutionsDial: checklist?.revolutions_dial_monitored,
      statusRevolutionsDial: checklist?.revolutions_dial_status,
      observationsRevolutionsDial: checklist?.revolutions_dial_observation,
      observationsRevolutionsDialCheck:
        checklist?.revolutions_dial_observation_check,
      monitoredKmCounter: checklist?.km_counter_monitored,
      statusKmCounter: checklist?.km_counter_status,
      observationsKmCounter: checklist?.km_counter_observation,
      observationsKmCounterCheck: checklist?.km_counter_observation_check,
      monitoredFuelDial: checklist?.fuel_dial_monitored,
      statusFuelDial: checklist?.fuel_dial_status,
      observationsFuelDial: checklist?.fuel_dial_observation,
      observationsFuelDialCheck: checklist?.fuel_dial_observation_check,
      monitoredTemperatureDial: checklist?.temperature_dial_monitored,
      statusTemperatureDial: checklist?.temperature_dial_status,
      observationsTemperatureDial: checklist?.temperature_dial_observation,
      observationsTemperatureDialCheck:
        checklist?.temperature_dial_observation_check,
      monitoredReflectiveVest: checklist?.reflective_vest_monitored,
      statusReflectiveVest: checklist?.reflective_vest_status,
      observationsReflectiveVest: checklist?.reflective_vest_observation,
      observationsReflectiveVestCheck:
        checklist?.reflective_vest_observation_check,
      monitoredFirstAidKit: checklist?.first_aid_kit_monitored,
      statusFirstAidKit: checklist?.first_aid_kit_status,
      observationsFirstAidKit: checklist?.first_aid_kit_observation,
      observationsFirstAidKitCheck: checklist?.first_aid_kit_observation_check,
      monitoredExtinguisher: checklist?.extinguisher_monitored,
      statusExtinguisher: checklist?.extinguisher_status,
      observationsExtinguisher: checklist?.extinguisher_observation,
      observationsExtinguisherCheck: checklist?.extinguisher_observation_check,
      monitoredTireLever: checklist?.tire_lever_monitored,
      statusTireLever: checklist?.tire_lever_status,
      observationsTireLever: checklist?.tire_lever_observation,
      observationsTireLeverCheck: checklist?.tire_lever_observation_check,
      monitoredWheelWrench: checklist?.wheel_wrench_monitored,
      statusWheelWrench: checklist?.wheel_wrench_status,
      observationsWheelWrench: checklist?.wheel_wrench_observation,
      observationsWheelWrenchCheck: checklist?.wheel_wrench_observation_check,
      monitoredToolbox: checklist?.toolbox_monitored,
      statusToolbox: checklist?.toolbox_status,
      observationsToolbox: checklist?.toolbox_observation,
      observationsToolboxCheck: checklist?.toolbox_observation_check,
      monitoredTriangle: checklist?.triangle_monitored,
      statusTriangle: checklist?.triangle_status,
      observationsTriangle: checklist?.triangle_observation,
      observationsTriangleCheck: checklist?.triangle_observation_check,
      monitoredPowerCord: checklist?.power_cord_monitored,
      statusPowerCord: checklist?.power_cord_status,
      observationsPowerCord: checklist?.power_cord_observation,
      observationsPowerCordCheck: checklist?.power_cord_observation_check,
      monitoredAirbag: checklist?.airbag_monitored,
      statusAirbag: checklist?.airbag_status,
      observationsAirbag: checklist?.airbag_observation,
      observationsAirbagCheck: checklist?.airbag_observation_check,
      monitoredInnerAntiRollBar: checklist?.inner_anti_roll_bar_monitored,
      statusInnerAntiRollBar: checklist?.inner_anti_roll_bar_status,
      observationsInnerAntiRollBar: checklist?.inner_anti_roll_bar_observation,
      observationsInnerAntiRollBarCheck:
        checklist?.inner_anti_roll_bar_observation_check,
      monitoredOuterAntiRollBar: checklist?.outer_anti_roll_bar_monitored,
      statusOuterAntiRollBar: checklist?.outer_anti_roll_bar_status,
      observationsOuterAntiRollBar: checklist?.outer_anti_roll_bar_observation,
      observationsOuterAntiRollBarCheck:
        checklist?.outer_anti_roll_bar_observation_check,
      monitoredHorn: checklist?.horn_monitored,
      statusHorn: checklist?.horn_status,
      observationsHorn: checklist?.horn_observation,
      observationsHornCheck: checklist?.horn_observation_check,
      monitoredBackupAlarm: checklist?.backup_alarm_monitored,
      statusBackupAlarm: checklist?.backup_alarm_status,
      observationsBackupAlarm: checklist?.backup_alarm_observation,
      observationsBackupAlarmCheck: checklist?.backup_alarm_observation_check,
      monitoredGearShift: checklist?.gear_shift_monitored,
      statusGearShift: checklist?.gear_shift_status,
      observationsGearShift: checklist?.gear_shift_observation,
      observationsGearShiftCheck: checklist?.gear_shift_observation_check,
      monitoredRadio: checklist?.radio_monitored,
      statusRadio: checklist?.radio_status,
      observationsRadio: checklist?.radio_observation,
      observationsRadioCheck: checklist?.radio_observation_check,
      monitoredRadioAntenna: checklist?.radio_antenna_monitored,
      statusRadioAntenna: checklist?.radio_antenna_status,
      observationsRadioAntenna: checklist?.radio_antenna_observation,
      observationsRadioAntennaCheck: checklist?.radio_antenna_observation_check,
      monitoredWindshieldWipers: checklist?.windshield_wipers_monitored,
      statusWindshieldWipers: checklist?.windshield_wipers_status,
      observationsWindshieldWipers: checklist?.windshield_wipers_observation,
      observationsWindshieldWipersCheck:
        checklist?.windshield_wipers_observation_check,
      monitoredWiperRubbers: checklist?.wiper_rubbers_monitored,
      statusWiperRubbers: checklist?.wiper_rubbers_status,
      observationsWiperRubbers: checklist?.wiper_rubbers_observation,
      observationsWiperRubbersCheck: checklist?.wiper_rubbers_observation_check,
      monitoredFlashlight: checklist?.flashlight_monitored,
      statusFlashlight: checklist?.flashlight_status,
      observationsFlashlight: checklist?.flashlight_observation,
      observationsFlashlightCheck: checklist?.flashlight_observation_check,
      monitoredFloorRubbers: checklist?.floor_rubbers_monitored,
      statusFloorRubbers: checklist?.floor_rubbers_status,
      observationsFloorRubbers: checklist?.floor_rubbers_observation,
      observationsFloorRubbersCheck: checklist?.floor_rubbers_observation_check,
      monitoredHandrail: checklist?.handrail_monitored,
      statusHandrail: checklist?.handrail_status,
      observationsHandrail: checklist?.handrail_observation,
      observationsHandrailCheck: checklist?.handrail_observation_check,
      monitoredCard: checklist?.card_monitored,
      statusCard: checklist?.card_status,
      observationsCard: checklist?.card_observation,
      observatiosnCardCheck: checklist?.card_observation_check,
      monitoredDirection: checklist?.direction_monitored,
      statusDirection: checklist?.direction_status,
      observationsDirection: checklist?.direction_observation,
      observationsDirectionCheck: checklist?.direction_observation_check,
      monitoredAbsorbers: checklist?.absorbers_monitored,
      statusAbsorbers: checklist?.absorbers_status,
      observationsAbsorbers: checklist?.absorbers_observation,
      observationsAbsorbersCheck: checklist?.absorbers_observation_check,
      monitoredPaint: checklist?.paint_monitored,
      statusPaint: checklist?.paint_status,
      observationsPaint: checklist?.paint_observation,
      observationsPaintCheck: checklist?.paint_observation_check,
      monitoredCompanyLogo: checklist?.company_logo_monitored,
      statusCompanyLogo: checklist?.company_logo_status,
      observationsCompanyLogo: checklist?.company_logo_observation,
      observationsCompanyLogoCheck: checklist?.company_logo_observation_check,
      monitoredBody: checklist?.body_monitored,
      statusBody: checklist?.body_status,
      observationsBody: checklist?.body_observation,
      observationsBodyCheck: checklist?.body_observation_check,
      monitoredFootrests: checklist?.footrests_monitored,
      statusFootrests: checklist?.footrests_status,
      observationsFootrests: checklist?.footrests_observation,
      observationsFootrestsCheck: checklist?.footrests_observation_check,
      monitoredPlatform: checklist?.platform_monitored,
      statusPlatform: checklist?.platform_status,
      observationsPlatform: checklist?.platform_observation,
      observationsPlatformCheck: checklist?.platform_observation_check,
      monitoredLadder: checklist?.ladder_monitored,
      statusLadder: checklist?.ladder_status,
      observationsLadder: checklist?.ladder_observation,
      observationsLadderCheck: checklist?.ladder_observation_check,
      monitoredRailing: checklist?.railing_monitored,
      statusRailing: checklist?.railing_status,
      observationsRailing: checklist?.railing_observation,
      observationsRailingCheck: checklist?.railing_observation_check,
      monitoredHose: checklist?.hose_monitored,
      statusHose: checklist?.hose_status,
      observationsHose: checklist?.hose_observation,
      observationsHoseCheck: checklist?.hose_observation_check,
      monitoredFrontBumpers: checklist?.front_bumpers_monitored,
      statusFrontBumpers: checklist?.front_bumpers_status,
      observationsFrontBumpers: checklist?.front_bumpers_observation,
      observationsFrontBumpersCheck: checklist?.front_bumpers_observation_check,
      monitoredBackBumpers: checklist?.back_bumpers_monitored,
      statusBackBumpers: checklist?.back_bumpers_status,
      observationsBackBumpers: checklist?.back_bumpers_observation,
      observationsBackBumpersCheck: checklist?.back_bumpers_observation_check,
      monitoredMudCover: checklist?.mud_cover_monitored,
      statusMudCover: checklist?.mud_cover_status,
      observationsMudCover: checklist?.mud_cover_observation,
      observationsMudCoverCheck: checklist?.mud_cover_observation_check,
      monitoredArrowSupport: checklist?.arrow_support_monitored,
      statusArrowSupport: checklist?.arrow_support_status,
      observationsArrowSupport: checklist?.arrow_support_observation,
      observationsArrowSupportCheck: checklist?.arrow_support_observation_check,
      monitoredLedArrow: checklist?.led_arrow_monitored,
      statusLedArrow: checklist?.led_arrow_status,
      observationsLedArrow: checklist?.led_arrow_observation,
      observationsLedArrowCheck: checklist?.led_arrow_observation_check,
      monitoredBeaconSupport: checklist?.beacon_support_monitored,
      statusBeaconSupport: checklist?.beacon_support_status,
      observationsBeaconSupport: checklist?.beacon_support_observation,
      observationsBeaconSupportCheck:
        checklist?.beacon_support_observation_check,
      monitoredBeacon2: checklist?.beacon2_monitored,
      statusBeacon2: checklist?.beacon2_status,
      observationsBeacon2: checklist?.beacon2_observation,
      observationsBeacon2Check: checklist?.beacon2_observation_check,
      other: checklist?.other,
      observations: checklist?.observations,
      exteriorFrontImage: checklist?.exterior_front_image,
      backSideImage: checklist?.back_side_image,
      leftSideImage: checklist?.left_side_image,
      rightSideImage: checklist?.right_side_image,
      interiorImage: checklist?.interior_image,
      boardImage: checklist?.board_image,
      exteriorFrontImageObservation:
        checklist?.exterior_front_image_observation,
      backSideImageObservation: checklist?.back_side_image_observation,
      leftSideImageObservation: checklist?.left_side_image_observation,
      rightSideImageObservation: checklist?.right_side_image_observation,
      interiorImageObservation: checklist?.interior_image_observation,
      boardImageObservation: checklist?.board_image_observation,
      created_by: checklist?.created_by
    },
    onSubmit: (values) => {
      const formData = {
        id: values.id,
        vehicle: vehicle.id,
        mileage: values.mileage,
        seat_belt_monitored: values.monitoredSeatBelt,
        seat_belt_status: values.statusSeatBelt,
        seat_belt_observation: values.observationsSeatBelt,
        seat_belt_observation_check: values.observationsSeatBeltCheck,
        seats_monitored: values.monitoredSeats,
        seats_status: values.statusSeats,
        seats_observation: values.observationsSeats,
        seats_observation_check: values.observationsSeatsCheck,
        windshield_monitored: values.monitoredWindshield,
        windshield_status: values.statusWindshield,
        windshield_observation: values.observationsWindshield,
        windshield_observation_check: values.observationsWindshieldCheck,
        windows_monitored: values.monitoredWindows,
        windows_status: values.statusWindows,
        windows_observation: values.observationsWindows,
        windows_observation_check: values.observationsWindowsCheck,
        lifter_monitored: values.monitoredLifter,
        lifter_status: values.statusLifter,
        lifter_observation: values.observationsLifter,
        lifter_observation_check: values.observationsLifterCheck,
        knob_monitored: values.monitoredKnob,
        knob_status: values.statusKnob,
        knob_observation: values.observationsKnob,
        knob_observation_check: values.observationsKnobCheck,
        heating_monitored: values.monitoredHeating,
        heating_status: values.statusHeating,
        heating_observation: values.observationsHeating,
        heating_observation_check: values.observationsHeatingCheck,
        air_conditioning_monitored: values.monitoredAirConditioning,
        air_conditioning_status: values.statusAirConditioning,
        air_conditioning_observation: values.observationsAirConditioning,
        air_conditioning_observation_check:
          values.observationsAirConditioningCheck,
        doors_monitored: values.monitoredDoors,
        doors_status: values.statusDoors,
        doors_observation: values.observationsDoors,
        doors_observation_check: values.observationsDoorsCheck,
        cleanliness_monitored: values.monitoredCleanliness,
        cleanliness_status: values.statusCleanliness,
        cleanliness_observation: values.observationsCleanliness,
        cleanliness_observation_check: values.observationsCleanlinessCheck,
        front_pressure_monitored: values.monitoredFrontPressure,
        front_pressure_status: values.statusFrontPressure,
        front_pressure_observation: values.observationsFrontPressure,
        front_pressure_observation_check: values.observationsFrontPressureCheck,
        back_pressure_monitored: values.monitoredBackPressure,
        back_pressure_status: values.statusBackPressure,
        back_pressure_observation: values.observationsBackPressure,
        back_pressure_observation_check: values.observationsBackPressureCheck,
        spare_pressure_monitored: values.monitoredSparePressure,
        spare_pressure_status: values.statusSparePressure,
        spare_pressure_observation: values.observationsSparePressure,
        spare_pressure_observation_check: values.observationsSparePressureCheck,
        front_deep_monitored: values.monitoredFrontDeep,
        front_deep_status: values.statusFrontDeep,
        front_deep_observation: values.observationsFrontDeep,
        front_deep_observation_check: values.observationsFrontDeepCheck,
        back_deep_monitored: values.monitoredBackDeep,
        back_deep_status: values.statusBackDeep,
        back_deep_observation: values.observationsBackDeep,
        back_deep_observation_check: values.observationsBackDeepCheck,
        spare_deep_monitored: values.monitoredSpareDeep,
        spare_deep_status: values.statusSpareDeep,
        spare_deep_observation: values.observationsSpareDeep,
        spare_deep_observation_check: values.observationsSpareDeepCheck,
        high_beams_monitored: values.monitoredHighBeams,
        high_beams_status: values.statusHighBeams,
        high_beams_observation: values.observationsHighBeams,
        high_beams_observation_check: values.observationsHighBeamsCheck,
        low_beams_monitored: values.monitoredLowBeams,
        low_beams_status: values.statusLowBeams,
        low_beams_observation: values.observationsLowBeams,
        low_beams_observation_check: values.observationsLowBeamsCheck,
        dash_lights_monitored: values.monitoredDashLights,
        dash_lights_status: values.statusDashLights,
        dash_lights_observation: values.observationsDashLights,
        dash_lights_observation_check: values.observationsDashLightsCheck,
        inner_light_monitored: values.monitoredInnerLight,
        inner_light_status: values.statusInnerLight,
        inner_light_observation: values.observationsInnerLight,
        inner_light_observation_check: values.observationsInnerLightCheck,
        plate_light_monitored: values.monitoredPlateLight,
        plate_light_status: values.statusPlateLight,
        plate_light_observation: values.observationsPlateLight,
        plate_light_observation_check: values.observationsPlateLightCheck,
        parking_lights_monitored: values.monitoredParkingLights,
        parking_lights_status: values.statusParkingLights,
        parking_lights_observation: values.observationsParkingLights,
        parking_lights_observation_check: values.observationsParkingLightsCheck,
        emergency_lights_monitored: values.monitoredEmergencyLights,
        emergency_lights_status: values.statusEmergencyLights,
        emergency_lights_observation: values.observationsEmergencyLights,
        emergency_lights_observation_check:
          values.observationsEmergencyLightsCheck,
        beacons_monitored: values.monitoredBeacons,
        beacons_status: values.statusBeacons,
        beacons_observation: values.observationsBeacons,
        beacons_observation_check: values.observationsBeaconsCheck,
        fr_turn_monitored: values.monitoredFRTurn,
        fr_turn_status: values.statusFRTurn,
        fr_turn_observation: values.observationsFRTurn,
        fr_turn_observation_check: values.observationsFRTurnCheck,
        fl_turn_monitored: values.monitoredFLTurn,
        fl_turn_status: values.statusFLTurn,
        fl_turn_observation: values.observationsFLTurn,
        fl_turn_observation_check: values.observationsFLTurnCheck,
        br_turn_monitored: values.monitoredBRTurn,
        br_turn_status: values.statusBRTurn,
        br_turn_observation: values.observationsBRTurn,
        br_turn_observation_check: values.observationsBRTurnCheck,
        bl_turn_monitored: values.monitoredBLTurn,
        bl_turn_status: values.statusBLTurn,
        bl_turn_observation: values.observationsBLTurn,
        bl_turn_observation_check: values.observationsBLTurnCheck,
        brake_lights_monitored: values.monitoredBrakeLights,
        brake_lights_status: values.statusBrakeLights,
        brake_lights_observation: values.observationsBrakeLights,
        brake_lights_observation_check: values.observationsBrakeLightsCheck,
        reversing_lights_monitored: values.monitoredReversingLights,
        reversing_lights_status: values.statusReversingLights,
        reversing_lights_observation: values.observationsReversingLights,
        reversing_lights_observation_check:
          values.observationsReversingLightsCheck,
        fog_lights_monitored: values.monitoredFogLights,
        fog_lights_status: values.statusFogLights,
        fog_lights_observation: values.observationsFogLights,
        fog_lights_observation_check: values.observationsFogLightsCheck,
        lenses_monitored: values.monitoredLenses,
        lenses_status: values.statusLenses,
        lenses_observation: values.observationsLenses,
        lenses_observation_check: values.observationsLensesCheck,
        circulation_licence_monitored: values.monitoredCirculationLicense,
        circulation_licence_date: values.dateCirculationLicense,
        technical_review_monitored: values.monitoredTechnicalReview,
        technical_review_date: values.dateTechnicalReview,
        insurance_monitored: values.monitoredInsurance,
        insurance_date: values.dateInsurance,
        foot_brakes_monitored: values.monitoredFootBrakes,
        foot_brakes_status: values.statusFootBrakes,
        foot_brakes_observation: values.observationsFootBrakes,
        foot_brakes_observation_check: values.observationsFootBrakesCheck,
        hand_brake_monitored: values.monitoredHandBrake,
        hand_brake_status: values.statusHandBrake,
        hand_brake_observation: values.observationsHandBrake,
        hand_brake_observation_check: values.observationsHandBrakeCheck,
        brake_liquid_monitored: values.monitoredBrakeLiquid,
        brake_liquid_status: values.statusBrakeLiquid,
        brake_liquid_observation: values.observationsBrakeLiquid,
        brake_liquid_observation_check: values.observationsBrakeLiquidCheck,
        coolant_monitored: values.monitoredCoolant,
        coolant_status: values.statusCoolant,
        coolant_observation: values.observationsCoolant,
        coolant_observation_check: values.observationsCoolantCheck,
        motor_oil_monitored: values.monitoredMotorOil,
        motor_oil_status: values.statusMotorOil,
        motor_oil_observation: values.observationsMotorOil,
        motor_oil_observation_check: values.observationsMotorOilCheck,
        hydraulic_oil_monitored: values.monitoredHydraulicOil,
        hydraulic_oil_status: values.statusHydraulicOil,
        hydraulic_oil_observation: values.observationsHydraulicOil,
        hydraulic_oil_observation_check: values.observationsHydraulicOilCheck,
        water_monitored: values.monitoredWater,
        water_status: values.statusWater,
        water_observation: values.observationsWater,
        water_observation_check: values.observationsWaterCheck,
        right_rearview_monitored: values.monitoredRightRearview,
        right_rearview_status: values.statusRightRearview,
        right_rearview_observation: values.observationsRightRearview,
        right_rearview_observation_check: values.observationsRightRearviewCheck,
        left_rearview_monitored: values.monitoredLeftRearview,
        left_rearview_status: values.statusLeftRearview,
        left_rearview_observation: values.observationsLeftRearview,
        left_rearview_observation_check: values.observationsLeftRearviewCheck,
        center_rearview_monitored: values.monitoredCenterRearview,
        center_rearview_status: values.statusCenterRearview,
        center_rearview_observation: values.observationsCenterRearview,
        center_rearview_observation_check:
          values.observationsCenterRearviewCheck,
        speed_dial_monitored: values.monitoredSpeedDial,
        speed_dial_status: values.statusSpeedDial,
        speed_dial_observation: values.observationsSpeedDial,
        speed_dial_observation_check: values.observationsSpeedDialCheck,
        revolutions_dial_monitored: values.monitoredRevolutionsDial,
        revolutions_dial_status: values.statusRevolutionsDial,
        revolutions_dial_observation: values.observationsRevolutionsDial,
        revolutions_dial_observation_check:
          values.observationsRevolutionsDialCheck,
        km_counter_monitored: values.monitoredKmCounter,
        km_counter_status: values.statusKmCounter,
        km_counter_observation: values.observationsKmCounter,
        km_counter_observation_check: values.observationsKmCounterCheck,
        fuel_dial_monitored: values.monitoredFuelDial,
        fuel_dial_status: values.statusFuelDial,
        fuel_dial_observation: values.observationsFuelDial,
        fuel_dial_observation_check: values.observationsFuelDialCheck,
        temperature_dial_monitored: values.monitoredTemperatureDial,
        temperature_dial_status: values.statusTemperatureDial,
        temperature_dial_observation: values.observationsTemperatureDial,
        temperature_dial_observation_check:
          values.observationsTemperatureDialCheck,
        reflective_vest_monitored: values.monitoredReflectiveVest,
        reflective_vest_status: values.statusReflectiveVest,
        reflective_vest_observation: values.observationsReflectiveVest,
        reflective_vest_observation_check:
          values.observationsReflectiveVestCheck,
        first_aid_kit_monitored: values.monitoredFirstAidKit,
        first_aid_kit_status: values.statusFirstAidKit,
        first_aid_kit_observation: values.observationsFirstAidKit,
        first_aid_kit_observation_check: values.observationsFirstAidKitCheck,
        extinguisher_monitored: values.monitoredExtinguisher,
        extinguisher_status: values.statusExtinguisher,
        extinguisher_observation: values.observationsExtinguisher,
        extinguisher_observation_check: values.observationsExtinguisherCheck,
        tire_lever_monitored: values.monitoredTireLever,
        tire_lever_status: values.statusTireLever,
        tire_lever_observation: values.observationsTireLever,
        tire_lever_observation_check: values.observationsTireLeverCheck,
        wheel_wrench_monitored: values.monitoredWheelWrench,
        wheel_wrench_status: values.statusWheelWrench,
        wheel_wrench_observation: values.observationsWheelWrench,
        wheel_wrench_observation_check: values.observationsWheelWrenchCheck,
        toolbox_monitored: values.monitoredToolbox,
        toolbox_status: values.statusToolbox,
        toolbox_observation: values.observationsToolbox,
        toolbox_observation_check: values.observationsToolboxCheck,
        triangle_monitored: values.monitoredTriangle,
        triangle_status: values.statusTriangle,
        triangle_observation: values.observationsTriangle,
        triangle_observation_check: values.observationsTriangleCheck,
        power_cord_monitored: values.monitoredPowerCord,
        power_cord_status: values.statusPowerCord,
        power_cord_observation: values.observationsPowerCord,
        power_cord_observation_check: values.observationsPowerCordCheck,
        airbag_monitored: values.monitoredAirbag,
        airbag_status: values.statusAirbag,
        airbag_observation: values.observationsAirbag,
        airbag_observation_check: values.observationsAirbagCheck,
        inner_anti_roll_bar_monitored: values.monitoredInnerAntiRollBar,
        inner_anti_roll_bar_status: values.statusInnerAntiRollBar,
        inner_anti_roll_bar_observation: values.observationsInnerAntiRollBar,
        inner_anti_roll_bar_observation_check:
          values.observationsInnerAntiRollBarCheck,
        outer_anti_roll_bar_monitored: values.monitoredOuterAntiRollBar,
        outer_anti_roll_bar_status: values.statusOuterAntiRollBar,
        outer_anti_roll_bar_observation: values.observationsOuterAntiRollBar,
        outer_anti_roll_bar_observation_check:
          values.observationsOuterAntiRollBarCheck,
        horn_monitored: values.monitoredHorn,
        horn_status: values.statusHorn,
        horn_observation: values.observationsHorn,
        horn_observation_check: values.observationsHornCheck,
        backup_alarm_monitored: values.monitoredBackupAlarm,
        backup_alarm_status: values.statusBackupAlarm,
        backup_alarm_observation: values.observationsBackupAlarm,
        backup_alarm_observation_check: values.observationsBackupAlarmCheck,
        gear_shift_monitored: values.monitoredGearShift,
        gear_shift_status: values.statusGearShift,
        gear_shift_observation: values.observationsGearShift,
        gear_shift_observation_check: values.observationsGearShiftCheck,
        radio_monitored: values.monitoredRadio,
        radio_status: values.statusRadio,
        radio_observation: values.observationsRadio,
        radio_observation_check: values.observationsRadioCheck,
        radio_antenna_monitored: values.monitoredRadioAntenna,
        radio_antenna_status: values.statusRadioAntenna,
        radio_antenna_observation: values.observationsRadioAntenna,
        radio_antenna_observation_check: values.observationsRadioAntennaCheck,
        windshield_wipers_monitored: values.monitoredWindshieldWipers,
        windshield_wipers_status: values.statusWindshieldWipers,
        windshield_wipers_observation: values.observationsWindshieldWipers,
        windshield_wipers_observation_check:
          values.observationsWindshieldWipersCheck,
        wiper_rubbers_monitored: values.monitoredWiperRubbers,
        wiper_rubbers_status: values.statusWiperRubbers,
        wiper_rubbers_observation: values.observationsWiperRubbers,
        wiper_rubbers_observation_check: values.observationsWiperRubbersCheck,
        flashlight_monitored: values.monitoredFlashlight,
        flashlight_status: values.statusFlashlight,
        flashlight_observation: values.observationsFlashlight,
        flashlight_observation_check: values.observationsFlashlightCheck,
        floor_rubbers_monitored: values.monitoredFloorRubbers,
        floor_rubbers_status: values.statusFloorRubbers,
        floor_rubbers_observation: values.observationsFloorRubbers,
        floor_rubbers_observation_check: values.observationsFloorRubbersCheck,
        handrail_monitored: values.monitoredHandrail,
        handrail_status: values.statusHandrail,
        handrail_observation: values.observationsHandrail,
        handrail_observation_check: values.observationsHandrailCheck,
        card_monitored: values.monitoredCard,
        card_status: values.statusCard,
        card_observation: values.observationsCard,
        card_observation_check: values.observatiosnCardCheck,
        direction_monitored: values.monitoredDirection,
        direction_status: values.statusDirection,
        direction_observation: values.observationsDirection,
        direction_observation_check: values.observationsDirectionCheck,
        absorbers_monitored: values.monitoredAbsorbers,
        absorbers_status: values.statusAbsorbers,
        absorbers_observation: values.observationsAbsorbers,
        absorbers_observation_check: values.observationsAbsorbersCheck,
        paint_monitored: values.monitoredPaint,
        paint_status: values.statusPaint,
        paint_observation: values.observationsPaint,
        paint_observation_check: values.observationsPaintCheck,
        company_logo_monitored: values.monitoredCompanyLogo,
        company_logo_status: values.statusCompanyLogo,
        company_logo_observation: values.observationsCompanyLogo,
        company_logo_observation_check: values.observationsCompanyLogoCheck,
        body_monitored: values.monitoredBody,
        body_status: values.statusBody,
        body_observation: values.observationsBody,
        body_observation_check: values.observationsBodyCheck,
        footrests_monitored: values.monitoredFootrests,
        footrests_status: values.statusFootrests,
        footrests_observation: values.observationsFootrests,
        footrests_observation_check: values.observationsFootrestsCheck,
        platform_monitored: values.monitoredPlatform,
        platform_status: values.statusPlatform,
        platform_observation: values.observationsPlatform,
        platform_observation_check: values.observationsPlatformCheck,
        ladder_monitored: values.monitoredLadder,
        ladder_status: values.statusLadder,
        ladder_observation: values.observationsLadder,
        ladder_observation_check: values.observationsLadderCheck,
        railing_monitored: values.monitoredRailing,
        railing_status: values.statusRailing,
        railing_observation: values.observationsRailing,
        railing_observation_check: values.observationsRailingCheck,
        hose_monitored: values.monitoredHose,
        hose_status: values.statusHose,
        hose_observation: values.observationsHose,
        hose_observation_check: values.observationsHoseCheck,
        front_bumpers_monitored: values.monitoredFrontBumpers,
        front_bumpers_status: values.statusFrontBumpers,
        front_bumpers_observation: values.observationsFrontBumpers,
        front_bumpers_observation_check: values.observationsFrontBumpersCheck,
        back_bumpers_monitored: values.monitoredBackBumpers,
        back_bumpers_status: values.statusBackBumpers,
        back_bumpers_observation: values.observationsBackBumpers,
        back_bumpers_observation_check: values.observationsBackBumpersCheck,
        mud_cover_monitored: values.monitoredMudCover,
        mud_cover_status: values.statusMudCover,
        mud_cover_observation: values.observationsMudCover,
        mud_cover_observation_check: values.observationsMudCoverCheck,
        arrow_support_monitored: values.monitoredArrowSupport,
        arrow_support_status: values.statusArrowSupport,
        arrow_support_observation: values.observationsArrowSupport,
        arrow_support_observation_check: values.observationsArrowSupportCheck,
        led_arrow_monitored: values.monitoredLedArrow,
        led_arrow_status: values.statusLedArrow,
        led_arrow_observation: values.observationsLedArrow,
        led_arrow_observation_check: values.observationsLedArrowCheck,
        beacon_support_monitored: values.monitoredBeaconSupport,
        beacon_support_status: values.statusBeaconSupport,
        beacon_support_observation: values.observationsBeaconSupport,
        beacon_support_observation_check: values.observationsBeaconSupportCheck,
        beacon2_monitored: values.monitoredBeacon2,
        beacon2_status: values.statusBeacon2,
        beacon2_observation: values.observationsBeacon2,
        beacon2_observation_check: values.observationsBeacon2Check,
        other_check: check,
        other: values.other,
        observations: values.observations,
        exterior_front_image: values.exteriorFrontImage,
        back_side_image: values.backSideImage,
        left_side_image: values.leftSideImage,
        right_side_image: values.rightSideImage,
        interior_image: values.interiorImage,
        board_image: values.boardImage,
        exterior_front_image_observation: values.exteriorFrontImageObservation,
        back_side_image_observation: values.backSideImageObservation,
        left_side_image_observation: values.leftSideImageObservation,
        right_side_image_observation: values.rightSideImageObservation,
        interior_image_observation: values.interiorImageObservation,
        board_image_observation: values.boardImageObservation,
        created_by: values.created_by
      }
      mutate(formData, {
        onSuccess: () => {
          reset()
          refetchChecklist()
          /* formik.resetForm() */
          setConfirm(true)
        },
        onError: (err) => {
          reset()
          console.log(err)
        }
      })
    }
  })

  // Fecha actual
  const currentDate = () => {
    const newDate = new Date()
    const today = `${newDate.getDate()} ${months[newDate.getMonth()].substring(
      0,
      3
    )} ${newDate.getFullYear()}`
    return today
  }

  const handleChangeCheck = () => {
    setCheck(!check)
  }

  const handleClickConfirm = () => {
    onOpenConfirm()
  }

  //Deja la url de la imagen la variable correspondiente
  const handleImage = (field, url) => {
    formik.setFieldValue(field, url)
  }

  //Lógica de subida de imagen
  const [isLoadingUploadFile, setIsLoadingUploadFile] = useState({
    front: false,
    back: false,
    left: false,
    right: false,
    interior: false,
    board: false
  })
  const imgUploadFront = async (formImage) => {
    try {
      setIsLoadingUploadFile({
        ...isLoadingUploadFile,
        front: true
      })
      const response = await dispatch(getUrlImage(formImage))
      if (response) {
        handleImage('exteriorFrontImage', response.url)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoadingUploadFile({
        ...isLoadingUploadFile,
        front: false
      })
    }
  }
  const imgUploadBack = async (formImage) => {
    try {
      setIsLoadingUploadFile({
        ...isLoadingUploadFile,
        back: true
      })
      const response = await dispatch(getUrlImage(formImage))
      if (response) {
        handleImage('backSideImage', response.url)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoadingUploadFile({
        ...isLoadingUploadFile,
        back: false
      })
    }
  }
  const imgUploadLeft = async (formImage) => {
    try {
      setIsLoadingUploadFile({
        ...isLoadingUploadFile,
        left: true
      })
      const response = await dispatch(getUrlImage(formImage))
      if (response) {
        handleImage('leftSideImage', response.url)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoadingUploadFile({
        ...isLoadingUploadFile,
        left: false
      })
    }
  }
  const imgUploadRight = async (formImage) => {
    try {
      setIsLoadingUploadFile({
        ...isLoadingUploadFile,
        right: true
      })
      const response = await dispatch(getUrlImage(formImage))
      if (response) {
        handleImage('rightSideImage', response.url)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoadingUploadFile({
        ...isLoadingUploadFile,
        right: false
      })
    }
  }
  const imgUploadInterior = async (formImage) => {
    try {
      setIsLoadingUploadFile({
        ...isLoadingUploadFile,
        interior: true
      })
      const response = await dispatch(getUrlImage(formImage))
      if (response) {
        handleImage('interiorImage', response.url)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoadingUploadFile({
        ...isLoadingUploadFile,
        interior: false
      })
    }
  }
  const imgUploadBoard = async (formImage) => {
    try {
      setIsLoadingUploadFile({
        ...isLoadingUploadFile,
        board: true
      })
      const response = await dispatch(getUrlImage(formImage))
      if (response) {
        handleImage('boardImage', response.url)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoadingUploadFile({
        ...isLoadingUploadFile,
        board: false
      })
    }
  }

  //Obtener nombre de imagen
  const getFilename = (img) => {
    const index = img.lastIndexOf('/') + 1
    const filename = img.substr(index)
    return filename
  }

  //Cambia de página
  const changeStep = (number) => {
    setStep(number)
  }

  // Manejadores de número de página
  const nextStep = () => {
    if (step < 4) {
      setStep(step + 1)
    }
  }
  const prevStep = () => {
    if (step > 1) {
      setStep(step - 1)
    }
  }

  // Primera página: datos generales, documentación, marcadores y niveles
  const stepOne = () => {
    return (
      <>
        <FormControl
          id="mileage"
          isInvalid={formik.touched.mileage && Boolean(formik.errors.mileage)}
        >
          <FormLabel>Kilometraje</FormLabel>
          <Input
            name="mileage"
            value={formik.values.mileage}
            onChange={formik.handleChange}
            placeholder="Kilometraje"
          />
          <FormErrorMessage>
            {formik.touched.mileage && formik.errors.mileage}
          </FormErrorMessage>
        </FormControl>
        <Box w="100%" bg="#F2F1FB" pb={5}>
          <Flex spacing={10} align="left" pt="15px">
            <Stack spacing={2} pl={30}>
              <Text fontSize="15px" fontWeight={'bold'} color="#36355F">
                Documentación
              </Text>
            </Stack>
          </Flex>
        </Box>
        <HStack
          spacing="24px"
          overflowX={'scroll'}
          py={3}
          px={2}
          css={{
            '&::-webkit-scrollbar': {
              width: '4px'
            },
            '&::-webkit-scrollbar-track': {
              width: '6px'
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#59BABA',
              borderRadius: '15px'
            }
          }}
        >
          <CheckDocsVehicle
            title={'Permiso de Circulación'}
            switchName={'monitoredCirculationLicense'}
            monitored={formik.values.monitoredCirculationLicense}
            setMonitored={formik.setFieldValue}
            touched={formik.touched.dateCirculationLicense}
            errors={formik.errors.dateCirculationLicense}
            handleChange={formik.handleChange}
            value={formik.values.dateCirculationLicense}
            dateName={'dateCirculationLicense'}
          />
          <CheckDocsVehicle
            title={'Revisión Técnica'}
            switchName={'monitoredTechnicalReview'}
            monitored={formik.values.monitoredTechnicalReview}
            setMonitored={formik.setFieldValue}
            touched={formik.touched.dateTechnicalReview}
            errors={formik.errors.dateTechnicalReview}
            handleChange={formik.handleChange}
            value={formik.values.dateTechnicalReview}
            dateName={'dateTechnicalReview'}
          />
          <CheckDocsVehicle
            title={'Seguro Obligatorio'}
            switchName={'monitoredInsurance'}
            monitored={formik.values.monitoredInsurance}
            setMonitored={formik.setFieldValue}
            touched={formik.touched.dateInsurance}
            errors={formik.errors.dateInsurance}
            handleChange={formik.handleChange}
            value={formik.values.dateInsurance}
            dateName={'dateInsurance'}
          />
        </HStack>
        <Box w="100%" bg="#F2F1FB" pb={5}>
          <Flex spacing={10} align="left" pt="15px">
            <Stack spacing={2} pl={30}>
              <Text fontSize="15px" fontWeight={'bold'} color="#36355F">
                General
              </Text>
            </Stack>
          </Flex>
        </Box>
        <HStack
          spacing="24px"
          overflowX={'scroll'}
          py={3}
          px={2}
          css={{
            '&::-webkit-scrollbar': {
              width: '4px'
            },
            '&::-webkit-scrollbar-track': {
              width: '6px'
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#59BABA',
              borderRadius: '15px'
            }
          }}
        >
          <CheckVehicle
            title={'Cinturon de seguridad'}
            setValues={formik.setFieldValue}
            switchName={'monitoredSeatBelt'}
            monitored={formik.values.monitoredSeatBelt}
            status={formik.values.statusSeatBelt}
            statusName={'statusSeatBelt'}
            observation={formik.values.observationsSeatBeltCheck}
            observationSwitchName={'observationsSeatBeltCheck'}
            touched={formik.touched.observationsSeatBelt}
            errors={formik.errors.observationsSeatBelt}
            handleChange={formik.handleChange}
            value={formik.values.observationsSeatBelt}
            textName={'observationsSeatBelt'}
          />
          <CheckVehicle
            title={'Asientos'}
            setValues={formik.setFieldValue}
            switchName={'monitoredSeats'}
            monitored={formik.values.monitoredSeats}
            status={formik.values.statusSeats}
            statusName={'statusSeats'}
            observation={formik.values.observationsSeatsCheck}
            observationSwitchName={'observationsSeatsCheck'}
            touched={formik.touched.observationsSeats}
            errors={formik.errors.observationsSeats}
            handleChange={formik.handleChange}
            value={formik.values.observationsSeats}
            textName={'observationsSeats'}
          />
          <CheckVehicle
            title={'Parabrisas'}
            setValues={formik.setFieldValue}
            switchName={'monitoredWindshield'}
            monitored={formik.values.monitoredWindshield}
            status={formik.values.statusWindshield}
            statusName={'statusWindshield'}
            observation={formik.values.observationsWindshieldCheck}
            observationSwitchName={'observationsWindshieldCheck'}
            touched={formik.touched.observationsWindshield}
            errors={formik.errors.observationsWindshield}
            handleChange={formik.handleChange}
            value={formik.values.observationsWindshield}
            textName={'observationsWindshield'}
          />
          <CheckVehicle
            title={'Vidrios de ventanas'}
            setValues={formik.setFieldValue}
            switchName={'monitoredWindows'}
            monitored={formik.values.monitoredWindows}
            status={formik.values.statusWindows}
            statusName={'statusWindows'}
            observation={formik.values.observationsWindowsCheck}
            observationSwitchName={'observationsWindowsCheck'}
            touched={formik.touched.observationsWindows}
            errors={formik.errors.observationsWindows}
            handleChange={formik.handleChange}
            value={formik.values.observationsWindows}
            textName={'observationsWindows'}
          />
          <CheckVehicle
            title={'Alza vidrios'}
            setValues={formik.setFieldValue}
            switchName={'monitoredLifter'}
            monitored={formik.values.monitoredLifter}
            status={formik.values.statusLifter}
            statusName={'statusLifter'}
            observation={formik.values.observationsLifterCheck}
            observationSwitchName={'observationsLifterCheck'}
            touched={formik.touched.observationsLifter}
            errors={formik.errors.observationsLifter}
            handleChange={formik.handleChange}
            value={formik.values.observationsLifter}
            textName={'observationsLifter'}
          />
          <CheckVehicle
            title={'Chapas'}
            setValues={formik.setFieldValue}
            switchName={'monitoredKnob'}
            monitored={formik.values.monitoredKnob}
            status={formik.values.statusKnob}
            statusName={'statusKnob'}
            observation={formik.values.observationsKnobCheck}
            observationSwitchName={'observationsKnobCheck'}
            touched={formik.touched.observationsKnob}
            errors={formik.errors.observationsKnob}
            handleChange={formik.handleChange}
            value={formik.values.observationsKnob}
            textName={'observationsKnob'}
          />
          <CheckVehicle
            title={'Calefacción'}
            setValues={formik.setFieldValue}
            switchName={'monitoredHeating'}
            monitored={formik.values.monitoredHeating}
            status={formik.values.statusHeating}
            statusName={'statusHeating'}
            observation={formik.values.observationsHeatingCheck}
            observationSwitchName={'observationsHeatingCheck'}
            touched={formik.touched.observationsHeating}
            errors={formik.errors.observationsHeating}
            handleChange={formik.handleChange}
            value={formik.values.observationsHeating}
            textName={'observationsHeating'}
          />
          <CheckVehicle
            title={'Aire Acondicionado'}
            setValues={formik.setFieldValue}
            switchName={'monitoredAirConditioning'}
            monitored={formik.values.monitoredAirConditioning}
            status={formik.values.statusAirConditioning}
            statusName={'statusAirConditioning'}
            observation={formik.values.observationsAirConditioningCheck}
            observationSwitchName={'observationsAirConditioningCheck'}
            touched={formik.touched.observationsAirConditioning}
            errors={formik.errors.observationsAirConditioning}
            handleChange={formik.handleChange}
            value={formik.values.observationsAirConditioning}
            textName={'observationsAirConditioning'}
          />
          <CheckVehicle
            title={'Puertas'}
            setValues={formik.setFieldValue}
            switchName={'monitoredDoors'}
            monitored={formik.values.monitoredDoors}
            status={formik.values.statusDoors}
            statusName={'statusDoors'}
            observation={formik.values.observationsDoorsCheck}
            observationSwitchName={'observationsDoorsCheck'}
            touched={formik.touched.observationsDoors}
            errors={formik.errors.observationsDoors}
            handleChange={formik.handleChange}
            value={formik.values.observationsDoors}
            textName={'observationsDoors'}
          />
          <CheckVehicle
            title={'Aseo'}
            setValues={formik.setFieldValue}
            switchName={'monitoredCleanliness'}
            monitored={formik.values.monitoredCleanliness}
            status={formik.values.statusCleanliness}
            statusName={'statusCleanliness'}
            observation={formik.values.observationsCleanlinessCheck}
            observationSwitchName={'observationsCleanlinessCheck'}
            touched={formik.touched.observationsCleanliness}
            errors={formik.errors.observationsCleanliness}
            handleChange={formik.handleChange}
            value={formik.values.observationsCleanliness}
            textName={'observationsCleanliness'}
          />
        </HStack>
        <Box w="100%" bg="#F2F1FB" pb={5}>
          <Flex spacing={10} align="left" pt="15px">
            <Stack spacing={2} pl={30}>
              <Text fontSize="15px" fontWeight={'bold'} color="#36355F">
                Marcadores
              </Text>
            </Stack>
          </Flex>
        </Box>
        <HStack
          spacing="24px"
          overflowX={'scroll'}
          py={3}
          px={2}
          css={{
            '&::-webkit-scrollbar': {
              width: '4px'
            },
            '&::-webkit-scrollbar-track': {
              width: '6px'
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#59BABA',
              borderRadius: '15px'
            }
          }}
        >
          <CheckVehicle
            title={'Marcador de Velocidad'}
            setValues={formik.setFieldValue}
            switchName={'monitoredSpeedDial'}
            monitored={formik.values.monitoredSpeedDial}
            status={formik.values.statusSpeedDial}
            statusName={'statusSpeedDial'}
            observation={formik.values.observationsSpeedDialCheck}
            observationSwitchName={'observationsSpeedDialCheck'}
            touched={formik.touched.observationsSpeedDial}
            errors={formik.errors.observationsSpeedDial}
            handleChange={formik.handleChange}
            value={formik.values.observationsSpeedDial}
            textName={'observationsSpeedDial'}
          />
          <CheckVehicle
            title={'Marcador de Revoluciones'}
            setValues={formik.setFieldValue}
            switchName={'monitoredRevolutionsDial'}
            monitored={formik.values.monitoredRevolutionsDial}
            status={formik.values.statusRevolutionsDial}
            statusName={'statusRevolutionsDial'}
            observation={formik.values.observationsRevolutionsDialCheck}
            observationSwitchName={'observationsRevolutionsDialCheck'}
            touched={formik.touched.observationsRevolutionsDial}
            errors={formik.errors.observationsRevolutionsDial}
            handleChange={formik.handleChange}
            value={formik.values.observationsRevolutionsDial}
            textName={'observationsRevolutionsDial'}
          />
          <CheckVehicle
            title={'Cuenta Kilómetros'}
            setValues={formik.setFieldValue}
            switchName={'monitoredKmCounter'}
            monitored={formik.values.monitoredKmCounter}
            status={formik.values.statusKmCounter}
            statusName={'statusKmCounter'}
            observation={formik.values.observationsKmCounterCheck}
            observationSwitchName={'observationsKmCounterCheck'}
            touched={formik.touched.observationsKmCounter}
            errors={formik.errors.observationsKmCounter}
            handleChange={formik.handleChange}
            value={formik.values.observationsKmCounter}
            textName={'observationsKmCounter'}
          />
          <CheckVehicle
            title={'Marcador de Combustible'}
            setValues={formik.setFieldValue}
            switchName={'monitoredFuelDial'}
            monitored={formik.values.monitoredFuelDial}
            status={formik.values.statusFuelDial}
            statusName={'statusFuelDial'}
            observation={formik.values.observationsFuelDialCheck}
            observationSwitchName={'observationsFuelDialCheck'}
            touched={formik.touched.observationsFuelDial}
            errors={formik.errors.observationsFuelDial}
            handleChange={formik.handleChange}
            value={formik.values.observationsFuelDial}
            textName={'observationsFuelDial'}
          />
          <CheckVehicle
            title={'Marcador de Temperatura'}
            setValues={formik.setFieldValue}
            switchName={'monitoredTemperatureDial'}
            monitored={formik.values.monitoredTemperatureDial}
            status={formik.values.statusTemperatureDial}
            statusName={'statusTemperatureDial'}
            observation={formik.values.observationsTemperatureDialCheck}
            observationSwitchName={'observationsTemperatureDialCheck'}
            touched={formik.touched.observationsTemperatureDial}
            errors={formik.errors.observationsTemperatureDial}
            handleChange={formik.handleChange}
            value={formik.values.observationsTemperatureDial}
            textName={'observationsTemperatureDial'}
          />
        </HStack>
        <Box w="100%" bg="#F2F1FB" pb={5}>
          <Flex spacing={10} align="left" pt="15px">
            <Stack spacing={2} pl={30}>
              <Text fontSize="15px" fontWeight={'bold'} color="#36355F">
                Niveles
              </Text>
            </Stack>
          </Flex>
        </Box>
        <HStack
          spacing="24px"
          overflowX={'scroll'}
          py={3}
          px={2}
          css={{
            '&::-webkit-scrollbar': {
              width: '4px'
            },
            '&::-webkit-scrollbar-track': {
              width: '6px'
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#59BABA',
              borderRadius: '15px'
            }
          }}
        >
          <CheckVehicle
            title={'Nivel de Líquido Refrigerante de Radiador'}
            setValues={formik.setFieldValue}
            switchName={'monitoredCoolant'}
            monitored={formik.values.monitoredCoolant}
            status={formik.values.statusCoolant}
            statusName={'statusCoolant'}
            observation={formik.values.observationsCoolantCheck}
            observationSwitchName={'observationsCoolantCheck'}
            touched={formik.touched.observationsCoolant}
            errors={formik.errors.observationsCoolant}
            handleChange={formik.handleChange}
            value={formik.values.observationsCoolant}
            textName={'observationsCoolant'}
          />
          <CheckVehicle
            title={'Nivel de Aceite de Motor'}
            setValues={formik.setFieldValue}
            switchName={'monitoredMotorOil'}
            monitored={formik.values.monitoredMotorOil}
            status={formik.values.statusMotorOil}
            statusName={'statusMotorOil'}
            observation={formik.values.observationsMotorOilCheck}
            observationSwitchName={'observationsMotorOilCheck'}
            touched={formik.touched.observationsMotorOil}
            errors={formik.errors.observationsMotorOil}
            handleChange={formik.handleChange}
            value={formik.values.observationsMotorOil}
            textName={'observationsMotorOil'}
          />
          <CheckVehicle
            title={'Nivel de Aceite Sistema Hidráulico'}
            setValues={formik.setFieldValue}
            switchName={'monitoredHydraulicOil'}
            monitored={formik.values.monitoredHydraulicOil}
            status={formik.values.statusHydraulicOil}
            statusName={'statusHydraulicOil'}
            observation={formik.values.observationsHydraulicOilCheck}
            observationSwitchName={'observationsHydraulicOilCheck'}
            touched={formik.touched.observationsHydraulicOil}
            errors={formik.errors.observationsHydraulicOil}
            handleChange={formik.handleChange}
            value={formik.values.observationsHydraulicOil}
            textName={'observationsHydraulicOil'}
          />
          <CheckVehicle
            title={'Agua Deposito Limpia Parabrisas'}
            setValues={formik.setFieldValue}
            switchName={'monitoredWater'}
            monitored={formik.values.monitoredWater}
            status={formik.values.statusWater}
            statusName={'statusWater'}
            observation={formik.values.observationsWaterCheck}
            observationSwitchName={'observationsWaterCheck'}
            touched={formik.touched.observationsWater}
            errors={formik.errors.observationsWater}
            handleChange={formik.handleChange}
            value={formik.values.observationsWater}
            textName={'observationsWater'}
          />
        </HStack>
      </>
    )
  }

  // Segunda página: neumáticos, luces, espejos y frenos
  const stepTwo = () => {
    return (
      <>
        <Box w="100%" bg="#F2F1FB" pb={5}>
          <Flex spacing={10} align="left" pt="15px">
            <Stack spacing={2} pl={30}>
              <Text fontSize="15px" fontWeight={'bold'} color="#36355F">
                Neumáticos
              </Text>
            </Stack>
          </Flex>
        </Box>
        <HStack
          spacing="24px"
          overflowX={'scroll'}
          py={3}
          px={2}
          css={{
            '&::-webkit-scrollbar': {
              width: '4px'
            },
            '&::-webkit-scrollbar-track': {
              width: '6px'
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#59BABA',
              borderRadius: '15px'
            }
          }}
        >
          <CheckVehicle
            title={'Presión Neumáticos delanteros'}
            setValues={formik.setFieldValue}
            switchName={'monitoredFrontPressure'}
            monitored={formik.values.monitoredFrontPressure}
            status={formik.values.statusFrontPressure}
            statusName={'statusFrontPressure'}
            observation={formik.values.observationsFrontPressureCheck}
            observationSwitchName={'observationsFrontPressureCheck'}
            touched={formik.touched.observationsFrontPressure}
            errors={formik.errors.observationsFrontPressure}
            handleChange={formik.handleChange}
            value={formik.values.observationsFrontPressure}
            textName={'observationsFrontPressure'}
          />
          <CheckVehicle
            title={'Presión Neumáticos traseros'}
            setValues={formik.setFieldValue}
            switchName={'monitoredBackPressure'}
            monitored={formik.values.monitoredBackPressure}
            status={formik.values.statusBackPressure}
            statusName={'statusBackPressure'}
            observation={formik.values.observationsBackPressureCheck}
            observationSwitchName={'observationsBackPressureCheck'}
            touched={formik.touched.observationsBackPressure}
            errors={formik.errors.observationsBackPressure}
            handleChange={formik.handleChange}
            value={formik.values.observationsBackPressure}
            textName={'observationsBackPressure'}
          />
          <CheckVehicle
            title={'Presión Neumático de Repuesto'}
            setValues={formik.setFieldValue}
            switchName={'monitoredSparePressure'}
            monitored={formik.values.monitoredSparePressure}
            status={formik.values.statusSparePressure}
            statusName={'statusSparePressure'}
            observation={formik.values.observationsSparePressureCheck}
            observationSwitchName={'observationsSparePressureCheck'}
            touched={formik.touched.observationsSparePressure}
            errors={formik.errors.observationsSparePressure}
            handleChange={formik.handleChange}
            value={formik.values.observationsSparePressure}
            textName={'observationsSparePressure'}
          />
          <CheckVehicle
            title={'Profundidad surcos Neumáticos delanteros'}
            setValues={formik.setFieldValue}
            switchName={'monitoredFrontDeep'}
            monitored={formik.values.monitoredFrontDeep}
            status={formik.values.statusFrontDeep}
            statusName={'statusFrontDeep'}
            observation={formik.values.observationsFrontDeepCheck}
            observationSwitchName={'observationsFrontDeepCheck'}
            touched={formik.touched.observationsFrontDeep}
            errors={formik.errors.observationsFrontDeep}
            handleChange={formik.handleChange}
            value={formik.values.observationsFrontDeep}
            textName={'observationsFrontDeep'}
          />
          <CheckVehicle
            title={'Profundidad surcos Neumáticos Traseros'}
            setValues={formik.setFieldValue}
            switchName={'monitoredBackDeep'}
            monitored={formik.values.monitoredBackDeep}
            status={formik.values.statusBackDeep}
            statusName={'statusBackDeep'}
            observation={formik.values.observationsBackDeepCheck}
            observationSwitchName={'observationsBackDeepCheck'}
            touched={formik.touched.observationsBackDeep}
            errors={formik.errors.observationsBackDeep}
            handleChange={formik.handleChange}
            value={formik.values.observationsBackDeep}
            textName={'observationsBackDeep'}
          />
          <CheckVehicle
            title={'Profundidad surcos Neumático de repuesto'}
            setValues={formik.setFieldValue}
            switchName={'monitoredSpareDeep'}
            monitored={formik.values.monitoredSpareDeep}
            status={formik.values.statusSpareDeep}
            statusName={'statusSpareDeep'}
            observation={formik.values.observationsSpareDeepCheck}
            observationSwitchName={'observationsSpareDeepCheck'}
            touched={formik.touched.observationsSpareDeep}
            errors={formik.errors.observationsSpareDeep}
            handleChange={formik.handleChange}
            value={formik.values.observationsSpareDeep}
            textName={'observationsSpareDeep'}
          />
        </HStack>
        <Box w="100%" bg="#F2F1FB" pb={5}>
          <Flex spacing={10} align="left" pt="15px">
            <Stack spacing={2} pl={30}>
              <Text fontSize="15px" fontWeight={'bold'} color="#36355F">
                Luces
              </Text>
            </Stack>
          </Flex>
        </Box>
        <HStack
          spacing="24px"
          overflowX={'scroll'}
          py={3}
          px={2}
          css={{
            '&::-webkit-scrollbar': {
              width: '4px'
            },
            '&::-webkit-scrollbar-track': {
              width: '6px'
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#59BABA',
              borderRadius: '15px'
            }
          }}
        >
          <CheckVehicle
            title={'Luces Altas'}
            setValues={formik.setFieldValue}
            switchName={'monitoredHighBeams'}
            monitored={formik.values.monitoredHighBeams}
            status={formik.values.statusHighBeams}
            statusName={'statusHighBeams'}
            observation={formik.values.observationsHighBeamsCheck}
            observationSwitchName={'observationsHighBeamsCheck'}
            touched={formik.touched.observationsHighBeams}
            errors={formik.errors.observationsHighBeams}
            handleChange={formik.handleChange}
            value={formik.values.observationsHighBeams}
            textName={'observationsHighBeams'}
          />
          <CheckVehicle
            title={'Luces Bajas'}
            setValues={formik.setFieldValue}
            switchName={'monitoredLowBeams'}
            monitored={formik.values.monitoredLowBeams}
            status={formik.values.statusLowBeams}
            statusName={'statusLowBeams'}
            observation={formik.values.observationsLowBeamsCheck}
            observationSwitchName={'observationsLowBeamsCheck'}
            touched={formik.touched.observationsLowBeams}
            errors={formik.errors.observationsLowBeams}
            handleChange={formik.handleChange}
            value={formik.values.observationsLowBeams}
            textName={'observationsLowBeams'}
          />
          <CheckVehicle
            title={'Luces de tablero'}
            setValues={formik.setFieldValue}
            switchName={'monitoredDashLights'}
            monitored={formik.values.monitoredDashLights}
            status={formik.values.statusDashLights}
            statusName={'statusDashLights'}
            observation={formik.values.observationsDashLightsCheck}
            observationSwitchName={'observationsDashLightsCheck'}
            touched={formik.touched.observationsDashLights}
            errors={formik.errors.observationsDashLights}
            handleChange={formik.handleChange}
            value={formik.values.observationsDashLights}
            textName={'observationsDashLights'}
          />
          <CheckVehicle
            title={'Luz interior'}
            setValues={formik.setFieldValue}
            switchName={'monitoredInnerLight'}
            monitored={formik.values.monitoredInnerLight}
            status={formik.values.statusInnerLight}
            statusName={'statusInnerLight'}
            observation={formik.values.observationsInnerLightCheck}
            observationSwitchName={'observationsInnerLightCheck'}
            touched={formik.touched.observationsInnerLight}
            errors={formik.errors.observationsInnerLight}
            handleChange={formik.handleChange}
            value={formik.values.observationsInnerLight}
            textName={'observationsInnerLight'}
          />
          <CheckVehicle
            title={'Luz patente'}
            setValues={formik.setFieldValue}
            switchName={'monitoredPlateLight'}
            monitored={formik.values.monitoredPlateLight}
            status={formik.values.statusPlateLight}
            statusName={'statusPlateLight'}
            observation={formik.values.observationsPlateLightCheck}
            observationSwitchName={'observationsPlateLightCheck'}
            touched={formik.touched.observationsPlateLight}
            errors={formik.errors.observationsPlateLight}
            handleChange={formik.handleChange}
            value={formik.values.observationsPlateLight}
            textName={'observationsPlateLight'}
          />
          <CheckVehicle
            title={'Luces de estacionamiento'}
            setValues={formik.setFieldValue}
            switchName={'monitoredParkingLights'}
            monitored={formik.values.monitoredParkingLights}
            status={formik.values.statusParkingLights}
            statusName={'statusParkingLights'}
            observation={formik.values.observationsParkingLightsCheck}
            observationSwitchName={'observationsParkingLightsCheck'}
            touched={formik.touched.observationsParkingLights}
            errors={formik.errors.observationsParkingLights}
            handleChange={formik.handleChange}
            value={formik.values.observationsParkingLights}
            textName={'observationsParkingLights'}
          />
          <CheckVehicle
            title={'Luces de Emergencias'}
            setValues={formik.setFieldValue}
            switchName={'monitoredEmergencyLights'}
            monitored={formik.values.monitoredEmergencyLights}
            status={formik.values.statusEmergencyLights}
            statusName={'statusEmergencyLights'}
            observation={formik.values.observationsEmergencyLightsCheck}
            observationSwitchName={'observationsEmergencyLightsCheck'}
            touched={formik.touched.observationsEmergencyLights}
            errors={formik.errors.observationsEmergencyLights}
            handleChange={formik.handleChange}
            value={formik.values.observationsEmergencyLights}
            textName={'observationsEmergencyLights'}
          />
          <CheckVehicle
            title={'Balizas'}
            setValues={formik.setFieldValue}
            switchName={'monitoredBeacons'}
            monitored={formik.values.monitoredBeacons}
            status={formik.values.statusBeacons}
            statusName={'statusBeacons'}
            observation={formik.values.observationsBeaconsCheck}
            observationSwitchName={'observationsBeaconsCheck'}
            touched={formik.touched.observationsBeacons}
            errors={formik.errors.observationsBeacons}
            handleChange={formik.handleChange}
            value={formik.values.observationsBeacons}
            textName={'observationsBeacons'}
          />
          <CheckVehicle
            title={'Intermitente Derecho delantero'}
            setValues={formik.setFieldValue}
            switchName={'monitoredFRTurn'}
            monitored={formik.values.monitoredFRTurn}
            status={formik.values.statusFRTurn}
            statusName={'statusFRTurn'}
            observation={formik.values.observationsFRTurnCheck}
            observationSwitchName={'observationsFRTurnCheck'}
            touched={formik.touched.observationsFRTurn}
            errors={formik.errors.observationsFRTurn}
            handleChange={formik.handleChange}
            value={formik.values.observationsFRTurn}
            textName={'observationsFRTurn'}
          />
          <CheckVehicle
            title={'Intermitente Izquierdo delantero'}
            setValues={formik.setFieldValue}
            switchName={'monitoredFLTurn'}
            monitored={formik.values.monitoredFLTurn}
            status={formik.values.statusFLTurn}
            statusName={'statusFLTurn'}
            observation={formik.values.observationsFLTurnCheck}
            observationSwitchName={'observationsFLTurnCheck'}
            touched={formik.touched.observationsFLTurn}
            errors={formik.errors.observationsFLTurn}
            handleChange={formik.handleChange}
            value={formik.values.observationsFLTurn}
            textName={'observationsFLTurn'}
          />
          <CheckVehicle
            title={'Intermitente Derecho Trasero'}
            setValues={formik.setFieldValue}
            switchName={'monitoredBRTurn'}
            monitored={formik.values.monitoredBRTurn}
            status={formik.values.statusBRTurn}
            statusName={'statusBRTurn'}
            observation={formik.values.observationsBRTurnCheck}
            observationSwitchName={'observationsBRTurnCheck'}
            touched={formik.touched.observationsBRTurn}
            errors={formik.errors.observationsBRTurn}
            handleChange={formik.handleChange}
            value={formik.values.observationsBRTurn}
            textName={'observationsBRTurn'}
          />
          <CheckVehicle
            title={'Intermitente Izquierdo Trasero'}
            setValues={formik.setFieldValue}
            switchName={'monitoredBLTurn'}
            monitored={formik.values.monitoredBLTurn}
            status={formik.values.statusBLTurn}
            statusName={'statusBLTurn'}
            observation={formik.values.observationsBLTurnCheck}
            observationSwitchName={'observationsBLTurnCheck'}
            touched={formik.touched.observationsBLTurn}
            errors={formik.errors.observationsBLTurn}
            handleChange={formik.handleChange}
            value={formik.values.observationsBLTurn}
            textName={'observationsBLTurn'}
          />
          <CheckVehicle
            title={'Luces de Freno'}
            setValues={formik.setFieldValue}
            switchName={'monitoredBrakeLights'}
            monitored={formik.values.monitoredBrakeLights}
            status={formik.values.statusBrakeLights}
            statusName={'statusBrakeLights'}
            observation={formik.values.observationsBrakeLightsCheck}
            observationSwitchName={'observationsBrakeLightsCheck'}
            touched={formik.touched.observationsBrakeLights}
            errors={formik.errors.observationsBrakeLights}
            handleChange={formik.handleChange}
            value={formik.values.observationsBrakeLights}
            textName={'observationsBrakeLights'}
          />
          <CheckVehicle
            title={'Luces marcha atrás'}
            setValues={formik.setFieldValue}
            switchName={'monitoredReversingLights'}
            monitored={formik.values.monitoredReversingLights}
            status={formik.values.statusReversingLights}
            statusName={'statusReversingLights'}
            observation={formik.values.observationsReversingLightsCheck}
            observationSwitchName={'observationsReversingLightsCheck'}
            touched={formik.touched.observationsReversingLights}
            errors={formik.errors.observationsFLTurn}
            handleChange={formik.handleChange}
            value={formik.values.observationsReversingLights}
            textName={'observationsReversingLights'}
          />
          <CheckVehicle
            title={'Neblineros'}
            setValues={formik.setFieldValue}
            switchName={'monitoredFogLights'}
            monitored={formik.values.monitoredFogLights}
            status={formik.values.statusFogLights}
            statusName={'statusFogLights'}
            observation={formik.values.observationsFogLightsCheck}
            observationSwitchName={'observationsFogLightsCheck'}
            touched={formik.touched.observationsFogLights}
            errors={formik.errors.observationsFogLights}
            handleChange={formik.handleChange}
            value={formik.values.observationsFogLights}
            textName={'observationsFogLights'}
          />
          <CheckVehicle
            title={'Micas'}
            setValues={formik.setFieldValue}
            switchName={'monitoredLenses'}
            monitored={formik.values.monitoredLenses}
            status={formik.values.statusLenses}
            statusName={'statusLenses'}
            observation={formik.values.observationsLensesCheck}
            observationSwitchName={'observationsLensesCheck'}
            touched={formik.touched.observationsLenses}
            errors={formik.errors.observationsLenses}
            handleChange={formik.handleChange}
            value={formik.values.observationsLenses}
            textName={'observationsLenses'}
          />
        </HStack>
        <Box w="100%" bg="#F2F1FB" pb={5}>
          <Flex spacing={10} align="left" pt="15px">
            <Stack spacing={2} pl={30}>
              <Text fontSize="15px" fontWeight={'bold'} color="#36355F">
                Espejos
              </Text>
            </Stack>
          </Flex>
        </Box>
        <HStack
          spacing="24px"
          overflowX={'scroll'}
          py={3}
          px={2}
          css={{
            '&::-webkit-scrollbar': {
              width: '4px'
            },
            '&::-webkit-scrollbar-track': {
              width: '6px'
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#59BABA',
              borderRadius: '15px'
            }
          }}
        >
          <CheckVehicle
            title={'Espejo Retrovisor Derecho'}
            setValues={formik.setFieldValue}
            switchName={'monitoredRightRearview'}
            monitored={formik.values.monitoredRightRearview}
            status={formik.values.statusRightRearview}
            statusName={'statusRightRearview'}
            observation={formik.values.observationsRightRearviewCheck}
            observationSwitchName={'observationsRightRearviewCheck'}
            touched={formik.touched.observationsRightRearview}
            errors={formik.errors.observationsRightRearview}
            handleChange={formik.handleChange}
            value={formik.values.observationsRightRearview}
            textName={'observationsRightRearview'}
          />
          <CheckVehicle
            title={'Espejo Retrovisor Izquierdo'}
            setValues={formik.setFieldValue}
            switchName={'monitoredLeftRearview'}
            monitored={formik.values.monitoredLeftRearview}
            status={formik.values.statusLeftRearview}
            statusName={'statusLeftRearview'}
            observation={formik.values.observationsLeftRearviewCheck}
            observationSwitchName={'observationsLeftRearviewCheck'}
            touched={formik.touched.observationsLeftRearview}
            errors={formik.errors.observationsLeftRearview}
            handleChange={formik.handleChange}
            value={formik.values.observationsLeftRearview}
            textName={'observationsLeftRearview'}
          />
          <CheckVehicle
            title={'Espejo Retrovisor Central'}
            setValues={formik.setFieldValue}
            switchName={'monitoredCenterRearview'}
            monitored={formik.values.monitoredCenterRearview}
            status={formik.values.statusCenterRearview}
            statusName={'statusCenterRearview'}
            observation={formik.values.observationsCenterRearviewCheck}
            observationSwitchName={'observationsCenterRearviewCheck'}
            touched={formik.touched.observationsCenterRearview}
            errors={formik.errors.observationsCenterRearview}
            handleChange={formik.handleChange}
            value={formik.values.observationsCenterRearview}
            textName={'observationsCenterRearview'}
          />
        </HStack>
        <Box w="100%" bg="#F2F1FB" pb={5}>
          <Flex spacing={10} align="left" pt="15px">
            <Stack spacing={2} pl={30}>
              <Text fontSize="15px" fontWeight={'bold'} color="#36355F">
                Sistema de frenos
              </Text>
            </Stack>
          </Flex>
        </Box>
        <HStack
          spacing="24px"
          overflowX={'scroll'}
          py={3}
          px={2}
          css={{
            '&::-webkit-scrollbar': {
              width: '4px'
            },
            '&::-webkit-scrollbar-track': {
              width: '6px'
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#59BABA',
              borderRadius: '15px'
            }
          }}
        >
          <CheckVehicle
            title={'Frenos de Pie'}
            setValues={formik.setFieldValue}
            switchName={'monitoredFootBrakes'}
            monitored={formik.values.monitoredFootBrakes}
            status={formik.values.statusFootBrakes}
            statusName={'statusFootBrakes'}
            observation={formik.values.observationsFootBrakesCheck}
            observationSwitchName={'observationsFootBrakesCheck'}
            touched={formik.touched.observationsFootBrakes}
            errors={formik.errors.observationsFootBrakes}
            handleChange={formik.handleChange}
            value={formik.values.observationsFootBrakes}
            textName={'observationsFootBrakes'}
          />
          <CheckVehicle
            title={'Freno de Mano'}
            setValues={formik.setFieldValue}
            switchName={'monitoredHandBrake'}
            monitored={formik.values.monitoredHandBrake}
            status={formik.values.statusHandBrake}
            statusName={'statusHandBrake'}
            observation={formik.values.observationsHandBrakeCheck}
            observationSwitchName={'observationsHandBrakeCheck'}
            touched={formik.touched.observationsHandBrake}
            errors={formik.errors.observationsHandBrake}
            handleChange={formik.handleChange}
            value={formik.values.observationsHandBrake}
            textName={'observationsHandBrake'}
          />
          <CheckVehicle
            title={'Líquido de Freno'}
            setValues={formik.setFieldValue}
            switchName={'monitoredBrakeLiquid'}
            monitored={formik.values.monitoredBrakeLiquid}
            status={formik.values.statusBrakeLiquid}
            statusName={'statusBrakeLiquid'}
            observation={formik.values.observationsBrakeLiquidCheck}
            observationSwitchName={'observationsBrakeLiquidCheck'}
            touched={formik.touched.observationsBrakeLiquid}
            errors={formik.errors.observationsBrakeLiquid}
            handleChange={formik.handleChange}
            value={formik.values.observationsBrakeLiquid}
            textName={'observationsBrakeLiquid'}
          />
        </HStack>
      </>
    )
  }

  // Página tres: accesorios y otros
  const stepThree = () => {
    return (
      <>
        <Box w="100%" bg="#F2F1FB" pb={5}>
          <Flex spacing={10} align="left" pt="15px">
            <Stack spacing={2} pl={30}>
              <Text fontSize="15px" fontWeight={'bold'} color="#36355F">
                Accesorios
              </Text>
            </Stack>
          </Flex>
        </Box>
        <HStack
          spacing="24px"
          overflowX={'scroll'}
          py={3}
          px={2}
          css={{
            '&::-webkit-scrollbar': {
              width: '4px'
            },
            '&::-webkit-scrollbar-track': {
              width: '6px'
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#59BABA',
              borderRadius: '15px'
            }
          }}
        >
          <CheckVehicle
            title={'Chaleco Reflectante'}
            setValues={formik.setFieldValue}
            switchName={'monitoredReflectiveVest'}
            monitored={formik.values.monitoredReflectiveVest}
            status={formik.values.statusReflectiveVest}
            statusName={'statusReflectiveVest'}
            observation={formik.values.observationsReflectiveVestCheck}
            observationSwitchName={'observationsReflectiveVestCheck'}
            touched={formik.touched.observationsReflectiveVest}
            errors={formik.errors.observationsReflectiveVest}
            handleChange={formik.handleChange}
            value={formik.values.observationsReflectiveVest}
            textName={'observationsReflectiveVest'}
          />
          <CheckVehicle
            title={'Botiquín'}
            setValues={formik.setFieldValue}
            switchName={'monitoredFirstAidKit'}
            monitored={formik.values.monitoredFirstAidKit}
            status={formik.values.statusFirstAidKit}
            statusName={'statusFirstAidKit'}
            observation={formik.values.observationsFirstAidKitCheck}
            observationSwitchName={'observationsFirstAidKitCheck'}
            touched={formik.touched.observationsFirstAidKit}
            errors={formik.errors.observationsFirstAidKit}
            handleChange={formik.handleChange}
            value={formik.values.observationsFirstAidKit}
            textName={'observationsFirstAidKit'}
          />
          <CheckVehicle
            title={'Extintor'}
            setValues={formik.setFieldValue}
            switchName={'monitoredExtinguisher'}
            monitored={formik.values.monitoredExtinguisher}
            status={formik.values.statusExtinguisher}
            statusName={'statusExtinguisher'}
            observation={formik.values.observationsExtinguisherCheck}
            observationSwitchName={'observationsExtinguisherCheck'}
            touched={formik.touched.observationsExtinguisher}
            errors={formik.errors.observationsExtinguisher}
            handleChange={formik.handleChange}
            value={formik.values.observationsExtinguisher}
            textName={'observationsExtinguisher'}
          />
          <CheckVehicle
            title={'Gata/Barrote'}
            setValues={formik.setFieldValue}
            switchName={'monitoredTireLever'}
            monitored={formik.values.monitoredTireLever}
            status={formik.values.statusTireLever}
            statusName={'statusTireLever'}
            observation={formik.values.observationsTireLeverCheck}
            observationSwitchName={'observationsTireLeverCheck'}
            touched={formik.touched.observationsTireLever}
            errors={formik.errors.observationsTireLever}
            handleChange={formik.handleChange}
            value={formik.values.observationsTireLever}
            textName={'observationsTireLever'}
          />
          <CheckVehicle
            title={'Llave de Ruedas'}
            setValues={formik.setFieldValue}
            switchName={'monitoredWheelWrench'}
            monitored={formik.values.monitoredWheelWrench}
            status={formik.values.statusWheelWrench}
            statusName={'statusWheelWrench'}
            observation={formik.values.observationsWheelWrenchCheck}
            observationSwitchName={'observationsWheelWrenchCheck'}
            touched={formik.touched.observationsWheelWrench}
            errors={formik.errors.observationsWheelWrench}
            handleChange={formik.handleChange}
            value={formik.values.observationsWheelWrench}
            textName={'observationsWheelWrench'}
          />
          <CheckVehicle
            title={'Caja de Herramientas'}
            setValues={formik.setFieldValue}
            switchName={'monitoredToolbox'}
            monitored={formik.values.monitoredToolbox}
            status={formik.values.statusToolbox}
            statusName={'statusToolbox'}
            observation={formik.values.observationsToolboxCheck}
            observationSwitchName={'observationsToolboxCheck'}
            touched={formik.touched.observationsToolbox}
            errors={formik.errors.observationsToolbox}
            handleChange={formik.handleChange}
            value={formik.values.observationsToolbox}
            textName={'observationsToolbox'}
          />
          <CheckVehicle
            title={'Triángulos o cono'}
            setValues={formik.setFieldValue}
            switchName={'monitoredTriangle'}
            monitored={formik.values.monitoredTriangle}
            status={formik.values.statusTriangle}
            statusName={'statusTriangle'}
            observation={formik.values.observationsTriangleCheck}
            observationSwitchName={'observationsTriangleCheck'}
            touched={formik.touched.observationsTriangle}
            errors={formik.errors.observationsTriangle}
            handleChange={formik.handleChange}
            value={formik.values.observationsTriangle}
            textName={'observationsTriangle'}
          />
          <CheckVehicle
            title={'Cable Toma Corriente'}
            setValues={formik.setFieldValue}
            switchName={'monitoredPowerCord'}
            monitored={formik.values.monitoredPowerCord}
            status={formik.values.statusPowerCord}
            statusName={'statusPowerCord'}
            observation={formik.values.observationsPowerCordCheck}
            observationSwitchName={'observationsPowerCordCheck'}
            touched={formik.touched.observationsPowerCord}
            errors={formik.errors.observationsPowerCord}
            handleChange={formik.handleChange}
            value={formik.values.observationsPowerCord}
            textName={'observationsPowerCord'}
          />
          <CheckVehicle
            title={'Bolsa de Aire'}
            setValues={formik.setFieldValue}
            switchName={'monitoredAirbag'}
            monitored={formik.values.monitoredAirbag}
            status={formik.values.statusAirbag}
            statusName={'statusAirbag'}
            observation={formik.values.observationsAirbagCheck}
            observationSwitchName={'observationsAirbagCheck'}
            touched={formik.touched.observationsAirbag}
            errors={formik.errors.observationsAirbag}
            handleChange={formik.handleChange}
            value={formik.values.observationsAirbag}
            textName={'observationsAirbag'}
          />
          <CheckVehicle
            title={'Barra Antivuelco Interior'}
            setValues={formik.setFieldValue}
            switchName={'monitoredInnerAntiRollBar'}
            monitored={formik.values.monitoredInnerAntiRollBar}
            status={formik.values.statusInnerAntiRollBar}
            statusName={'statusInnerAntiRollBar'}
            observation={formik.values.observationsInnerAntiRollBarCheck}
            observationSwitchName={'observationsInnerAntiRollBarCheck'}
            touched={formik.touched.observationsInnerAntiRollBar}
            errors={formik.errors.observationsInnerAntiRollBar}
            handleChange={formik.handleChange}
            value={formik.values.observationsInnerAntiRollBar}
            textName={'observationsInnerAntiRollBar'}
          />
          <CheckVehicle
            title={'Barra Antivuelco Exterior'}
            setValues={formik.setFieldValue}
            switchName={'monitoredOuterAntiRollBar'}
            monitored={formik.values.monitoredOuterAntiRollBar}
            status={formik.values.statusOuterAntiRollBar}
            statusName={'statusOuterAntiRollBar'}
            observation={formik.values.observationsOuterAntiRollBarCheck}
            observationSwitchName={'observationsOuterAntiRollBarCheck'}
            touched={formik.touched.observationsOuterAntiRollBar}
            errors={formik.errors.observationsOuterAntiRollBar}
            handleChange={formik.handleChange}
            value={formik.values.observationsOuterAntiRollBar}
            textName={'observationsOuterAntiRollBar'}
          />
          <CheckVehicle
            title={'Bocina'}
            setValues={formik.setFieldValue}
            switchName={'monitoredHorn'}
            monitored={formik.values.monitoredHorn}
            status={formik.values.statusHorn}
            statusName={'statusHorn'}
            observation={formik.values.observationsHornCheck}
            observationSwitchName={'observationsHornCheck'}
            touched={formik.touched.observationsHorn}
            errors={formik.errors.observationsHorn}
            handleChange={formik.handleChange}
            value={formik.values.observationsHorn}
            textName={'observationsHorn'}
          />
          <CheckVehicle
            title={'Alarma de Retroceso'}
            setValues={formik.setFieldValue}
            switchName={'monitoredBackupAlarm'}
            monitored={formik.values.monitoredBackupAlarm}
            status={formik.values.statusBackupAlarm}
            statusName={'statusBackupAlarm'}
            observation={formik.values.observationsBackupAlarmCheck}
            observationSwitchName={'observationsBackupAlarmCheck'}
            touched={formik.touched.observationsBackupAlarm}
            errors={formik.errors.observationsBackupAlarm}
            handleChange={formik.handleChange}
            value={formik.values.observationsBackupAlarm}
            textName={'observationsBackupAlarm'}
          />
          <CheckVehicle
            title={'Palanca de Cambios'}
            setValues={formik.setFieldValue}
            switchName={'monitoredGearShift'}
            monitored={formik.values.monitoredGearShift}
            status={formik.values.statusGearShift}
            statusName={'statusGearShift'}
            observation={formik.values.observationsGearShiftCheck}
            observationSwitchName={'observationsGearShiftCheck'}
            touched={formik.touched.observationsGearShift}
            errors={formik.errors.observationsGearShift}
            handleChange={formik.handleChange}
            value={formik.values.observationsGearShift}
            textName={'observationsGearShift'}
          />
          <CheckVehicle
            title={'Radio'}
            setValues={formik.setFieldValue}
            switchName={'monitoredRadio'}
            monitored={formik.values.monitoredRadio}
            status={formik.values.statusRadio}
            statusName={'statusRadio'}
            observation={formik.values.observationsRadioCheck}
            observationSwitchName={'observationsRadioCheck'}
            touched={formik.touched.observationsRadio}
            errors={formik.errors.observationsRadio}
            handleChange={formik.handleChange}
            value={formik.values.observationsRadio}
            textName={'observationsRadio'}
          />
          <CheckVehicle
            title={'Antena de Radio'}
            setValues={formik.setFieldValue}
            switchName={'monitoredRadioAntenna'}
            monitored={formik.values.monitoredRadioAntenna}
            status={formik.values.statusRadioAntenna}
            statusName={'statusRadioAntenna'}
            observation={formik.values.observationsRadioAntennaCheck}
            observationSwitchName={'observationsRadioAntennaCheck'}
            touched={formik.touched.observationsRadioAntenna}
            errors={formik.errors.observationsRadioAntenna}
            handleChange={formik.handleChange}
            value={formik.values.observationsRadioAntenna}
            textName={'observationsRadioAntenna'}
          />
          <CheckVehicle
            title={'Limpia parabrisas'}
            setValues={formik.setFieldValue}
            switchName={'monitoredWindshieldWipers'}
            monitored={formik.values.monitoredWindshieldWipers}
            status={formik.values.statusWindshieldWipers}
            statusName={'statusWindshieldWipers'}
            observation={formik.values.observationsWindshieldWipersCheck}
            observationSwitchName={'observationsWindshieldWipersCheck'}
            touched={formik.touched.observationsWindshieldWipers}
            errors={formik.errors.observationsWindshieldWipers}
            handleChange={formik.handleChange}
            value={formik.values.observationsWindshieldWipers}
            textName={'observationsWindshieldWipers'}
          />
          <CheckVehicle
            title={'Gomas limpiaparabrisas'}
            setValues={formik.setFieldValue}
            switchName={'monitoredWiperRubbers'}
            monitored={formik.values.monitoredWiperRubbers}
            status={formik.values.statusWiperRubbers}
            statusName={'statusWiperRubbers'}
            observation={formik.values.observationsWiperRubbersCheck}
            observationSwitchName={'observationsWiperRubbersCheck'}
            touched={formik.touched.observationsWiperRubbers}
            errors={formik.errors.observationsWiperRubbers}
            handleChange={formik.handleChange}
            value={formik.values.observationsWiperRubbers}
            textName={'observationsWiperRubbers'}
          />
          <CheckVehicle
            title={'Linterna'}
            setValues={formik.setFieldValue}
            switchName={'monitoredFlashlight'}
            monitored={formik.values.monitoredFlashlight}
            status={formik.values.statusFlashlight}
            statusName={'statusFlashlight'}
            observation={formik.values.observationsFlashlightCheck}
            observationSwitchName={'observationsFlashlightCheck'}
            touched={formik.touched.observationsFlashlight}
            errors={formik.errors.observationsFlashlight}
            handleChange={formik.handleChange}
            value={formik.values.observationsFlashlight}
            textName={'observationsFlashlight'}
          />
          <CheckVehicle
            title={'Gomas Piso'}
            setValues={formik.setFieldValue}
            switchName={'monitoredFloorRubbers'}
            monitored={formik.values.monitoredFloorRubbers}
            status={formik.values.statusFloorRubbers}
            statusName={'statusFloorRubbers'}
            observation={formik.values.observationsFloorRubbersCheck}
            observationSwitchName={'observationsFloorRubbersCheck'}
            touched={formik.touched.observationsFloorRubbers}
            errors={formik.errors.observationsFloorRubbers}
            handleChange={formik.handleChange}
            value={formik.values.observationsFloorRubbers}
            textName={'observationsFloorRubbers'}
          />
          <CheckVehicle
            title={'Pasamanos Subida'}
            setValues={formik.setFieldValue}
            switchName={'monitoredHandrail'}
            monitored={formik.values.monitoredHandrail}
            status={formik.values.statusHandrail}
            statusName={'statusHandrail'}
            observation={formik.values.observationsHandrailCheck}
            observationSwitchName={'observationsHandrailCheck'}
            touched={formik.touched.observationsHandrail}
            errors={formik.errors.observationsHandrail}
            handleChange={formik.handleChange}
            value={formik.values.observationsHandrail}
            textName={'observationsHandrail'}
          />
          <CheckVehicle
            title={'Tarjeta Carga Combustible (Copec)'}
            setValues={formik.setFieldValue}
            switchName={'monitoredCard'}
            monitored={formik.values.monitoredCard}
            status={formik.values.statusCard}
            statusName={'statusCard'}
            observation={formik.values.observatiosnCardCheck}
            observationSwitchName={'observatiosnCardCheck'}
            touched={formik.touched.observationsCard}
            errors={formik.errors.observationsCard}
            handleChange={formik.handleChange}
            value={formik.values.observationsCard}
            textName={'observationsCard'}
          />
        </HStack>
        <Box w="100%" bg="#F2F1FB" pb={5}>
          <Flex spacing={10} align="left" pt="15px">
            <Stack spacing={2} pl={30}>
              <Text fontSize="15px" fontWeight={'bold'} color="#36355F">
                Otros
              </Text>
            </Stack>
          </Flex>
        </Box>
        <HStack
          spacing="24px"
          overflowX={'scroll'}
          py={3}
          px={2}
          css={{
            '&::-webkit-scrollbar': {
              width: '4px'
            },
            '&::-webkit-scrollbar-track': {
              width: '6px'
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#59BABA',
              borderRadius: '15px'
            }
          }}
        >
          <CheckVehicle
            title={'Dirección'}
            setValues={formik.setFieldValue}
            switchName={'monitoredDirection'}
            monitored={formik.values.monitoredDirection}
            status={formik.values.statusDirection}
            statusName={'statusDirection'}
            observation={formik.values.observationsDirectionCheck}
            observationSwitchName={'observationsDirectionCheck'}
            touched={formik.touched.observationsDirection}
            errors={formik.errors.observationsDirection}
            handleChange={formik.handleChange}
            value={formik.values.observationsDirection}
            textName={'observationsDirection'}
          />
          <CheckVehicle
            title={'Amortiguadores'}
            setValues={formik.setFieldValue}
            switchName={'monitoredAbsorbers'}
            monitored={formik.values.monitoredAbsorbers}
            status={formik.values.statusAbsorbers}
            statusName={'statusAbsorbers'}
            observation={formik.values.observationsAbsorbersCheck}
            observationSwitchName={'observationsAbsorbersCheck'}
            touched={formik.touched.observationsAbsorbers}
            errors={formik.errors.observationsAbsorbers}
            handleChange={formik.handleChange}
            value={formik.values.observationsAbsorbers}
            textName={'observationsAbsorbers'}
          />
          <CheckVehicle
            title={'Pintura'}
            setValues={formik.setFieldValue}
            switchName={'monitoredPaint'}
            monitored={formik.values.monitoredPaint}
            status={formik.values.statusPaint}
            statusName={'statusPaint'}
            observation={formik.values.observationsPaintCheck}
            observationSwitchName={'observationsPaintCheck'}
            touched={formik.touched.observationsPaint}
            errors={formik.errors.observationsPaint}
            handleChange={formik.handleChange}
            value={formik.values.observationsPaint}
            textName={'observationsPaint'}
          />
          <CheckVehicle
            title={'Logo de la Empresa'}
            setValues={formik.setFieldValue}
            switchName={'monitoredCompanyLogo'}
            monitored={formik.values.monitoredCompanyLogo}
            status={formik.values.statusCompanyLogo}
            statusName={'statusCompanyLogo'}
            observation={formik.values.observationsCompanyLogoCheck}
            observationSwitchName={'observationsCompanyLogoCheck'}
            touched={formik.touched.observationsCompanyLogo}
            errors={formik.errors.observationsCompanyLogo}
            handleChange={formik.handleChange}
            value={formik.values.observationsCompanyLogo}
            textName={'observationsCompanyLogo'}
          />
          <CheckVehicle
            title={'Carrocería'}
            setValues={formik.setFieldValue}
            switchName={'monitoredBody'}
            monitored={formik.values.monitoredBody}
            status={formik.values.statusBody}
            statusName={'statusBody'}
            observation={formik.values.observationsBodyCheck}
            observationSwitchName={'observationsBodyCheck'}
            touched={formik.touched.observationsBody}
            errors={formik.errors.observationsBody}
            handleChange={formik.handleChange}
            value={formik.values.observationsBody}
            textName={'observationsBody'}
          />
          <CheckVehicle
            title={'Pisaderas'}
            setValues={formik.setFieldValue}
            switchName={'monitoredFootrests'}
            monitored={formik.values.monitoredFootrests}
            status={formik.values.statusFootrests}
            statusName={'statusFootrests'}
            observation={formik.values.observationsFootrestsCheck}
            observationSwitchName={'observationsFootrestsCheck'}
            touched={formik.touched.observationsFootrests}
            errors={formik.errors.observationsFootrests}
            handleChange={formik.handleChange}
            value={formik.values.observationsFootrests}
            textName={'observationsFootrests'}
          />
          <CheckVehicle
            title={'Plataforma'}
            setValues={formik.setFieldValue}
            switchName={'monitoredPlatform'}
            monitored={formik.values.monitoredPlatform}
            status={formik.values.statusPlatform}
            statusName={'statusPlatform'}
            observation={formik.values.observationsPlatformCheck}
            observationSwitchName={'observationsPlatformCheck'}
            touched={formik.touched.observationsPlatform}
            errors={formik.errors.observationsPlatform}
            handleChange={formik.handleChange}
            value={formik.values.observationsPlatform}
            textName={'observationsPlatform'}
          />
          <CheckVehicle
            title={'Escalera'}
            setValues={formik.setFieldValue}
            switchName={'monitoredLadder'}
            monitored={formik.values.monitoredLadder}
            status={formik.values.statusLadder}
            statusName={'statusLadder'}
            observation={formik.values.observationsLadderCheck}
            observationSwitchName={'observationsLadderCheck'}
            touched={formik.touched.observationsLadder}
            errors={formik.errors.observationsLadder}
            handleChange={formik.handleChange}
            value={formik.values.observationsLadder}
            textName={'observationsLadder'}
          />
          <CheckVehicle
            title={'Baranda'}
            setValues={formik.setFieldValue}
            switchName={'monitoredRailing'}
            monitored={formik.values.monitoredRailing}
            status={formik.values.statusRailing}
            statusName={'statusRailing'}
            observation={formik.values.observationsRailingCheck}
            observationSwitchName={'observationsRailingCheck'}
            touched={formik.touched.observationsRailing}
            errors={formik.errors.observationsRailing}
            handleChange={formik.handleChange}
            value={formik.values.observationsRailing}
            textName={'observationsRailing'}
          />
          <CheckVehicle
            title={'Manguera'}
            setValues={formik.setFieldValue}
            switchName={'monitoredHose'}
            monitored={formik.values.monitoredHose}
            status={formik.values.statusHose}
            statusName={'statusHose'}
            observation={formik.values.observationsHoseCheck}
            observationSwitchName={'observationsHoseCheck'}
            touched={formik.touched.observationsHose}
            errors={formik.errors.observationsHose}
            handleChange={formik.handleChange}
            value={formik.values.observationsHose}
            textName={'observationsHose'}
          />
          <CheckVehicle
            title={'Parachoques delanteros'}
            setValues={formik.setFieldValue}
            switchName={'monitoredFrontBumpers'}
            monitored={formik.values.monitoredFrontBumpers}
            status={formik.values.statusFrontBumpers}
            statusName={'statusFrontBumpers'}
            observation={formik.values.observationsFrontBumpersCheck}
            observationSwitchName={'observationsFrontBumpersCheck'}
            touched={formik.touched.observationsFrontBumpers}
            errors={formik.errors.observationsFrontBumpers}
            handleChange={formik.handleChange}
            value={formik.values.observationsFrontBumpers}
            textName={'observationsFrontBumpers'}
          />
          <CheckVehicle
            title={'Parachoques traseros'}
            setValues={formik.setFieldValue}
            switchName={'monitoredBackBumpers'}
            monitored={formik.values.monitoredBackBumpers}
            status={formik.values.statusBackBumpers}
            statusName={'statusBackBumpers'}
            observation={formik.values.observationsBackBumpersCheck}
            observationSwitchName={'observationsBackBumpersCheck'}
            touched={formik.touched.observationsBackBumpers}
            errors={formik.errors.observationsBackBumpers}
            handleChange={formik.handleChange}
            value={formik.values.observationsBackBumpers}
            textName={'observationsBackBumpers'}
          />
          <CheckVehicle
            title={'Tapa Barros'}
            setValues={formik.setFieldValue}
            switchName={'monitoredMudCover'}
            monitored={formik.values.monitoredMudCover}
            status={formik.values.statusMudCover}
            statusName={'statusMudCover'}
            observation={formik.values.observationsMudCoverCheck}
            observationSwitchName={'observationsMudCoverCheck'}
            touched={formik.touched.observationsMudCover}
            errors={formik.errors.observationsMudCover}
            handleChange={formik.handleChange}
            value={formik.values.observationsMudCover}
            textName={'observationsMudCover'}
          />
        </HStack>
        <Box w="100%" bg="#F2F1FB" pb={5}>
          <Flex spacing={10} align="left" pt="15px">
            <Stack spacing={2} pl={30}>
              <Text fontSize="15px" fontWeight={'bold'} color="#36355F">
                Otros
              </Text>
            </Stack>
          </Flex>
        </Box>
        <HStack
          spacing="24px"
          overflowX={'scroll'}
          py={3}
          px={2}
          css={{
            '&::-webkit-scrollbar': {
              width: '4px'
            },
            '&::-webkit-scrollbar-track': {
              width: '6px'
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#59BABA',
              borderRadius: '15px'
            }
          }}
        >
          <CheckVehicle
            title={'Estado soporte flecha'}
            setValues={formik.setFieldValue}
            switchName={'monitoredArrowSupport'}
            monitored={formik.values.monitoredArrowSupport}
            status={formik.values.statusArrowSupport}
            statusName={'statusArrowSupport'}
            observation={formik.values.observationsArrowSupportCheck}
            observationSwitchName={'observationsArrowSupportCheck'}
            touched={formik.touched.observationsArrowSupport}
            errors={formik.errors.observationsArrowSupport}
            handleChange={formik.handleChange}
            value={formik.values.observationsArrowSupport}
            textName={'observationsArrowSupport'}
          />
          <CheckVehicle
            title={'Led Flecha'}
            setValues={formik.setFieldValue}
            switchName={'monitoredLedArrow'}
            monitored={formik.values.monitoredLedArrow}
            status={formik.values.statusLedArrow}
            statusName={'statusLedArrow'}
            observation={formik.values.observationsLedArrowCheck}
            observationSwitchName={'observationsLedArrowCheck'}
            touched={formik.touched.observationsLedArrow}
            errors={formik.errors.observationsLedArrow}
            handleChange={formik.handleChange}
            value={formik.values.observationsLedArrow}
            textName={'observationsLedArrow'}
          />
          <CheckVehicle
            title={'Soporte Baliza'}
            setValues={formik.setFieldValue}
            switchName={'monitoredBeaconSupport'}
            monitored={formik.values.monitoredBeaconSupport}
            status={formik.values.statusBeaconSupport}
            statusName={'statusBeaconSupport'}
            observation={formik.values.observationsBeaconSupportCheck}
            observationSwitchName={'observationsBeaconSupportCheck'}
            touched={formik.touched.observationsBeaconSupport}
            errors={formik.errors.observationsBeaconSupport}
            handleChange={formik.handleChange}
            value={formik.values.observationsBeaconSupport}
            textName={'observationsBeaconSupport'}
          />
          <CheckVehicle
            title={'Baliza'}
            setValues={formik.setFieldValue}
            switchName={'monitoredBeacon2'}
            monitored={formik.values.monitoredBeacon2}
            status={formik.values.statusBeacon2}
            statusName={'statusBeacon2'}
            observation={formik.values.observationsBeacon2Check}
            observationSwitchName={'observationsBeacon2Check'}
            touched={formik.touched.observationsBeacon2}
            errors={formik.errors.observationsBeacon2}
            handleChange={formik.handleChange}
            value={formik.values.observationsBeacon2}
            textName={'observationsBeacon2'}
          />
        </HStack>
        <VStack spacing={8} py={5} px={6}>
          <HStack justify={'space-between'} w={['100%', '15%']}>
            <Text fontSize="14px" fontWeight="bold" color="#36355F">
              Otro
            </Text>
            <Checkbox
              size="md"
              colorScheme="blue"
              name="Other"
              isChecked={check}
              onChange={handleChangeCheck}
              borderColor="grey"
            ></Checkbox>
          </HStack>
          {check && (
            <FormControl id="other">
              <FormLabel>Descripción y estado</FormLabel>
              <Input
                name="other"
                value={formik.values.other}
                onChange={formik.handleChange}
                placeholder="Otro"
              />
            </FormControl>
          )}
          <FormControl id="observations">
            <FormLabel>Observaciones</FormLabel>
            <Textarea
              name="observations"
              value={formik.values.observations}
              onChange={formik.handleChange}
              placeholder="Observaciones"
              h="20%"
              maxLength={1000}
            />
          </FormControl>
        </VStack>
      </>
    )
  }

  // Página cuatro: fotos
  const stepFour = () => {
    return (
      <>
        <Text fontSize="17px" fontWeight="bold" color="#36355F">
          Foto delantero exterior
        </Text>
        {formik.values.exteriorFrontImage ? (
          <RenderImageContainer
            images={[
              {
                url: formik.values.exteriorFrontImage,
                observation: formik.values.exteriorFrontImageObservation
              }
            ]}
            getNewArray={(arr) => {
              const img = arr?.at(0)
              formik.setFieldValue(
                'exteriorFrontImageObservation',
                img.observation
              )
            }}
            getNewArrayDelete={() => {
              formik.setFieldValue('exteriorFrontImage', '')
              formik.setFieldValue('exteriorFrontImageObservation', '')
            }}
          />
        ) : (
          <UploadImage imgUpload={imgUploadFront} />
        )}
        <Text fontSize="17px" fontWeight="bold" color="#36355F">
          Foto delantero posterior
        </Text>
        {formik.values.backSideImage ? (
          <RenderImageContainer
            images={[
              {
                url: formik.values.backSideImage,
                observation: formik.values.backSideImageObservation
              }
            ]}
            getNewArray={(arr) => {
              const img = arr?.at(0)
              formik.setFieldValue('backSideImageObservation', img.observation)
            }}
            getNewArrayDelete={() => {
              formik.setFieldValue('backSideImage', '')
              formik.setFieldValue('backSideImageObservation', '')
            }}
          />
        ) : (
          <UploadImage imgUpload={imgUploadBack} />
        )}
        <Text fontSize="17px" fontWeight="bold" color="#36355F">
          Foto lateral izquierdo
        </Text>
        {formik.values.leftSideImage ? (
          <RenderImageContainer
            images={[
              {
                url: formik.values.leftSideImage,
                observation: formik.values.leftSideImageObservation
              }
            ]}
            getNewArray={(arr) => {
              const img = arr?.at(0)
              formik.setFieldValue('leftSideImageObservation', img.observation)
            }}
            getNewArrayDelete={() => {
              formik.setFieldValue('leftSideImage', '')
              formik.setFieldValue('leftSideImageObservation', '')
            }}
          />
        ) : (
          <UploadImage imgUpload={imgUploadLeft} />
        )}
        <Text fontSize="17px" fontWeight="bold" color="#36355F">
          Foto lateral derecho
        </Text>
        {formik.values.rightSideImage ? (
          <RenderImageContainer
            images={[
              {
                url: formik.values.rightSideImage,
                observation: formik.values.rightSideImageObservation
              }
            ]}
            getNewArray={(arr) => {
              const img = arr?.at(0)
              formik.setFieldValue('rightSideImageObservation', img.observation)
            }}
            getNewArrayDelete={() => {
              formik.setFieldValue('rightSideImage', '')
              formik.setFieldValue('rightSideImageObservation', '')
            }}
          />
        ) : (
          <UploadImage imgUpload={imgUploadRight} />
        )}
        <Text fontSize="17px" fontWeight="bold" color="#36355F">
          Foto interior
        </Text>
        {formik.values.interiorImage ? (
          <RenderImageContainer
            images={[
              {
                url: formik.values.interiorImage,
                observation: formik.values.interiorImageObservation
              }
            ]}
            getNewArray={(arr) => {
              const img = arr?.at(0)
              formik.setFieldValue('interiorImageObservation', img.observation)
            }}
            getNewArrayDelete={() => {
              formik.setFieldValue('interiorImage', '')
              formik.setFieldValue('interiorImageObservation', '')
            }}
          />
        ) : (
          <UploadImage imgUpload={imgUploadInterior} />
        )}
        <Text fontSize="17px" fontWeight="bold" color="#36355F">
          Foto tablero
        </Text>
        {formik.values.boardImage ? (
          <RenderImageContainer
            images={[
              {
                url: formik.values.boardImage,
                observation: formik.values.boardImageObservation
              }
            ]}
            getNewArray={(arr) => {
              const img = arr?.at(0)
              formik.setFieldValue('boardImageObservation', img.observation)
            }}
            getNewArrayDelete={() => {
              formik.setFieldValue('boardImage', '')
              formik.setFieldValue('boardImageObservation', '')
            }}
          />
        ) : (
          <UploadImage imgUpload={imgUploadBoard} />
        )}
      </>
    )
  }

  // Controlador de página
  const stepper = () => {
    if (step === 1) {
      return stepOne()
    }
    if (step === 2) {
      return stepTwo()
    }
    if (step === 3) {
      return stepThree()
    }
    if (step === 4) {
      return stepFour()
    }
    return null
  }

  const handleBack = () => {
    history.goBack()
  }

  useEffect(() => {
    const { front, back, left, right, interior, board } = isLoadingUploadFile
    if (front || back || left || right || interior || board) {
      toast({
        duration: 9000 * 9000,
        isClosable: true,
        position: 'bottom-right',
        render: ({ onClose }) => (
          <CustomToast
            text={'Cargando Imagen...'}
            status={'info'}
            onClose={onClose}
          />
        )
      })
      return
    }
    toast.closeAll()
  }, [isLoadingUploadFile])

  return (
    <Stack spacing={8} py={12} px={6}>
      <Flex>
        <Stack spacing={10} align={'center'}>
          <Button
            onClick={handleBack}
            bg="#FFFFFF"
            color="#666587"
            border="1px"
            borderStyle="solid"
            borderRadius="lg"
            borderColor="#F2F1FB"
          >
            Regresar
          </Button>
        </Stack>
      </Flex>
      <Box w="100%" bg="#F2F1FB" pb={5}>
        <Flex spacing={10} align="left" pt="15px">
          <Stack spacing={2} pl={30}>
            <Text fontSize="15px" color="#36355F">
              <Icon mr="4" fontSize="22" as={VehicleIcon} color="#36355F" />
              Checklist de vehiculos
            </Text>
          </Stack>
          <Spacer />
          <Stack spacing={2} pr={30}>
            <Text fontSize="10px" color="#36355F">
              {currentDate()}
            </Text>
          </Stack>
        </Flex>
      </Box>
      {vehicle ? (
        <>
          <Box w="100%" bg="#D1F1F5" pb={5}>
            <Flex spacing={10} align="left" pt="15px">
              <Stack spacing={2} pl={30}>
                <Text fontSize="20px" color="#36355F">
                  Modelo
                </Text>
                <Text fontSize="25px" fontWeight="bold" color="#36355F">
                  {vehicle.model}
                </Text>
              </Stack>
              <Spacer />
              <IconButton
                icon={
                  <Icon
                    fontSize="22"
                    as={DeleteIcon}
                    color="#59BABA"
                    bg="#D1F1F5"
                  />
                }
                bg="#D1F1F5"
                onClick={() => setVehicle(null)}
              />
            </Flex>
            <Flex spacing={10} align="left" pt="15px">
              <Stack spacing={2} pl={30}>
                <Text fontSize="10px" color="#36355F">
                  Marca
                </Text>
                <Text fontSize="17px" fontWeight="bold" color="#36355F">
                  {vehicle.brand}
                </Text>
              </Stack>
              <Stack spacing={2} pl={30}>
                <Text fontSize="10px" color="#36355F">
                  Año
                </Text>
                <Text fontSize="17px" fontWeight="bold" color="#36355F">
                  {vehicle.year}
                </Text>
              </Stack>
              <Stack spacing={2} pl={30}>
                <Text fontSize="10px" color="#36355F">
                  Patente
                </Text>
                <Text fontSize="17px" fontWeight="bold" color="#36355F">
                  {vehicle.plate}
                </Text>
              </Stack>
            </Flex>
          </Box>
          <Stack spacing={2} bg="#F2F1FB" borderRadius="lg">
            <Flex spacing={10} align="left">
              <Box
                as="button"
                px={4}
                py={2}
                bg={step === 1 ? '#D1F1F5' : '#F2F1FB'}
                borderRadius="lg"
                display="flex"
                onClick={() => changeStep(1)}
              >
                <Text
                  fontSize="15px"
                  fontWeight="bold"
                  color={step === 1 ? '#59BABA' : '#36355F'}
                  m="auto"
                >
                  General
                </Text>
              </Box>
              <Spacer />
              <Box
                as="button"
                px={4}
                py={2}
                bg={step === 2 ? '#D1F1F5' : '#F2F1FB'}
                borderRadius="lg"
                display="flex"
                onClick={() => changeStep(2)}
              >
                <Text
                  fontSize="15px"
                  fontWeight="bold"
                  color={step === 2 ? '#59BABA' : '#36355F'}
                  m="auto"
                >
                  Partes
                </Text>
              </Box>
              <Spacer />
              <Box
                as="button"
                px={4}
                py={2}
                bg={step === 3 ? '#D1F1F5' : '#F2F1FB'}
                borderRadius="lg"
                display="flex"
                onClick={() => changeStep(3)}
              >
                <Text
                  fontSize="15px"
                  fontWeight="bold"
                  color={step === 3 ? '#59BABA' : '#36355F'}
                  m="auto"
                >
                  Otros
                </Text>
              </Box>
              <Spacer />
              <Box
                as="button"
                px={4}
                py={2}
                bg={step === 4 ? '#D1F1F5' : '#F2F1FB'}
                borderRadius="lg"
                display="flex"
                onClick={() => changeStep(4)}
              >
                <Text
                  fontSize="15px"
                  fontWeight="bold"
                  color={step === 4 ? '#59BABA' : '#36355F'}
                  m="auto"
                >
                  Fotos
                </Text>
              </Box>
            </Flex>
          </Stack>
          {stepper()}
          <Stack spacing={10} align={'center'}>
            <Flex spacing={10} align="center" pt="15px">
              {step > 1 ? (
                <Button
                  align="center"
                  isLoading={isLoading}
                  onClick={prevStep}
                  bg="#F2F1FB"
                  color="#59BABA"
                  disabled={isLoading}
                  mr={5}
                >
                  Regresar
                </Button>
              ) : null}
              {step > 3 ? null : (
                <Button
                  align="center"
                  isLoading={isLoading}
                  onClick={nextStep}
                  bg={'teal.500'}
                  color={'white'}
                  _hover={{
                    bg: 'teal.600'
                  }}
                  disabled={isLoading}
                >
                  Siguiente
                </Button>
              )}
            </Flex>
            {step > 3 ? (
              <Button
                align="center"
                isLoading={isLoading}
                onClick={handleClickConfirm}
                bg="#FFFFFF"
                color="#59BABA"
                border="1px"
                borderColor="#59BABA"
                disabled={isLoading}
              >
                Guardar documento
              </Button>
            ) : null}

            {openConfirm && (
              <ConfirmModal
                isOpen={openConfirm}
                onClose={closeConfirm}
                route={`/visitas`}
                confirm={confirm}
                setConfirm={setConfirm}
                handleSubmit={formik.handleSubmit}
                loading={isLoading}
              />
            )}
          </Stack>
        </>
      ) : (
        <FormControl id="vehicle">
          <FormLabel>Introduzca patente del vehiculo</FormLabel>
          <Select
            placeholder="Buscar"
            name="vehicle"
            onChange={(e) => {
              setVehicle(JSON.parse(e.target.value))
            }}
            icon={<SearchIcon />}
          >
            {vehicles
              ? vehicles.map((v) => (
                  <option value={JSON.stringify(v)} key={v.plate}>
                    {v.plate} {v.brand} {v.model} {v.year}
                  </option>
                ))
              : []}
          </Select>
        </FormControl>
      )}
    </Stack>
  )
}

export default EditVehicles
