import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  Flex,
  Box,
  Stack,
  HStack,
  Heading,
  Button,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Text,
  Avatar,
  VStack,
  Grid,
  GridItem,
  Input,
  Select,
  Skeleton,
  useDisclosure,
  useToast
} from '@chakra-ui/react'
import { AddIcon } from '@chakra-ui/icons'

import Roles from '../../resources/Roles'
import { useUsers, useMutateUser } from '../../hooks/users'
import DeleteModal from '../../components/Shared/DeleteModal'
import Pagination from '../../components/Shared/Pagination'

import { updateFilterList } from '../../store/slices/user'

// Pantalla de usuarios
const Users = () => {
  const dispatch = useDispatch()
  const { filterList } = useSelector((state) => state.user)
  const history = useHistory()
  const toast = useToast()
  const [selectedUser, setSelectedUser] = useState({
    id: 0,
    name: '',
    mail: '',
    role: 0,
    image: ''
  })
  const { data: users, refetch } = useUsers(filterList.page, {
    search: filterList.search,
    role: filterList.role
  })
  const { mutate } = useMutateUser('DELETE')
  const {
    isOpen: openDelete,
    onOpen: onOpenDelete,
    onClose: closeDelete
  } = useDisclosure()

  const handleClick = () => {
    history.push(`/usuarios/nuevo`)
  }

  // Obtener rol
  const getRole = (id) => {
    const role = Roles.find((rol) => {
      return rol.id === id
    })
    return role
  }

  // Manejador de usuario seleccionado
  const openOptions = (user) => {
    setSelectedUser(user)
  }
  const closeOptions = () => {
    setSelectedUser({ id: 0, name: '', mail: '', role: 0, image: '' })
  }

  const handleChangeGlobalState = (fieldName, value) => {
    dispatch(
      updateFilterList({
        page: 1,
        [fieldName]: value
      })
    )
  }

  useEffect(() => {
    refetch()
  }, [filterList])

  const handleDelete = (user) => {
    mutate(user, {
      onSuccess: () => {
        toast({
          title: `Usuario eliminado`,
          status: 'success',
          isClosable: true
        })
        dispatch(updateFilterList({ page: 1 }))
        refetch()
      },
      onError: () => {
        toast({
          title: `Error al eliminar usuario`,
          status: 'error',
          isClosable: true
        })
      }
    })
    closeDelete()
  }

  const handleEdit = () => {
    history.push(`/usuarios/${selectedUser.id}`)
  }

  return (
    <Stack spacing={8} px={6}>
      <Flex bg={'#FFF'}>
        <Box w="100%">
          <HStack justify={'space-between'} align="center" p={2}>
            <Heading fontSize={['xl', '4xl']} color={'#36355F'}>
              Usuarios
            </Heading>
            <Button
              onClick={handleClick}
              bg={'teal.500'}
              color={'white'}
              _hover={{
                bg: 'teal.600'
              }}
              leftIcon={<AddIcon />}
            >
              Agregar usuario
            </Button>
          </HStack>
          <Stack spacing={10} align={'left'}></Stack>
        </Box>
      </Flex>
      {users ? (
        <>
          <Grid display="flex">
            <GridItem display="flex" gap={3} w="100%">
              <Input
                borderRadius="15px"
                value={filterList.search}
                onChange={(e) =>
                  handleChangeGlobalState('search', e.currentTarget.value)
                }
                marginRight={3}
              />
              <Select
                placeholder="Seleccione"
                name="role"
                onChange={(e) =>
                  handleChangeGlobalState('role', e.currentTarget.value)
                }
                value={filterList.role}
              >
                {Roles.map((role) => (
                  <option value={role.id}>{role.name}</option>
                ))}
              </Select>
              {/* <IconButton
                backgroundColor="#59BABA"
                icon={<SearchIcon color="white" />}
                onClick={handleSearch}
              /> */}
            </GridItem>
          </Grid>
          <Flex bg={'#FFF'}>
            <Box w="100%">
              <TableContainer>
                <Table variant="simple" size="sm">
                  <Thead>
                    <Tr>
                      <Th color="#36355F" fontWeight={'normal'}>
                        Nombres y apellidos
                      </Th>
                      <Th color="#36355F" fontWeight={'normal'}>
                        Rol
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {users.items.map((user) => (
                      <Tr>
                        <Td>
                          <HStack>
                            <Box as="button" onClick={() => openOptions(user)}>
                              <Avatar name={user.name} src={user.image || ''} />
                            </Box>
                            <VStack>
                              <Text
                                fontSize={['13px', '20px']}
                                color="#36355F"
                                fontWeight="bold"
                              >
                                {user.name}
                              </Text>
                              <Text fontSize={['9px', '15px']} color="#36355F">
                                {user.mail}
                              </Text>
                            </VStack>
                          </HStack>
                          {selectedUser.id === user.id ? (
                            <HStack
                              justify={'space-between'}
                              w={['90%', '100%']}
                              pt={4}
                            >
                              <Button
                                align="center"
                                onClick={closeOptions}
                                bg="#FFFFFF"
                                color="#59BABA"
                                border="1px"
                                borderColor="#59BABA"
                              >
                                Cancelar
                              </Button>
                              <Button
                                align="center"
                                onClick={onOpenDelete}
                                bg="#F2F1FB"
                                color="#59BABA"
                              >
                                Eliminar
                              </Button>
                              <Button
                                align="center"
                                onClick={handleEdit}
                                bg="#59BABA"
                                color="#FFFFFF"
                              >
                                Editar
                              </Button>
                            </HStack>
                          ) : null}
                        </Td>
                        <Td>
                          <Text
                            fontSize={['10px', '15px']}
                            color="#36355F"
                            fontWeight="bold"
                          >
                            {getRole(user.id_role).name}
                          </Text>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </Box>
          </Flex>
          <Grid m="auto" pt="2%" alignSelf={'center'}>
            <Pagination
              totalPages={Math.ceil(users.total / 10)}
              actualPage={users.page}
              setPage={(page) => {
                dispatch(
                  updateFilterList({
                    page
                  })
                )
              }}
            />
          </Grid>
        </>
      ) : (
        <Stack>
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
        </Stack>
      )}
      {openDelete && (
        <DeleteModal
          isOpen={openDelete}
          onClose={closeDelete}
          message={`¿Esta seguro de querer eliminar al usuario ${selectedUser.name}?`}
          handleDelete={handleDelete}
          selected={selectedUser}
        />
      )}
    </Stack>
  )
}

export default Users
