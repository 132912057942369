import { useState } from 'react'
import { Text, Select } from '@chakra-ui/react'

import PersonForm from './PersonForm'
import CompanyForm from './CompanyForm'

// Formulario para crear cliente
const SingleClient = () => {
  const [type, setType] = useState('')
  const handleChangetype = (event) => {
    setType(event.target.value)
  }
  return (
    <>
      <Text fontSize="16px" fontWeight="bold" color="#36355F">
        Tipo de cliente
      </Text>
      <Select placeholder="Seleccione" name="type" onChange={handleChangetype}>
        <option value="COMPANY">Empresa</option>
        <option value="PERSON">Persona</option>
      </Select>
      {type ? type === 'COMPANY' ? <CompanyForm /> : <PersonForm /> : <></>}
    </>
  )
}

export default SingleClient
