import * as Yup from 'yup'
/* eslint-disable import/prefer-default-export */
export const productSchema = Yup.object().shape({
  requiresQuantity: Yup.boolean(),
  quantity: Yup.number().when('requiresQuantity', {
    is: true,
    then: Yup.number('La cantidad es requerida')
      .min(0, 'La cantidad debe ser al menos 0')
      .required('La cantidad es requerida'),
    otherwise: Yup.number().nullable()
  }),
  value: Yup.number('El valor es requerido')
    .min(0, 'La cantidad debe ser al menos 0')
    .required('El valor es requerido'),
  productId: Yup.number().required('El ID del producto es requerido'),
  productCommercialName: Yup.string().required(
    'El nombre comercial del producto es requerido'
  ),
  productUnit: Yup.string().required('La unidad del producto es requerida')
})

export const schema = Yup.object().shape({
  products: Yup.array().of(productSchema)
})
