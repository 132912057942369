import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import {
  Flex,
  Stack,
  VStack,
  HStack,
  Box,
  Button,
  Icon,
  Spacer,
  Text,
  Textarea,
  Checkbox,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Grid,
  useDisclosure,
  Select,
  Skeleton,
  useToast,
  IconButton,
  InputGroup,
  InputRightElement
} from '@chakra-ui/react'
import { ArrowBackIcon } from '@chakra-ui/icons'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import _ from 'lodash'

import { RatIcon } from '../../components/Shared/Icons'
import LocationsForm from './LocationsForm'
import ConfirmModal from '../Shared/ConfirmModal'
import { useMutateRegistry, useRegistry } from '../../hooks/rodentsRegistry'
import {
  AiFillLeftCircle as ArrowLeftIcon,
  AiFillRightCircle as ArrowRightIcon
} from 'react-icons/ai'
import usePaginateTable from '../../hooks/usePaginateTable'

const frecuencies = [
  { name: 'Semanal', id: 1 },
  { name: 'Quincenal', id: 2 },
  { name: 'Mensual', id: 3 },
  { name: 'Bimensual', id: 4 }
]

const validationSchema = Yup.object().shape({
  frequency: Yup.string().required('Ingrese frecuencia'),
  rodentsLocations: Yup.array().of(
    Yup.object().shape({
      location_name: Yup.string(),
      type: Yup.string(),
      stations: Yup.number()
    })
  ),
  locations: Yup.number().required('Ingrese ubicaciones')
})

// Formulario registro de roedores
const RodentRegistry = () => {
  const toast = useToast()
  const { registryId } = useParams()
  const history = useHistory()

  const {
    data: registry,
    isFetching,
    isLoading: loadingGet,
    error
  } = useRegistry(registryId)
  const [locationExtensions, setLocationExtensions] = useState([])

  const {
    page,
    limitPerPage,
    maxPage,
    onNextPage,
    onPrevPage,
    reset: resetPagination
  } = usePaginateTable({
    totalItems: locationExtensions?.length || 0,
    limitPerPage: 5
  })

  const [confirm, setConfirm] = useState(false)
  const { client, branch } = useSelector((state) => state.visits)
  const {
    mutate,
    isLoading: loadingUpdate,
    reset
  } = useMutateRegistry('UPDATE')
  const {
    isOpen: openConfirm,
    onOpen: onOpenConfirm,
    onClose: closeConfirm
  } = useDisclosure()

  const formik = useFormik({
    validationSchema,
    enableReinitialize: true,
    initialValues: {
      frequency: '',
      locations: 0,
      rodentsLocations: [],
      finalObservations: ''
    },
    onSubmit: (values) => {
      const formData = {
        id: registry.id,
        frequency: values.frequency,
        locations: values.locations,
        final_observations: values.finalObservations,
        rodents_locations: locationExtensions
      }

      mutate(formData, {
        onSuccess: () => {
          reset()
          formik.resetForm()
          setConfirm(true)
        },
        onError: (err) => {
          reset()
          console.log(err)
          if (err.request.response.includes('ERROR_SAVING_RODENT_REGISTRY')) {
            toast({
              title: `Error al guardar datos`,
              status: 'error',
              isClosable: true
            })
          } else if (err.request.response.includes('ERROR_GENERATING_PDF')) {
            toast({
              title: `Error al crear PDF, datos guardados sin problemas`,
              status: 'error',
              isClosable: true
            })
          } else {
            toast({
              title: `Error indefinido`,
              status: 'error',
              isClosable: true
            })
          }
        }
      })
    }
  })

  //Maneja array de ubicaciones
  useEffect(() => {
    //Si no hay ubicaciones las crea
    if (formik.values.locations > 0) {
      if (locationExtensions.length === 0) {
        const arr = Array.from({ length: formik.values.locations }, (v) => {
          return {
            location_name: '',
            type: '1',
            stations: 0,
            stations_data: []
          }
        })
        setLocationExtensions(arr)
        formik.setFieldValue('rodentsLocations', arr)
        //Si ya exiten ubicaciones calcula la diferencia
      } else {
        let dif = formik.values.locations - locationExtensions.length
        //Si se agregan más crea nuevas ubicaciones
        if (dif > 0) {
          const newArr = Array.from({ length: dif }, (v) => {
            return {
              location_name: '',
              type: '1',
              stations: 0,
              stations_data: []
            }
          })
          const otherArr = [...locationExtensions]
          setLocationExtensions(otherArr.concat(newArr))
          formik.setFieldValue('rodentsLocations', otherArr.concat(newArr))
          //Si se quitan elimina las de la derecha
        } else {
          let otherArr = [...locationExtensions]
          dif = Math.abs(dif)
          const newArr = _.dropRight(otherArr, dif)
          setLocationExtensions(newArr)
          formik.setFieldValue('rodentsLocations', newArr)
        }
      }
    }
  }, [formik.values.locations])

  const handleBack = () => {
    history.push(`/visitas/${registry.id_visit}`)
  }

  //Modifica un atributo de un elemento de la lista de ubicaciones
  const handleChange = (event, position) => {
    const { name, value } = event.target
    console.log(name)
    console.log(value)
    const newArray = [...locationExtensions]
    newArray[position] = {
      ...newArray[position],
      [name]: value
    }
    setLocationExtensions(newArray)
    formik.setFieldValue('rodentsLocations', newArray)
  }

  //Aumenta el contador de estaciones al usar el botón +
  const addStation = (position) => {
    const newArray = [...locationExtensions]
    newArray[position] = {
      ...newArray[position],
      stations: parseInt(newArray[position].stations, 10) + 1
    }
    setLocationExtensions(newArray)
    formik.setFieldValue('rodentsLocations', newArray)
  }

  //Agrega nueva estación al usar el botón +
  const addStationList = (position, stationsList) => {
    const newArray = [...locationExtensions]
    newArray[position] = {
      ...newArray[position],
      stations_data: stationsList
    }
    setLocationExtensions(newArray)
    formik.setFieldValue('rodentsLocations', newArray)
  }

  //Cambia un atributo de un elemento de la lista de estaciones
  const handleChangeStation = (event, position1, position2) => {
    const { name, value } = event.target
    const newArray = _.cloneDeep(locationExtensions)
    newArray[position1].stations_data[position2] = {
      ...newArray[position1].stations_data[position2],
      [name]: value
    }
    setLocationExtensions(newArray)
    formik.setFieldValue('rodentsLocations', newArray)
  }

  //Actualiza la lista de checklist
  const handleChangeStationChecks = (checks, position1, position2) => {
    if (locationExtensions) {
      const newArray = _.cloneDeep(locationExtensions)
      newArray[position1].stations_data[position2] = {
        ...newArray[position1].stations_data[position2],
        checks: checks
      }
      setLocationExtensions(newArray)
      formik.setFieldValue('rodentsLocations', newArray)
    }
  }

  //Borra una estación de la lista
  const handleDeleteStation = (position1, position2) => {
    const newArray = _.cloneDeep(locationExtensions)
    newArray[position1].stations_data.splice(position2, 1)
    newArray[position1] = {
      ...newArray[position1],
      stations: parseInt(newArray[position1].stations, 10) - 1
    }
    setLocationExtensions(newArray)
    formik.setFieldValue('rodentsLocations', newArray)
  }

  const handleClickConfirm = () => {
    onOpenConfirm()
  }

  //Guarda en estado la lista de ubicaciones
  useEffect(() => {
    if (registry) {
      setLocationExtensions(registry?.rodents_locations)

      formik.setValues({
        id: registry?.id || -1,
        frequency: registry?.frequency || '',
        locations: registry?.locations || 0,
        rodentsLocations: registry?.rodents_locations || [],
        finalObservations: registry?.final_observations || ''
      })
    }
  }, [registry])

  return (
    <Stack spacing={8} py={12} px={6}>
      <Flex spacing={10} align="right" pt="15px">
        <Button
          onClick={handleBack}
          bg="#FFFFFF"
          color="#666587"
          border="1px"
          borderStyle="solid"
          borderRadius="lg"
          borderColor="#F2F1FB"
          leftIcon={<ArrowBackIcon />}
        >
          Documentos de visita
        </Button>
      </Flex>
      {registry ? (
        <>
          <Box w="100%" bg="#F2F1FB80" pb={5}>
            <Flex spacing={10} align="left" pt="15px">
              <Stack spacing={2} pl={30}>
                <Text fontSize="10px" color="#36355F">
                  Cliente
                </Text>
                <Text fontSize="17px" fontWeight="bold" color="#36355F">
                  {client}
                </Text>
              </Stack>
              <Stack spacing={2} pl={30}>
                <Text fontSize="10px" color="#36355F">
                  Sucursal
                </Text>
                <Text fontSize="17px" fontWeight="bold" color="#36355F">
                  {branch}
                </Text>
              </Stack>
            </Flex>
          </Box>
          <Box w="100%" bg="#F2F1FB" pb={5}>
            <Flex spacing={10} align="left" pt="15px">
              <Stack spacing={2} pl={30}>
                <Text fontSize="15px" color="#36355F">
                  <Icon mr="4" fontSize="22" as={RatIcon} color="#36355F" />
                  Registro estación control de roedores
                </Text>
              </Stack>
              <Spacer />
              <Stack spacing={2} pr={30}>
                <Text fontSize="10px" color="#36355F">
                  Serial {registry.id}
                </Text>
              </Stack>
            </Flex>
          </Box>
          <FormControl
            id="frequency"
            isInvalid={
              formik.touched.frequency && Boolean(formik.errors.frequency)
            }
          >
            <FormLabel>Frecuencia</FormLabel>
            <Select
              placeholder="Seleccione"
              name="frequency"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.frequency}
            >
              {frecuencies.map((period) => (
                <option value={period.id} key={period.id}>
                  {period.name}
                </option>
              ))}
            </Select>
            <FormErrorMessage>
              {formik.touched.frequency && formik.errors.frequency}
            </FormErrorMessage>
          </FormControl>
          {/* <Flex justifyContent="flex-end">
            <Button
              colorScheme="teal"
              size="sm"
              isDisabled={registry.client?.locations.length === 0}
              onClick={() =>
                formik.setFieldValue(
                  'locations',
                  registry.client?.locations.length
                )
              }
            >
              Refrescar ubicaciones del cliente
            </Button>
          </Flex> */}
          <FormControl
            id="locations"
            isInvalid={
              formik.touched.locations && Boolean(formik.errors.locations)
            }
          >
            <FormLabel>Cantidad de ubicaciones</FormLabel>
            <Input
              name="locations"
              value={formik.values.locations}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Cantidad de ubicaciones"
            />
            <FormErrorMessage>
              {formik.touched.locations && formik.errors.locations}
            </FormErrorMessage>
          </FormControl>
          {locationExtensions
            ?.slice(page * limitPerPage, limitPerPage * (page + 1))
            ?.map((location, index) => (
              <LocationsForm
                key={index}
                index={page * limitPerPage + index}
                locationsList={registry.client?.locations || []}
                values={location}
                handleChange={(event) =>
                  handleChange(event, page * limitPerPage + index)
                }
                addStation={() => addStation(page * limitPerPage + index)}
                handleChangeStation={handleChangeStation}
                handleChangeStationChecks={handleChangeStationChecks}
                addStationList={addStationList}
                handleDeleteStation={handleDeleteStation}
              />
            ))}
          {/* --- */}
          <Box textAlign="center">
            <Text>Paginación ubicaciones</Text>
            <Flex justify="center" alignItems="center" gap={2} py={2}>
              <IconButton
                icon={<Icon fontSize="22" as={ArrowLeftIcon} />}
                onClick={() => onPrevPage()}
                disabled={page === 0}
              />
              <IconButton
                icon={<Icon fontSize="22" as={ArrowRightIcon} />}
                onClick={() => onNextPage()}
                disabled={page === maxPage - 1}
              />
            </Flex>
          </Box>
          {/* --- */}
          <FormControl
            id="finalObservations"
            isInvalid={
              formik.touched.finalObservations &&
              Boolean(formik.errors.finalObservations)
            }
          >
            <FormLabel>Observaciones Finales</FormLabel>
            <Textarea
              name="finalObservations"
              value={formik.values.finalObservations}
              onChange={formik.handleChange}
              placeholder="Observaciones Finales"
              h="20%"
            />
            <FormErrorMessage>
              {formik.touched.finalObservations &&
                formik.errors.finalObservations}
            </FormErrorMessage>
          </FormControl>
          <Stack spacing={10} align={'center'}>
            <Button
              align="center"
              isLoading={loadingUpdate}
              onClick={handleClickConfirm}
              bg="#FFFFFF"
              color="#59BABA"
              border="1px"
              borderColor="#59BABA"
              disabled={loadingUpdate || !formik.isValid}
            >
              Guardar documento
            </Button>
            {openConfirm && (
              <ConfirmModal
                isOpen={openConfirm}
                onClose={closeConfirm}
                route={`/visitas/${registry.id_visit}`}
                confirm={confirm}
                setConfirm={setConfirm}
                handleSubmit={formik.handleSubmit}
                loading={loadingUpdate}
              />
            )}
          </Stack>
        </>
      ) : (
        <Stack>
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
        </Stack>
      )}
    </Stack>
  )
}

export default RodentRegistry
