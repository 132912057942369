import { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { Text, Box, SimpleGrid, Image, useToast } from '@chakra-ui/react'
import * as d3 from 'd3'

import addFileIcon from '../../assets/images/addFile.svg'
import { useMutateMassive } from '../../hooks/clients'

// Subida de archivos para carga masiva
const UploadFile = () => {
  const inputRef = useRef()
  const toast = useToast()
  const [file, setFile] = useState(null)
  const { user } = useSelector((state) => state.auth)
  const { mutate, isLoading, reset } = useMutateMassive()

  // Realiza la lectura del archivo csv y envia un json con los datos
  useEffect(() => {
    if (file) {
      const reader = new FileReader()
      reader.onload = function (e) {
        const text = e.target.result
        const data = d3.csvParse(text)
        const formData = { clients: data, created_by: user.id }
        toast({
          title: `Proceso de creación en progreso, puede abandonar la página`,
          status: 'info',
          isClosable: true
        })
        mutate(formData, {
          onSuccess: () => {
            reset()
            // toast({
            //   title: `Usuario creado`,
            //   status: 'success',
            //   isClosable: true
            // })
          },
          onError: (err) => {
            reset()
            console.log(err)
            // toast({
            //   title: `Error al crear usuario`,
            //   status: 'error',
            //   isClosable: true
            // })
          }
        })
      }
      reader.readAsText(file)
      // const asd = csv(file).then((d) => {
      //   console.log(d)
      // })
    }
  }, [file])

  // Limita a 1 la cantidad de archivos
  const handleSetFile = (event) => {
    event.preventDefault()
    const { files } = event.target
    if (Array.from(files).length > 1) {
      alert('Puedes subir 1 archivo a la vez')
      return
    }
    setFile(files[0])
  }

  return (
    <Box
      as="button"
      // h="50px"
      w="90%"
      bg="#FFFFFF"
      border="2px"
      borderStyle="dashed"
      borderRadius="lg"
      borderColor="#59BABA"
      alignItems="center"
      justifyContent="center"
      // key={data.id}
      display="flex"
      onClick={() => inputRef.current.click()}
    >
      <input
        ref={inputRef}
        style={{ margin: 'auto', display: 'none' }}
        type="file"
        accept=".csv"
        onChange={(event) => handleSetFile(event)}
      ></input>
      <SimpleGrid columns={1} spacing={10} py={3}>
        <Image src={addFileIcon} m="auto" />
        <Text fontSize="15px" fontWeight="bold" color="#59BABA" m="auto">
          Subir un archivo
        </Text>
        <Text fontSize="15px" color="#BABACB" m="auto">
          Formato Permitido: csv
        </Text>
        <Text fontSize="15px" color="#BABACB" m="auto">
          Peso: 300 mb max
        </Text>
      </SimpleGrid>
    </Box>
  )
}

export default UploadFile
