import axios from '../Axios'

export const getRegistries = async (page = 1, size = 10) => {
  const { data } = await axios.get(
    `rodents-registry/all?page=${page}&size=${size}`
  )
  return data
}

export const getOneRegistry = async (RegistryId) => {
  const { data } = await axios.get(
    `rodents-registry/get-one?id_registry=${RegistryId}`
  )
  return data
}

export const createRegistry = async (formData) => {
  const { data } = await axios.post(`rodents-registry/`, formData)
  return data
}

export const updateRegistry = async (formData) => {
  const { data } = await axios.patch(`rodents-registry/`, formData)
  return data
}

export const deleteRegistry = async (RegistryId) => {
  const { data } = await axios.delete(`rodents-registry/?id=${RegistryId}`)
  return data
}
