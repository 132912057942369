import { useQuery, useMutation, useQueryClient } from 'react-query'
import {
  getChecklists,
  getOneChecklist,
  createChecklist,
  updateChecklist,
  deleteChecklist,
  getVehicle
} from '../api/checkVehices'

const getAction = (type) => {
  if (type === 'CREATE') return createChecklist
  if (type === 'UPDATE') return updateChecklist
  if (type === 'DELETE') return deleteChecklist
  return null
}

export function useChecklistsVehicles(page, formData, props = {}) {
  return useQuery(
    ['checkVehicles', page],
    () => getChecklists(page, formData),
    { enabled: false },
    {
      ...props,
      onError: (err) => {
        console.log(err.response)
      }
    }
  )
}

export function useChecklist(checkId) {
  return useQuery(['checkVehicle', checkId], () => getOneChecklist(checkId), {
    onError: (err) => {
      console.log(err.response)
    }
  })
}

export function useMutateChecklist(type) {
  const queryClient = useQueryClient()
  const actionFn = getAction(type)
  return useMutation(actionFn, {
    onSuccess: () => {
      queryClient.invalidateQueries('checkVehicles')
    },
    onError: (err) => {
      console.log(err.response)
    }
  })
}

export function useVehicle(userId) {
  return useQuery(['vehicle', userId], () => getVehicle(userId), {
    onError: (err) => {
      console.log(err.response)
    }
  })
}
