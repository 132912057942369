import { useState, useEffect } from 'react'
import {
  Flex,
  Stack,
  VStack,
  HStack,
  Box,
  Button,
  Icon,
  Spacer,
  Text,
  Textarea,
  Checkbox,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Grid,
  useDisclosure,
  Select
} from '@chakra-ui/react'
import _ from 'lodash'

import TUVStations from './TUVStations'

// Formulario de area de TUV
const AreaForm = ({
  index,
  values,
  locationsList,
  handleChange,
  addStation,
  handleChangeStation,
  addStationList,
  handleChangeStationBools,
  handleDeleteStation,
  handleDeleteArea
}) => {
  const [locationId, setLocationId] = useState(0)
  const [stationExtensions, setStationExtensions] = useState([])

  // Modifica la lista de estaciones de acuerdo a la cantidad indicada
  useEffect(() => {
    if (values.stations > 0) {
      if (stationExtensions.length === 0) {
        const arr = Array.from({ length: values.stations }, (v) => {
          return {
            stationName: '',
            observations: '',
            flys: 0,
            minorFlys: 0,
            mosquitoes: 0,
            moths: 0,
            wasps: 0,
            bees: 0,
            otherName: '',
            amount: 0,
            monitored: false,
            functional: false,
            working: false,
            plate: false,
            other: false
          }
        })
        setStationExtensions(arr)
      } else {
        let dif = values.stations - values.tuv_stations.length
        if (dif > 0) {
          const newArr = Array.from({ length: dif }, (v) => {
            return {
              stationName: '',
              observations: '',
              flys: 0,
              minorFlys: 0,
              mosquitoes: 0,
              moths: 0,
              wasps: 0,
              bees: 0,
              otherName: '',
              amount: 0,
              monitored: false,
              functional: false,
              working: false,
              plate: false,
              other: false
            }
          })
          const otherArr = [...values.tuv_stations]
          setStationExtensions(otherArr.concat(newArr))
        } else {
          let otherArr = [...values.tuv_stations]
          dif = Math.abs(dif)
          const newArr = _.dropRight(otherArr, dif)
          setStationExtensions(newArr)
        }
      }
    }
  }, [values.stations])

  // Se guarda la lista de estaciones en el estado
  useEffect(() => {
    if (values.tuv_stations !== stationExtensions) {
      setStationExtensions(values.tuv_stations)
    }
  }, [])

  // Se agrega la nueva lista de estaciones del área
  useEffect(() => {
    addStationList(index, stationExtensions)
  }, [stationExtensions])

  const selectLocation = (id) => {
    setLocationId(id)
  }

  return (
    <>
      <Box w="100%" bg="#D1F1F5" pb={5} borderRadius="20px">
        <Flex spacing={10} align="left" pt="15px">
          <HStack
            spacing={2}
            pl={30}
            pr={30}
            width="100%"
            justify="space-between"
          >
            <Text fontSize="17px" fontWeight="bold" color="#36355F">
              Área {index + 1}
            </Text>
            <Button colorScheme="red" onClick={handleDeleteArea}>
              Eliminar area
            </Button>
          </HStack>
        </Flex>
      </Box>
      <FormControl id="area_name" isInvalid={values.area_name === ''}>
        {/* <FormLabel>Nombre del área</FormLabel>
        <Select
          placeholder="Seleccione"
          name="area_name"
          onChange={handleChange}
          value={values.area_name}
        >
          {locationsList.map((location) => (
            <option key={location.id} value={location.name}>
              {location.name}
            </option>
          ))}
        </Select> */}
        <Input
          name="area_name"
          value={values.area_name}
          onChange={handleChange}
          placeholder="Nombre de la ubicación"
        />
        <FormErrorMessage>Ingrese nombre</FormErrorMessage>
      </FormControl>
      <FormControl id="stations" isInvalid={values.stations < 0}>
        <FormLabel>Cantidad de estaciones</FormLabel>
        <Input
          name="stations"
          value={values.stations}
          onChange={handleChange}
          placeholder="Cantidad de estaciones"
          type={'number'}
        />
        <FormErrorMessage>
          {values.stations < 0 && 'N° de estaciones debe ser mayor a 0'}
        </FormErrorMessage>
      </FormControl>
      <HStack
        spacing="24px"
        overflowX={'scroll'}
        css={{
          '&::-webkit-scrollbar': {
            width: '4px'
          },
          '&::-webkit-scrollbar-track': {
            width: '6px'
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#59BABA',
            borderRadius: '15px'
          }
        }}
      >
        {values.tuv_stations.map((station, index) => (
          <Box
            as="button"
            px={4}
            py={2}
            bg={locationId === index ? '#59BABA' : '#F2F1FB'}
            borderRadius="lg"
            key={index}
            display="flex"
            onClick={() => selectLocation(index)}
          >
            <Text
              fontSize="15px"
              fontWeight={'bold'}
              color={locationId === index ? '#FFFFFF' : '#59BABA'}
              m="auto"
            >
              {index + 1}
            </Text>
          </Box>
        ))}
        <Box
          as="button"
          px={4}
          py={2}
          bg="#F2F1FB"
          border="1px"
          borderRadius="lg"
          borderStyle="dashed"
          borderColor="#59BABA"
          display="flex"
          onClick={() => addStation()}
        >
          <Text fontSize="15px" fontWeight={'bold'} color="#59BABA" m="auto">
            +
          </Text>
        </Box>
      </HStack>
      {values.tuv_stations.map((station, ind) =>
        locationId === ind ? (
          <TUVStations
            values={station}
            type={values.type}
            key={ind}
            handleChangeStation={(event) =>
              handleChangeStation(event, index, locationId)
            }
            handleChangeStationBools={handleChangeStationBools}
            handleDeleteStation={handleDeleteStation}
            index1={index}
            index2={ind}
          />
        ) : null
      )}
    </>
  )
}

export default AreaForm
