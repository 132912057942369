import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  filterList: {
    page: 1,
    search: '',
    service: ''
  }
}

export const productsSlice = createSlice({
  name: 'products',
  initialState: initialState,
  reducers: {
    updateFilterList: (state, action) => {
      state.filterList = {
        ...state.filterList,
        ...action.payload
      }
    },
    resetClient: (state) => {
      state.filterList.page = initialState.filterList.page
      state.filterList.search = initialState.filterList.search
      state.filterList.service = initialState.filterList.service
    }
  }
})
export const { updateFilterList, resetClient } = productsSlice.actions
/* --- */
export default productsSlice.reducer
/* --- */
