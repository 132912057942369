import axios from '../Axios'

export const forgetPassword = async (values) => {
  const { data } = await axios.post(
    `/auth/forgotpassword?email=${values.email}`
  )
  return data
}

export const recoverPassword = async (values) => {
  const { data } = await axios.post(
    `/auth/recoverpassword?recover_token=${values.token}&new_pass=${values.password1}`
  )
  return data
}

export const changePassword = async (values) => {
  const { data } = await axios.patch(`/auth/changepassword`, values)
  return data
}
