import { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  Flex,
  Stack,
  HStack,
  Heading,
  Button,
  useToast,
  Skeleton,
  Grid
} from '@chakra-ui/react'
import { AddIcon } from '@chakra-ui/icons'

import formatDate from '../../utils/formatDate'
import { useVisits } from '../../hooks/clients'
import VisitsTable from '../../components/Visits/VisitsTable'
import Pagination from '../../components/Shared/Pagination'

// Listado de visitas de un cliente en específico
const Visits = () => {
  const { clientId } = useParams()
  const history = useHistory()
  const toast = useToast()
  const [page, setPage] = useState(1)
  const {
    data: visits,
    isLoading,
    isFetching,
    error
  } = useVisits(clientId, page)

  useEffect(() => {
    if (error) {
      toast({
        title: `Error al cargar visitas`,
        status: 'error',
        isClosable: true
      })
    }
  }, [error])

  const handleClick = () => {
    history.push(`/visitas/nuevo`)
  }

  return (
    <Stack spacing={8} px={6}>
      {visits ? (
        <>
          <VisitsTable data={visits.items} />
          <Grid m="auto" pt="2%" alignSelf={'center'}>
            <Pagination
              totalPages={Math.ceil(visits.total / 10)}
              actualPage={visits.page}
              setPage={setPage}
            />
          </Grid>
        </>
      ) : (
        <Stack>
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
        </Stack>
      )}
    </Stack>
  )
}

export default Visits
