import {
  HStack,
  Box,
  Text,
  FormControl,
  Switch,
  FormLabel,
  Input,
  FormHelperText
} from '@chakra-ui/react'
import { CalendarIcon } from '../../components/Shared/Icons'

// Módulo de check para documentos de vehículos
const CheckDocsVehicle = ({
  title,
  switchName,
  monitored,
  setMonitored,
  touched,
  errors,
  handleChange,
  value,
  dateName
}) => {
  return (
    <Box
      rounded={'lg'}
      bg={'#FFF'}
      w={['90%', '30%']}
      boxShadow={'md'}
      border={`1px solid ${touched && Boolean(errors) && 'red'}`}
    >
      <HStack justify={'space-between'} align="center" p={2}>
        <Text fontSize="14px" fontWeight="bold" color="#36355F">
          {title}
        </Text>
        <Switch
          size="md"
          colorScheme={'cyan'}
          name={switchName}
          isChecked={monitored}
          onChange={() => setMonitored(switchName, !monitored)}
        ></Switch>
      </HStack>
      <HStack justify={'space-between'} align="center" p={2}>
        <FormControl id={dateName} /* isInvalid={touched && Boolean(errors)} */>
          <FormLabel>Ingrese Fecha</FormLabel>
          <Input
            name={dateName}
            value={value}
            onChange={handleChange}
            type="date"
            Icon={<CalendarIcon />}
            disabled={!monitored}
          />
          {errors && <FormHelperText color="red.500">{errors}</FormHelperText>}
        </FormControl>
      </HStack>
    </Box>
  )
}

export default CheckDocsVehicle
