import { useQuery } from 'react-query'
import { getAllVehicles, getOneVehicle } from '../api/vehicles'

export function useAllVehicles() {
  return useQuery(['vehiclesAll'], () => getAllVehicles(), {
    onError: (err) => {
      console.log(err.response)
    }
  })
}

export function useVehicle(vehicleId) {
  return useQuery(['vehicle', vehicleId], () => getOneVehicle(vehicleId), {
    onError: (err) => {
      console.log(err.response)
    }
  })
}
