import axios from '../Axios'

export const getAllVehicles = async () => {
  const { data } = await axios.get(`vehicles/all-no-pag`)
  return data
}

export const getOneVehicle = async (vehicleId) => {
  const { data } = await axios.get(`vehicles/get-one?id_vehicle=${vehicleId}`)
  return data
}
