import { Tag, TagLabel } from '@chakra-ui/react'

// Tag para estados
export default function StatusTag({ status }) {
  const colors = (state) => {
    if (state === 'Borrador') {
      return {
        bg: '#E9E5FE',
        color: '#5241AB'
      }
    }
    if (state === 'Firmada' || state === 'Completado') {
      return {
        bg: '#E5FDCB',
        color: '#8CBB59'
      }
    }
    return {
      bg: '#FFCCC1',
      color: '#FD876F'
    }
  }
  return (
    <Tag
      size="lg"
      borderRadius="full"
      variant="outline"
      bg={colors(status).bg}
      color={colors(status).color}
      boxShadow="none"
      fontWeight="800"
      textTransform="capitalize"
    >
      <TagLabel>{status}</TagLabel>
    </Tag>
  )
}
