import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  filterList: {
    folder_name: 'reportability_xslx/'
  }
}

export const s3FileSlice = createSlice({
  name: 's3Files',
  initialState: initialState,
  reducers: {
    updateFilterList: (state, action) => {
      state.filterList = {
        ...state.filterList,
        ...action.payload
      }
    }
  }
})
export const { updateFilterList } = s3FileSlice.actions
/* --- */
export default s3FileSlice.reducer
/* --- */
