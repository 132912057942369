import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Flex,
  Stack,
  VStack,
  HStack,
  Box,
  Button,
  Icon,
  Spacer,
  Text,
  Textarea,
  Checkbox,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Grid,
  useDisclosure,
  Switch,
  SimpleGrid,
  Image
} from '@chakra-ui/react'
import Select from 'react-select'
import { VehicleIcon, ToolIcon, EPPIcon } from '../../components/Shared/Icons'
import { useAllVehicles } from '../../hooks/vehicles'

// Banner de documentos semanales
const PreVisits = ({ docs, vehiclePlate, setVehiclePlate }) => {
  const history = useHistory()

  const { data: vehicles, isLoading: loadingVehicles } = useAllVehicles()

  const handleVehicle = () => {
    history.push(`/check_vehiculo`, { plate: vehiclePlate })
  }
  const handleTools = () => {
    history.push(`/check_herramientas`)
  }
  const handleEPP = () => {
    history.push(`/epp`)
  }
  return (
    <Box rounded={'lg'} bg={'#FFF'} w={'90%'} px={2}>
      <Text fontSize="14px" fontWeight="bold" color="#36355F">
        Antes de comenzar tus visitas
      </Text>
      <Text fontSize="14px" color="#36355F">
        Debes completar los checklist de herramientas y vehiculos
      </Text>
      <HStack justify={'space-between'} alignItems="strech" p={2}>
        <Box
          minH={'7vh'}
          minW={'15vw'}
          bg={docs.vehicle ? '#FFFAF0' : '#FFF4DC'}
          borderRadius="lg"
          display="flex"
          disabled={docs.vehicle}
          pt={1}
        >
          <VStack width="100%" py={2}>
            <Text
              as="button"
              fontSize="15px"
              fontWeight="bold"
              color={docs.vehicle ? '#EEC776' : '#F2B32D'}
              m="auto"
              onClick={handleVehicle}
            >
              <Icon
                mr="4"
                fontSize="40"
                as={VehicleIcon}
                color="#FFFFFF"
                background={docs.vehicle ? '#F5B40A40' : '#F5B40A80'}
                borderRadius="full"
                p={2}
              />
              Check list de vehículos
            </Text>
            <Select
              placeholder="Seleccione patente"
              menuPortalTarget={document.querySelector('body')}
              isDisabled={loadingVehicles}
              isLoading={loadingVehicles}
              isSearchable
              value={vehicles?.find((item) => item.plate === vehiclePlate)}
              options={vehicles || []}
              getOptionLabel={(option) => option.plate}
              isOptionSelected={(option) => option.plate === vehiclePlate}
              onChange={(item) => setVehiclePlate(item.plate)}
            />
            {docs.vehicle && (
              <Text fontSize="10px" fontWeight="bold" color="#EEC776" mt="-10">
                Completo
              </Text>
            )}
          </VStack>
        </Box>
        <Box
          as="button"
          minH={'7vh'}
          minW={'15vw'}
          bg={docs.tools ? '#E9F0FA' : '#D4E4FE'}
          borderRadius="lg"
          display="flex"
          onClick={handleTools}
          disabled={docs.tools}
          pt={1}
          alignItems="center"
        >
          <VStack width="100%" py={2}>
            <Text
              fontSize="15px"
              fontWeight="bold"
              color={docs.tools ? '#7B9FFF' : '#487AFF'}
              m="auto"
            >
              <Icon
                mr="4"
                fontSize="40"
                as={ToolIcon}
                color="#FFFFFF"
                background={docs.tools ? '#7B9FFF' : '#487AFF'}
                borderRadius="full"
                p={2}
              />
              Check list herramientas
            </Text>
            {docs.tools && (
              <Text fontSize="10px" fontWeight="bold" color="#7B9FFF" mt="-10">
                Completo
              </Text>
            )}
          </VStack>
        </Box>
        <Box
          as="button"
          minH={'7vh'}
          minW={'15vw'}
          bg={docs.epp ? '#F9FCF6' : '#EDFAE0'}
          borderRadius="lg"
          display="flex"
          onClick={handleEPP}
          disabled={docs.epp}
          pt={1}
          alignItems="center"
        >
          <VStack width="100%" py={2}>
            <Text
              fontSize="15px"
              fontWeight="bold"
              color={docs.epp ? '#A8B994' : '#8CBB59'}
              m="auto"
            >
              <Icon
                mr="4"
                fontSize="40"
                as={EPPIcon}
                color="#FFFFFF"
                background={docs.epp ? '#8CBB5940' : '#8CBB5980'}
                borderRadius="full"
                p={2}
              />
              Inspección EPP
            </Text>
            {docs.epp && (
              <Text fontSize="10px" fontWeight="bold" color="#A8B994" mt="-10">
                Completo
              </Text>
            )}
          </VStack>
        </Box>
      </HStack>
    </Box>
  )
}

export default PreVisits
